import React from 'react';
import {updatePageTitle} from "../../actions/breadcrumbs";
import {connect} from "react-redux";
import {getPaymentId} from "./FinanceService";
import Form from "../../ubold/commons/form";
import routes from "../../routes";
import Alert from "../partials/Alert";
import FinanceAddFundLoading from "../../components/loadings/FinanceAddFundLoading";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import PaymentIdsForm from "./components/PaymentIdsForm";
import {Link} from "react-router-dom";
import CompleteInformations from "../user/completeInformations";
import UserCheckLegal from "../user/UserCheckLegal";

class FinancePaymentId extends Form {
    constructor(props) {
        super(props);

        this.state = {
            loadingForm: true,
            paymentIds: [],
            credits: [],
            modalPaymentIds: false,
            wantToUpdatePaymentIds: false,

        };


        this.props.dispatch(updatePageTitle(
            'پرداخت ساتنا و شبا (مبالغ بالای 25 میلیون تومان)',
            [
                {
                    'title': 'کیف پول من',
                    'link': routes.finance
                },
                {
                    'title': 'پرداخت شناسه دار',
                    'active': true
                }
            ]
        ));
    }

    toggleModalPaymentIds = () => {
        this.setState({modalPaymentIds: !this.state.modalPaymentIds});
    }

    handleSubmitAddPaymentId = () => {
        this.toggleModalPaymentIds();
        this.getPaymentId();
    }

    getPaymentId = () => {
        getPaymentId().then(res => {
            var wantToUpdatePaymentIds = false;


            if (res.paymentId && res.paymentId.ibans != null) {
                res.credits.map(cr => {
                    if (!res.paymentId.ibans.includes(cr.sheba))
                        wantToUpdatePaymentIds = true;
                })
            }

            this.setState({
                loadingForm: false,
                paymentIds: res.paymentId,
                credits: res.credits,
                wantToUpdatePaymentIds: wantToUpdatePaymentIds,

            });
        })
    }

    componentDidMount() {
        this.getPaymentId();
    }


    render() {

        var co = 0;
        this.state.credits.map((credit, index) => {
            if (credit.status === 'CONFIRMED' || credit.status === 'PENDING')
                co = co + 1;
        });

        return (

            <React.Fragment>
                <div className="mt-4">
                    {
                        this.state.loadingForm ?
                            <FinanceAddFundLoading/>
                            :
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-8 col-lg-6">
                                    {
                                        this.props.user.legal && this.props.user.completeProfile === 'no' ?
                                            <div className="card card-body">
                                                <CompleteInformations user={this.props.user}/>
                                            </div>
                                            :
                                            <>


                                                {
                                                    co === 0 ?
                                                        <Alert type={'warning'}
                                                               title={'توجه کنید'}>
                                                            {
                                                                this.props.user.legal ?
                                                                    <>

                                                                        {
                                                                            !this.props.user.branch || (this.props.user.branch && this.props.user.branch.name === '') ?

                                                                                !this.props.user.national_code?<UserCheckLegal title={'no'}/>:
                                                                                <>
                                                                                    در انتظار استعلام اطلاعت شرکت

                                                                                    <div className={'mt-3'}>
                                                                                        <button
                                                                                            className={'btn btn-outline-warning'}
                                                                                            onClick={()=>window.location=routes.finance_payment_id}>
                                                                                            تلاش مجدد</button>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    برای ایجاد شناسه باید یک یا چند
                                                                                    شماره شبا به نام شرکت <strong
                                                                                    className={'ml-1 mr-1 text-t'}>{this.props.user.branch.name}</strong> در
                                                                                    سیستم
                                                                                    ثبت کنید

                                                                                    <div className={'mt-3'}>
                                                                                        <Link
                                                                                            className={'btn btn-outline-warning'}
                                                                                            to={routes.profile_ibans}>افزودن
                                                                                            شماره شبا</Link>
                                                                                    </div>
                                                                                </>
                                                                        }


                                                                    </>
                                                                    : <>
                                                                        برای ایجاد شناسه باید یک یا چند کارت
                                                                        بانکی معتبر به نام <strong
                                                                        className={'ml-1 mr-1 text-t'}>{this.props.user.name}</strong> در
                                                                        سیستم
                                                                        ثبت کنید

                                                                        <div className={'mt-3'}>
                                                                            <Link
                                                                                className={'btn btn-outline-warning'}
                                                                                to={routes.profile_bank_credits}>ثبت
                                                                                کارت بانکی</Link>
                                                                        </div>
                                                                    </>
                                                            }


                                                        </Alert>
                                                        : ""
                                                }

                                                {
                                                    (!this.state.paymentIds && co > 0 || (this.state.paymentIds && co > 0 && this.state.paymentIds.status === 'reject')) &&
                                                    <div className='mt-5'>

                                                        <div className='card card-body'>
                                                            <h4 className={'text-center mb-3 mt-4'}>شناسه پرداخت برای
                                                                شما در
                                                                سیستم وجود ندارد</h4>
                                                            <button onClick={() => this.toggleModalPaymentIds()}
                                                                    type={"button"}
                                                                    className={'btn btn-t btn-lg d-inline-block w-auto'}> +
                                                                ایجاد شناسه واریز
                                                            </button>

                                                        </div>
                                                    </div>
                                                }

                                                {
                                                    (this.state.paymentIds && this.state.paymentIds.status !== 'reject') &&
                                                    <>
                                                        {
                                                            this.state.paymentIds.status === 'process' ?
                                                                <Alert type={'warning'} title={'در انتظار تایید  بانک'}>
                                                                    اطلاعات شما به علاوه شماره کارت های بانکی ثبت شده در
                                                                    سایت به
                                                                    بانک ارسال گردید، تا دقایقی دیگر وضعیت تایید مشخص
                                                                    خواهد شد
                                                                    <div className="mt-3">
                                                                        <div>
                                                                            <strong>حساب های ثبت شده شما در سایت
                                                                                :</strong>
                                                                        </div>
                                                                        {
                                                                            this.state.paymentIds.ibans.map(ib =>
                                                                                <div>{ib}</div>)
                                                                        }
                                                                    </div>
                                                                </Alert>
                                                                :
                                                                <div>

                                                                    <p className={'mb-2 mt-4'}>مبلغ مورد نظر را از طریق
                                                                        شناسه زیر با مراجعه به بانک و یا همراه بانک
                                                                        پرداخت
                                                                        کنید</p>


                                                                    <div className={'card card-body  mb-4'}>
                                                                        <h5 className='mb-4 '>اطلاعات حساب برای
                                                                            پرداخت </h5>
                                                                        <div
                                                                            className="d-flex justify-content-between align-items-center">
                                                                            <div>
                                                                                شماره شبا
                                                                            </div>

                                                                            <strong className={'ltr en left'}>
                                                                                {this.state.paymentIds.iban}
                                                                            </strong>
                                                                        </div>
                                                                        <div
                                                                            className="d-flex justify-content-between align-items-center">
                                                                            <div>
                                                                                شناسه پرداخت
                                                                            </div>

                                                                            <strong style={{
                                                                                fontSize: '17px',
                                                                                fontWeight: '900'
                                                                            }}
                                                                                    className={'ltr en left text-primary text-lg'}>
                                                                                {this.state.paymentIds.payment_id}
                                                                            </strong>
                                                                        </div>
                                                                        <div
                                                                            className="d-flex justify-content-between align-items-center">
                                                                            <div>
                                                                                نام دارنده حساب
                                                                            </div>

                                                                            <strong className={'ltr en left'}>
                                                                                {this.state.paymentIds.destination}
                                                                            </strong>
                                                                        </div>


                                                                    </div>

                                                                    <div className="mt-4 card  card-body ">
                                                                        <div className='pt-3'>
                                                                            <p><strong className={'text-warning'}>لطفا
                                                                                توجه
                                                                                کنید</strong></p>

                                                                            در صورتی که <strong className='text-danger'>شناسه
                                                                            پرداخت</strong> بالا را در هنگام پرداخت وارد
                                                                            نکرده باشید، <strong
                                                                            className='text-danger'>مبلغ
                                                                            به حساب ما منتقل نخواهد شد</strong>، پس در
                                                                            انجام
                                                                            عملیات دقت فرمایید‌‌. همچنین باید واریزی
                                                                            توسط
                                                                            حساب بانکی مجاز شده به کافه ارز باشد در غیر
                                                                            این
                                                                            صورت مبلغ به حساب مبدا بازمیگردد. کارمزد هر
                                                                            تراکنش واریز با شناسه، « 0.02725%» است که
                                                                            توسط بانک بابت انتقال وجه شما کسر میشود برای
                                                                            مثال درصورت واریز مبلغ 50 میلیون تومان مبلغ
                                                                            13625 تومان بابت کارمزد توسط بانک کسر میشود.
                                                                            پس از واریز وجه افزایش موجودی در پنل شما به
                                                                            صورت خودکار لحاظ می گردد.
                                                                        </div>

                                                                        <div className="mt-4 text-danger text-right">
                                                                            <p style={{fontSize: '12px'}}>
                                                                                توجه داشته باشید که فقط از طریق کارتهای
                                                                                مجاز
                                                                                زیر قادر به پرداخت از طریق شناسه بالا
                                                                                هستید:
                                                                            </p>

                                                                        </div>

                                                                        {
                                                                            this.state.credits.map(cr =>
                                                                                <div style={{
                                                                                    background: "rgba(1,1,1,0.05)",
                                                                                    borderRadius: '5px',
                                                                                    border: '1px solid rgba(1,1,1,0.2)'
                                                                                }}
                                                                                     className='p-2 d-flex justify-content-between align-items-center'>
                                                                                    <div>
                                                                                        {cr.bank_icon && typeof cr.bank_icon != 'object' ?
                                                                                            <div
                                                                                                className=" ">
                                                                                                <img
                                                                                                    alt={'bank icon'}
                                                                                                    src={cr.bank_icon}
                                                                                                    height="40"
                                                                                                    width="40"/>
                                                                                            </div>
                                                                                            : ''}
                                                                                    </div>
                                                                                    <div>
                                                                                        {
                                                                                            this.state.paymentIds.ibans.includes(cr.sheba) ?
                                                                                                <strong
                                                                                                    className={"badge badge-success"}>مجاز
                                                                                                    برای
                                                                                                    پرداخت</strong> :
                                                                                                <strong
                                                                                                    className={"badge badge-danger"}> غیر
                                                                                                    مجاز </strong>
                                                                                        }
                                                                                    </div>
                                                                                    <div className='ltr en left'>
                                                                                        <div
                                                                                            className={'mb-1'}>{cr.sheba}</div>
                                                                                        {cr.card_number ? (cr.card_number).match(new RegExp('.{1,4}', 'g')).join("-") : ''}
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>

                                                                    <br/>

                                                                    {
                                                                        this.state.wantToUpdatePaymentIds &&
                                                                        <div className="mt-3">
                                                                            برای تبدیل کارت های غیر مجاز به مجاز وبه روز
                                                                            رسانی شناسه
                                                                            <button
                                                                                className='btn btn-link text-primary'
                                                                                type={'button'}
                                                                                onClick={() => this.toggleModalPaymentIds()}> کلید
                                                                                کنید</button>
                                                                        </div>
                                                                    }


                                                                    <Alert type={'info'}>
                                                                        <p>پس از پرداخت مبلغ مورد نظر توسط شما ، انتقال
                                                                            وجه به حساب ما توسط سیکل پایا انجا میشود پس
                                                                            ممکن است این پروسه تا چندین سایت به طول
                                                                            بیانجامد. پس از تایید و انجام این انتقال
                                                                            مبلغ به صورت اتوماتیک به کیف پول شما افزایش
                                                                            داده خواهد شد.</p>

                                                                    </Alert>
                                                                </div>

                                                        }

                                                    </>

                                                }

                                                <Modal isOpen={this.state.modalPaymentIds}
                                                       toggle={this.toggleModalPaymentIds}
                                                       className='credit '>
                                                    <ModalHeader toggle={this.toggleModal}> ایجاد شناسه
                                                        واریز</ModalHeader>
                                                    <ModalBody>
                                                        <Alert type={'info'} title={'توجه کنید'}>
                                                            اگر نام و نام خانوادگی با کارت ملی شما مطابقت ندارد و یا
                                                            شماره همراه زیر به
                                                            نام خودتان نیست، لطفا آنها را اصلاح کنید سپس اقدام به ارسال
                                                            نمایید.
                                                            در غیر این صورت شناسه ایجاد نخواهد شد.

                                                        </Alert>


                                                        <div className="p-3">
                                                            <p>شماره شباهای زیر به لیست مجاز شناسه پرداخت افزوده می شوند
                                                                : </p>

                                                            {
                                                                this.state.credits.map(cr =>
                                                                    <div style={{
                                                                        background: "rgba(1,1,1,0.05)",
                                                                        borderRadius: '5px',
                                                                        border: '1px solid rgba(1,1,1,0.2)'
                                                                    }}
                                                                         className='p-2 d-flex justify-content-between align-items-center'>
                                                                        <div className='d-flex align-items-center'>
                                                                            {cr.bank_icon && typeof cr.bank_icon != 'object' ?
                                                                                <div
                                                                                    className=" ">
                                                                                    <img
                                                                                        alt={'bank icon'}
                                                                                        src={cr.bank_icon}
                                                                                        height="40"
                                                                                        width="40"/>
                                                                                </div>
                                                                                : ''}

                                                                            <div className="mr-2">{cr.name}</div>
                                                                        </div>


                                                                        <div className='ltr en left'>
                                                                            <div
                                                                                className={'text-warning'}>{cr.sheba}</div>
                                                                            {cr.card_number ? (cr.card_number).match(new RegExp('.{1,4}', 'g')).join("-") : ''}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>


                                                        <div className="mt-4">
                                                            <PaymentIdsForm
                                                                callback={() => this.handleSubmitAddPaymentId()}/>
                                                        </div>


                                                    </ModalBody>
                                                </Modal>
                                            </>

                                    }
                                </div>
                            </div>
                    }
                </div>

            </React.Fragment>
        )
    }

}

const mapStateToProps = (state) => {

    return {
        user: state.user.info,
        app: state.app,
    }
}

export default connect(mapStateToProps)(FinancePaymentId);
