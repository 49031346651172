import React from 'react';
import {updatePageTitle} from "../../actions/breadcrumbs";
import {connect} from "react-redux";
import FormLoader from "../../ubold/commons/FormLoader";
import parse from "html-react-parser";
import Form from "../../ubold/commons/form";
import routes from "../../routes";
import {getNotify} from "./NotificationsService"
import {updateUserStorage} from "../../modules/auth/authService";
import {updateUser} from "../../actions/user";
import jwtDecode from "jwt-decode";

class NotificationShow extends Form {
    constructor(props) {
        super(props);

        this.state = {
            type:this.props.match.params.type,
            id:this.props.match.params.id,
            notification: null,
            event: null,
            loadingForm: true,
        };

        this.props.dispatch(updatePageTitle(
            (this.state.type==='notification'?('اطلاعیه شماره # '+this.state.id) :'رویداد جدید '),
            [
                {
                    'title': 'اطلاعیه ها و رویداد ها',
                    'link': routes.notifications
                },
                {
                    'title': (this.state.type==='notification'?('اطلاعیه شماره # '+this.state.id):'رویداد جدید ') ,
                    'active': true
                }
            ]
        ));


    }

    componentDidMount() {
        getNotify(this.state.id,this.state.type).then(res => {
            if(this.state.type==='notification'){
                this.setState(
                    {
                        notification: res.notification,
                        loadingForm: false,
                    }
                );

                this.updateUser(res.user);
            } else {
                this.setState(
                    {
                        event: res.event,
                        loadingForm: false,
                    }
                );

                this.updateUser(res.user);
            }
        });
    }

    updateUser = (user) =>{
        updateUserStorage(user);
         user = jwtDecode(user);
        this.props.dispatch(updateUser(user))
    }

    render() {

        const {notification,event} = this.state;

        return (
            <React.Fragment>
                {
                    this.state.loadingForm ?
                        <FormLoader/>
                        :
                        <React.Fragment>
                            <div className='notification-container'>
                                {notification ?
                                    <>
                                        <div className="card card-body notify">
                                            <h2><i className='fe-bell'></i> &nbsp; {notification.title}</h2>
                                            { parse(notification.content) }

                                            {
                                                notification.file_attachs.length > 0 ?
                                                   <>
                                                       <br/>
                                                       <div className='mb-3' style={{
                                                           padding: '5px',
                                                           fontSize: ' 12px',
                                                           boxShadow: 'none'
                                                       }}>
                                                           <p><strong> فایل پیوست </strong></p>

                                                           <div className="mt-3">
                                                               <ul className='p-0 m-0'>
                                                                   {notification.file_attachs.map(file =>
                                                                       <li key={file.name}>
                                                                           <a target="_blank" rel='noopener noreferrer' href={file.url}>
                                                                               <i className='fa fa-paperclip'></i>
                                                                               {file.name}
                                                                           </a>
                                                                       </li>
                                                                   )}

                                                               </ul>

                                                           </div>
                                                       </div>
                                                   </>
                                                    : ''
                                            }
                                        </div>
                                    </>
                                    : <>
                                        <div className="card card-body notify">
                                            <h2><i className='fe-bell'></i> &nbsp; {event.data.msg}</h2>
                                            { event.data.content?parse(event.data.content):'' }

                                        </div>
                                    </>}
                            </div>
                        </React.Fragment>
                }
            </React.Fragment>


        )
    }

}

export default connect()(NotificationShow);
