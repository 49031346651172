import React, { Component } from "react";
import Table from "../../ubold/commons/table";
import {Link} from "react-router-dom";
import moment from "moment-jalaali";

class NotificationsTable extends Component {

    columns = [
        {
            path: "id",
            label: "وضعیت",
            content : noti=> (noti.is_read?<span className=' text-success'><i style={{marginRight:'-6px'}}  className='fe-check'></i><i className='fe-check'></i></span>:<span className='badge badge-danger'>مشاهده نشده</span>)

        },
        {
            path: "title",
            label: "موضوع",
        },

        {
            path: "created_at",
            label: "تاریخ ",
            content : noti=> moment(noti.created_at).locale('fa').format('jYYYY/jMM/jDD - HH:mm')
        },
        {
            path: "action",
            label: "عملیات",
            content : noti=> <Link to={"/notify/notification/"+noti.id} className='btn btn-default btn-xs'><i className='fe-eye'></i> مشاهده اطلاعیه</Link>

        },

    ];

    render() {
        const { notifications, onSort, sortColumn } = this.props;
        return (
            <React.Fragment>
                <Table
                    columns={this.columns}
                    data={notifications}
                    sortColumn={sortColumn}
                    onSort={onSort}
                />
            </React.Fragment>
        );
    }
}

export default NotificationsTable;
