import React from 'react';
import {connect} from "react-redux";
import parse from "html-react-parser";
import Form from "../../ubold/commons/form";
import {spilitNumber} from "../../services/helpers";
import {Link} from "react-router-dom";
import Status from "../partials/Status";
import routes from "../../routes";

class OrderInvoice extends Form {
    constructor(props) {
        super(props);

        this.state = {
            order: this.props.order,
            loadingForm: true,
        };

    }

    render() {

        const {order} = this.state;
        const transaction = order.invoice_transaction;

        return (
            <React.Fragment>
                <div className="card height-100">
                    <div className="card-body invoices-box">
                        <div>
                            <div className="d-flex justify-content-between mb-3">
                                <h5 className="text-primary m-0 p-0">فاکتور سفارش</h5>

                                <Status status={transaction.trans_status_text} />

                            </div>

                            {
                                order.invoice_prices.map(price =>


                                    <React.Fragment>
                                        <div key={price.id} className="invoices">
                                            <div className="item">
                                                <div className="row">
                                                    <div className="col-6">
                                                        {
                                                            price.type === 'DISCOUNT' ?
                                                                <small className="text-danger">تخفیف</small>
                                                                : ""
                                                        }
                                                        {' ' + price.title}
                                                    </div>
                                                    <div className="col-6 ltr">
                                                        {
                                                            price.is_wage_and_no_toman ?
                                                                price.p_amount + ' ' + price.currency_name
                                                                : spilitNumber(price.total) + ' تومان '
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </React.Fragment>
                                )
                            }

                            <h5 style={{    background: 'rgba(255,255,255,0.02)', padding:' 11px',   margin:0}} className="font-weight-bold mt-3"><small>هزینه
                                نهایی</small> {spilitNumber(order.total)} تومان




                            </h5>


                        </div>

                        {order.transactions?
                            <div className="table-reponsive">
                                <table style={{fontSize: '12px'}} className="table table-striped">
                                    <tr>
                                        <th>شماره تراکنش</th>
                                        <th>مبلغ</th>
                                        <th>نوع پرداخت</th>
                                        <th>وضعیت</th>
                                    </tr>
                                    {order.transactions.map(tr=>
                                        <tr key={tr.transaction_id}>
                                            <td>{tr.transaction_id}</td>
                                            <td>{spilitNumber(tr.price)} <small>تومان</small></td>
                                            <td>{tr.trans_pay_type?parse(tr.trans_pay_type):''}</td>
                                            <td>
                                                <span className={'badge badge-'+tr.trans_status_text['class']}>{tr.trans_status_text['label']}</span>
                                            </td>
                                        </tr>
                                    )}

                                </table>
                            </div>
                            :''}

                        {transaction && (transaction.status !== 'PENDING' && transaction.status !== 'PAID' && order.status !== 'CANCEL') ?
                            <div className='mt-3'>
                                <Link className="btn btn-t btn-lg btn-block"
                                      to={routes.finance_checkout+transaction.transaction_id}>
                                    <i className="fe-shopping-cart ml-2"></i>
                                    پرداخت
                                </Link>
                            </div>
                            : ""}

                    </div>
                </div>
            </React.Fragment>
        )
    }

}

export default connect()(OrderInvoice);
