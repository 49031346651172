import React from "react";

const ProtectionCode = ({ valueProtection,valueCode,errorCode,onChange,changeSchema}) => {

  return (
      <React.Fragment>

              <div className="form-group  form-control-select" style={{height:' auto !important'}}>
                  <label className="form-label ml-3" htmlFor="f1">
                      آیا میخواهید از کد حفاظتی استفاده کنید ؟
                      <span className="label-require">*</span>
                  </label>
                  &nbsp; &nbsp;
                  <label>
                      بله

                      <input onClick={()=>changeSchema('pcode','کد حفاظتی (Protection code)',true)} onChange={onChange} checked={valueProtection==='بله'} type="radio" name="hpcode"  value="بله" />
                  </label> &nbsp;
                  <label>
                      خیر
                      <input onClick={()=>changeSchema('pcode','کد حفاظتی (Protection code)')} onChange={onChange} type="radio" checked={valueProtection==='خیر' || !valueProtection}  name="hpcode"  value="خیر" />
                  </label>
                  <div>

                  </div>
                  <br/>
                  <div style={{display:(valueProtection==='بله'?'block':'none'),position:'relative'}}   id="account-info" className="">
                      <div className="form-group ">
                          <label>کد حفاظتی (Protection code) </label>
                          <input  onChange={onChange} name='pcode' value={valueCode?valueCode:''}  className='form-control english'/>
                            {errorCode && <small className="font-11 error-label">{errorCode}</small>}
                      </div>
                  </div>
              </div>
          </React.Fragment>
  );

};

export default ProtectionCode;
