import React from 'react';
import Form from "../../../../ubold/commons/form";
import PhoneForm from "./PhoneForm";
import Alert from "../../../partials/Alert";
import {Link} from "react-router-dom";
import routes from "../../../../routes";
import HeaderForm from "./HeaderForm";
import LoadingReload from "./LoadingReload";


class step5 extends Form {

    render() {
        const user = this.props.user;
        const verification = this.props.verification;

        return (
            <React.Fragment>

                <div className="row justify-content-center mt-5">
                    <div className="col-xl-6 col-lg-8 ">
                        <div className="box">
                            <HeaderForm closepopup={() => this.props.closepopup(this.props.verification.key, false)}
                                        icon={'/assets/images/sprite-ver.svg#PHONE'}
                                        title={'تایید تلفن ثابت'} k={this.props.verification.key}/>


                            <div style={{position: 'relative'}}>
                                {
                                    this.props.loadingreload ?
                                        <LoadingReload/>
                                        : ''
                                }
                                {
                                    user.phone ?
                                        <h4 className='text-center'>
                                            {user.phone}

                                            {
                                                (verification.status !== 'PENDING' && verification.status !== 'CONFIRMED') ?
                                                    <div>
                                                        <Link style={{fontSize: '11px'}} to={routes.profile_edit}>برای
                                                            ویرایش تلفن
                                                            کلیک کنید</Link>
                                                    </div>
                                                    : ""
                                            }

                                        </h4>
                                        : ""
                                }


                                <div className="mt-4">
                                    {verification.rejected_message && verification.status === 'REJECTED' ?
                                        <Alert type={'danger'} title={'دلیل عدم تایید تلفن ثابت'}>
                                            {verification.rejected_message}
                                        </Alert>
                                        : ''}

                                    <PhoneForm reloadUser={(load) => this.props.reloadUser(load)}
                                               verification={verification} user={user}/>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>


            </React.Fragment>
        )
    }

}


export default step5;