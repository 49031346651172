import React from 'react';
import {connect} from "react-redux";
import ReactTooltip from 'react-tooltip';
import loading from "../../../assets/images/loading3.svg";
import routes from "../../../routes";
import { Link} from "react-router-dom";
import {user} from "../../../reducers/user";

class Verifications extends React.Component {

    render(){
        return (
            <React.Fragment>
                {
                    this.props.verifications.length===0?
                        <img alt={'loading'} style={{
                            margin: '7px auto',
                            display: 'block',
                            'margin-left':'10px',
                        }} src={loading} height={25} width={25} />:
                        <Link to={routes.profile_verify}>
                        <ul className="user-verifications user-verificationsuser-verifications user-verifications-medium   transparent">
                            { this.props.verifications.map((verify) => (
                                (verify.key!=='EMAIL' || (verify.key==='EMAIL' && (this.props.user.level==='B' || this.props.user.level==='A')) )?
                                (verify.key==='MOBILE'|| ( verify.key==='PHONE' && this.props.user.shahkar==='confirm'))?"":
                                    <li key={verify.key}  title={verify.label+' ('+verify.status_text+') '} data-tip={verify.label+' ('+verify.status_text+') '}  className={verify.status_class}>
                                        <svg className="icon" dangerouslySetInnerHTML={{__html: '<use xlink:href="/assets/images/sprite-ver.svg#'+verify.key+'"></use>'}}/>
                                    </li>
                                    :''
                            ))}

                        </ul>
                        </Link>
                }
                <ReactTooltip />

            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.info,
    }
}

export default connect(mapStateToProps)(Verifications);

