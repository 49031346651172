import React, {Component} from "react";
import parse from "html-react-parser";
import img from "./../../assets/images/1.svg";
import {getUrlWithUserAttr, showHtmlText} from "../../services/helpers";

class TItem extends Component {

    render() {
        const {ticket} = this.props;

        return (

            <React.Fragment>
                {
                    ticket.private ? '' :
                        <div key={ticket.ticket_reply_id}  className={"comment d-flex "+(ticket.is_admin?'admin':'')} >
                            <div className="d-flex v3-flex-center">
                                <div className="mt-3">
                                        <img alt={ticket.user_info.name} width="30" height="30" className=" rounded mb-1 "
                                             src={ticket.user_info.avatar}/>
                                </div>
                            </div>
                            <div className="mr-3 " style={{flex:1}}>
                                <div className="comment-body">
                                    <div className="d-flex">
                                        <div className="name ml-3">{ticket.user_info.name}</div>
                                        <div className="date">{ticket.shamsi_created_at}</div>
                                    </div>
                                    <div dangerouslySetInnerHTML={{ __html: ticket.contentt }} />


                                    {/*{showHtmlText(ticket.contentt)}*/}

                                    {
                                        ticket.file_attachs.length > 0 ?
                                            <div className='attach' >
                                                    <ul>
                                                        {ticket.file_attachs.map(file =>
                                                            <li key={file.name}>
                                                                <a target="_blank" rel='noopener noreferrer' href={getUrlWithUserAttr(file.url,this.props.secid)}>
                                                                    <i className='fa fa-paperclip'></i>
                                                                    {file.name}
                                                                </a>
                                                            </li>
                                                        )}

                                                    </ul>
                                            </div>
                                            : ''
                                    }

                                    {/*{ticket.is_admin ?*/}
                                    {/*    <div className="b-socials mb-4">*/}
                                    {/*        <p> می توانید از طریق شبکه های اجتماعی با ما در*/}
                                    {/*            ارتباط باشید*/}
                                    {/*            :</p>*/}
                                    {/*        <ul className="socials">*/}

                                    {/*            <li><a target="_blank" rel="noopener noreferrer" href="https://fb.me/Cafearz">*/}
                                    {/*                <i className="fe-facebook"></i>*/}
                                    {/*            </a>*/}
                                    {/*            </li>*/}

                                    {/*            <li><a target="_blank" rel="noopener noreferrer"*/}
                                    {/*                   href="https://twitter.com/ArzCafe">*/}
                                    {/*                <span className="icon"><i className="fe-twitter"></i></span>*/}
                                    {/*            </a>*/}
                                    {/*            </li>*/}

                                    {/*            <li><a target="_blank" rel="noopener noreferrer"*/}
                                    {/*                   href="https://linkedin.com/in/%DA%A9%D8%A7%D9%81%D9%87-%D8%A7%D8%B1%D8%B2">*/}
                                    {/*                <span className="icon"><i className="fe-linkedin"></i></span>*/}
                                    {/*            </a>*/}
                                    {/*            </li>*/}

                                    {/*            <li><a target="_blank" rel="noopener noreferrer"*/}
                                    {/*                   href="https://www.instagram.com/cafearz_com">*/}
                                    {/*                    <span className="icon"><i*/}
                                    {/*                        className="fe-instagram"></i></span>*/}
                                    {/*            </a>*/}
                                    {/*            </li>*/}

                                    {/*            <li><a target="_blank" rel="noopener noreferrer" href="https://t.me/cafearzz">*/}
                                    {/*                <span className="icon"><i className="fe-send"></i></span>*/}
                                    {/*            </a>*/}
                                    {/*            </li>*/}
                                    {/*        </ul>*/}
                                    {/*    </div>*/}
                                    {/*    : ""}*/}

                                </div>
                            </div>
                        </div>
                }


            </React.Fragment>
        );
    }
}

export default TItem;
