import React from 'react';
import {updatePageTitle} from "../../actions/breadcrumbs";
import {connect} from "react-redux";
import Pagination from "../../ubold/commons/pagination";
import {allNotifications} from "./NotificationsService";
import NotificationsTable from "./NotificationsTable";
import FormLoader from "../../ubold/commons/FormLoader";
import jwtDecode from "jwt-decode";
import PricesLoading from "../../components/loadings/PricesLoading";

class NotificationItems extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPage: 1,
            total: 1,
            last_page: 1,
            notifications: [],
            loadingForm: true,
            sortColumn:'id'
        };

        this.props.dispatch(updatePageTitle(
            ' اطلاعیه ها',
            [
                {
                    'title': 'اطلاعیه ها',
                    'active': true
                }
            ]
        ));
    }

    componentDidMount() {
        this.getData();
    }

    getData = (no_read=false) => {
        this.setState({
            loadingForm: true
        });
        allNotifications(this.state.currentPage,'notification').then(res=>{
            let notifications=jwtDecode(res.data.notifications);

            this.setState({
                notifications: notifications.data,
                total: notifications.total,
                last_page: notifications.last_page,
                loadingForm:false
            });
        });
    }



    handlePageChange = page => {
        if (page === 'next')
            this.setState({currentPage: this.state.currentPage + 1}, () => this.getData());
        else if (page === 'prev')
            this.setState({currentPage: this.state.currentPage - 1}, () => this.getData());
        else
            this.setState({currentPage: page}, () => this.getData());
    };

    render() {

        const {last_page, currentPage,notifications,sortColumn} = this.state;

        return (
            <React.Fragment>
                {
                    this.state.loadingForm ?
                        <PricesLoading/>
                        :
                        <React.Fragment>
                            <div className="card card-body ">
                                <div className="table-box">
                                    <NotificationsTable
                                        notifications={notifications}
                                        sortColumn={sortColumn}
                                    />
                                </div>

                            </div>
                            <Pagination
                                total={last_page}
                                currentPage={currentPage}
                                onPageChange={this.handlePageChange}
                            />
                        </React.Fragment>
                }
            </React.Fragment>
        )
    }

}

export default connect ()(NotificationItems) ;