import React from 'react';
import {connect} from "react-redux";
import Form from "../../ubold/commons/form";
import FormLoader from "../../ubold/commons/FormLoader";
import {getReferralData} from "./userService";
import Pagination from "../../ubold/commons/pagination";
import DataBox from "../partials/DataBox";
import RefferalItem from "./RefferalItem";
import jwtDecode from "jwt-decode";
import RefferalListLoading from "../../components/loadings/RefferalListLoading";

class ReferralData extends Form {
    constructor(props) {
        super(props);
        this.state = {
            loadingForm: true,
            data: {},
            total: 1,
            last_page: 1,
            currentPage:1,
            sortColumn: {path: "", order: ""},
        };

    }

    componentDidMount() {
        this.getData()
    }

    getData = () =>{
        this.setState({
            loadingForm: true,
        });

        getReferralData(this.state.currentPage).then(res=>{
            const data=res.data.data;

            this.setState({
                total: res.data.pagination.total,
                last_page: res.data.pagination.last_page,
                data: (data.data?data.data:data),
                loadingForm: false,
            });
        });
    }

    handlePageChange = page => {
        if (page === 'next')
            this.setState({currentPage: this.state.currentPage + 1}, () => this.getData());
        else if (page === 'prev')
            this.setState({currentPage: this.state.currentPage - 1}, () => this.getData());
        else
            this.setState({currentPage: page}, () => this.getData());

    };

    render() {

        const {data,last_page,currentPage}=this.state;

        return (
            this.state.loadingForm ?
                <RefferalListLoading/>
                :
                <React.Fragment>
                    <h6 className="card-title text-dark">لیست پورسانت ها</h6>
                    <DataBox data={data}/>

                    {
                        (data && data.length >0 )?
                            data && data.map(d=>(
                                <div key={d.id} className='card card-body card-table'>
                                    <RefferalItem key={d}
                                                  item={d}
                                    />
                                </div>
                            )):''
                    }

                    <Pagination
                        total={last_page}
                        currentPage={currentPage}
                        onPageChange={this.handlePageChange}
                    />
                </React.Fragment>
        )
    }

}


export default connect()(ReferralData);
