import React from 'react';
import {updatePageTitle} from "../../actions/breadcrumbs";
import {connect} from "react-redux";
import FormLoader from "../../ubold/commons/FormLoader";
import Form from "../../ubold/commons/form";
import routes from "../../routes";
import {checkCryptoStatus, show} from "./CashincomeService";
import moment from "moment-jalaali";
import {formatMoney, getUrlWithUserAttr} from "../../services/helpers";
import Alert from "../partials/Alert";
import Invoice from "./parts/Invoice";
import {Link} from "react-router-dom";
import ll from "../../assets/images/loading.svg";
import {CopyToClipboard} from "react-copy-to-clipboard";
import $ from "jquery";
import CashShowLoading from "../../components/loadings/CashShowLoading";

class CashincomeShow extends Form {
    constructor(props) {
        super(props);

        this.state = {
            id:this.props.match.params.id,
            cash: null,
            loadingForm: true,
            loadingStatus: false,
            crypto_status: false,

        };


        this.props.dispatch(updatePageTitle(
            'سفارش نقد شماره # ' + this.state.id,
            [
                {
                    'title': 'لیست نقد ها',
                    'link': routes.orders+'?type=sell'
                },
                {
                    'title': 'نقد شماره # ' + this.state.id,
                    'active': true
                }
            ]
        ));


    }

    componentDidMount() {
        this.reload();
    }

    reload =()=>{
        show(this.state.id).then(res => {
            this.setState(
                {
                    cash: res.data.cash,
                    loadingForm: false,
                }
            );

            const cash=res.data.cash;

            if(cash.is_crypto && !cash.crypto_status)
                this.checkCryptoStatus(this.state.id);
        });

    }

    checkCryptoStatus=(id)=>{
        let x=setInterval(()=>{
            this.setState({
                loadingStatus:true,
            });
            checkCryptoStatus({id:id}).then(res=>{
                this.setState({
                    crypto_status : res.data.ok
                });

                if(res.data.ok){
                    this.setState({
                        loadingStatus:false,
                    });
                    clearInterval(x);

                    window.location=window.location.href
                }
            });
        },10000);
    }



    render() {
        if(this.state.copied_code){
            $(".copied_code .copied").addClass('show');
            const me=this;

            setTimeout(function () {
                me.setState({copied_code:false});
                $(".copied_code .copied").removeClass('show');
            },1000);
        }
        const {cash} = this.state;
        return (
            <React.Fragment>
                {
                    this.state.loadingForm ?
                        <CashShowLoading/>
                        :
                        <React.Fragment>

                            <div className="">
                                {
                                    cash.status==='INVOICING'?
                                        <Alert type={'warning'} title={'در انتظار صدور فاکتور'}>
                                            سفارش شما با موفقیت ثبت شده و در دست کارشناسان برای بررسی و صدور فاکتور می باشد، لطفا شکیبا باشید .
                                        </Alert>
                                        :''
                                }

                                {
                                    (cash.status==='PENDING' && !cash.is_crypto)?
                                        <Alert type={'primary'} title={'در انتظار پردازش انتقال'}>
                                            میزان ارز مورد نظر توسط شما ارسال شده است، پس از تایید انتقال ارز توسط کارشناسان درخواست شما در انتظار تسویه قرار می گیرد
                                        </Alert>
                                        :''
                                }

                                {
                                    (cash.status==='SETTLEMENT' )?
                                        <Alert type={'info'} title={'در انتظار تسویه تیم مالی'}>
                                            درخواست نقد شما با موفقیت انجام شد، تیم مالی پس از بررسی، مبلغ سفارش را به کیف پول شما در سایت افزایش موجودی می دهند.
                                        </Alert>
                                        :''
                                }

                                {
                                    (cash.status==='DONE' )?
                                        <Alert type={'success'} title={'سفارش انجام شده است'}>
                                            درخواست نقد شما با موفقیت انجام شده و مبلغ فاکتور به کیف پول شما افزایش داده شده است.
                                        </Alert>
                                        :''
                                }

                                {
                                    (cash.status==='PAYING' )?
                                        <Alert type={'info'} title={'در انتظار انتقال ارز'}>
                                            لطفا میزان ارز مورد نظر را به حساب معرفی شده توسط ما انتقال دهید و اعلام پرداخت نمایید
                                        </Alert>
                                        :''
                                }

                            </div>

                                <div className="row h-100">
                                    <div className="col-12 col-lg-6 ">
                                        <div className="card card-body cashincomes height-100 ">
                                            <div className="head d-flex mb-3 align-item-center">
                                                <img alt={cash.title} className="m-0" height="35" src={cash.cash_icon}  />
                                                <h5 className="d-inline-block m-0">
                                                    {cash.title}
                                                </h5>
                                            </div>

                                            <div className='rec d-flex justify-content-between'>
                                                <div className="title">وضعیت</div>
                                                <div className="value"> <span className={'badge badge-'+cash.status_text.class}>{cash.status_text.label}</span></div>
                                            </div>

                                            {
                                                (cash.is_crypto && cash.status==='PENDING')?
                                                    <div className='rec d-flex justify-content-between'>
                                                        <div className="title">وضعیت تراکنش در شبکه انتقال</div>
                                                        <div className="value">
                                                            {
                                                                this.state.loadingStatus? <img alt='loading' className='ml-2' src={ll} height={15} width={15} />:''
                                                            }


                                                            <span  className={'badge '+((cash.crypto_status|| this.state.crypto_status)?"badge-success":"badge-danger")}>
                                                                {
                                                                    (cash.crypto_status || this.state.crypto_status)?"دریافت شده":
                                                                        <span>
                                                                            در انتظار دریافت
                                                                            {cash.meta.confirmTimes ?
                                                                                <>
                                                                                <span className="ml-1 mr-1">
                                                                                    (تعداد کانفرم : {cash.meta.confirmTimes})
                                                                                </span>

                                                                                </>
                                                                                : ''
                                                                            }
                                                                        </span>
                                                                }
                                                            </span>


                                                        </div>
                                                    </div>

                                                    :''
                                            }


                                            <div className='rec d-flex justify-content-between'>
                                                <div className="title">تاریخ ثبت</div>
                                                <div className="value">   {moment(cash.created_at).locale('fa').format('jYYYY/jMM/jDD - HH:mm')}</div>
                                            </div>
                                            {
                                                cash.automatic_time ?
                                                    <div className='rec d-flex justify-content-between'>
                                                        <div className="title">تاریخ پرداخت </div>
                                                        <div className="value">   <div className="badge badge-success">{moment(cash.automatic_time).locale('fa').format('jYYYY/jMM/jDD - HH:mm')}</div></div>
                                                    </div>
                                                    :
                                                    (
                                                        (cash.pay_date && cash.pay_date!=='0000-00-00 00:00:00' )?
                                                            <div className='rec d-flex justify-content-between'>
                                                                <div className="title">تاریخ پرداخت </div>
                                                                <div className="value">   <div className="badge badge-success">{moment(cash.pay_date).locale('fa').format('jYYYY/jMM/jDD - HH:mm')}</div></div>
                                                            </div>
                                                            :''
                                                    )
                                            }

                                            <div className='rec d-flex justify-content-between'>
                                                <div className="title">میزان</div>
                                                <div className="value">{cash.amount}</div>
                                            </div>
                                            <div className='rec d-flex justify-content-between'>
                                                <div className="title">نوع ارز</div>
                                                <div className="value">{cash.currency_name}</div>
                                            </div>

                                            <div className='rec d-flex justify-content-between'>
                                                <div className="title">نرخ ارز</div>
                                                <div className="value"> {formatMoney(cash.currency_price)} تومان</div>
                                            </div>


                                            {
                                                cash.account_type?
                                                    <div className='rec d-flex justify-content-between'>
                                                        <div className="title">نوع حساب</div>
                                                        <div className="value">{cash.account_type==='personal'?'شخصی':'شرکتی'}</div>
                                                    </div>

                                                    :''
                                            }

                                            {
                                                cash.wallet?
                                                    <div className='rec d-flex justify-content-between'>
                                                        <div className="title">شناسه اکانت</div>
                                                        <div className="value">{cash.wallet}</div>
                                                    </div>

                                                    :''
                                            }

                                            {
                                                cash.admin_wallet?
                                                    <div style={{wordWrap: 'break-word'}} className='rec d-flex justify-content-between break-word'>
                                                        <div className="title">آدرس کیف پول واریزی</div>
                                                        <div style={{wordWrap: 'break-word',    width: '63%'}} className="value">{cash.admin_wallet}</div>
                                                    </div>

                                                    :''
                                            }

                                            {
                                                cash.tx_id?
                                                    <div style={{wordWrap: 'break-word'}} className='rec d-flex justify-content-between'>
                                                        <div className="title">شماره تراکنش (TxId)</div>
                                                        <div style={{wordWrap: 'break-word',    width: '63%'}} className="value">{cash.tx_id}</div>
                                                    </div>

                                                    :''
                                            }


                                            {
                                                cash.register_avalp_to_site?
                                                    <div className='rec d-flex justify-content-between'>
                                                        <div className="title">حساب کاربری کافه ارز در سایت مبدا ثبت شده ؟</div>
                                                        <div className="value">{cash.register_avalp_to_site?'بله':'خیر'}</div>
                                                    </div>

                                                    :''
                                            }
                                            {
                                                cash.company_address?
                                                    <div className='rec d-flex justify-content-between'>
                                                        <div className="title">آدرس سایت پرداخت کننده </div>
                                                        <div className="value">
                                                            <a href={'https://'+cash.company_address} target='_blank' rel='noreferrer nofollow'>
                                                                {cash.company_address}
                                                            </a>
                                                        </div>
                                                    </div>

                                                    :''
                                            }
                                            {
                                                cash.secure_password?
                                                    <div className='rec d-flex justify-content-between'>
                                                        <div className="title">کد امنیتی</div>
                                                        <div className="value">{cash.secure_password}</div>
                                                    </div>
                                                    :''
                                            }

                                            {
                                                cash.user_desc?
                                                    <div className='rec d-flex justify-content-between'>
                                                        <div className="title">توضیحات کاربر</div>
                                                        <div className="value">
                                                            <div dangerouslySetInnerHTML={{ __html: cash.user_desc }} />

                                                        </div>
                                                    </div>
                                                    :''
                                            }

                                            {cash.status === 'PAYING' ?
                                                <>
                                                    <div className="mt-3">
                                                        {
                                                            !cash.is_crypto?
                                                                cash.invoice_link ?
                                                                    <Alert type={'primary'}>
                                                                        برای انتقال پول از لینک پرداخت که در زیر
                                                                        آمده است استفاده کنید
                                                                    </Alert>
                                                                    :
                                                                    <Alert type={'warning'}>
                                                                        میزان ارز  را به این حساب و یا کیف
                                                                        پول  معرفی شده در پایین انتقال دهید و سپس دکمه اعلام پرداخت را فشار
                                                                        دهید.
                                                                    </Alert>


                                                                :''
                                                        }
                                                    </div>

                                                    {
                                                        cash.invoice_link?
                                                            <div className="alert alert-info text-center d-block">
                                                                <div style={{    fontSize: '20px',  color: '#1db6cf'}}>
                                                                    {cash.invoice_link}
                                                                    <CopyToClipboard text={cash.invoice_link}
                                                                                     onCopy={() => this.setState({copied_code: true})}>
                                                                        <button  title={'کپی'} className={'btn-copy copied_code'}><i className='fe-copy'></i>
                                                                            <span className="copied d-inline-block">کپی شد</span>
                                                                        </button>
                                                                    </CopyToClipboard>
                                                                </div>
                                                            </div>

                                                            :
                                                            (cash.is_crypto?

                                                                    <div className='mt-5'>

                                                                        <p>
                                                                            برای ادامه روند سفارش از طریق دکمه زیر به صفحه انتقال ارزهای دیجیتال منتقل شوید
                                                                        </p>
                                                                        <Link className='btn btn-primary btn-lg btn-block mt-2'  style={{borderRadius:'50px !important'}} to={routes.cash_crypt_pay+cash.id}>
                                                                            <i className='fe-repeat d-inline-block ml-1'></i>
                                                                            انتقال ارز به کیف پول ما
                                                                        </Link>
                                                                    </div>
                                                                    :
                                                                    <Alert type={''} title={'کیف پول'}>
                                                                        <span style={{fontSize:'20px'}}>   {cash.admin_wallet}</span>
                                                                        <CopyToClipboard text={cash.admin_wallet}
                                                                                         onCopy={() => this.setState({copied_code: true})}>
                                                                            <button  title={'کپی'} className={'btn-copy copied_code'}><i className='fe-copy'></i>
                                                                                <span className="copied d-inline-block">کپی شد</span>
                                                                            </button>
                                                                        </CopyToClipboard>
                                                                    </Alert>

                                                            )

                                                    }


                                                </>



                                                : ''
                                            }

                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <Invoice onReload={this.reload} cash={cash} />

                                    </div>
                                </div>

                            <div className="mt-3">
                                {
                                    ( cash.admin_desc || cash.file_attachs.length > 0)  ?
                                        <Alert type={'info'} title={'توضیحات ادمین'}>

                                            <div dangerouslySetInnerHTML={{ __html: cash.admin_desc }} />

                                            {
                                                cash.file_attachs.length > 0 ?
                                                    <div className='mb-3' style={{
                                                        padding: '5px',
                                                        fontSize: ' 12px',
                                                        boxShadow: 'none'
                                                    }}>
                                                        <p><strong> فایل پیوست </strong></p>

                                                        <div className="mt-3">
                                                            <ul>
                                                                {cash.file_attachs.map(file =>
                                                                    <li key={file.name}>
                                                                        <a target="_blank" rel='noopener noreferrer' href={getUrlWithUserAttr(file.url,cash.secure_id)}>
                                                                            <i className='fa fa-paperclip'></i>
                                                                            {file.name}
                                                                        </a>
                                                                    </li>
                                                                )}

                                                            </ul>

                                                        </div>
                                                    </div>
                                                    : ''
                                            }

                                        </Alert>
                                        :''
                                }
                            </div>


                        </React.Fragment>
                }
            </React.Fragment>
        )
    }

}

export default connect()(CashincomeShow);
