import React from 'react';
import Squre from "../skelete/Squre";
import SkeleteLoading from "../SkeleteLoading";
import Circle from "../skelete/Circle";

class CommentsCreateLoading extends React.Component {

    render(){

        return (
            <React.Fragment>
                <Squre height={400} />
            </React.Fragment>
        );
    }
}

export default CommentsCreateLoading;
