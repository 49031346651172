import React from 'react';

class Circle extends React.Component {

    render() {
        return (
            <div style={{height:this.props.height?(this.props.height):'60',width:this.props.width?(this.props.width):'60',borderRadius:(this.props.radius?this.props.radius:'100%')}} className={'base circle '+(this.props.class?this.props.class:'')}></div>
        )
    }
}

export default Circle;
