import React from 'react';
import {storeOrder} from "../../modules/orders/OrderService";
import FormLoader from "../../ubold/commons/FormLoader";
import {connect} from "react-redux";
import { Redirect} from "react-router-dom";
import {siteUrl} from "../../config";
import StickyBox from "react-sticky-box";
import loading from "../../assets/images/loading.svg";
import OrderForm from "./OrderForm";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import parse from "html-react-parser";
import {toast} from "react-toastify";
import Joi from "joi-browser";
import OrderFormLoading from "../../components/loadings/OrderFormLoading";

class OrdersNew extends OrderForm {


    toggleModal = () => {
        this.setState({modal: !this.state.modal});
    }

    doSubmit = async () => {
        try {

            const cl = this;
            var data = this.state.data;
            const FData = new FormData();

            Object.keys(data).forEach(item => {
                if (item !== 'attachments')
                {
                    if (cl.no_meta_filed.indexOf(item) < 0) {
                        if (this.attach_fields.indexOf(item) < 0) {
                            FData.append('meta[' + item + ']', data[item]);
                        } else
                            FData.append('attachments[' + item + ']', data[item]);
                    } else
                        FData.append(item, data[item]);
                }

            });

            if (document.getElementById('attachments') && document.getElementById('attachments').files.length > 0) {
                Array.from(document.getElementById('attachments').files).forEach(file => {
                    FData.append('attachments[]', file);
                });
            }

            storeOrder(this.state.service.id, FData).then(res => {
                this.toggle();
                toast.success(res.data.message);
                this.setState({
                    redirect: '/finance/checkout/' + res.data.transaction
                })
            }).catch(res => {
                this.toggle();
            });

        } catch (ex) {
            console.log(ex);
            this.toggle();
        }
    };

    qtyChangeCallback= ()=>{
        this.orderCalculate()
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }
        return (
            <React.Fragment>
                {
                    this.state.loadingForm ?
                        <OrderFormLoading/>
                        :
                        <React.Fragment>
                            <Modal isOpen={this.state.modal} toggle={this.toggleModal} className='credit '>
                                <ModalHeader toggle={this.toggleModal}> نکات مهم قبل از سفارش</ModalHeader>
                                <form method="POST" onSubmit={this.handleSubmit}>
                                    <ModalBody>
                                        <div className="show-info-order">
                                            {this.state.service.instruction && parse(this.state.service.instruction)}
                                        </div>
                                    </ModalBody>
                                </form>
                            </Modal>

                            <form method="POST" onSubmit={this.handleSubmit}>
                                <div className="row mt-1 card-order-new">
                                    <div className="col-md-7">
                                        <div className="card card-body">

                                            <div className="d-flex justify-content-between align-items-center hed">
                                                <h4>{this.state.service.title}</h4>
                                                <img alt={this.state.service.title} src={this.state.service.service_icon} height={60} width={60} />
                                            </div>

                                            {this.state.service.instruction?
                                                <button className="btn btn-outline-warning btn-block mt-3 mb-4" type="button"
                                                        onClick={()=>this.toggleModal()}>
                                                    <i  className="fe-file-text"></i>
                                                    مشاهده نکات مهم سفارش
                                                </button>
                                                :                                            <hr/>
                                            }

                                            {
                                                this.state.plans.length === 1 ?
                                                    this.renderInput('fk_plan_id', '', 'hidden', '', null, 0, false, this.state.plans[0].id)
                                                    : ''
                                            }

                                            {
                                                this.state.plans.length > 1 ?
                                                    this.renderSelect('fk_plan_id', 'پلن', this.state.plansOptions, this.state.data.fk_plan_id)
                                                    : ''
                                            }

                                            {
                                                this.state.currencies.length > 1 ?
                                                    this.renderSelect('fk_currency_id', 'نوع ارز', this.state.currenciesOptions, this.state.data.fk_currency_id)
                                                    : ''
                                            }
                                             {
                                                this.state.fields.map((field,index) => {
                                                    switch (field.type) {
                                                        case 'text' :
                                                        case 'file' :
                                                        case 'checkbox' :
                                                        case 'number' :
                                                        case 'email' :
                                                        case 'link' :
                                                            return this.renderInput(field.name, field.label, field.type, field.class, null, 0, false, field.value, field.placeholder, this.handleKeyUp,{desc:field.desc})
                                                        case 'account':
                                                            return this.renderAccount();
                                                        case 'select':
                                                            return this.renderSelect(field.name,field.label,field.opt)
                                                        case 'protection_code':
                                                            return this.renderProtectionCode('خیر',index)
                                                        case 'qty':
                                                            return this.renderQty(field.label,field.opt,field.default_value,false,this.state.maxGiftQty)
                                                    }

                                                    return  field;
                                                })
                                            }

                                            {
                                               !this.state.service.is_automatic && this.renderTextArea('user_description', 'توضیحات ',)
                                            }

                                            {
                                                (!this.state.service.is_automatic && !this.state.hasFormAttachment) && this.renderInput('attachments', 'فایل پیوست', 'file', '')
                                            }

                                        </div>
                                    </div>
                                    <div id='afix' className="col-md-5">
                                        <StickyBox offsetTop={80} offsetBottom={20}>
                                            <div className="card calculate-box">
                                                <div className=" card-body cal-box">
                                                    <div className="card-title mb-4">
                                                        فاکتور سفارش
                                                    </div>
                                                    <div className="form-group mt-4" id="box_info_prices">
                                                        <div  style={{
                                                            padding: '20px 0',
                                                            display: (this.state.calcLoading ? 'block' : 'none')
                                                        }}>
                                                            <img alt={'loading'} src={loading}
                                                                 style={{display: 'block', margin: '0 auto'}}
                                                                 height="40" width="40"/>
                                                            <div style={{textAlign: 'center', marginTop: '10px'}}>لطفا
                                                                صبر
                                                                کنید ...
                                                            </div>
                                                        </div>

                                                        <div className="cart-invoice" id="result">
                                                            <div style={{
                                                                display: (this.state.calcLoading ? 'none' : 'block'),
                                                                background: 'rgba(0,0,0,0.1)',
                                                                padding: '10px',
                                                                textAlign: 'center'
                                                            }}>
                                                                لطفا مقادیر را وارد کنید
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="fancy-checkbox font-11">

                                                            {
                                                                this.renderCheckbox('rules')
                                                            }
                                                            <span>
                                                                <a rel='noreferrer' href={siteUrl + 'terms'} target="_blank"> شرایط   </a>   استفاده را با دقت مطالعه نموده و قبول دارم
                                                            </span>
                                                        </label>

                                                        <div>

                                                            {
                                                                (this.state.errors && this.state.errors['rules']) &&   <div className="form-error"><small
                                                                    className="font-11 ">{this.state.errors.rules}</small>
                                                                </div>
                                                            }
                                                        </div>

                                                    </div>

                                                    {this.renderButton(<span><i className='fe-shopping-bag'></i> ثبت سفارش </span>, 'btn btn-t btn-block btn-lg',false,'',false,'panel_store_order')}

                                                </div>
                                            </div>
                                        </StickyBox>
                                    </div>
                                </div>
                            </form>
                        </React.Fragment>

                }
            </React.Fragment>
        )
    }

}

export default connect()(OrdersNew);
