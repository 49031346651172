import React from 'react';
import {connect} from "react-redux";
import Form from "../../ubold/commons/form";
import {formatMoney, spilitNumber} from "../../services/helpers";
import {Link} from "react-router-dom";
import routes from "../../routes";

class OrderItem extends Form {


    render() {

        const {order}=this.props;
        const transaction = order.invoice_transaction;

        return (
            <>
                <div className="row">
                    <div className="col-lg-7 col-sm-7 col-7">
                        <div className="row align-items-center">
                            <div className="col-xl-4 col-lg-3">
                                <div>
                                    شماره :
                                    <span className="mr-1">
                                        <Link to={routes.orders_show+order.id}>#{order.id}</Link>
                                    </span>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-5">
                                <div>
                                    <img alt={order.title} src={order.order_icon} height={20} width={20} /> &nbsp;
                                    <strong > {order.title}</strong>
                                </div>
                                <div>
                                    <div className={'mt-1'} style={{opacity:'1'}}>  {order.amount} {order.currency_name}</div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4">
                                <div style={{opacity:'1'}}>نرخ : {spilitNumber(parseFloat(order.currency_price))} <small>تومان</small></div>
                                <div>مجموع تومانی : {spilitNumber(order.total)}</div>

                            </div>


                        </div>
                    </div>
                    <div className="col-lg-5 col-sm-5 col-5">
                        <div className="row align-items-center">

                            <div className="col-xl-6 col-lg-6">
                                <div className="">
                                    تاریخ ثبت :
                                    <span
                                        className={'badge d-inline-block mr-1 ' }>{order.shamsi_created_at}</span>

                                    <div>
                                        وضعیت :
                                        <span
                                            className={'badge d-inline-block mr-1 badge-' + order.status_text.class}>{order.status_text.label}</span>

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 ">
                                <div className="d-block d-md-none">
                                    <div className="mb-3"></div>
                                </div>
                                <div>
                                    {
                                        !order.is_paid?
                                            <React.Fragment>
                                                {
                                                    (order.status==='PENDING' || order.status==='PROCESSING')?
                                                       <>
                                                           <Link to={'/finance/checkout/'+ transaction.transaction_id} className='btn btn-default btn-xs'><i className='fe-shopping-cart'></i>
                                                               پرداخت
                                                           </Link>
                                                           <Link to={order.st==='digital'?(routes.orders_crypto+'?edit='+order.id):('/orders/'+ order.id+'/edit')} className='btn btn-default btn-xs'><i className='fe-edit'></i></Link>

                                                       </>
                                                    :''
                                                }



                                            </React.Fragment>
                                            :''
                                    }

                                    <Link to={routes.orders_show+order.id} className='btn btn-default btn-xs'><i className='fe-eye'></i>

                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }

}

export default connect ()(OrderItem);
