import React from "react";
import loading from "../../assets/images/loading2.svg";

class ButtonLoading extends React.Component {

    render() {
        return (
            <React.Fragment>
                <button disabled={this.props.show} onClick={this.props.sn} className={this.props.classname?this.props.classname:'btn btn-primary'}  >
                    {(this.props.icon && !this.props.show) ?
                        <React.Fragment><i className={this.props.icon}></i> &nbsp;</React.Fragment>
                        : ''}
                    <img alt={'loading'} style={{'display': this.props.show ? 'inline-block' : 'none'}} height="20" className="ml-1"
                         src={loading}/>
                    {this.props.children}
                </button>
            </React.Fragment>
        );
    }
}

export default ButtonLoading;
