import React from 'react';
import {Link} from "react-router-dom";
import {updatePageTitle} from "../actions/breadcrumbs";
import {spilitNumber} from "../services/helpers";
import {connect} from "react-redux";
import http from "./../services/httpService";
import routes from "../routes";
import jwtDecode from "jwt-decode";
import './../assets/css/owl.carousel.css';
import OrdersListDashboard from "./orders/parts/OrdersListDashboard";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import plg from '../assets/images/plg-new.png';
import plgdark from '../assets/images/plg-new-dark.png';
import DashboardLoading from "../components/loadings/DashboardLoading";
import Slider from "../ubold/components/slider/Slider";

class Dashboard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingForm: true,
            currencies_price: [],
            userDashboard: null,
            tickets: [],
            authorizes: {},
            orders: {},
        }

        this.props.dispatch(updatePageTitle(
            'داشبورد',
            {},
        ));
    }

    componentDidMount() {
        http.post('dashboard/get/data')
            .then(response => {

                const user = jwtDecode(response.data.user);
                this.setState({
                    userDashboard: user,
                    authorizes: user.authorizes,
                    loadingForm: false
                });
            });
    }



    render() {
        const user = this.props.user;
        const userDashboard = this.state.userDashboard;

        return (
            this.state.loadingForm ?
               <DashboardLoading/>
                :
                <React.Fragment>

                    <div className="row">
                        <div className={'order-2 order-lg-1 '+(userDashboard.sliders.length>0?"col-xl-5 col-lg-12 ":"col-md-12  ")}>
                            <div style={{background: (this.props.app.dark_theme ? "#262b4e" : "#fff") + " url(" + (this.props.app.dark_theme ? plgdark : plg) + ")"}}
                                 className='card card-body dashboard-account'>
                                <div className="row align-items-center">
                                    <div className="col-12">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className='d-flex align-items-center'>
                                                <div style={{width: '50px', height: '50px'}}>
                                                    <Link className='d-flex align-items-center' to={routes.profile_verify}>
                                                        <CircularProgressbar strokeWidth={8}
                                                                             value={userDashboard.precentage_verify}
                                                                             text={`${userDashboard.precentage_verify}%`}/>
                                                    </Link>
                                                </div>
                                                <div className="mr-3">
                                                    <div className="name">
                                                        {user.info.name}
                                                    </div>
                                                    <div className="mt-1 ">
                                                        {user.info.mobile}
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <Link className='d-flex align-items-center' to={routes.profile_vip}>
                                                    <div className="level ml-2">
                                                        {user.info.level_label}
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div className="mt-1 balance">
                                            <img className='d-inline-block ml-2'
                                                 src='/assets/images/app/wallet.svg' height={20}/>
                                            <span className='d-none d-md-inline-block'>موجودی حساب : </span>
                                            <strong className={'text-t mr-2'}>{user.info.balance}
                                                <small>تومان</small></strong>
                                        </div>

                                        <div className="mt-4">
                                            <div className="btn-fast d-flex justify-content-between align-items-center">
                                                <div>ثبت کارت بانکی برای پرداخت و تسویه</div>
                                                <div>
                                                    <Link to={routes.profile_bank_credits} className='btn btn-t'>ثبت کارت بانکی</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div className="col-xl-7 col-lg-12 order-1 order-lg-2" >
                            <Slider slides={userDashboard.sliders} />
                        </div>

                    </div>

                    <div className=" dash-access">
                        <div className="row">
                            <div className="col-4 col-lg-2">
                                <Link to='/orders/services/' className="card  link">
                                    <img style={{opacity:'0'}} src={'/assets/images/app-home-icon/'+(this.props.app.dark_theme?"home-icon-app_perfect-01-dark.svg":"home-icon-app_perfect-01.svg")} />

                                    <div className='title'>
                                       همه خدمات
                                    </div>
                                </Link>
                            </div>

                            <div className="col-4 col-lg-2">
                                <Link to='/orders/crypto?type=buy' className="card card-body  link">
                                    <img  src={'/assets/images/app-home-icon/'+(this.props.app.dark_theme?"home-icon-app_crpto-01-dark.svg":"home-icon-app_crpto-01.svg")} />


                                    <div className='title'>
                                         معامله ارز دیجیتال
                                    </div>
                                </Link>
                            </div>


                            <div className="col-4 col-lg-2">
                                <Link to='/orders/services/items/1' className="card card-body  link">
                                    <img  src={'/assets/images/app-home-icon/'+(this.props.app.dark_theme?"home-icon-app_paypal-1-dark.svg":"home-icon-app_paypal-1.svg")} />

                                    <div className='title'>
                                        خدمات پی پال
                                    </div>
                                </Link>
                            </div>
                            <div className="col-4 col-lg-2">
                                <Link to='/orders/services/items/16' className="card card-body  link">
                                    <img  src={'/assets/images/app-home-icon/'+(this.props.app.dark_theme?"home-icon-app_sell-01-dark.svg":"home-icon-app_sell-01.svg")} />

                                    <div className='title'>
                                        فروش ارز به ما
                                    </div>
                                </Link>
                            </div>


                            <div className="col-4 col-lg-2">
                                <Link to='/orders/services/items/5' className="card card-body  link">
                                    <img  src={'/assets/images/app-home-icon/'+(this.props.app.dark_theme?"gift-dark.svg":"home-icon-app_gift-01.svg")} />

                                    <div className='title'>
                                        خرید گیفت کارت
                                    </div>
                                </Link>
                            </div>
                            <div className="col-4 col-lg-2">
                                <Link to='/orders/services/items/4' className="card card-body  link">
                                    <img  src={'/assets/images/app-home-icon/'+(this.props.app.dark_theme?"home-icon-app_visa-01-dark.svg":"home-icon-app_gift-01.svg")} />

                                    <div className='title'>
                                        خدمات ویزا مستر
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4 mb-3">
                        <div className="col-lg-6">
                            <div className="card card-body dashboard-rr">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="card-title">پاداش ها</div>
                                    <div className="balance">{spilitNumber(userDashboard.totalRewards)}
                                        <small className='ml-1 mr-1'>تومان</small></div>
                                </div>
                                <div className="">
                                    درصد پاداش :
                                    <strong className="d-inline-block mr-1"> {userDashboard.reward_discount} %</strong>
                                </div>
                                <div className='d-flex flex-row-reverse mt-3 go-link'>
                                    <Link to={routes.rewards}>
                                        مشاهده و مدیریت پاداش ها
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="card card-body dashboard-rr">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="card-title">پورسانت ها</div>
                                    <div className="balance">{spilitNumber(userDashboard.commission)}
                                        <small className='ml-1 mr-1'>تومان</small></div>
                                </div>
                                <div className="">
                                    درصد پورسانت :
                                    <strong className="d-inline-block mr-1">{userDashboard.commissionPrecent} %</strong>
                                </div>
                                <div className='d-flex flex-row-reverse mt-3 go-link'>
                                    <Link to={routes.referrals}>
                                        مشاهده و مدیریت پورسانت ها
                                    </Link>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="card card-body dashboard-user">
                        <OrdersListDashboard />
                    </div>


                </React.Fragment>

        )
    }

}

const mapStateToComponent = state => {
    return {
        user: state.user,
        app: state.app,
    }
}

export default connect(mapStateToComponent)(Dashboard);
