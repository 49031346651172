import {useHistory, useLocation} from "react-router-dom";
import {useEffect} from "react";
import $ from "jquery";

const LocationsHandler = (props) => {
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if(history.action==='PUSH' || history.action==='push'){
            props.sync();

            $(".sidebar .menu ul > li a").each(function () {
                var pageUrl = window.location.href.split(/[?#]/)[0];
                if (this.href === pageUrl) {
                    $(".sidebar .menu ul > li a").removeClass('active');
                    $(".sidebar .menu ul > li ").removeClass('active');

                    $(this).addClass("active");
                    $(this).parent().addClass("active"); // add active to li of the current link
                    $(this).parent().parent().prev().addClass("active"); // add active class to an anchor
                    $(this).parent().parent().parent().addClass("active"); // add active class to an anchor
                }
            });

        }


    }, [location]);

    return '';
};

export default LocationsHandler;