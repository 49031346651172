import React from 'react';

import {Link, Redirect} from "react-router-dom";
import {connect} from "react-redux";



class RegisterFinish extends React.Component {

    constructor(props) {
        super(props);

        this.state={
            redirect:false
        }

        if(localStorage.getItem('isdf'))
            this.setState({redirect:'/'})

    }


    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }

        if(localStorage.getItem('isdf'))
            return <Redirect to={'/'}/>

            return (

            <div className='row justify-content-center mt-5'>
                <div className='col-lg-5 col-md-8'>
                    <div className="card">
                        <div className="card-body d-flex justify-content-center align-items-center flex-column">
                            <svg style={{display:'block'}} width="92" height="92" viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_d_1709_11599)">
                                    <rect x="10" width="71.7431" height="71.7431" rx="20" fill="#3377E8"/>
                                    <rect x="10.25" y="0.25" width="71.2431" height="71.2431" rx="19.75" fill="#3377E8" stroke="url(#paint0_linear_1709_11599)" stroke-width="0.5"/>
                                    <path d="M45.9987 36.0001C49.6806 36.0001 52.6654 33.0153 52.6654 29.3334C52.6654 25.6515 49.6806 22.6667 45.9987 22.6667C42.3168 22.6667 39.332 25.6515 39.332 29.3334C39.332 33.0153 42.3168 36.0001 45.9987 36.0001Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M34.5469 49.3333C34.5469 44.1733 39.6802 40 46.0002 40C47.2802 40 48.5202 40.1733 49.6802 40.4933" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M59.3346 44.0001C59.3346 45.0001 59.0547 45.9467 58.5613 46.7467C58.2813 47.2267 57.9213 47.6534 57.508 48.0001C56.5746 48.8401 55.348 49.3334 54.0013 49.3334C52.0546 49.3334 50.3613 48.2934 49.4413 46.7467C48.948 45.9467 48.668 45.0001 48.668 44.0001C48.668 42.3201 49.4413 40.8134 50.668 39.8401C51.588 39.1068 52.748 38.6667 54.0013 38.6667C56.948 38.6667 59.3346 41.0534 59.3346 44.0001Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M51.9219 44L53.2419 45.32L56.0819 42.6934" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </g>
                                <defs>
                                    <filter id="filter0_d_1709_11599" x="0" y="0" width="91.7422" height="91.7432" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dy="10"/>
                                        <feGaussianBlur stdDeviation="5"/>
                                        <feComposite in2="hardAlpha" operator="out"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.0538194 0 0 0 0 0.190138 0 0 0 0 0.416667 0 0 0 0.15 0"/>
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1709_11599"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1709_11599" result="shape"/>
                                    </filter>
                                    <linearGradient id="paint0_linear_1709_11599" x1="45.8716" y1="0" x2="45.8716" y2="71.7431" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="white" stop-opacity="0.53"/>
                                        <stop offset="1" stop-color="white" stop-opacity="0.14"/>
                                    </linearGradient>
                                </defs>
                            </svg>

                            <br/>
                            <p>{this.props.user.name} ، به کافه ارز خوش آمدید</p>
                            <h3>ثبت نام با <span style={{color:"#23C162"}}>موفقیت</span> انجام شد</h3>
                            <br/>
                            <br/>
                            <br/>
                            <div className=" mt-4">

                                <Link id={'btn-go-dashboard'}  to={'/'} className={'btn btn-primary btn-got-dashboard'} >ورود به داشبورد </Link>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}



const mapStateToProps= state =>{
    return {
        user:state.user.info,
    }
}

export default connect(mapStateToProps)(RegisterFinish);
