import React from 'react';
import Form from "../../../../ubold/commons/form";
import NationalCodeUploadForm from "./NationalCodeUploadForm";
import HeaderForm from "./HeaderForm";
import LoadingReload from "./LoadingReload";

class step3 extends Form {

    render() {
        const user = this.props.user;
        const verification = this.props.verification;
        return (
            <React.Fragment>
                <div className="row justify-content-center  mt-5">
                    <div className="col-xl-8 col-lg-9 col-md-11">
                        <div className="box">
                            <HeaderForm closepopup={() => this.props.closepopup(this.props.verification.key, false)}
                                        icon={'/assets/images/sprite-ver.svg#NATIONAL_ID'} title={user.legal?'ارسال اساسنامه و آگهی تاسیس / تغییرات':'تایید کد ملی'}
                                        k={this.props.verification.key}/>
                            <div style={{position: 'relative'}}>
                                {
                                    this.props.loadingreload ?
                                        <LoadingReload/>
                                        : ''
                                }
                                <NationalCodeUploadForm verification={verification}
                                                        reloadUser={(load) => this.props.reloadUser(load)} user={user}/>

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}


export default step3;