import React from 'react';
import {Link} from "react-router-dom";
import {getUrl} from "../../../services/helpers";
import $ from "jquery";

class LinkBox extends React.Component {

    componentDidMount() {
        $(".links-box a").click(function () {
            $(".links-box a").removeClass('active');
            $(this).addClass("active");
        });

        $(".links-box a").each(function () {

            var url=$(this).attr('href');

            if(url.charAt((url.length -1)) === '/')
                url= url.substring(0, (url.length -1));

            if (url === getUrl()) {
                $(this).addClass("active");
            }
        });
    }

    render(){

        return (
            <React.Fragment>
                <div className="d-flex  align-items-stretch align-content-stretch align-self-stretch links-box mb-4">
                    {
                        this.props.links.map(link=>{
                            return  <Link className='flex-fill' key={link.url} to={link.url}>
                                {link.icon?<i className={link.icon}></i>:''}
                                <span className=' d-none d-md-inline-block'>{link.title}</span>
                            </Link>
                        })
                    }
                </div>
            </React.Fragment>
        )
    }
}

export default LinkBox ;
