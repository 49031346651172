import http from "./../../services/httpService";
const prefix='cashes/';


export function allCashincomes(currentPage,take=false) {
    return http.post( prefix+'all',{ page:currentPage,take:take });
}

export function show(id) {
    return http.post( prefix+'show/'+id+'/cash');
}

export function getCash(id,hasData=false) {
    return http.post(  prefix+ 'get/'+id+(hasData?"?has_data=1":""));
}

export function settlement(id,data) {
    return http.post(  prefix+ 'settlement/'+id,data);
}

export function checkCryptoStatus(data) {
    return http.post(  prefix+ 'check-crypto-status',data);
}

export function getCashForm(id) {
    return http.post( prefix+'form/'+id);
}

export function getTonTokensCash() {
    return fetch(  'https://api2.cafearz.com/api/client/v1/crypto/ton/tokens');
}