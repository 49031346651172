import React from 'react';

class PopUpVerify extends React.Component{

    render() {
        return (
            <>
                {this.props.show?
                    <div className='popUpVerify'>
                        <div className='container'>
                            {this.props.children}
                        </div>
                    </div>
                    :""}
            </>
        )
    }

}


export default PopUpVerify;
