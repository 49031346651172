import React from 'react';
import {connect} from "react-redux";
import {updatePageTitle} from "../../actions/breadcrumbs";
import Form from "../../ubold/commons/form";
import http from "../../services/httpService";
import Layout from "./Layout";
import UserBankCards from "./UserBankCards";
import ErrorBox from "../partials/ErrorBox";
import routes from "../../routes";
import jwtDecode from "jwt-decode";


class UserCreditAccount extends Form {
    constructor(props) {
        super(props);
        this.state = {
            loadingForm: true,
            data: {},
            user: {},
        };

        this.props.dispatch(updatePageTitle(
            'افزودن کارت بانکی',
            [
                {
                    'title': 'افزودن کارت بانکی',
                    'active': true
                }
            ]
        ));
    }

    componentDidMount() {
        http.post('/user/get-info').then(res => {
            const data= jwtDecode(res.data);

            this.setState({
                user: data.user,
                data: {
                    bank_name: data.info.bank_name,
                    bank_account_card_number: data.info.bank_account_card_number,
                    bank_account_sheba: data.info.bank_account_sheba,
                },
                loadingForm: false
            });

        });
    }


    render() {
        return (
             <UserBankCards/>
        )
    }

}


const mapStateToProps = appState => {
    return {
        user: appState.user.info
    }

};
export default connect(mapStateToProps)(UserCreditAccount);
