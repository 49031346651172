import React from 'react';
import Squre from "../skelete/Squre";
import SkeleteLoading from "../SkeleteLoading";

class UserEditLoading extends React.Component {

    render(){
        return (
            <React.Fragment>
                <SkeleteLoading>
                    <div className="row justify-content-between">
                        <div className="col-md-7"><Squre height={500}/></div>
                        <div className="col-md-4">
                            <div className="row">
                                <div className="col-6">
                                    <div className="mb-5">
                                        <Squre height={20} />
                                    </div>
                                </div>
                                <div className="col-8">
                                    <div className="mb-2">
                                        <Squre height={20} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mb-4">
                                        <Squre height={170} />
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="mb-3">
                                        <Squre height={50} />
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </SkeleteLoading>
            </React.Fragment>
        );
    }
}

export default UserEditLoading;
