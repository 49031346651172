import React, {useEffect, useState} from "react";

const UseCheckMobileScreen = (props) => {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
        console.log(window.innerWidth)

        if(window.innerWidth <=768){
            props.syncMobile();
        }

        if(window.innerWidth >=768){
            props.syncDesktop();
        }
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        window.addEventListener('onload', handleWindowSizeChange);
        handleWindowSizeChange();

        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }


    }, []);

    return (width <= 768);
}

export default UseCheckMobileScreen