const base_url = '';

const routes = {
    home: base_url + '/',
    login: base_url + '/login',
    switchLogout: base_url + '/switch/go/logout',
    switch: base_url + '/switch/:token',
    logout: base_url + '/logout',
    register: base_url + '/register',
    register_finish: base_url + '/register-finish',
    prices: base_url + '/prices',
    login_pass: base_url + '/login/password',
    two_factor_authenticator: base_url + '/tow-factor-authentication',
    forgot_password: base_url + '/forgot-password',
    reset_password: base_url + '/password/reset/:token',
    verify_email: base_url + '/user/verify/email',

    profile_edit: base_url + '/profile/edit',
    profile_verify: base_url + '/profile/verify',
    profile_change_password: base_url + '/profile/change-password',
    profile_credit_account: base_url + '/profile/credit-account',
    profile_security: base_url + '/profile/security',
    profile_bank_credits: base_url + '/profile/bank-credits',
    profile_ibans: base_url + '/profile/ibans',
    profile_vip: base_url + '/profile/vip-layers',
    profile_logins:base_url + '/profile/logins',
    finance: base_url + '/finance',
    finance_add_fund: base_url + '/finance/add-fund',
    finance_withdraw: base_url + '/finance/withdraw',
    finance_transactions: base_url + '/finance/transactions',
    finance_checkout: base_url + '/finance/checkout/',
    finance_checkout_id: base_url + '/finance/checkout/:id',
    finance_callback: base_url + '/finance/callback',
    finance_payment_id: base_url + '/finance/paymentid',


    ticket: base_url + '/tickets',
    ticket_create: base_url + '/tickets/create',
    ticket_show: base_url + '/tickets/show/',
    ticket_show_id: base_url + '/tickets/show/:id',

    orders: base_url + '/orders',
    orders_create: base_url + '/orders/create',
    orders_service: base_url + '/orders/services',
    service_items: base_url + '/orders/services/items/',
    service_items_id: base_url + '/orders/services/items/:id',
    orders_show: base_url + '/orders/',
    orders_crypto: base_url + '/orders/crypto',
    orders_new_id: base_url + '/orders/s/new/:id',
    orders_new: base_url + '/orders/s/new/',
    orders_show_id: base_url + '/orders/:id',
    orders_edit_id: base_url + '/orders/:id/edit',

    cash_list: base_url + '/cash/list/',
    cash_crypt_pay: base_url + '/cash/crypto/',
    cash_callback: base_url + '/cash/callback/',
    cash_show: base_url + '/cash/show/',
    cash_new: base_url + '/cash/new/',
    cash_crypt_pay_id: base_url + '/cash/crypto/:id',
    cash_new_id: base_url + '/cash/new/:id',
    cash_show_id: base_url + '/cash/show/:id',
    cash_pay_id: base_url + '/cash/pay/:id',
    cash_pay: base_url + '/cash/pay/',

    comments: base_url + '/comments',
    comments_create: base_url + '/comments/new',
    referrals: base_url + '/referrals',

    rewards: base_url + '/rewards',

    notifications: base_url + '/notifications',
    notification_show: base_url + '/notify/:type/:id',

}


export default routes;