import React from 'react';
import Form from "./../../../ubold/commons/form";
import Joi from "joi-browser";
import auth from "../../../modules/auth/authService";
import {Redirect, Link} from "react-router-dom";
import Recaptcha from 'react-google-invisible-recaptcha';
import {siteKey, siteUrl} from '../../../config';
import routes from "../../../routes";
import lock from "../../../assets/images/svg-icons/lock.svg";


class Password extends Form {

    constructor(props) {
        super(props);

        const search = this.props.location.search;
        const token = new URLSearchParams(search).get('token');
        const mobile = new URLSearchParams(search).get('m');

        this.state = {
            data: {password: "", 'g-recaptcha-response': "", _tk: token},
            errors: {},
            loading: false,
            redirect: false,
            token:token,
            mobile: mobile
        };

        this.schema = {
            password: Joi.string()
                .required()
                .label("رمز عبور")
        };
    }

    doSubmit = async () => {
        this.recaptcha.execute().then(res => {
            this.toSubmit(res);
        }).catch(res => {
            window.location = window.location.href;
        })
    };

    toSubmit = async (token) => {
        try {
            const {data} = this.state;
            data['g-recaptcha-response'] = token;
            this.setState({data});
            const res = await auth.loginPassword(data);

            if (!res.login) {
                this.setState({redirect: res.url});
            } else
                window.location = routes.home;

        } catch (ex) {
            this.toggle();
            this.recaptcha.reset();
        }
    }

    onResolved = () => {
        const {data} = this.state;
        data['g-recaptcha-response'] = this.recaptcha.getResponse();
        this.setState({data});
    }


    render() {
        if (auth.getCurrentUser()) return <Redirect to={routes.home}/>;

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }

        if (!this.state.token) {
            return <Redirect to={routes.home}/>
        }

        return (
            <div className='col-lg-5 col-md-6'>
                <div className="col-12 ">
                    <div className="mb-3"  >
                        <div className="img-box">
                            <img className='d-block m-auto logo'  height={200} width={200} alt={'cafearz'} src={lock} />
                            <div className="btt"></div>
                        </div>
                        <div className="auth-title-box">
                            <h1 className="auth-title">رمز عبور وارد کنید</h1>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body auth-box ">
                        <div className="auth-title-box mb-4">
                            <div className='help text-center mb-2'>لطفا رمز عبور شماره <strong className='d-inline-block ml-1 mr-1'>{this.state.mobile}</strong> را وارد کنید </div>
                            <div className='help text-center'>آیا شماره موبایل اشتباه است؟
                                <Link href='#' to={routes.login} className="text-t mr-2 text-primary">ویرایش کنید</Link>
                            </div>
                        </div>
                        <form method="POST" onSubmit={this.handleSubmit}
                              className="form-horizontal dialog-form embed">


                            <div className="form-group rtl-label ">
                                {this.renderPassword("password", "",'','','رمز عبور')}

                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-4">آیا رمز عبور
                                خود را فراموش کرده‌اید؟
                                <Link href='#' to={routes.forgot_password} className="text-t mr-2">بازیابی رمز عبور</Link>
                            </div>

                            <div className="form-group">

                                {this.renderButton("تایید و ادامه", 'btn-block btn-primary ')}

                            </div>


                            <Recaptcha
                                ref={ref => this.recaptcha = ref}
                                sitekey={siteKey}
                                onResolved={this.onResolved}/>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

}


export default Password;

