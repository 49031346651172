import React from "react";
const icon={
    textAlign: 'center',
    width: '50px',
    fontSize: '22px',
    paddingTop: '12px',
    background: 'rgb(255 255 255 / 8%)'
}
const Alert = ({type,title,children,className,icontext}) => {

    const mapIcon = {
        'warning' :  'fe-clock',
        '' :  'fe-database',
        'danger' :  'fe-alert-triangle',
        'info' :  'fe-help-circle',
        'primary' :  'fe-thumbs-up',
        'success' :  'fe-thumbs-up',
        'dark' :'fe-help-circle'
    };

    return (
        <div className={'d-flex align-items-center align-items-stretch app-alert  p-0 alert  alert-'+(type==='dark'?'warning':type)}>
            {
                type?
                    <div className='ml-1 p-3 d-flex align-items-center align-content-center bell' style={icon}>
                        {icontext?icontext:  <i className={mapIcon[type]}></i>}
                    </div>
                    :''
            }

            <div className={'p-3 content d-block w-100 '+className}>
                {title?<div className={'title-c text-'}>{title}</div>:""}
                {children}
            </div>
        </div>
    );
};

export default Alert;