import React from 'react';
import {connect} from "react-redux";
import {all} from "../../../modules/orders/OrderService";
import FormLoader from "../../../ubold/commons/FormLoader";
import DataBox from "../../partials/DataBox";
import {allCashincomes} from "../../cashincomes/CashincomeService";
import OrderItemDashboard from "./OrderItemDashboard";
import CashincomeItemDashboard from "../../cashincomes/parts/CashincomeItemDashboard";
import {Link} from "react-router-dom";
import routes from "../../../routes";
import jwtDecode from "jwt-decode";
import DashboardOrdersLoading from "../../../components/loadings/DashboardOrdersLoading";

class OrdersListDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orders:[],
            loadingForm: true,
            orderType:'buy'
        };

    }

    componentDidMount() {
        this.handleGetDataWithType(this.state.orderType);
    }

    getData= () => {
        this.setState({
            loadingForm:true
        })

        all(1,6).then(res=>{
            this.setState({
                orders:jwtDecode(res.orders),
                loadingForm:false
            })
        })
    }

    getCashData= () => {
        this.setState({
            loadingForm:true
        })

        allCashincomes(1,6).then(res=>{
            this.setState({
                orders:jwtDecode(res.data.cashes),
                loadingForm:false
            })
        })
    }


    handleGetDataWithType = (type) => {
        if(type==='buy')
            this.getData();
        else
            this.getCashData();
    }

    changeOrderType = (type) => {
        this.setState({orderType: type});
        this.handleGetDataWithType(type);
    }

    render() {

        const { orders } = this.state;

        return (
            <>
                <div className="link-box big mb-4 order">
                    <button onClick={() => this.changeOrderType('buy')}           data-value="buy"
                       className={"order-btn " + (this.state.orderType === 'buy' ? 'active' : '')}>آخرین سفارش های خرید</button>
                    <button onClick={() => this.changeOrderType('sell')}       data-value="sell"
                       className={"order-btn " + (this.state.orderType === 'sell' ? 'active' : '')}>آخرین سفارش های فروش</button>
                </div>

                {
                    this.state.loadingForm ?
                        <DashboardOrdersLoading/>
                        :
                        <div className='dashboard-rr'>


                            <DataBox data={orders}/>

                            {
                                this.state.orderType==='buy'?
                                    <>
                                    {
                                        orders.map(order=>(
                                            <div key={order.id} className='card card-body card-table'>
                                                <OrderItemDashboard
                                                    order={order}
                                                />
                                            </div>
                                        ))
                                    }


                                    {orders.length>4?
                                        <div className='go-link text-center pt-3'>
                                            <Link  to={routes.orders+'?type=buy'}><i className='fe-eye'></i> مشاهده همه سفارشات خرید</Link>

                                        </div>
                                        :""}

                                    </>
                                    :
                                    <>
                                        {
                                            orders.map(order=>(
                                                <div key={order.id} className='card card-body card-table'>
                                                    <CashincomeItemDashboard
                                                        cash={order}
                                                    />
                                                </div>
                                            ))
                                        }

                                        {orders.length>4?
                                            <div className='go-link text-center pt-3'>
                                                <Link  to={routes.orders+'?type=sell'}><i className='fe-eye'></i> مشاهده همه سفارشات فروش</Link>
                                            </div>

                                            :""}
                                    </>

                            }

                        </div>
                }
            </>


        )
    }

}

export default connect ()(OrdersListDashboard);
