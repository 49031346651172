import React, { Component } from "react";
import Table from "../../ubold/commons/table";
import moment from "moment-jalaali";
import {getNotify} from "./NotificationsService";
import {setAppRedirect} from "../../actions/app";
import {updateUserStorage} from "../auth/authService";
import jwtDecode from "jwt-decode";
import {updateUser} from "../../actions/user";
import {mapToRoute} from "../../ubold/components/layouts/parts/Notifications";
import {connect} from "react-redux";

class NotificationsTable extends Component {

    columns = [
        {
            path: "title",
            label: "رویداد",
            content : event=> event.data.msg
        },

        {
            path: "created_at",
            label: "تاریخ ",
            content : event=> moment(event.data.date).locale('fa').format('jYYYY/jMM/jDD - HH:mm')
        },
        {
            path: "action",
            label: "عملیات",
            content : event=> <button onClick={()=>this.handleEvent(event)} className='btn btn-default btn-xs'><i className='fe-eye'></i> مشاهده رویداد</button>

        },

    ];

    handleEvent= (event)=>{
        if(!event.data.content){
            getNotify(event.id,'event').then((res)=>{
                this.props.dispatch(setAppRedirect(mapToRoute(event.type,event.notifiable_id)));

                if(res.user){
                    updateUserStorage(res.user);
                    let user = jwtDecode(res.user);
                    this.props.dispatch(updateUser(user))
                }
            });
        }else{
            this.props.dispatch(setAppRedirect("/notify/event/"+event.id));
        }
    }


    render() {
        const { events, onSort, sortColumn } = this.props;
        return (
            <React.Fragment>
                <Table
                    columns={this.columns}
                    data={events}
                    sortColumn={sortColumn}
                    onSort={onSort}
                />
            </React.Fragment>
        );
    }
}

export default connect()(NotificationsTable);
