import React from 'react';
import Squre from "../skelete/Squre";
import SkeleteLoading from "../SkeleteLoading";
import Circle from "../skelete/Circle";

class TicketListLoading extends React.Component {

    render(){
        var html=[];
        for(var i=0; i<=10;i++){
            html.push(<div className='mb-2'>
                <div key={i} className="d-flex">
                    <div className="ml-3"><Circle radius={10} height={35} width={35}/></div>
                    <div className="order-1 flex-fill"><Squre height={70} /></div>
                </div>

            </div>)
        }

        return (
            <React.Fragment>
                <div className="mt-3">
                    <SkeleteLoading>
                        {html}
                    </SkeleteLoading>
                </div>
            </React.Fragment>
        );
    }
}

export default TicketListLoading;
