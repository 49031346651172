import React from 'react';
import {connect} from "react-redux";

class BankCardItem extends React.Component {

    componentDidMount() {
        console.log(this.props.card.bank_icon);

    }

    addDash(val){
        if(val)
            return  val.match(new RegExp('.{1,4}', 'g')).join("-");
    }

    render() {

        const {card}=this.props;

        var icon=card.bank_icon;

        if(typeof icon == "object" && Object.keys(icon).length===0)
            icon='';

        return (
            <>
                <div className="card card-body card-bank">
                    <div className="d-flex justify-content-between align-items-center">

                        <div className="mt-3 mb-4">
                            <div className="card_number">
                                {this.addDash(card.card_number)}
                            </div>

                            <div className="sheba">
                                {card.sheba}
                            </div>
                        </div>
                        {
                            icon &&
                            <div className="d-flex flex-row  align-items-lg-end">
                                <div className="ltr">
                                    <img alt={'bank-icon'} height="60" width="60" src={icon} />
                                </div>
                            </div>
                        }


                    </div>




                    <div className="d-flex justify-content-between">
                        <div>
                            <div className="mt-3">
                                <span
                                    className={"badge badge-" + card.status_text.class}>{card.status_text.label}</span>
                            </div>
                        </div>

                        <div className="ltr">
                            <ul className="list-inline list-inline-condensed m-0 p-0">
                                {(card.status !== 'CONFIRMED' && card.status !== 'PENDING') ?
                                    <React.Fragment>
                                        {card.is_tasvie?'':
                                            <li className="list-inline-item">
                                                <button  onClick={()=> this.props.editCredit(card.id) } className=" btn-default btn btn-default  btn-card btn-xs"
                                                ><i className="fe-edit"></i> ویرایش</button>
                                            </li>
                                        }

                                        <li className="list-inline-item">
                                            <button id={card.id} type='button' onClick={()=>this.props.deleteCredit(card.id,card.is_tasvie)}
                                                    className=" btn-default btn btn-default   btn-card  btn-xs"
                                            ><i
                                                className="fe-trash"></i> حذف
                                            </button>
                                        </li>
                                    </React.Fragment>

                                    : ''}
                            </ul>
                        </div>
                    </div>

                    {
                        card.reject_message && card.status==='REJECTED'?
                            <div className='reject_message d-block'>
                                <i style={{fontSize:'14px'}} className='fe-volume'></i>&nbsp;
                                <strong>توضیحات ادمین : </strong> &nbsp;
                                {card.reject_message}
                            </div>
                            :''
                    }

                </div>
            </>
        )
    }

}

export default connect ()(BankCardItem);
