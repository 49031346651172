import React from 'react';
import Squre from "../skelete/Squre";
import SkeleteLoading from "../SkeleteLoading";

class UserSecurityLoading extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className="mt-4">
                    <SkeleteLoading>
                        <div className="row justify-content-center pt-3 ver-list">
                            <div className="col-xl-6 col-lg-7 col-md-10">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="mb-3">
                                            <Squre height={20} />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="mb-4">
                                            <Squre height={100} />
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <div className="mb-3">
                                            <Squre height={50} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SkeleteLoading>
                </div>
            </React.Fragment>
        );
    }
}

export default UserSecurityLoading;
