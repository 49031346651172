import React from 'react';
import {updatePageTitle} from "../../actions/breadcrumbs";
import {connect} from "react-redux";
import { getOrder, getOrderForm,  updateOrder} from "../../modules/orders/OrderService";
import FormLoader from "../../ubold/commons/FormLoader";
import Joi from "joi-browser";
import { Redirect} from "react-router-dom";
import StickyBox from "react-sticky-box";
import loading from "../../assets/images/loading.svg";
import {siteUrl} from "../../config";
import OrderForm from "./OrderForm";
import routes from "../../routes";

class OrderEdit extends OrderForm {
    constructor(props) {
        super(props);

        this.state = {
            loadingForm: true,
            service: null,
            currencies: null,
            data: {},
            errors: {},
            loading: false,
            calcLoading: false,
            timer: null,
            redirect: false,
            order_id:this.props.match.params.id,
            maxGiftQty:5,
            hasFormAttachment:false

        };

        this.props.dispatch(updatePageTitle(
            'سفارش شماره # '+ this.state.order_id,
            [
                    {
                        'title': 'لیست سفارشات',
                        'link': routes.orders
                    },
                    {
                        'title':   'ویرایش سفارش # '+ this.state.order_id,
                        'active': true
                    }
            ]
        ));
    }

    componentDidMount() {
        getOrder(this.state.order_id).then(res=>{
            this.setState(
                {
                    order:res.data.order,
                }
            );

            getOrderForm(res.data.order.service.id).then(res => {
                let sh = {};
                let data = {};
                const order = this.state.order;
                const service=res.data.service;

                this.setState({
                    data: data,
                    maxGiftQty: res.data.max_gift_qty
                });


                res.data.fields.map(f => {
                    if (f.type === 'account') {
                        sh['account'] = Joi.label('اطلاعات حساب کاربری');
                        sh['user_account_username'] = Joi.label('نام کاربری');
                        sh['user_account_password'] = Joi.label('رمز عبور');

                        data['user_account_username'] = (order.meta.user_account_username?order.meta.user_account_username: '');
                        data['user_account_password'] = (order.meta.user_account_password?order.meta.user_account_password: '');

                    } else if (f.type === 'protection_code') {
                        sh['hpcode'] = Joi.label('فیلد کد حفاظتی');
                        data['hpcode'] = 'خیر';
                        sh['pcode'] = Joi.label('کد حفاظتی');
                        data['hpcode'] = (order.meta.hpcode?order.meta.hpcode: '');
                        data['pcode'] = (order.meta.pcode?order.meta.pcode: '');

                    } else {
                        if (this.attach_fields.indexOf(f.name) < 0){
                            this.setState({hasAttachField:true});
                            if(f.pattern)
                                sh[f.name] = Joi.string().regex(new RegExp(f.pattern)).required().label(f.label);
                            else
                                sh[f.name] = Joi.any().required().label(f.label);
                        }
                        else
                            sh[f.name] = Joi.any().meta({ swaggerType: 'file' })
                                .description('file to upload').required().label(f.label);
                    }

                    try {
                        if(Object.keys(order).includes(f.name))
                            data[f.name]=order[f.name];
                        else
                            data[f.name] = (order.meta[f.name]?order.meta[f.name]: f.default_value);
                    }catch (e){
                        data[f.name] = (order.meta[f.name]?order.meta[f.name]: f.default_value);
                    }

                    return data;

                });

                // if(service.plans.length > 1)
                //     data['fk_plan_id']=service.plans[0].id;
                // else
                //     data['fk_plan_id']=0;

                data['amount']=order.amount;
                data['fk_plan_id']=order.fk_plan_id;
                data['fk_currency_id']=order.fk_currency_id;
                data['user_description'] = (order.user_description?order.user_description: '');

                this.setState({
                    data: data
                });

                if (service.currency.length > 0) {
                    let ot = [];
                    service.currency.map(cr => {
                        ot.push({
                            'name': cr.name,
                            '_id': cr.id,
                        })
                        return ot;
                    });

                    this.setState({ currenciesOptions: ot});

                    sh['fk_currency_id'] = Joi.required()
                        .label('نوع ارز');
                }

                if (service.plans.length > 0) {
                    let ot = [];
                    service.plans.map(pl => {
                        ot.push({
                            'name': pl.fa_name,
                            '_id': pl.id,
                        });
                        return ot;
                    });

                    this.setState({ plansOptions: ot});
                }

                sh['rules'] = Joi.required()
                    .label('شرایط و قوانین');

                sh['user_description'] = Joi.label('توضیحات کاربر');
                sh['attachments'] = Joi.label(' فایل پیوست');
                sh['fk_plan_id'] = Joi.label('پلن');


                this.setState({
                    fields: res.data.fields,
                    service: service,
                    currencies: service.currency,
                    plans:service.plans,
                    loadingForm:false
                });

                this.schema = sh;

                this.props.dispatch(updatePageTitle(
                    'ویرایش سفارش ' + this.state.service.title,
                    [
                        {
                            'title': 'لیست سفارشات',
                            'link': routes.orders
                        },
                        {
                            'title': 'ویرایش سفارش',
                            'active': true
                        }
                    ]
                ));

                this.orderCalculate();

            });

        });
    }



    doSubmit = async () => {
        try {
            var data = this.state.data;
            const FData = new FormData();
            const cl = this;

            Object.keys(data).forEach(function (item) {
                if (cl.no_meta_filed.indexOf(item) < 0) {
                    if (cl.attach_fields.indexOf(item) >= 0) {
                        FData.append('attachments[' + item + ']', data[item]);
                    } else
                        FData.append('meta[' + item + ']', data[item]);
                } else
                    FData.append(item, data[item]);
            });


            updateOrder(this.state.order_id, FData).then(res => {
                this.toggle();
                this.setState({
                    redirect: '/finance/checkout/' + this.state.order.fk_transaction_id
                })
            }).catch(res => {
                this.toggle();
            });

        } catch (ex) {
            this.toggle();
        }
    };

    getValue(filed){
        if(this.state.order[filed.name])
           return this.state.order[filed.name];
        else
            return (this.state.order.meta[filed.name]?this.state.order.meta[filed.name]: '');
    }

    qtyChangeCallback= ()=>{
        this.orderCalculate()
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }

        return (
            <React.Fragment>
                {
                    this.state.loadingForm ?
                            <FormLoader/>
                        :
                        <React.Fragment>

                            <form method="POST" onSubmit={this.handleSubmit}>

                                <div className="row mt-1">
                                    <div className="col-md-7">
                                        <div className="card card-body">
                                            {
                                                this.state.plans.length === 1 ?
                                                    this.renderInput('fk_plan_id', '', 'hidden', '', null, 0, false, this.state.plans[0].id)
                                                    : ''
                                            }

                                            {
                                                this.state.plans.length > 1 ?
                                                    this.renderSelect('fk_plan_id', 'پلن', this.state.plansOptions, this.state.data.fk_plan_id)
                                                    : ''
                                            }

                                            {
                                                this.state.currencies.length > 1 ?
                                                    this.renderSelect('fk_currency_id', 'نوع ارز', this.state.currenciesOptions, this.state.order.fk_currency_id)
                                                    : ''
                                            }
                                            {
                                                this.state.fields.map(field => {

                                                    switch (field.type) {
                                                        case 'text' :
                                                        case 'file' :
                                                        case 'checkbox' :
                                                        case 'number' :
                                                        case 'email' :
                                                        case 'link' :
                                                            return this.renderInput(field.name, field.label, field.type, field.class, null, 0, false, field.default_value, field.placeholder, this.handleKeyUp)
                                                        case 'account':
                                                            return this.renderAccount();
                                                        case 'select':
                                                            return this.renderSelect(field.name,field.label,field.opt);
                                                        case 'protection_code':
                                                            return this.renderProtectionCode(field.name,field.label,field.opt)
                                                        case 'qty':
                                                            return this.renderQty(field.label,field.opt,field.default_value,false,this.state.maxGiftQty)
                                                    }

                                                    return field;
                                                })
                                            }


                                            {
                                                !this.state.service.is_automatic && this.renderTextArea('user_description', 'توضیحات ','',false,3,this.state.data.user_description)
                                            }

                                            {
                                                (!this.state.service.is_automatic && !this.state.hasFormAttachment) && this.renderInput('attachments', 'فایل پیوست', 'file', '')
                                            }


                                        </div>
                                    </div>
                                    <div id='afix' className="col-md-5">
                                        <StickyBox offsetTop={80} offsetBottom={20}>
                                            <div className="card calculate-box">
                                                <div className=" card-body cal-box">
                                                    <div className="card-title mb-4">
                                                        فاکتور سفارش
                                                    </div>
                                                    <div className="form-group mt-4" id="box_info_prices">
                                                        <div style={{
                                                            padding: '20px 0',
                                                            display: (this.state.calcLoading ? 'block' : 'none')
                                                        }}>
                                                            <img src={loading}
                                                                 style={{display: 'block', margin: '0 auto'}}
                                                                 height="40" width="40"/>
                                                            <div style={{textAlign: 'center', marginTop: '10px'}}>لطفا
                                                                صبر
                                                                کنید ...
                                                            </div>
                                                        </div>

                                                        <div className="cart-invoice" id="result">
                                                            <div style={{
                                                                display: (this.state.calcLoading ? 'none' : 'block'),
                                                                background: 'rgba(0,0,0,0.1)',
                                                                padding: '10px',
                                                                textAlign: 'center'
                                                            }}>
                                                                لطفا مقادیر را وارد کنید
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="fancy-checkbox font-11">

                                                            {
                                                                this.renderCheckbox('rules')
                                                            }

                                                            <span>
                                                            <a href={siteUrl + 'terms'} target="_blank"> شرایط   </a>   استفاده را با دقت مطالعه نموده و قبول دارم   </span>
                                                        </label>

                                                        <div>

                                                            {
                                                                (this.state.errors && this.state.errors['rules']) &&   <div className="form-error"><small
                                                                    className="font-11 ">{this.state.errors.rules}</small>
                                                                </div>
                                                            }
                                                        </div>


                                                    </div>


                                                    {this.renderButton('ویرایش سفارش', 'btn btn-primary btn-block btn-lg')}


                                                </div>
                                            </div>
                                        </StickyBox>


                                    </div>

                                </div>
                            </form>


                        </React.Fragment>

                }
            </React.Fragment>
        )
    }

}

export default connect ()(OrderEdit);
