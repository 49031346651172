import React from 'react';
import Squre from "../skelete/Squre";
import SkeleteLoading from "../SkeleteLoading";
import CommentsListLoading from "./CommentsListLoading";

class TicketShowLoading extends React.Component {

    render(){


        return (
            <React.Fragment>
                <SkeleteLoading>
                    <div className="mb-3">
                        <Squre height={170} />
                    </div>
                    <div className="mb-3">
                        <Squre height={220} />
                    </div>
                    <div className="mb-3">
                        <div className="row">
                            <div className="col-2">
                                <Squre height={10} />
                            </div>
                        </div>
                    </div>

                    <CommentsListLoading count={3} />

                </SkeleteLoading>
            </React.Fragment>
        );
    }
}

export default TicketShowLoading;
