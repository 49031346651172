import React from 'react';
import {updatePageTitle} from "../../actions/breadcrumbs";
import Form from "../../ubold/commons/form";
import {getVipInfo} from "./userService";
import {spilitNumber} from "./../../services/helpers";
import {connect} from "react-redux";
import ReactTooltip from "react-tooltip";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import B from "./vip/B";
import D from "./vip/D";
import C from "./vip/C";
import A from "./vip/A";
import UserVipLoading from "../../components/loadings/UserVipLoading";
import UseCheckMobileScreen from "../../components/UseCheckMobileScreen";
import OwlCarousel from "react-owl-carousel-rtl";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";


class VipLayers extends Form {
    constructor(props) {
        super(props);
        this.state = {
            loadingForm: true,
            vipInfo: {},
            modal: false,
        };

        this.props.dispatch(updatePageTitle(
            'سطح کاربری',
            [
                {
                    'title': 'سطح کاربری',
                    'active': true
                }
            ]
        ));
    }

    toggleModal = () => {
        this.setState({modal: !this.state.modal});
    }


    componentDidMount() {
        getVipInfo().then(res => {
            this.setState({
                vipInfo: res.data,
                loadingForm: false,
            });
        });
    }

    handleClass=(v)=>{
        const user = this.props.user.info;
        const arrE= [];
        const arrD= ['E'];
        const arrC= ['D','E'];
        const arrB= ['C','D','E'];

        if(user.level==='A' && v!=='A')
            return 'disabled';

        if(user.level==='B' && arrB.includes(v))
            return 'disabled';

        if(user.level==='C' && arrC.includes(v))
            return 'disabled';

        if(user.level==='D' && arrD.includes(v))
            return 'disabled';

        if(user.level==='E' && arrE.includes(v))
            return 'disabled';

        return '';

    }

    handleCount=()=>{
        const user = this.props.user.info;

        var count=0;
        if(user.level==='A')
            count=1;

        else if(user.level==='B')
            count=2;

        else
            count=3;

        this.setState({count:count})
    }

    syncMobile =()=>{
        this.setState({count:1})
    }

    syncDesktop =()=>{
        this.setState({count:this.handleCount()})
    }

    render() {
        const user = this.props.user.info;
        const vipInfo = this.state.vipInfo;

        var text=null;

        if(!this.state.loadingForm )
             text={
                 E: <div className="items">
                    <div className="item d-flex justify-content-between align-items-center">
                        <div className="title">خرید ارزهای دیجیتال</div>
                        <div className="value"><i className="fe-x-circle text-danger"></i></div>
                    </div>
                    <div className="item d-flex justify-content-between align-items-center">
                        <div className="title">انجام سفارشات به صورت آنی (اتوماتیک)</div>
                        <div className="value"><i className="fe-x-circle text-danger"></i></div>
                    </div>
                    <div className="item d-flex justify-content-between align-items-center">
                        <div className="title">حداکثر میزان سفارش روزانه (وب مانی، پرفکت مانی، گیفت کارت)</div>
                        <div className="value">0</div>
                    </div>
                    <div className="item d-flex justify-content-between align-items-center">
                        <div className="title">حداکثر میزان سفارش روزانه ارزهای دیجیتال</div>
                        <div className="value">0</div>
                    </div>
                    <div className="item d-flex justify-content-between align-items-center">
                        <div className="title">حداکثر میزان سایر سفارش ها</div>
                        <div className="value">0</div>
                    </div>
                    <div className="item d-flex justify-content-between align-items-center">
                        <div className="title">حداکثر میزان برداشت وجه روزانه</div>
                        <div className="value">0</div>
                    </div>
                </div>,
                 D:<div className="items">
                     <div className="item d-flex justify-content-between align-items-center">
                         <div className="title">خرید ارزهای دیجیتال</div>
                         <div className="value"><i className="fe-x-circle text-danger"></i></div>
                     </div>
                     <div className="item d-flex justify-content-between align-items-center">
                         <div className="title">انجام سفارشات به صورت آنی (اتوماتیک)</div>
                         <div className="value"><i className="fe-check-circle text-success"></i></div>
                     </div>
                     <div className="item d-flex justify-content-between align-items-center">
                         <div className="title">حداکثر میزان سفارش روزانه (وب مانی، پرفکت مانی، گیفت کارت)</div>
                         <div className="value">{spilitNumber(vipInfo.wp_service.limit_amount_normal_first ? vipInfo.wp_service.limit_amount_normal_first : 0)}
                             <small>دلار</small></div>
                     </div>
                     <div className="item d-flex justify-content-between align-items-center">
                         <div className="title">حداکثر میزان سفارش روزانه ارزهای دیجیتال</div>
                         <div className="value">{spilitNumber(vipInfo.crypto_max_amount_d)} <small>دلار</small></div>
                     </div>
                     <div className="item d-flex justify-content-between align-items-center">
                         <div className="title">حداکثر میزان سایر سفارش ها</div>
                         <div className="value"><span className="text-success">نامحدود</span></div>
                     </div>
                     <div className="item d-flex justify-content-between align-items-center">
                         <div className="title">حداکثر میزان برداشت وجه روزانه</div>
                         <div className="value">{spilitNumber(vipInfo.user_max_withdraw_d)}
                             <small>میلیون</small></div>
                     </div>
                 </div>,
                 C:<div className="items">
                     <div className="item d-flex justify-content-between align-items-center">
                         <div className="title">خرید ارزهای دیجیتال</div>
                         <div className="value"><i className="fe-x-circle text-danger"></i></div>
                     </div>
                     <div className="item d-flex justify-content-between align-items-center">
                         <div className="title">انجام سفارشات به صورت آنی (اتوماتیک)</div>
                         <div className="value"><i className="fe-check-circle text-success"></i></div>
                     </div>
                     <div className="item d-flex justify-content-between align-items-center">
                         <div className="title">حداکثر میزان سفارش روزانه (وب مانی، پرفکت مانی، گیفت کارت)</div>
                         <div className="value">{spilitNumber(vipInfo.wp_service.limit_amount_normal ? vipInfo.wp_service.limit_amount_normal : 0)}
                             <small>دلار</small></div>
                     </div>
                     <div className="item d-flex justify-content-between align-items-center">
                         <div className="title">حداکثر میزان سفارش روزانه ارزهای دیجیتال</div>
                         <div className="value">{spilitNumber(vipInfo.crypto_max_amount_c)} <small>دلار</small></div>
                     </div>
                     <div className="item d-flex justify-content-between align-items-center">
                         <div className="title">حداکثر میزان سایر سفارش ها</div>
                         <div className="value"><span className="text-success">نامحدود</span></div>
                     </div>
                     <div className="item d-flex justify-content-between align-items-center">
                         <div className="title">حداکثر میزان برداشت وجه روزانه</div>
                         <div className="value">{spilitNumber(vipInfo.user_max_withdraw_c)}
                             <small>میلیون</small></div>
                     </div>
                 </div>,
                 B:<div className="items">
                     <div className="item d-flex justify-content-between align-items-center">
                         <div className="title">خرید ارزهای دیجیتال</div>
                         <div className="value"><i className="fe-check-circle text-success"></i></div>
                     </div>
                     <div className="item d-flex justify-content-between align-items-center">
                         <div className="title">انجام سفارشات به صورت آنی (اتوماتیک)</div>
                         <div className="value"><i className="fe-check-circle text-success"></i></div>
                     </div>
                     <div className="item d-flex justify-content-between align-items-center">
                         <div className="title">حداکثر میزان سفارش روزانه (وب مانی، پرفکت مانی، گیفت کارت)</div>
                         <div className="value">{spilitNumber(vipInfo.wp_service.limit_amount_gray ? vipInfo.wp_service.limit_amount_gray : 0)}
                             <small>دلار</small></div>
                     </div>
                     <div className="item d-flex justify-content-between align-items-center">
                         <div className="title">حداکثر میزان سفارش روزانه ارزهای دیجیتال</div>
                         <div className="value">{spilitNumber(vipInfo.crypto_max_amount_b)} <small>دلار</small></div>
                     </div>
                     <div className="item d-flex justify-content-between align-items-center">
                         <div className="title">حداکثر میزان سایر سفارش ها</div>
                         <div className="value"><span className="text-success">نامحدود</span></div>
                     </div>
                     <div className="item d-flex justify-content-between align-items-center">
                         <div className="title">حداکثر میزان برداشت وجه روزانه</div>
                         <div className="value">نامحدود
                          </div>
                     </div>
                 </div>,
                 A: <div className="items">
                     <div className="item d-flex justify-content-between align-items-center">
                         <div className="title">خرید ارزهای دیجیتال</div>
                         <div className="value"><i className="fe-check-circle text-success"></i></div>
                     </div>
                     <div className="item d-flex justify-content-between align-items-center">
                         <div className="title">انجام سفارشات به صورت آنی (اتوماتیک)</div>
                         <div className="value"><i className="fe-check-circle text-success"></i></div>
                     </div>
                     <div className="item d-flex justify-content-between align-items-center">
                         <div className="title">حداکثر میزان سفارش روزانه (وب مانی، پرفکت مانی، گیفت کارت)</div>
                         <div className="value"><span className="text-success">نامحدود</span></div>
                     </div>
                     <div className="item d-flex justify-content-between align-items-center">
                         <div className="title">حداکثر میزان سفارش روزانه ارزهای دیجیتال</div>
                         <div className="value">{spilitNumber(vipInfo.crypto_max_amount_a)} <small>دلار</small></div>
                     </div>
                     <div className="item d-flex justify-content-between align-items-center">
                         <div className="title">حداکثر میزان سایر سفارش ها</div>
                         <div className="value"><span className="text-success">نامحدود</span></div>
                     </div>
                     <div className="item d-flex justify-content-between align-items-center">
                         <div className="title">حداکثر میزان برداشت وجه روزانه</div>
                         <div className="value"><span className="text-success">نامحدود</span></div>
                     </div>
                 </div>,

             }


        return (
            this.state.loadingForm ? <UserVipLoading/>
                :
                <React.Fragment>
                    <UseCheckMobileScreen syncMobile={()=>this.syncMobile()} syncDesktop={()=>this.syncDesktop()} />
                    <div className='vip-layers'>
                        <Modal isOpen={this.state.modal} toggle={this.toggleModal} className='credit '>
                            <ModalHeader toggle={this.toggleModal}> مدارک لازم برای ارتقاع حساب
                                به {vipInfo.next_level_label}</ModalHeader>
                            <ModalBody>
                                <div className="vip-layer-table">
                                    {
                                        vipInfo.next_level === 'D' ?
                                            <D user={user} info={vipInfo}/>
                                            : (
                                                vipInfo.next_level === 'C' ?
                                                    <C user={user} info={vipInfo}/>
                                                    : (
                                                        vipInfo.next_level === 'B' ?
                                                            <B user={user} info={vipInfo}/>
                                                            : (
                                                                vipInfo.next_level === 'A' ?
                                                                    <A user={user} info={vipInfo}/>
                                                                    : ''
                                                            )
                                                    )
                                            )

                                    }
                                </div>

                            </ModalBody>
                        </Modal>
                        <div className="mt-4">
                            <OwlCarousel items={this.state.count} dots={false} center={true} rtl={true} className="owl-theme" loop margin={10} nav={false}>
                                {
                                    Object.keys(text).map((key, index)=>{
                                        return (
                                            this.handleClass(key)!=='disabled'?
                                                <div key={key} className="item">
                                                    <div className={"card card-vip-lc "+ this.handleClass(key)}>
                                                        {key===user.level?<div class='current'>سطح فعلی</div>:""}
                                                        <img src={'/assets/images/app/'+key+'.svg'}/>
                                                        {text[key]}

                                                        {
                                                            user.level!==key?
                                                                <button onClick={() => this.toggleModal()} className='btn btn-t btn-block btn-lg mt-4'>ارتقا سطح کاربری</button>
                                                                :""
                                                        }
                                                    </div>
                                                </div>
                                                :''
                                        )
                                    })
                                }

                            </OwlCarousel>
                        </div>
                    </div>
                    <ReactTooltip/>
                </React.Fragment>
        )
    }

}

const mapStateToComponent = state => {
    return {
        user: state.user
    }
}
export default connect(mapStateToComponent)(VipLayers);
