import React from 'react';
import Squre from "../skelete/Squre";
import SkeleteLoading from "../SkeleteLoading";
import {Link} from "react-router-dom";
import routes from "../../routes";

class FinanceTransactionsLoading extends React.Component {

    render(){
        var html=[];
        for(var i=0; i<=5;i++){
            html.push(<div className='mb-2'><Squre height={80} /></div>)
        }

        return (
            <React.Fragment>
                <SkeleteLoading>

                    {html}
                </SkeleteLoading>
            </React.Fragment>
        );
    }
}

export default FinanceTransactionsLoading;
