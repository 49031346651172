import React from 'react';
import Form from "../../../../ubold/commons/form";
import VerifyEmailForm from "../../../partials/VerifyEmailForm";
import Alert from "../../../partials/Alert";
import UpdateEmail from "../UpdateEmail";
import HeaderForm from "./HeaderForm";
import LoadingReload from "./LoadingReload";

class step2 extends Form {

    render() {


        return (
            <React.Fragment>
                <div className="row justify-content-center mt-5">
                    <div className="col-xl-6 col-lg-7 col-md-10">
                        <div className="box">
                            <HeaderForm closepopup={() => this.props.closepopup(this.props.verification.key, false)}
                                        icon={'/assets/images/sprite-ver.svg#EMAIL'}
                                        title={'تایید ایمیل (پست الکترونیکی)'} k={this.props.verification.key}/>

                            <div style={{position:'relative'}}>
                                {
                                    this.props.loadingreload?
                                        <LoadingReload />
                                        :''
                                }

                                {

                                    this.props.user.email ?
                                        <div>
                                            <Alert type={'info'}>
                                                اگر کد تایید به اینباکس ایمیل شما ارسال نشد به اسپم ایمیل شما ارسال میشه. درغیر
                                                اینصورت با پشتیبانی تماس بگیرید
                                            </Alert>
                                            <VerifyEmailForm  reloadUser={() => this.props.reloadUser(true)}/>
                                        </div>
                                        :
                                        <div>
                                            <div className="mt-4">
                                                <UpdateEmail  reloadUser={() => this.props.reloadUser(false)}/>
                                            </div>
                                        </div>

                                }

                            </div>


                        </div>


                    </div>
                </div>
            </React.Fragment>
        )
    }

}


export default step2;
