import React from 'react';
import Form from "../../ubold/commons/form";
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import {connect} from "react-redux";
import Alert from "../partials/Alert";
import {Link} from "react-router-dom";
import routes from "../../routes";


class UserCheckLegal extends Form {
    constructor(props) {
        super(props);
    }

    render() {
        var user = this.props.user;

        return (
            <Alert title={this.props.title==='no'?"":'توجه کنید'}>
                 ابتدا نیاز است تا مدارک شرکت شما از جمله اساس نامه و آگهی تاسیس / تغییرات  توسط شما بارگذاری شود و پس از استعلام و تایید ادامه مراحل را ادامه دهید.

                <div className="mt-4">
                    <Link
                        className={'btn btn-outline-warning'}
                        to={routes.profile_verify}>
                        ارسال مدارک
                    </Link>
                </div>
            </Alert>
        )
    }

}

const mapStateToProps = appState => {
    return {
        user: appState.user.info
    }
};

export default connect(mapStateToProps)(UserCheckLegal);
