import React from 'react';
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {connect} from "react-redux";
import Form from "../../../../ubold/commons/form";

import {getCoins} from "../../OrderService";

class ModalCoins extends Form {

    constructor(props) {
        super(props);
        this.state = {
            coins: [],
            coinsBackup: [],
            search: false,
            base_exchanger:this.props.app.crypto_base_exchanger,
            loading: false
        };
    }

    loadCoins = (exchanger = null) => {
        exchanger = exchanger ? exchanger : this.state.base_exchanger;

        this.setState({search: true});

        getCoins(exchanger).then(res => {

            this.setState({search: false});

            var allCoins = [];

            for (const [key, value] of Object.entries(res.data.coins)) {
                allCoins[key] = value;
            }

            var stateData = {
                loading: false,
                coins: allCoins,
                coinsBackup: allCoins,
                search: false
            };

            if(this.props.selectedCoin.id===0) {
                if(allCoins.length){
                    var coin=res.data.coins[Object.keys(res.data.coins)[0]];
                    this.props.handleSelectCoinFromModal(coin);
                }
            }

            this.setState(stateData);
        });
    }

    handleChangeExchanger = (ex) => {
        this.loadCoins( ex);
        this.setState({base_exchanger: ex})
    }

    componentDidMount() {
        this.loadCoins(null);
    }

    handleSearch = (input) => {
        var coins=this.state.coinsBackup;

        if(input.target.value)
            coins = coins.filter(coin => {
                let regex = new RegExp(input.target.value.toUpperCase(), 'i'); 	// Create a regex object (a constructor).
                if(regex.test(coin.symbol))
                    return true;
                else if(regex.test(coin.name))
                    return true;
                else if(regex.test(coin.title))
                    return true;
                else
                    return false;
            } );
        else
            coins=this.state.coinsBackup;

        this.setState({coins})

    }


    render() {
        var allCoins = this.state.coins;
        var exchanger = this.state.base_exchanger;

        return (
            <>
                <Modal isOpen={this.props.showCoinsModal} toggle={this.props.toggleCoinsModal}  className='credit '>
                    <ModalHeader toggle={this.props.toggleCoinsModal}> <span>انتخاب ارز</span></ModalHeader>

                    <ModalBody>
                        <div className='coin-search-box mb-3'>
                            <div className='mb-2'>
                                {
                                    this.props.app.crypto_active_exchangers.map(exchanger => (
                                        <button onClick={() => this.handleChangeExchanger(exchanger.key)}
                                                className={"ml-1 button btn btn-default btn-change-exchnager " + (this.state.base_exchanger === exchanger.key ? "ex-active" : "")}>
                                            <img className="ml-1 d-inline-block" height="20" src={exchanger.icon}/>
                                            ارزهای {exchanger.label}
                                        </button>
                                    ))
                                }
                            </div>

                            <input disabled={this.state.search} placeholder='جستجو' type='text' onChange={this.handleSearch} className='form-control search'/>

                            <div className="mt-3">

                                {this.state.search ?
                                    <div className='loading'>
                                        <img className='ml-2' height={17} width={17}
                                             src='/assets/images/loading.svg'/> در حال بارگذاری
                                    </div>
                                    :
                                    <div>
                                        <div className="coins-box">
                                            {allCoins.map((coin, index) => (
                                                <div key={index} onClick={() => this.props.handleSelectCoinFromModal(coin,exchanger)}
                                                     className='order-crypto-coins-list'>
                                                    <div
                                                        className="d-flex align-items-center justify-content-between">
                                                        <div className='d-flex align-items-center'>
                                                            <img className='ml-2' height={35} width={35}
                                                                 src={coin.icon}/>
                                                            <div className='d-flex flex-column'>
                                                                <h2 className='title'>{coin.title}
                                                                    <small>{coin.name}</small>
                                                                </h2>
                                                                <h4 className='type'>{coin.symbol}</h4>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            ))}

                                        </div>
                                    </div>
                                }
                            </div>

                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }

};

const mapStateToProps = (state) => {
    return {
        app: state.app,
    }
}

export default connect(mapStateToProps)(ModalCoins);
