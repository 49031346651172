import React, {Component} from "react";
import parse from "html-react-parser";
import Parents from "./Parents";
import img from "./../../assets/images/1.svg";

class Comment extends Component {

    render() {
        const {comment,child} = this.props;

        return (
            <React.Fragment>
                    <div key={comment.id}  className={"comment d-flex "+(comment.admin?'admin':'')+(child?' child':'')} >
                        <div className="d-flex v3-flex-center">
                            <div className="mt-3">
                                {comment.user_name ?
                                    <img alt={comment.user_name} width="30" height="30" className=" rounded mb-1 "
                                         src={comment.avatar}/>
                                    : <img alt={comment.user_name} width="30" height="30" className=" rounded mb-1 "
                                           src={img}/>
                                }

                            </div>
                        </div>
                        <div className="mr-3 " style={{flex:1}}>
                            <div className="comment-body">
                                <div className="d-flex mb-3">
                                    <div className="name ml-3">{comment.user_name}</div>
                                    <div className="date">{comment.shamsi_created_at}</div>
                                </div>
                                {parse(comment.content)}
                            </div>
                        </div>
                    </div>

                {
                    <Parents comments={comment.childs}/>
                }
            </React.Fragment>
        );
    }
}

export default Comment;
