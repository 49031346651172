import http from "./../../services/httpService";

const apiEndpoint = "/bank-cards";

export function all() {
    return http.post(apiEndpoint + '/list');
}

export function create(data) {
    return http.submit('post', apiEndpoint + '/create', data);
}

export function edit(card) {
    return http.post( apiEndpoint + '/edit/' + card);
}

export function deleteCard(card=false,isTasvie=false) {
    return http.delete( apiEndpoint + '/delete/' + (isTasvie?'isTasvie':card)+'?isTasvie='+isTasvie);
}

export function update(card,data) {
    return http.post(apiEndpoint + '/update/'+card, data);
}

export function getBankCodes() {
    return http.post( apiEndpoint + '/banks/get-code' );
}
