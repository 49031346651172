import React from 'react';
import Squre from "../skelete/Squre";
import SkeleteLoading from "../SkeleteLoading";

class RewardsLoading extends React.Component {

    render(){
        return (
            <React.Fragment>
                <SkeleteLoading>
                    <div className="mb-3">
                        <div className="row">
                            <div className="col-md-4 mb-2"><Squre height={140} /></div>
                            <div className="col-md-4 col-6 mb-2"><Squre height={140} /></div>
                            <div className="col-md-4 col-6" mb-2><Squre height={140} /></div>
                        </div>

                    </div>
                    <div className="mb-3">
                        <div className="mb-2"><Squre height={10} /></div>
                        <div className="mb-2"><Squre height={10} /></div>
                    </div>

                    <div className="mb-3">
                        <div className="row">
                            <div className="col-md-3 mb-2"><Squre height={160} /></div>
                            <div className="col-md-3 mb-2"><Squre height={160} /></div>
                            <div className="col-md-3 mb-2"><Squre height={160} /></div>
                            <div className="col-md-3 mb-2"><Squre height={160} /></div>
                        </div>

                    </div>
                    <div className="mb-3">
                        <Squre height={80} />
                    </div>

                    <div className="mb-3">
                        <Squre height={80} />
                    </div>
                    <div className="mb-3">
                        <Squre height={100} />
                    </div>

                </SkeleteLoading>
            </React.Fragment>
        );
    }
}

export default RewardsLoading;
