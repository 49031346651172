import routes from "../routes";
import {parse} from "@babel/core";
const fs = require("fs");

export function updateNewVersion (version)  {

    const jsonContent = JSON.stringify({version: version});

    fs.writeFile("./../../public/meta.json", jsonContent, "utf8", function (error) {
        if (error) {
            console.log("An error occured while saving build date and time to meta.json");
            return console.log(error);
        }
        console.log("Latest build date and time updated in meta.json file");
    });
};


export function toNumber(el) {
    return el.toString().replace("/[^0-9\\.]+/g", '');
}

export function spilitNumber(el=0) {
    el=el.toString().replace(',', "");
    el=parseFloat(el);
    return  el.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function spilitNumber2(el=0) {
    el=el.toString().replace(',', "");
    el=parseFloat(el);
    const nf = new Intl.NumberFormat();
    const str = nf.format(el);

    return  str;
}

export function formatMoney(n,cc=3) {
    n=parseFloat(n);
    let nn=n.toString();
    let array=nn.split('.');
    let count=array[0].length;

    if(count > 2)
        n= Math.round(n);
    else
        return n;

    var t=/\B(?=(\d{3})+(?!\d))/g;

    if(cc===8)
         t=/\B(?=(\d{8})+(?!\d))/g;

    return  n.toString().replace(t, ",");

    // return  n.toLocaleString();
}

export function formatMoneyDollar(n,cc=3) {
    n=parseFloat(n);
    let nn=n.toString();

    let array=Object.values(nn.split('.'));

    let count=0;

    if(array.length > 1)
        count=array[1].length;

    var t=/\B(?=(\d{3})+(?!\d))/g;

    if(array[0] < 1 && count > 3){
        if(count > 8)
            cc=8;
        else
            cc=count;

    }

    const  num=array[0].toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")

    if(count > 1){
        return num+'.'+ array[1].substring(0, cc);
    }

    return n;

    // return  n.toLocaleString();
}


export function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + ":" : "";
    var mDisplay = m > 0 ? m + ":" : "";
    var sDisplay = s > 0 ? s  : "";
    return hDisplay + mDisplay + sDisplay;
}

export function getUrl(withHost=false){
    let url='';

    if (withHost)
         url = window.location.protocol + '//' + window.location.host + window.location.pathname;
    else
         url = window.location.pathname;

    if (url.charAt((url.length - 1)) === '/')
        url = url.substring(0, (url.length - 1));

    return url;
}


export function clearConsole() {
    if(window.console || window.console.firebug) {
        console.clear();
    }
}


export function LightenDarkenColor(col, amt) {
    var usePound = false;
    if (col[0] === "#") {
        col = col.slice(1);
        usePound = true;
    }
    var num = parseInt(col, 16);
    var r = (num >> 16) + amt;
    if (r > 255) {
        r = 255;
    } else if (r < 0) {
        r = 0;
    }
    var b = ((num >> 8) & 0x00FF) + amt;
    if (b > 255) {
        b = 255;
    } else if (b < 0) {
        b = 0;
    }
    var g = (num & 0x0000FF) + amt;
    if (g > 255) {
        g = 255;
    } else if (g < 0) {
        g = 0;
    }
    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}

var persianDigits = "۰۱۲۳۴۵۶۷۸۹";
var persianMap = persianDigits.split("");

export function convertToEnglishNumber(input){
    return input.replace(/[\u06F0-\u06F90]/g, function(m){
        return persianDigits.indexOf(m);
    });
}
export function convertToPersianNumber(input){
    return input.replace(/\d/g,function(m){
        return persianMap[parseInt(m)];
    });
}

export function convertDateToArray(date){
    if(Array.isArray(date) || (typeof date === 'object'))
        return date;
    else if(date){
        const d= date.split("/");
        return {
            day : parseInt(d[2]),
            month : parseInt(d[1]),
            year : parseInt(d[0]),
        }
    } else
        return null;
}

export function convertArrayToDate(date){
    if(Array.isArray(date) || (typeof date === 'object')){
        var month=(date.month<10)?'0'+date.month:date.month;
        var day=(date.day<10)?'0'+date.day:date.day;
        return date.year+'/'+ month +'/'+ day;
    }
    else if(date){
        return date;
    } else
        return null;
}


export function convertDataToArray(data){
    if(typeof data === 'object'){
        let d=[];
        Object.entries(data).map((key,val) => {
           return  d.push(key[1]);
        });
        return d;
    } else
        return data;
}

export function getVerification(verifications,type){
    var ver=null;
    verifications.map(v => {
        if(type===v.key){
            ver=v;
        }
        return ver;
    })

    return ver;
}

export function toCurrency(number) {
    const formatter = new Intl.NumberFormat("sv-SE", {
        style: "decimal",
        currency: "SEK"
    });

    return formatter.format(number);
}

export function orderEditLink(trans) {
    const order=trans.order;

    if(order.service.st==='digital')
        return routes.orders_crypto+'?edit='+order.id;
    else
        return '/orders/'+order.id+'/edit';
}


export function showHtmlText(text){
    return  parse(text)
    // try {
    //     return  parse(text)
    //
    // }catch (e) {
    //     return  text
    // }
}

export function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}


export function getUrlWithUserAttr(url,user_id){
    return url + "?secure="+user_id
}

export function contains(obj,name){
    var found = false;

    for(var i = 0; i < Object.keys(obj).length; i++) {
        if (Object.keys(obj).includes(name)) {
            found = true;
            break;
        }
    }
    return found;
}