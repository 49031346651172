import React from 'react';
import Squre from "../skelete/Squre";
import SkeleteLoading from "../SkeleteLoading";
import Table from "../skelete/Table";

class ListLoading extends React.Component {

    render() {
        var h=[];
        var c=this.props.count?this.props.count:10;
        var height=this.props.height?this.props.height:50;

        for (var i=1;i<=c;i++){
            h.push( <div className='mb-2'><Squre height={height} /></div>)
        }
        return (
            <React.Fragment>
                <SkeleteLoading>
                    {h}
                </SkeleteLoading>
            </React.Fragment>
        );
    }
}

export default ListLoading;
