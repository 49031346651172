import React from 'react';
import {updatePageTitle} from "../../actions/breadcrumbs";
import {connect} from "react-redux";
import Pagination from "../../ubold/commons/pagination";
import {all} from "../../modules/orders/OrderService";
import FormLoader from "../../ubold/commons/FormLoader";
import OrderItem from "./OrderItem";
import DataBox from "../partials/DataBox";
import {Link} from "react-router-dom";
import routes from "../../routes";
import feather from "feather-icons";
import {allCashincomes} from "../cashincomes/CashincomeService";
import CashincomeItem from "../cashincomes/CashincomeItem";
import jwtDecode from "jwt-decode";
import OrdersListLoading from "../../components/loadings/OrdersListLoading";

class OrdersList extends React.Component {
    constructor(props) {
        super(props);

        const search = this.props.location.search;
        let type = new URLSearchParams(search);
        const params = Object.fromEntries(type.entries());
        type=params.type;

        this.state = {
            currentPage: 1,
            total : 1,
            last_page : 1,
            searchQuery: "",
            sortColumn: { path: "", order: "" },
            orders:[],
            loadingForm: true,
            orderType:type?type:'buy'
        };


        this.props.dispatch(updatePageTitle(
            'سفارش ها',
            [
                {
                    'title': 'لیست سفارش ها',
                    'active': true
                }
            ],

            (
                <React.Fragment>

                    <Link className={'btn btn-primary primary btn-sm'} to={routes.orders_service}>
                        <i className='d-inline-block ml-1' dangerouslySetInnerHTML={{__html: feather.icons['shopping-cart'].toSvg()}}/>
                        ثبت سفارش جدید
                    </Link>

                </React.Fragment>
            )
        ));
    }

    componentDidMount() {
        this.getData();
    }

    getData= () => {
        this.setState({
            loadingForm:true
        })

        all(this.state.currentPage).then(res=>{
            const orders=jwtDecode(res.orders);
            // const orders=(res.orders);
            this.setState({
                total : orders.total,
                last_page : orders.last_page,
                orders:orders.data,
                loadingForm:false
            })
        })
    }


    handlePageChange = page => {
        if (page === 'next')
            this.setState({currentPage: this.state.currentPage+1},()=> this.getData());
        else if (page === 'prev')
            this.setState({currentPage: this.state.currentPage-1},()=> this.getData());
        else
            this.setState({currentPage: page},()=> this.getData());
    };


    render() {

        const {last_page, currentPage } = this.state;
        const { orders } = this.state;

        return (
            <>
                {
                    this.state.loadingForm ?
                        <OrdersListLoading />
                        :
                        <React.Fragment>


                            <DataBox data={orders}/>

                            {
                                this.state.orderType==='buy'?
                                    orders.map(order=>(
                                        <div key={order.id} className='card card-body card-table'>
                                            <OrderItem
                                                order={order}
                                            />
                                        </div>
                                    ))
                                    :
                                    orders.map(order=>(
                                        <div key={order.id} className='card card-body card-table'>
                                            <CashincomeItem
                                                cash={order}
                                            />
                                        </div>
                                    ))
                            }



                            <Pagination
                                total={last_page}
                                currentPage={currentPage}
                                onPageChange={this.handlePageChange}
                            />
                        </React.Fragment>

                }
            </>


        )
    }

}

export default connect ()(OrdersList);
