import React from 'react';

import {connect} from "react-redux";

class HeaderForm extends React.Component {

    render() {
        return (
          <>
              <h5 className='d-flex align-items-center justify-content-between'>
                  <div className='d-flex align-items-center title'>
                      <div className={'svg_b '+this.props.k} >
                          <svg className="icon"
                               dangerouslySetInnerHTML={{__html: '<use xlink:href="'+this.props.icon+'"></use>'}}/>
                      </div>

                      {this.props.title}
                  </div>
                  <div onClick={()=>this.props.closepopup()} className="close">
                      <i className='fe-x'></i>
                  </div>
              </h5>

          </>
       );
    }

}

export default connect()(HeaderForm);
