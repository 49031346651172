import React from 'react';
import {connect} from "react-redux";
import Form from "../../ubold/commons/form";
import {Link} from "react-router-dom";
import routes from "../../routes";

class TicketItem extends Form {

    render() {

        const {ticket}=this.props;

        return (
            <>
                <div className="row align-items-center">
                    <div className="col-lg-7 col-sm-7 col-7">
                        <div className="row align-items-center">
                            <div className="col-xl-4 col-lg-3">
                                <div>
                                    شماره :
                                    <span className="mr-1">
                                        <Link to={routes.ticket_show+ticket.ticket_id}>#{ticket.ticket_id}</Link>
                                    </span>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-5">
                                {ticket.title}
                            </div>
                            <div className="col-xl-4 col-lg-4">
                                 ثبت :
                                <span
                                    className={' mr-1 ' }>{ticket.shamsi_created_at}</span>

                            </div>


                        </div>
                    </div>
                    <div className="col-lg-5 col-sm-5 col-5">
                        <div className="row align-items-center">

                            <div className="col-xl-6 col-lg-6">
                                <div className="">

                                    <div>
                                        وضعیت :
                                        <span
                                            className={'badge d-inline-block mr-1 badge-' + ticket.status_text.class}>{ticket.status_text.label}</span>

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 ">
                                <div className="d-block d-md-none">
                                    <div className="mb-3"></div>
                                </div>
                                <div>

                                    <Link to={routes.ticket_show+ ticket.ticket_id} className='btn btn-default btn-xs'><i className='fe-eye'></i> مشاهده تیکت</Link>                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }

}

export default connect ()(TicketItem);
