import React from 'react';
import Form from "../../ubold/commons/form";
import Joi from "joi-browser";
import {convertArrayToDate} from '../../services/helpers';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import {connect} from "react-redux";

import Birthday from "../auth/components/Birthday";
import {completeProfileNew} from "./userService";


class CompleteInformtions extends Form {
    constructor(props) {
        super(props);

        var user = this.props.user;

        var vl = {};
        var formFields = [];

        if(user.legal){
            const branch= user.branch;

            if (!branch || (branch && !branch.nationalCode)) {
                vl['nationalCode'] = Joi.string().required().label("شناسه ملی شرکت ");
                formFields.push({
                    name: 'nationalCode',
                    label: 'شناسه ملی شرکت ',
                    type: 'input',
                    inputType: 'text',
                })
            }

            if (!branch || (branch && !branch.economic_code)) {
                vl['economic_code'] = Joi.string().required().label("کد اقتصادی");
                formFields.push({
                    name: 'economic_code',
                    label: 'کد اقتصادی',
                    type: 'input',
                    inputType: 'text',
                })
            }

        } else {
            if (!user.first_name) {
                vl['first_name'] = Joi.string().required().label("نام ");
                formFields.push({
                    name: 'first_name',
                    label: 'نام',
                    type: 'input',
                    inputType: 'text',
                })
            }

            if (!user.last_name) {
                vl['last_name'] = Joi.string().required().label("نام خانوادگی ");
                formFields.push({
                    name: 'last_name',
                    label: 'نام خانوادگی',
                    type: 'input',
                    inputType: 'text',
                })
            }

            if (!user.national_code) {
                vl['national_code'] = Joi.string().required().label("کد ملی");

                formFields.push({
                    name: 'national_code',
                    label: 'کد ملی',
                    type: 'input',
                    inputType: 'number',
                })
            }

            if (!user.birthday_only) {
                vl['birthday'] = Joi.string().required().label("تاریخ تولد ");

                formFields.push({
                    name: 'birthday',
                    label: 'تاریخ تولد',
                    type: 'date',
                    inputType: 'date',
                })
            }
        }



        this.state = {
            loadingForm: true,
            data: {},
            errors: {},
            fileds: formFields,
            birthday: user.birthday,
            loading: false,
        };

        this.schema = vl;

    }


    doSubmit = async () => {
        try {
            var data = this.state.data;
            const FData = new FormData();

            Object.keys(data).forEach(item => {
                FData.append(item, data[item]);
            });

            const res =await completeProfileNew( FData);
            window.location.reload(true);

        } catch (ex) {
            console.log(ex);
            this.toggle();
        }
    };


    handleChangeBirthday = (value) => {
        var {data} = this.state;
        data['birthday'] = convertArrayToDate(value);

        const errors = this.state.errors;
        delete errors['birthday'];

        this.setState({data, errors});

    }

    render() {

        return (
            <form method="POST" onSubmit={this.handleSubmit}>

                <h5 className="mb-4">
                    {
                        this.props.user.legal?"لطفا ابتدا اطلاعات زیر را مطابق با اطلاعات شرکت خودتان تکمیل کنید":"لطفا ابتدا  اطلاعات خود را مطابق با کارت ملی تکمیل کنید"
                    }
                    </h5>
                {
                    this.state.fileds.map((item, index) => (
                        <div key={index} className="form-group mb-3">
                            {
                                item.type === 'input' ?
                                    this.renderInput(item.name, item.label, item.inputType, '', '', '', false, '', '', null)
                                    : item.type === 'date' ?
                                        <div className="form-group rtl-label">
                                            <label>تاریخ تولد</label>

                                            <Birthday callback={(date) => this.handleChangeBirthday(date)}
                                                      day={this.state.birthday.day} year={this.state.birthday.year}
                                                      month={this.state.birthday.month}/>


                                            {this.state.errors.birthday &&
                                                <small className="font-11 error-label">{this.state.errors.birthday}</small>}

                                        </div>
                                        : ""
                            }

                        </div>
                    ))
                }

                <div className="form-group mt-5">
                    {this.renderButton(<span> به روز رسانی</span>, 'btn-lg btn-block')}
                </div>
            </form>
        )
    }

}


export default connect()(CompleteInformtions);
