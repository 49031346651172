import React from 'react';

class Squre extends React.Component {

    render() {
        var count=this.props.count?this.props.count:10;
        var html=[];
        for (var i=1;i<=count;i++){
            html.push(<div className={'roww '+(this.props.small?'small':"")}></div>)
        }
        return (
            <div className='base tablel'>
                <div className="head"></div>
                {html}
            </div>
        )
    }
}

export default Squre;
