import React from 'react';

class GiftQty extends React.Component {

    constructor(props) {
        super(props);

        this.state={
            order: this.props.order,
            maxGiftQty:parseInt(this.props.maxGiftQty) ,
            qty: parseInt(this.props.value?this.props.value:1),
        }
    }

    upOrDown = (type)=>{
        var v = this.state.qty ;
        if(type==='up'){
            if(this.state.qty<this.state.maxGiftQty){
                v=v+1;
                this.setState({qty:v});
                this.props.handleCalculate(v);
            }
        }
        if(type==='down'){
            v=v-1;
            if(this.state.qty>1){
                this.setState({qty:v});
                this.props.handleCalculate(v);
            }
        }

    }

    render() {
        return (
            <React.Fragment>

                <div className="form-group">
                        <div className="row mb-3">
                            <div className="col ">
                                <button id="upQty" onClick={()=>this.upOrDown('up')} disabled={this.state.qty >= this.state.maxGiftQty} className={"btn btn-block btn-pr-qty "+(this.state.qty >= this.state.maxGiftQty?"btn-disabled":"")} type="button" >+</button>
                            </div>
                            <div className="col-5 p-0">
                                <input type="number" style={{width:'97%'}} value={this.state.qty} className="form-control input-qty" readOnly="true" name="qty" id="qty" />
                            </div>
                            <div className="col ">
                                <button id="downQty" disabled={(this.state.qty<=1)}  onClick={()=>this.upOrDown('down')} disabled={this.state.qty <= 1}  className={"btn btn-block btn-pr-qty "+(this.state.qty<=1 ? "btn-disable":'' )} type="button" >-</button>
                            </div>
                        </div>
                    <div  className="help-block">حداکثر تعداد سفارش <strong>{this.props.maxGiftQty}</strong> عدد است.</div>
                </div>

            </React.Fragment>
        )
    }

}

export default GiftQty;
