import React from 'react';
import AvatarEditor from 'react-avatar-editor'


class Cropper extends React.Component {

    constructor(props) {
        super(props);

        this.state={
            scale:1,
            selectImage:null,
            editor:null,
        }
    }

    onChangeScale =(input)=>{
        var s= input.target.value;
        this.setState({scale:s})
    }

    saveImage =()=>{

        const canvas = this.state.editor.getImage().toDataURL();
        const me=this;

        fetch(canvas)
            .then(res => res.blob())
            .then(blob => {
                var url=window.URL.createObjectURL(blob);

                me.setState({
                    selectImage:url
                });

                me.props.onApplyImage(url,blob);
            });
    }

    setEditorRef = (editor) => (this.setState({editor:editor}))

    render() {
        return (
           <>
               <div  style={{width:'200px'}}>
                   <AvatarEditor
                       ref={this.setEditorRef}
                       image={this.props.image}
                       className={'cropper'}
                       width={200}
                       height={200}
                       border={2}
                       borderRadius={10}
                       color={[0, 0, 0, 0.07]} // RGBA
                       scale={this.state.scale}
                       rotate={0}
                   />

                   <button className='btn btn-block btn-primary' type='button' onClick={()=>this.saveImage()}>
                       <i className='fe-save'></i>   تایید و ادامه
                   </button>
               </div>

           </>
        )
    }

}

export default Cropper;
