import React from "react";
import {Link} from "react-router-dom";
import routes from "../../../routes";
import {getVerification} from "../../../services/helpers";
import {connect} from "react-redux";
import {Modal, ModalBody, ModalHeader} from "reactstrap";


class A extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
        };
    }

    toggleModal = () => {
        this.setState({modal: !this.state.modal});
    }

    render() {
        const {user,info}=this.props;

        return (
            <>
                <Modal isOpen={this.state.modal} toggle={this.toggleModal} className='credit '>
                    <ModalHeader toggle={this.toggleModal}> ارسال ویریو احراز هویت</ModalHeader>
                    <ModalBody>
                        <p>با سلام و احترام</p>
                        <p> جهت تغییر سطح به VIP-4 ويديو احراز هويت را ارسال نماييد.</p>
                        <p>ابتدا کارت ملي و کارت بانکي خود را از نزديک نشان داده و متن زير را با صداي رسا بخوانيد و
                            ويدئو ضبط شده را به شماره <strong className="text-danger">09369948766</strong> واتساپ نماييد
                            اينجانب (نام و نام خانوادگي) درخواست تاييد مدارک خود در سايت کافه ارز را دارم. همچنين تعهد
                            مي&zwnj;دهم تمامي خريدها براي شخص خودم بوده و حساب کاربري خود را در اختيار هيچ فرد حقيقي يا
                            حقوقي ديگري نميگذارم. در عين حال ضمانت مي&zwnj;کنم منابع تمامي وجوه واريزي به مجموعه&zwnj;ي
                            کافه ارز مشخص و پاک مي&zwnj;باشد و در صورت شُبه&zwnj;ِ دار بودن وجوه واريزي، عواقب ناشي از
                            آن را به عهده گرفته و هرگونه خسارتي را که مجموعه کافه ارز ادعا نمايد، پرداخت خواهم کرد</p>
                        <p>موفق باشید</p>

                        <div className="alert alert-warning d-block">
                            لطفا پس از ارسال ویدیو جهت بررسی از طریق
                            <Link className='d-inline-block ml-1 mr-1' to={routes.ticket_create}>پشتیبانی
                                سایت</Link> با ما در ارتباط باشید

                        </div>

                    </ModalBody>
                </Modal>


                <p>پس از تکمیل موارد زیر سطح شما به صورت اتوماتیک به
                    <strong className={info.next_level_class+' d-inline-block ml-1 mr-1'}>{info.next_level_label}</strong>
                    تغییر می یابد.
                </p>
                <table className="table">
                    <tr>
                        <th><Link to={routes.profile_verify}>تایید ایمیل</Link></th>
                        <td>
                            {getVerification(user.verifications,'EMAIL').is_verify?
                                <span className="badge badge-success">تایید شده</span>
                                :
                                <span className="badge badge-danger">تایید نشده</span>
                            }

                        </td>
                    </tr>
                    <tr>
                        <th><Link to={routes.profile_verify}> تایید موبایل </Link></th>
                        <td>
                            {getVerification(user.verifications,'MOBILE').is_verify?
                                <span className="badge badge-success">تایید شده</span>
                                :
                                <span className="badge badge-danger">تایید نشده</span>
                            }

                        </td>
                    </tr>
                    <tr>
                        <th><Link to={routes.profile_verify}>ارسال تصویر کارت ملی </Link></th>
                        <td>
                            {getVerification(user.verifications,'NATIONAL_ID').is_verify?
                                <span className="badge badge-success">تایید شده</span>
                                :
                                <span className="badge badge-danger">تایید نشده</span>
                            }
                        </td>
                    </tr>
                    <tr>
                        <th><Link to={routes.profile_verify}>ارسال تصویر سلفی و تعهد نامه </Link></th>
                        <td>
                            {getVerification(user.verifications,'CREDIT').is_verify?
                                <span className="badge badge-success">تایید شده</span>
                                :
                                <span className="badge badge-danger">تایید نشده</span>
                            }

                        </td>
                    </tr>
                    <tr>
                        <th><Link to={routes.profile_verify}>تایید تلفن ثابت </Link></th>
                        <td>
                            {getVerification(user.verifications,'PHONE').is_verify?
                                <span className="badge badge-success">تایید شده</span>
                                :
                                <span className="badge badge-danger">تایید نشده</span>
                            }

                        </td>
                    </tr>
                    <tr>
                        <th><Link to={routes.profile_bank_credits}> داشتن حداقل یک کارت بانکی تایید شده</Link></th>
                        <td>
                            {info.credits_confirmed > 0?
                                <span className="badge badge-success">
                                    <span className="ml-1">{info.credits_confirmed}</span>
                                    عدد
                                    ثبت شده</span>
                                :
                                <span className="badge badge-danger">ثبت نشده</span>
                            }

                        </td>
                    </tr>
                    <tr>
                        <th><span> ارسال ویدیو احراز هویت </span></th>
                        <td>
                            {info.video_accept?
                                <span className="badge badge-success">ثبت شده</span>

                                :
                                <button onClick={()=>this.toggleModal()} className="btn btn-warning btn-sm" type="button">
                                    <i className="flaticon-upload"></i>
                                    ارسال ویدیو</button>
                            }

                        </td>
                    </tr>
                </table>
            </>
        )
    }

}


export default connect()(A);

