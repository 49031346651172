import React from 'react';
import PriceItem from "./PriceItem";
import PriceItemElectronic from "./PriceItemElectronic";

class ElectronicPrices extends React.Component {


    render() {
        return (
            <>
                <div className="header">

                    <div className="dg-name">
                        عکس
                    </div>
                    <div className="dg-price">
                        نام ارز
                    </div>

                    <div className=" dg-price ">
                        قیمت خرید
                    </div>
                    <div className=" dg-price ">
                           قیمت فروش
                    </div>


                </div>
                <div>
                    { this.props.services &&
                        this.props.services.data.map(service =>(
                            <PriceItemElectronic key={'elc'+service.id} service={service}/>
                        ))
                    }
                </div>
            </>
        )
    }

}


export default ElectronicPrices;
