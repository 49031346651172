import { Component } from "react";
import auth, {clearStorage} from "../../../modules/auth/authService";
import { showAppLoader} from "../../../actions/app";
import {connect} from "react-redux";
import {url} from "../../../config.json";

class Logout extends Component {
    constructor(props) {
        super(props);
        this.props.dispatch(showAppLoader());
    }

  componentDidMount() {
      try {
           auth.logout();
      }
      catch (e) {
      }
  }

  render() {
    return null;
  }
}

export default connect()(Logout);
