import React from 'react';
import {updatePageTitle} from "../../actions/breadcrumbs";
import {connect} from "react-redux";
import Pagination from "../../ubold/commons/pagination";
import {allNotifications} from "./NotificationsService";
import FormLoader from "../../ubold/commons/FormLoader";
import jwtDecode from "jwt-decode";
import EventsTable from "./EventsTable";
import PricesLoading from "../../components/loadings/PricesLoading";

class EventItems extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPage: 1,
            total: 1,
            last_page: 1,
            events: [],
            loadingForm: true,
            sortColumn:'id'
        };

        this.props.dispatch(updatePageTitle(
            ' رویداد ها',
            [
                {
                    'title': 'رویداد ها',
                    'active': true
                }
            ]
        ));
    }

    componentDidMount() {
        this.getData();
    }

    getData = (no_read=false) => {
        this.setState({
            loadingForm: true
        });

        allNotifications(this.state.currentPage,'event').then(res=>{
            let events=jwtDecode(res.data.events);
            this.setState({
                events:events.data,
                total: events.total,
                last_page:events.last_page,
                loadingForm:false
            });
        });
    }

    handlePageChange = page => {
        if (page === 'next')
            this.setState({currentPage: this.state.currentPage + 1}, () => this.getData());
        else if (page === 'prev')
            this.setState({currentPage: this.state.currentPage - 1}, () => this.getData());
        else
            this.setState({currentPage: page}, () => this.getData());
    };

    render() {

        const {last_page,  currentPage,events,sortColumn} = this.state;

        return (
            <React.Fragment>
                {
                    this.state.loadingForm ?
                        <PricesLoading/>
                        :
                        <React.Fragment>
                            <div className="card card-body ">
                                <div className="table-box">
                                    <EventsTable
                                        events={events}
                                        sortColumn={sortColumn}
                                        onDelete={this.handleDelete}
                                        onSort={()=>this.handleSort}
                                    />
                                </div>

                            </div>
                            <Pagination
                                total={last_page}
                                currentPage={currentPage}
                                onPageChange={this.handlePageChange}
                            />
                        </React.Fragment>
                }
            </React.Fragment>
        )
    }

}

export default connect ()(EventItems) ;