import React from 'react';
import Form from "../../../ubold/commons/form";
import http from "../../../services/httpService";
import Joi from "joi-browser";
import auth from "../../auth/authService";


class ChangePassForm extends Form {
    constructor(props) {
        super(props);

        this.state = {
            data: {
                current_password:'',
                password:'',
                password_confirmation:'',
            },
            errors: {},
            loading: false,
        };

        this.schema = {
            current_password: Joi.string()
                .required()
                .label("رمز عبور فعلی"),
            password: Joi.string()
                .required()
                .label("رمز عبور جدید "),
            password_confirmation: Joi.string()
                .required()
                .label("تکرار رمز عبور "),

        };

    }


    doSubmit = async () => {
        try {
            var data = this.state.data;
            http.submit('post',  '/user/update/change-password', data).then(res => {
                auth.logout();
            }).catch(res => {
                this.toggle();
            });

        } catch (ex) {
            this.toggle();
        }
    };

    render() {

        return (
            <form method="POST" onSubmit={this.handleSubmit}>

                                {
                                    this.renderInput('current_password', 'رمز عبور فعلی ', 'password')
                                }

                                {
                                    this.renderInput('password', 'رمز عبور جدید ', 'password')
                                }

                                {
                                    this.renderInput('password_confirmation', ' تکرار رمز عبور', 'password')
                                }

                               <div className="form-group mt-4">
                                   {this.renderButton(<span> به روز رسانی</span>, '')}
                               </div>

                            </form>
        )
    }

}


export default ChangePassForm;
