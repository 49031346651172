import React from 'react';
import {updatePageTitle} from "../../actions/breadcrumbs";
import {connect} from "react-redux";
import {getOrder} from "../../modules/orders/OrderService";
import FormLoader from "../../ubold/commons/FormLoader";
import Form from "../../ubold/commons/form";
import OrderInvoice from "./OrderInvoice";
import routes from "../../routes";
import Alert from "../partials/Alert";
import Status from "../partials/Status";
import {formatMoney, getUrlWithUserAttr} from "../../services/helpers";
import moment from "moment";
import OrderShowLoading from "../../components/loadings/OrderShowLoading";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {toast} from "react-toastify";

var todayDate = moment('01-02-2022', 'DD-MM-YYYY');
var x=null;

class OrderShow extends Form {
    constructor(props) {
        super(props);

        this.state = {
            order: [],
            is_crypto: '',
            is_night: '',
            order_id: this.props.match.params.id,
            loadingForm: true,
            canShowMarketStatus: false
        };

        this.props.dispatch(updatePageTitle(
            'سفارش شماره # ' + this.state.order_id,
            [
                {
                    'title': 'لیست سفارشات',
                    'link': routes.orders
                },
                {
                    'title': 'سفارش شماره # ' + this.state.order_id,
                    'active': true
                }
            ]
        ));
    }

    componentDidMount() {
      this.getOrder();
    }


    getOrder = () => {
        getOrder(this.state.order_id).then(res => {
            this.setState(
                {
                    order: res.data.order,
                    loadingForm: false
                }
            );

            const order=res.data.order;

            if (order.service.st === 'digital') {
                var dDiff = todayDate.diff(order.created_at);
                if (dDiff < 0) {
                    this.setState(
                        {
                            canShowMarketStatus: true
                        }
                    );
                }
            }

        });
    }

    render() {
        const {order} = this.state;

        return (
            <React.Fragment>
                {
                    this.state.loadingForm ?
                        <OrderShowLoading/>
                        :
                        <React.Fragment>
                            <div className="order-show">
                                <div className='card card-body ticket-info '>
                                    <h5><img alt={order.id} src={order.order_icon} height={30} width={30}/>
                                        سفارش &nbsp;{order.title}</h5>
                                    <div
                                        className='row  justify-content-between align-items-center mt-3 ticket-container'>
                                        <div className="col-md-3 col-6">
                                            <div className="info">
                                                میزان
                                                <div>
                                                    {order.amount} <small>{order.currency_name}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="info">
                                                نرخ
                                                <div>
                                                    {formatMoney(order.currency_price)} <small>تومان</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="info">
                                                تاریخ ثبت
                                                <div>
                                                    {order.shamsi_created_at}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="info">
                                                وضعیت
                                                <div>
                                                    {
                                                        <Status status={order.status_text}/>
                                                    }

                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <Alert type={order.status_text.class} title={
                                    <span> سفارش در وضعیت
                                <b className='d-inline-block ml1 mr-1 ml-1'>{order.status_text.label}</b>
                                قرار دارد</span>
                                }>

                                    <p>
                                        {(order.status === 'PENDING' || order.status === 'PROCESSING') ?
                                            <div> کار شناسان ما در حال بررسی و پیگیر ادامه روند انجام سفارش هستند. از
                                                صبوری
                                                شما متشکریم
                                            </div>
                                            :
                                            ''
                                        }
                                        {order.status === 'SUSPEND' ?
                                            <div>
                                                سفارش شما معلق شده است، لطفا با کارشناسان ما همکاری لازم را انجام دهید
                                            </div>
                                            :
                                            ''
                                        }
                                        {order.status === 'CANCEL' ?
                                            <div>
                                                سفارش شما کنسل شده و قابل پیگیری نیست، در صورت نیاز به این سفارش مجددا
                                                آن را
                                                ثبت کنید
                                            </div>
                                            :
                                            ''
                                        }
                                        {order.status === 'DONE' ?
                                            <div>
                                                سفارش شما با موفقیت انجام شده است.
                                            </div>
                                            :
                                            ''
                                        }
                                    </p>

                                    {
                                        ( order.status === 'DONE' &&  order.order_info.length > 0) ?
                                            <div className={''} style={{
                                                padding:'10px',

                                            }}>

                                                {
                                                    order.order_info.map(item=>(
                                                        <div className={'p-2 mb-1'} style={{
                                                            borderRadius:'10px',
                                                            background: "rgba(43,187,84,0.24)",
                                                            color:"#035e06",
                                                            textShadow:" 0 0 0 rgba(0, 0, 0, 0.6)"
                                                        }}>
                                                            <span className={'ml-2 d-inline-block'} style={{width:'100px'}}>{item.title} : </span>

                                                            <div className={'d-inline-block mt-2'}>
                                                                <CopyToClipboard text={item.value} onCopy={() => toast.success(item.title+ ' کپی شد') }>
                                                                    <button title={'کپی'} className={'btn-copy darkk'}>
                                                                        <i className='fe-copy'></i>
                                                                    </button>
                                                                </CopyToClipboard>
                                                            </div>

                                                            <span className={'mr-2 d-inline-block text-dark'}>{item.value}</span>
                                                        </div>
                                                    ))
                                                }

                                            </div>
                                            : ''
                                    }

                                    {
                                        order.admin_description ?
                                            <div>
                                                <hr/>
                                                <div style={{padding: 0}}><strong style={{fontWeight: 800}}>
                                                    توضیحات ادمین</strong>
                                                </div>

                                                <div dangerouslySetInnerHTML={{__html: order.admin_description}}/>

                                            </div>
                                            : ''
                                    }

                                    {

                                        order.cart_transaction ?
                                            <div className="mt-3"
                                                 style={{background: "rgba(0,0,0,0.05)", padding: '15px'}}>
                                                <p style={{padding: 0}}>
                                                    <strong style={{fontWeight: 800}}>
                                                        <i className="fe-credit-card"></i> &nbsp;
                                                        شماره تراکنش پرداخت سفارش
                                                    </strong>
                                                </p>
                                                {order.cart_transaction}
                                            </div>
                                            : ""
                                    }


                                    {
                                        order.file_attachs.length > 0 ?
                                            <div className='mt-3'
                                                 style={{padding: '5px', fontSize: ' 12px', boxShadow: 'none'}}>
                                                <hr/>
                                                <p><strong> فایل پیوست </strong></p>

                                                <div className="mt-3">
                                                    <ul>
                                                        {order.file_attachs.map(file =>
                                                            <li key={file.name}>
                                                                <a rel='noreferrer' target="_blank"
                                                                   href={getUrlWithUserAttr(file.url,order.secure_id)}><i
                                                                    className='fa fa-paperclip'></i> {file.name}
                                                                </a>
                                                            </li>
                                                        )}

                                                    </ul>

                                                </div>
                                            </div>
                                            : ''
                                    }


                                </Alert>

                                {
                                    (order.service.st === 'digital' && this.state.canShowMarketStatus) ?
                                        <>
                                            <div className="card card-body">
                                                <h4>وضعیت تراکنش در شبکه</h4>

                                                <div className="ltr">
                                                    <div>
                                                        <strong>TxId (Transaction Hash)
                                                            : </strong> {order.tx_id ? order.tx_id : 'در انتظار دریافت'}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        : ""
                                }


                                <div className="row h-100">
                                    <div className={"col-md-6 "}>
                                        {

                                            <OrderInvoice order={order}/>

                                        }

                                    </div>
                                    <div className={"col-md-6 "}>
                                        {
                                            order.meta_list.length > 0 ?
                                                <div className='card card-body height-100'>
                                                    <h2 className="card-title mb-4">اطلاعات سفارش</h2>

                                                    {
                                                        order.meta_list.map(meta =>
                                                            <p key={meta.label}>
                                                                {meta.label} {' : '} {meta.value}
                                                            </p>
                                                        )
                                                    }
                                                </div>

                                                : ''
                                        }
                                    </div>
                                </div>

                            </div>
                        </React.Fragment>
                }
            </React.Fragment>
        )
    }

}

export default connect()(OrderShow);
