import React from 'react';
import Squre from "../skelete/Squre";
import SkeleteLoading from "../SkeleteLoading";

class DashboardLoading extends React.Component {

    render(){
        return (
            <React.Fragment>
                <SkeleteLoading>
                    <div className="row">
                        <div className="col-md-12"><Squre height={150}/></div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-2 col-4 mb-3"><Squre height={100}/></div>
                        <div className="col-md-2 col-4 mb-3"><Squre height={100}/></div>
                        <div className="col-md-2 col-4 mb-3"><Squre height={100}/></div>
                        <div className="col-md-2 col-4 mb-3"><Squre height={100}/></div>
                        <div className="col-md-2 col-4 mb-3"><Squre height={100}/></div>
                        <div className="col-md-2 col-4 mb-3"><Squre height={100}/></div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-6"><Squre height={300}/></div>
                        <div className="col-md-6"><Squre height={300}/></div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-12"><Squre height={400}/></div>
                    </div>
                </SkeleteLoading>
            </React.Fragment>
        );
    }
}

export default DashboardLoading;
