import React from 'react';
import Squre from "../skelete/Squre";
import SkeleteLoading from "../SkeleteLoading";

class OrderServicesWithCatLoading extends React.Component {

    render(){

        return (
            <React.Fragment>
                <SkeleteLoading>
                    <div className="row justify-content-center mt-4">
                        <div className="col-xl-6 col-lg-8">
                            <div className="mb-2"><Squre height={53} /></div>
                            <div className="mb-2"><Squre height={53} /></div>
                            <div className="mb-2"><Squre height={53} /></div>
                            <div className="mb-2"><Squre height={53} /></div>
                            <div className="mb-2"><Squre height={53} /></div>

                        </div>
                    </div>
                </SkeleteLoading>
            </React.Fragment>
        );
    }
}

export default OrderServicesWithCatLoading;
