import React from 'react';
import {connect} from "react-redux";
import Form from "../../ubold/commons/form";
import {all, deleteCard} from "../../modules/user/BankCardService";
import BankCardForm from "./forms/BankCardForm";
import Layout from "./Layout";
import {updatePageTitle} from "../../actions/breadcrumbs";
import {toast} from "react-toastify";
import jwtDecode from "jwt-decode";
import UseBankCardsLoadings from "../../components/loadings/UseBankCardsLoadings";


class UserBankCards extends Form {
    constructor(props) {
        super(props);
        this.state = {
            loadingForm: true,
            data: {},
        };

        this.props.dispatch(updatePageTitle(
            'افزودن کارت های بانکی',
            [
                {
                    'title': 'افزودن کارت های بانکی',
                    'active': true
                }
            ]
        ));
    }

    componentDidMount() {
        this.getData();
    }

    getData(){
        all().then(res => {
            const data= jwtDecode(res.data.data);
            this.setState({
                data: data,
                loadingForm: false
            });
        });
    }

    onDelete=(id,isTasvie)=>{
        this.setState({loadingForm:true});

        deleteCard(id,isTasvie).then(res=>{
            this.setState({loadingForm:false,  data: jwtDecode(res.data.data)});
            toast.success(res.data.message);
        });
    }

    render() {


        return (
            <Layout>
                <div className="mt-4">
                    {
                        this.state.loadingForm ?
                            <UseBankCardsLoadings/>
                            :
                            <BankCardForm ondelete={this.onDelete} data={this.state.data}/>

                    }
                </div>
            </Layout>
        )
    }

}


export default connect()(UserBankCards);
