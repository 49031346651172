import React from 'react';
import {connect} from "react-redux";
import Form from "../../ubold/commons/form";

class LoginInfoRecord extends Form {

    render() {

        const {info}=this.props;

        return (
            <>
                <div className="row align-items-center ">
                    <div className="col-lg-7 col-sm-7 col-7">
                        <div className="row align-items-center">
                            <div className="col-xl-4 col-lg-3">
                                <div>
                                    <span className="mr-1">
                                        {info.created_at}
                                    </span>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-7">
                                <div>
                                    <div style={{opacity:'1'}}>  {info.ip}</div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className="col-lg-5 col-sm-5 col-5">
                        <div className="row align-items-center">

                            <div className="col-xl-6 col-lg-6">
                                <div className="">
                                    <span
                                        className={'badge d-inline-block mr-1 ' }>{info.data.device_type}</span>


                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 ">
                                <div className="d-block d-md-none">
                                </div>
                                <div>
                                    <div>
                                        مرورگر :
                                        <span>{info.data.browser}</span>

                                    </div>
                                    <div style={{opacity:0.5}}>پلتفرم : {info.data.platform}</div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }

}

export default connect ()(LoginInfoRecord);
