import React, { Component } from "react";
import Table from "../../ubold/commons/table";

import moment from "moment-jalaali";

class ReferralsTable extends Component {

    columns = [
        {
            path: "name",
            label: "نام و نام خانوادگی",
        },
        {
            path: "created_at",
            label: "ثبت نام",
            content : user=> moment(user.created_at).locale('fa').format('jYYYY/jMM/jDD - HH:mm')

        },


    ];

    render() {
        const { users, onSort, sortColumn } = this.props;
        return (
            <React.Fragment>
                <Table
                    columns={this.columns}
                    data={users}
                    sortColumn={sortColumn}
                    onSort={onSort}
                />
            </React.Fragment>
        );
    }
}

export default ReferralsTable;
