import http from "./../../services/httpService";
const prefix='/orders/';

export function getCoins(exchanger) {
    return http.post( prefix+'get/coins?exchanger='+exchanger);
}

export function getCoin(id) {
    return http.post( prefix+'get/coin/'+id);
}

export function getServiceCategories() {
    return http.post( prefix+'get/service-categories-services/panel');
}

export function getServicesByCategory(id) {
    return http.post( prefix+'get/services-by-category/'+id);
}

export function exchangePriceByType(id,type,amount,orderType) {
    return http.post( prefix+'crypto/exchange/price?id=' + id + '&t=' + type+'&amount=' + amount+'&ex_type='+orderType);
}

export function getService(id,network,type) {
    return http.post( prefix+'crypto/get/service?id=' + id + '&ntt=' + network+'&order_type='+type);
}

export function getCryptoMinAmount(id,network,type) {
    return http.post( prefix+'crypto/service/min-amount?id=' + id + '&ntt=' + network+'&order_type='+type);
}

export function calculateCrypto(data) {
    return http.post( prefix+'crypto/calc/service' ,data);
}

export function getOrderForm(id) {
    return http.post( prefix+'form/'+id);
}

export function all(currentPage,take=false) {
    return http.submit('post', prefix+'list', {page:currentPage,take:take});
}

export function create(id,data) {
    return http.post( prefix+'create/'+id, data);
}

export function calculate(id,data) {
    return http.post( prefix+'calculate/panel/'+id,data);
}

export function storeOrder(id,data,type='buy') {
    return http.post( prefix+'create/'+id+'?order_type='+type,data);
}

export function updateOrder(id,data) {
    return http.post( prefix+'update/'+id,data);
}

export function getOrder(id) {
    return http.post( prefix+id);
}





