import React from 'react';
import {updatePageTitle} from "../../actions/breadcrumbs";
import {connect} from "react-redux";
import Pagination from "../../ubold/commons/pagination";
import {bills} from "./FinanceService";
import FormLoader from "../../ubold/commons/FormLoader";
import routes from "../../routes";
import { Link} from "react-router-dom";
import DataBox from "../partials/DataBox";
import BillItem from "./BillItem";
import jwtDecode from "jwt-decode";
import {spilitNumber} from '../../services/helpers'
import FinanceListLoading from "../../components/loadings/FinanceListLoading";
const feather = require('feather-icons')


class FinanceList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPage: 1,
            total : 1,
            last_page : 1,
            searchQuery: "",
            sortColumn: { path: "", order: "" },
            transactions:[],
            loadingForm: true,
        };


        this.props.dispatch(updatePageTitle(
            'کیف پول من',
            [
                {
                    'title': 'لیست صورت حساب ها',
                    'active': true
                }
            ]
        ));
    }

    componentDidMount() {
       this.getData();
    }

    getData= () => {
        this.setState({
            loadingForm:true
        })

        bills(this.state.currentPage).then(res=>{
            this.setState({
                total : res.data.pagination.total,
                last_page : res.data.pagination.last_page,
                transactions:jwtDecode(res.data.data).data,
                loadingForm:false
            });
        })
    }

    handlePageChange = page => {
        if (page === 'next')
            this.setState({currentPage: this.state.currentPage+1},()=> this.getData());
        else if (page === 'prev')
            this.setState({currentPage: this.state.currentPage-1},()=> this.getData());
        else
            this.setState({currentPage: page},()=> this.getData());

    };

    getPagedData = () => {
        const {
            searchQuery,
            transactions
        } = this.state;

        let filtered = transactions;
        if (searchQuery)
            filtered = transactions.filter(m =>
                m.title.toLowerCase().startsWith(searchQuery.toLowerCase())
            );

        return { totalCount: filtered.length, data: filtered };
    };

    render() {

        const {last_page, currentPage } = this.state;
        const {user } = this.props;
        const { data: transactions } = this.getPagedData();

        return (
            <React.Fragment>
                    {
                        this.state.loadingForm ?
                                <FinanceListLoading/>
                            :
                            <React.Fragment >
                                <div className="bills">
                                    <div className="row">
                                        <div className="col-md-4">
                                                <div className="card card-body balance ">
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <div style={{flexBasis:'85%'}}>
                                                            <div>
                                                                موجودی <span className=''>حساب</span>
                                                            </div>
                                                            <strong className={'price ' + (user.info.balance<0?"text-danger":"text-t")}>{spilitNumber(user.info.balance)}
                                                                <small>تومان</small></strong>
                                                        </div>
                                                        <div>
                                                            <img className='d-inline-block ml-2'
                                                                 src='/assets/images/app/wallet.svg' height={60}/>
                                                        </div>
                                                    </div>

                                                </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-4">
                                                    <Link to={routes.finance_add_fund}>
                                                        <div className="card card-body hi addfund">
                                                            <img src='/assets/images/app/addFund.svg' />
                                                            <div className="title">افزایش موجودی</div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="col-4">
                                                    <Link to={routes.finance_withdraw}>
                                                        <div className="card card-body hi withdraw">
                                                            <img src='/assets/images/app/withdraw.svg' />
                                                            <div className="title">برداشت وجه</div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="col-4">
                                                    <Link to={routes.finance_transactions}>
                                                        <div className="card card-body hi transaction">
                                                            <img src='/assets/images/app/transaction.svg' />
                                                            <div className="title">تراکنش ها</div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <DataBox data={transactions}/>

                                    {transactions.map((transaction,index)=>(
                                        <div key={index} className='card card-body card-table'>
                                            <BillItem key={index}
                                                      transaction={transaction}
                                                      onDelete={this.handleDelete}
                                            />
                                        </div>
                                    ))}
                                </div>

                                <Pagination
                                    total={last_page}
                                    currentPage={currentPage}
                                    onPageChange={this.handlePageChange}
                                />
                            </React.Fragment>
                    }



            </React.Fragment>
        )
    }

}

const mapStateToComponent = state => {
    return {
        user: state.user
    }
}

export default connect(mapStateToComponent)(FinanceList);
