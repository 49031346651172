import React from 'react';
import {updatePageTitle} from "../../actions/breadcrumbs";
import {connect} from "react-redux";
import Pagination from "../../ubold/commons/pagination";
import {all} from "../../modules/tickets/TicketService";
import FormLoader from "../../ubold/commons/FormLoader";
import routes from "../../routes";
import {Link} from "react-router-dom";
import DataBox from "../partials/DataBox";
import TicketItem from "./CashincomeItem";
import {allCashincomes} from "./CashincomeService";
import jwtDecode from "jwt-decode";
import OrderItem from "../orders/OrderItem";
import CashincomeItem from "./CashincomeItem";
import CashListLoading from "../../components/loadings/CashListLoading";
const feather = require('feather-icons')

class CashincomeList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPage: 1,
            total: 1,
            last_page: 1,
            searchQuery: "",
            sortColumn: {path: "", order: ""},
            orders: [],
            loadingForm: true,
        };


        const user=this.props.user;

        this.props.dispatch(updatePageTitle(
            'لیست سفارش های فروش (نقد درآمد)',
            [
                {
                    'title': 'نقد درآمد ارزی',
                    'active': true
                }
            ],

            (
                <React.Fragment>
                    <Link className={'btn btn-primary primary btn-sm'} to={'/orders/services/items/16'}>
                        <i dangerouslySetInnerHTML={{__html: feather.icons['shopping-cart'].toSvg()}}/>
                        ثبت سفارش فروش ارز</Link>
                </React.Fragment>
            )
        ));
    }

    componentDidMount() {
        this.getData();
    }


    getData = () => {
        this.setState({
            loadingForm:true
        })

        allCashincomes(this.state.currentPage).then(res=>{
            const cashes=jwtDecode(res.data.cashes);
            this.setState({
                total : cashes.total,
                last_page : cashes.last_page,
                orders:cashes.data,
                loadingForm:false
            })
        })
    }

    handlePageChange = page => {
        if (page === 'next')
            this.setState({currentPage: this.state.currentPage + 1}, () => this.getData());
        else if (page === 'prev')
            this.setState({currentPage: this.state.currentPage - 1}, () => this.getData());
        else
            this.setState({currentPage: page}, () => this.getData());

    };


    render() {

        const {last_page,  currentPage} = this.state;
        const { orders } = this.state;


        return (
            <React.Fragment>
                {
                    this.state.loadingForm ?
                        <CashListLoading/>
                        :
                        <React.Fragment>


                            <DataBox data={orders}/>

                            {
                                orders.map(order=>(
                                    <div key={order.id} className='card card-body card-table'>
                                        <CashincomeItem
                                            cash={order}
                                        />
                                    </div>
                                ))

                            }

                            <Pagination
                                total={last_page}
                                currentPage={currentPage}
                                onPageChange={this.handlePageChange}
                            />
                        </React.Fragment>
                }
            </React.Fragment>
        )
    }

}

const mapStateToProps = state => {
    return {
        user: state.user.info
    }
}

export default connect (mapStateToProps)(CashincomeList) ;