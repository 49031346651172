import React from 'react';
import http from "../../../../services/httpService";
import Form from "../../../../ubold/commons/form";
import Joi from "joi-browser";
import $ from "jquery";
import '../../../../../node_modules/dropify/dist/css/dropify.min.css'
import '../../../../../node_modules/dropify/dist/js/dropify.min'
import Alert from "../../../partials/Alert";
import {updateUserInStore} from "../../userService";
import {connect} from "react-redux";

class NationalCodeUploadForm extends Form {

    constructor(props) {
        super(props);
        this.state = {
            data:{
                national_id_card_front:'',
                national_id_card_back:''
            },
            errors:{},
            loading: false,
        };

        this.schema = {
            national_id_card_front : Joi.label(this.props.user.legal?"تصویر اساس نامه ":"تصویر روی کارت ملی"),
            national_id_card_back : Joi.label(this.props.user.legal?"تصویر آگهی تاسیس / تغییرات":"تصویر پشت کارت ملی"),
        };
    }


    componentDidMount() {
        $('.dropify').dropify();
    }

    doSubmit = async () => {
        try {
            const data = new FormData();
            data.append('national_id_card_front', this.state.data.national_id_card_front);
            data.append('national_id_card_back', this.state.data.national_id_card_back);
            data.append('isNationalCard', 1);

            http.submit('post',  '/user/update/upload-document', data).then(res => {
                this.toggle();
                updateUserInStore(this.props.dispatch,res.new_user);
                this.props.reloadUser(false);

            }).catch(res => {
                this.toggle();
            });

        } catch (ex) {
            this.toggle();
        }
    }

    render() {
        const verification=this.props.verification;


        return (
            <React.Fragment>

            {verification && verification.rejected_message && verification.status==='REJECTED'?
                <Alert type={'danger'} title={'دلیل عدم تایید کارت ملی'}>
                    {verification.rejected_message}
                </Alert>
                :''}


            {verification && verification.status==='PENDING'?
                <div>
                    <Alert title={'پیغام کارشناس تایید مدارک'} type={'warning'} >
                        تصاویر کارت شناسایی شما با موفقیت بارگذاری شد و در دست کارشناس مربوطه قرار گرفت، لذا  تا دقایقی دیگر پاسخ استعلام مدارک شما به شما اعلام خواهد شد.
                        <div className="mt-2 text-muted">وضعیت   : &nbsp;
                            <span className={'badge badge-'+verification.status_class}>
{verification.status_text}
</span>
                        </div>
                    </Alert>

                </div>
                : ''
            }

            {verification.status===null || verification.canUploadDocument?
                <form method="POST" onSubmit={this.handleSubmit}>
                    <div className="row">
                        <div className="col-md-12">
                            <Alert type={'info'} title={'لطفا توجه کنید'}>
                                <ul style={{listStyle:'number'}} className='p-3'>
                                    <li>کارت ملي قديمي مورد تاييد نيست ( بجای کارت ملی قدیمی دو مدرک هويتي از بين مدارک زير انتخاب و ارسال نماييد. نياز به ارسال پشت مدرک نمي باشد يک مدرک را در قسمت روي کارت ويک مدرک را در قسمت پشت کارت ملي اپلود نماييد 1. پايان خدمت 2.گواهي نامه 3. شناسنامه 4. رسيد کارت ملي جديد ۵. پاسپورت</li>
                                    <li>لبه های مدارک کاملا مشخص و داخل کادر باشد.</li>
                                    <li>عکس اسکن شده مورد تایید نمی باشد</li>
                                    <li> اطلاعات کارت ملی شما قابل خواندن باشد .لطفا عکس با کیفیتی ارسال بفرمایید</li>
                                    <li>از پوشاندن اطلاعات مدرک هویتی خوداری کنید.</li>
                                </ul>
                            </Alert>
                        </div>
                        <div className="col-md-6">
                            {this.renderInput("national_id_card_front",this.props.user.legal?"تصویر اساس نامه ":"تصویر روی کارت ملی",'file')}
                        </div>

                        <div className="col-md-6">

                            {this.renderInput("national_id_card_back",this.props.user.legal?"تصویر آگهی تاسیس / تغییرات ":"تصویر پشت کارت ملی",'file','','',false,false,'','',false,{desc:this.props.user.legal?"(در صورت داشتن آگهی تغییرات تصویر آن را ارسال کنید در غیر این صورت آگهی تاسیس ارسال شود)":""})}
                        </div>
                    </div>

                    {this.renderButton("ارسال")}

                </form>

                :""}

            </React.Fragment>

        )
    }

}

export default connect()(NationalCodeUploadForm) ;

