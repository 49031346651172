import React from 'react';
import FormLoader from "../../ubold/commons/FormLoader";
import {Redirect} from "react-router-dom";
import queryString from 'query-string';
import { getTransaction} from "./FinanceService";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import routes from "../../routes";

class CallbackBank extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            params: this.props.location.search,
            redirect: false
        };
    }

    componentDidMount = async () => {
        const parsed = queryString.parse(this.state.params);


        if (parsed.res && parsed.trans_id) {
            let transaction = await getTransaction(parsed.trans_id);

            if (!transaction)
                window.location.href = '/';

            transaction = transaction.transaction;

            if (parseInt(parsed.res) === 1) {
                if (parsed.message) {
                    toast.success(parsed.message);
                }

                if (transaction.type === 'order') {
                    this.setState({redirect: routes.orders_show + transaction.fk_order_id});
                    return false;
                } else if (transaction.type === 'addfund') {
                    this.setState({redirect: routes.finance});
                    return false;
                } else {
                    this.setState({redirect: routes.finance_checkout + parsed.trans_id});
                    return false;
                }

            } else {
                if (parsed.message) {
                    toast.error(parsed.message);
                }
                this.setState({redirect: routes.finance_checkout + parsed.trans_id});
            }
        } else {
            window.location.href = '/';
        }

        // callback(this.state.params).then(res => {
        //     if (res.data.redirect_url)
        //         this.setState({redirect: res.data.redirect_url});
        //
        //     toast.success(res.data.message);
        //
        //     if (res.data.user) {
        //         updateUserStorage(res.data.user);
        //         this.props.dispatch(updateUser(jwtDecode(res.data.user)));
        //     }
        //
        //     if (res.data.order)
        //         this.setState({redirect: routes.orders_show + res.data.order});
        //
        //     if (res.data.trans_id)
        //         this.setState({redirect: routes.finance_checkout + res.data.trans_id});
        //
        //     this.setState({redirect: routes.finance});
        //
        // }).catch(res => {
        //     this.setState({redirect: routes.finance_transactions});
        // });
    }


    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }

        return (
                <FormLoader/>
        )
    }

}

export default connect()(CallbackBank);
