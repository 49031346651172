import React from 'react';
import {updatePageTitle} from "../../actions/breadcrumbs";
import {connect} from "react-redux";
import Pagination from "../../ubold/commons/pagination";
import {deleteTransaction, transactions} from "./FinanceService";
import FormLoader from "../../ubold/commons/FormLoader";
import SweetAlert from "react-bootstrap-sweetalert";
import {updateUser} from "../../actions/user";
import jwtDecode from "jwt-decode";
import DataBox from "../partials/DataBox";
import TransactionItem from "./TransactionItem";
import FinanceListLoading from "../../components/loadings/FinanceListLoading";
import routes from "../../routes";
import FinanceTransactionsLoading from "../../components/loadings/FinanceTransactionsLoading";

class FinanceTransactions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPage: 1,
            total: 1,
            last_page: 1,
            searchQuery: "",
            sortColumn: {path: "", order: ""},
            transactions: [],
            loadingForm: true,
            alert: false,
            user:this.props.user

        };

        this.initHeader();
    }


    initHeader=()=>{
        this.props.dispatch(updatePageTitle(
            'تراکنش ها',
            [
                {
                    'title': 'کیف پول من',
                    'link': routes.finance
                },
                {
                    'title': 'لیست تراکنش ها',
                    'active': true
                }
            ],
            (
                <React.Fragment>
                    {this.state.user.alarms && this.state.user.alarms.transactions?
                        <React.Fragment>
                            <button className='btn btn-default ml-2  btn-sm' onClick={()=>{this.getData(false)}}> همه</button>
                            <button className='btn btn-default ml-2  btn-sm' onClick={()=>{this.getData(true)}}>پرداخت نشده <span className='badge badge-danger'>{this.state.user.alarms.transactions}</span></button>
                        </React.Fragment>
                        :''}

                </React.Fragment>
            )
        ));
    }

    componentDidMount() {
        this.getData();
    }


    getData = (no_pay=false) => {
        this.setState({
            loadingForm: true
        })

        transactions(this.state.currentPage,no_pay).then(res => {
            this.setState({
                total: res.data.pagination.total,
                last_page: res.data.pagination.last_page,
                transactions: jwtDecode(res.data.data).data,
                loadingForm: false
            })
        })
    }

    handlePageChange = page => {
        if (page === 'next')
            this.setState({currentPage: this.state.currentPage + 1}, () => this.getData());
        else if (page === 'prev')
            this.setState({currentPage: this.state.currentPage - 1}, () => this.getData());
        else
            this.setState({currentPage: page}, () => this.getData());

    };

    getPagedData = () => {
        const {
            searchQuery,
            transactions
        } = this.state;

        let filtered = transactions;
        if (searchQuery)
            filtered = transactions.filter(m =>
                m.title.toLowerCase().startsWith(searchQuery.toLowerCase())
            );

        return {totalCount: filtered.length, data: filtered};
    };

    getAlert(id) {
        return <SweetAlert
            warning
            showCancel
            confirmBtnText="بله, مطمئنم!"
            cancelBtnText="خیر"
            confirmBtnBsStyle="danger"
            title="توجه!"
            onConfirm={() => this.deleteTrans(id)}
            onCancel={this.hideAlert}
            focusCancelBtn
        >
            آیا مطمئن به حذف این رکورد هستید ؟
        </SweetAlert>
    }

    hideAlert = () => {
        this.setState({
            alert: null
        });
    }


    deleteTrans = (id) => {
        this.hideAlert();
        this.setState({
            loadingForm: true,
        });

        deleteTransaction(id).then(res => {
            this.props.dispatch(updateUser(jwtDecode(res.user)));
            this.getData();
            this.setState({user:this.props.user})
            this.initHeader()
        }).catch(res=>{
            this.setState({
                loadingForm: false,
            });
        });
    }

    handleDelete = (id) => {
        this.setState({
            alert: this.getAlert(id)
        })
    }

    render() {
        const {last_page,  currentPage} = this.state;
        const {data: transactions} = this.getPagedData();
        const user=this.props.user;
        return (
            <React.Fragment>
                {this.state.alert}

                {
                    this.state.loadingForm ?
                        <FinanceTransactionsLoading/>
                        :
                        <React.Fragment>
                            <DataBox data={transactions}/>

                            {transactions.map(transaction=>(
                                <div key={transaction.transaction_id} className='card card-body card-table'>
                                    <TransactionItem key={transaction.transaction_id}
                                        transaction={transaction}
                                        onDelete={this.handleDelete}
                                        handleDelete={this.handleDelete}
                                    />
                                </div>
                            ))}
                            <Pagination
                                total={last_page}
                                currentPage={currentPage}
                                onPageChange={this.handlePageChange}
                            />
                        </React.Fragment>
                }


            </React.Fragment>
        )
    }

}

const mapStateToProps = state => {
    return {
        user: state.user.info
    }
}

export default connect (mapStateToProps)(FinanceTransactions) ;