import React from 'react';
import {updatePageTitle} from "../../../actions/breadcrumbs";
import {connect} from "react-redux";
import FormLoader from "../../../ubold/commons/FormLoader";
import Form from "../../../ubold/commons/form";
import Joi from "joi-browser";
import Alert from "./../../../modules/partials/Alert";
import {getCash, getTonTokensCash, settlement} from "../CashincomeService";
import routes from "../../../routes";
import moment from "moment-jalaali";
import {CopyToClipboard} from "react-copy-to-clipboard";
import $ from "jquery";
import {Redirect} from "react-router-dom";
import {toast} from "react-toastify";
import CashCryptoLoading from "../../../components/loadings/CashCryptoLoading";

var QRCode = require('qrcode.react');

class PayCrypto extends Form {

    constructor(props) {
        super(props);

        this.state = {
            data: {
                tx_id: '',
                service: null,
                hasKucoinTransfer:false
            },
            errors: {},
            cashId: this.props.match.params.id,
            cashincome: null,
            network: null,
            loadingForm: true,
            countDownDate: null,
            expire: false,
            time: null,
            end_time: null,
            copied_code: false,
            copied_tag: false,
            copied_remark: false,
            redirect: null,
            isTonToken:false
        };


        this.schema = {
            tx_id: Joi
                .string()
                .required()
                .label("txID"),
            user_attachment: Joi
                .any()
                .label("فایل پیوست"),
        };




        this.props.dispatch(updatePageTitle(
            'انتقال ارز ',
            [
                {
                    'title': 'لیست نقد ها',
                    'link': routes.orders + '?type=sell'
                },
                {
                    'title': 'انتقال ارز ',
                    'active': true
                }
            ]
        ));


    }

    tonTokens = async (cash)=>{
        const response = await getTonTokensCash();
        if (!response.ok) {
            throw new Error(`HTTP error: Status ${response.status}`);
        }
        const data =  await response.json();

        var  isTon =false;

        try {
            isTon =data.tokens.includes(cash.service_model.type);

            if(isTon){
                this.schema['user_attachment'] =Joi
                    .any()
                    .required()
                    .label("فایل پیوست");

                this.setState({
                    isTonToken:isTon
                })
            }
        }catch (e){
            console.log(e);
        }

    }

    componentDidMount() {
        getCash(this.state.cashId).then(res => {
            const data = this.state.data;
            data['service'] = res.data.cash.service;

           this.tonTokens(res.data.cash);

            this.setState(
                {
                    cashincome: res.data.cash,
                    network: res.data.network,
                    loadingForm: false,
                    data,
                    time: res.data.end_time,
                }
            );

            if (res.data.cash.status !== 'PAYING') {
                this.setState({
                    redirect: routes.cash_show + this.state.cashincome.id
                });
            }

            let countDownDate = new Date(res.data.end_time).getTime();
            const me = this;

            var x = setInterval(function () {

                // Get today's date and time
                var now = new Date().getTime();

                // Find the distance between now and the count down date
                var distance = countDownDate - now;


                // Time calculations for days, hours, minutes and seconds
                var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);

                // Display the result in the element with id="demo"
                me.setState(
                    {
                        end_time: (hours + ":" + minutes + ":" + seconds + "")
                    }
                );

                // If the count down is finished, write some text
                if (distance < 0) {
                    clearInterval(x);

                    me.setState(
                        {
                            end_time: "منقضی شد",
                            expire: true
                        }
                    );
                }
            }, 1000);

        });

    }

    doSubmit = async () => {
        try {
            var data = this.state.data;
            const FData = new FormData();

            Object.keys(data).forEach(item => {
                if (item !== 'user_attachment')
                    FData.append(item, data[item]);
            });

            if (document.getElementById('user_attachment') && document.getElementById('user_attachment').files.length > 0) {
                Array.from(document.getElementById('user_attachment').files).forEach(file => {
                    FData.append('user_attachment[]', file);
                });
            }

            settlement(this.state.cashincome.id, FData ? FData : data, {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }).then(res => {
                this.toggle();
                this.setState({
                    redirect: routes.cash_show + this.state.cashincome.id
                })
            }).catch(res => {
                this.toggle();
            });

        } catch (ex) {
            console.log(ex)
            this.toggle();
        }
    };

    handleChangeHasKucoinTransfer = ({currentTarget: input}) => {
        const data = this.state.data;
        if (input.type === 'checkbox') {
            data[input.name] = input.checked;
        }

        this.setState({data});

        const sc = this.schema;

        if (input.checked) {
            sc['tx_id'] = Joi
                .any()
                .required()
                .label("txID");

        } else {
            sc['tx_id'] = Joi
                .string()
                .required()
                .label("txID");
        }

        this.schema = sc;
    }

    render() {

        if (this.state.redirect)
            return <Redirect to={this.state.redirect}/>

        const cash = this.state.cashincome;
        const network = this.state.network;

        if (this.state.copied_code) {
            $(".copied_code .copied").addClass('show');
            const me = this;

            setTimeout(function () {
                me.setState({copied_code: false});
                $(".copied_code .copied").removeClass('show');
            }, 1000);
        }

        if (this.state.copied_tag) {
            $(".copied_tag .copied").addClass('show');
            const me = this;

            setTimeout(function () {
                me.setState({copied_tag: false});
                $(".copied_tag .copied").removeClass('show');
            }, 1000);
        }

        if (this.state.copied_remark) {
            $(".copied_remark .copied").addClass('show');
                const me = this;

            setTimeout(function () {
                me.setState({copied_remark: false});
                $(".copied_remark .copied").removeClass('show');
            }, 1000);
        }

        return (
            <React.Fragment>
                {
                    this.state.loadingForm ?
                        <CashCryptoLoading/>
                        :
                        <React.Fragment>
                            <div className="row justify-content-center cash-box">
                                <div id="wallet" className="col-md-6">
                                    <div className="card card-crypto card-body calculator">

                                        <div className="d-flex justify-content-between align-items-center ">
                                            <h4>
                                                فروش <strong className='ml-1 mr-1 '>{cash.amount + ' واحد '+ cash.service_model.n_type}</strong> به ما
                                            </h4>
                                            <img img={cash.id} alt={cash.cash_icon} className='ml-2'
                                                 src={cash.cash_icon} height="30" width={30}/>
                                        </div>
                                        <hr/>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h5 className='m-0 text-danger'>مدت زمان باقی مانده</h5>
                                            <div className="text-danger" id="demo">{this.state.end_time}</div>
                                        </div>
                                        <hr/>



                                        {
                                            cash.service_model.exchanger=='KUCOIN'?
                                                <div>
                                                    <label className={'m-0 p-0'}>
                                                        {
                                                            this.state.data.hasKucoinTransfer?
                                                                <input name='hasKucoinTransfer' value={this.state.data.hasKucoinTransfer} onClick={this.handleChangeHasKucoinTransfer} checked type='checkbox'  />
                                                                :
                                                                <input name='hasKucoinTransfer' value={this.state.data.hasKucoinTransfer} onClick={this.handleChangeHasKucoinTransfer}  type='checkbox'  />
                                                        }
                                                        &nbsp;
                                                        از صرافی  <strong className='ml-1 mr-1'  style={{color:'#24ae8f'}}>Kucoin</strong>  ارز <span className='ml-1 mr-1'> { cash.service_model.n_type}</span> را به کافه ارز انتقال می دهم.

                                                    </label>
                                                </div>
                                                :""
                                        }

                                        <div className=" wallets">
                                            <div className="head">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div>
                                                        ادرس کیف پول کافه ارز جهت واریز
                                                    </div>
                                                    <div>
                                                        <span className="badge badge-primary d-inline-block mr-1">(شبکه <span>{cash.meta.network ? cash.meta.network : cash.meta.networkName}</span>)</span>

                                                    </div>
                                                </div>

                                            </div>

                                            <div className="item">
                                                <div className="d-flex justify-content-between">
                                                    <div className='d-flex'>
                                                        <CopyToClipboard text={cash.admin_wallet} onCopy={() => toast.success('آدرس کیف پول کپی شد') }>
                                                            <button title={'کپی'} className={'btn-copy'}>
                                                                <i className='fe-copy'></i>
                                                            </button>
                                                        </CopyToClipboard>

                                                        <div>
                                                            <div className='pop-qrcode'>
                                                                <i className='fe-grid'></i>
                                                                <div className='qr'>
                                                                    <QRCode style={{height: '150px', width: '150px'}} value={cash.admin_wallet}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='ltr'>
                                                        <div className='title'>{cash.service_model.type} Address :</div>
                                                        <div className='value'>{cash.admin_wallet} </div>
                                                    </div>

                                                </div>
                                            </div>
                                                {cash.secure_password ?
                                                    <div className="item">
                                                        <div className="d-flex justify-content-between">
                                                        <div className='d-flex'>

                                                            <CopyToClipboard text={cash.secure_password} onCopy={() => toast.success('تگ کپی شد') }>
                                                                <button title={'کپی'} className={'btn-copy'}>
                                                                    <i className='fe-copy'></i>
                                                                </button>
                                                            </CopyToClipboard>

                                                            <div>
                                                                <div className='pop-qrcode'>
                                                                    <i className='fe-grid'></i>
                                                                    <div className='qr'>
                                                                        <QRCode style={{height: '150px', width: '150px'}} value={cash.secure_password}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='ltr'>
                                                            <div className='title'>Your {cash.service_model.exchanger==='KUCOIN' ?(network?network.memoRegex:"memo \\ tag"):'memo \\ tag'}  :</div>
                                                            <div className='value'>{cash.secure_password} </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    : ""}

                                                {
                                                    cash.service_model.exchanger==='KUCOIN'?
                                                        <>
                                                            {
                                                                this.state.data.hasKucoinTransfer?
                                                                    <>
                                                                       <div className="item">
                                                                           <div className="d-flex justify-content-between">
                                                                               <div className='d-flex'>

                                                                                   <CopyToClipboard text={cash.id} onCopy={() => toast.success('remark کپی شد') }>
                                                                                       <button title={'کپی'} className={'btn-copy'}>
                                                                                           <i className='fe-copy'></i>
                                                                                       </button>
                                                                                   </CopyToClipboard>

                                                                               </div>

                                                                               <div className='ltr'>
                                                                                   <div className='title'>Remark :</div>
                                                                                   <div className='value'>{cash.id} </div>
                                                                               </div>
                                                                           </div>
                                                                       </div>
                                                                    </>
                                                                    :""
                                                            }

                                                        </>
                                                        :""
                                                }

                                        </div>
                                        {cash.secure_password ?
                                            <Alert className='mb-2 ' type={'danger'}>
                                                لطفاً هنگام انتقال وجه، حتماً از برچسب (<strong className='ml1- mr-1 text-danger'>{cash.service_model.exchanger==='KUCOIN' ?(network?network.memoRegex:"memo \\ tag"):'memo \\ tag'}</strong> ) استفاده کنید. در غیر این صورت، وجه به حساب ما منتقل نمی‌شود و مسئولیت آن بر عهده شما خواهد بود.

                                                {cash.secure_password ?
                                                    <div style={{fontSize:'16px',fontWeight:'bold',width:'300px'}} className="item ma-auto">
                                                        <div className="d-flex ltr mt-2">

                                                            <div className='title mr-2' style={{fontSize:'12px',fontWeight:'bold'}}>Your {cash.service_model.exchanger==='KUCOIN' ?(network?network.memoRegex:"memo \\ tag"):'memo \\ tag'}  :</div>

                                                            <div className='ltr'>
                                                                <div className='value'>{cash.secure_password} </div>
                                                            </div>
                                                            <div className='d-flex ml-2'>

                                                                <CopyToClipboard text={cash.secure_password} onCopy={() => toast.success('تگ کپی شد') }>
                                                                    <button title={'کپی'} className={'btn-copy'}>
                                                                        <i className='fe-copy'></i>
                                                                    </button>
                                                                </CopyToClipboard>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ""}
                                            </Alert>
                                            : ""
                                        }

                                        <Alert className='mb-2 ' type={'warning'}>
                                            لطفاً دقت کنید که از صرافی‌های ایرانی مانند نوبیتکس، ولکس، رمزینکس، پیت‌پین و... ارز دیجیتال خود را به کافه ارز ارسال نکنید. به دلیل تحریم‌های بین‌المللی، تراکنش‌هایی صرافی‌های p2p ایرانی رهگیری شده و  با توجه به اینکه کافه ارز از سرویس‌های خارجی برای خرید و فروش استفاده می کند در صورت انتقال از ولت ایرانی موجودی در کیف پول ریجکت یا مسدود می‌شود. پیشنهاد ما این است که ابتدا ارز خود را به کیف پول‌های معتبر شخصی مانند Trust Wallet، SafePal، MetaMask و غیره منتقل کنید و سپس از آنجا پول را به حساب ما منتقل کنید.
                                        </Alert>

                                        <ul style={{color: 'rgb(144, 144 ,144)'  , fontSize: '13px', fontWeight: 300}} className='p-0 pr-3'>

                                            {
                                                cash.service_model.exchanger==='KUCOIN' && this.state.data.hasKucoinTransfer?
                                                    <li>
                                                        <p className='note'>       اگر می خواهید ارز <strong>{cash.service_model.type}</strong> را از صرافی <strong
                                                            className="ml-1 mr-1"
                                                            style={{color: 'rgb(36, 174, 143)'}}>Kucoin</strong> انتقال دهید، می بایست کد <strong className='text-danger'>Remark</strong> را هنگام <strong className='text-danger'>withdraw</strong> در سایت کوکین فیلد <strong>remark</strong> وارد کنید
                                                            ، در صورت عدم استفاده ممکن است  تراکنش شما با تاخیر نقد شود</p>
                                                    </li>
                                                    :""
                                            }
                                            {
                                                network && network.confirms?
                                                    <li>
                                                        <p className='note'>       حداقل تعداد تایید (confirm) تراکنش در این شبکه <strong className='text-danger'>{network.confirms}</strong> عدد می باشد </p>
                                                    </li>
                                                    :""
                                            }
                                        </ul>



                                        <form method="POST" onSubmit={this.handleSubmit}>
                                            <div className=" card-crypto calculator ">

                                                <p>
                                                    پس از انتقال ارز،
                                                    {
                                                        this.state.data.hasKucoinTransfer?
                                                            ''
                                                            :'شماره تراکنش را در زیر وارد کنید و'
                                                    }

                                                    دکمه پرداخت را فشار
                                                    دهید
                                                </p>


                                                {
                                                    !this.state.data.hasKucoinTransfer?
                                                        <>
                                                            {
                                                                this.renderInput('tx_id', 'شماره تراکنش یا Transaction Hash (TX ID)', 'text', 'ltr','','','','','Transaction Hash')
                                                            }
                                                        </>
                                                        :""
                                                }

                                                {
                                                    this.state.isTonToken?
                                                        <>
                                                            {
                                                                this.renderInput('user_attachment','اسکرین شات از کیف پول ارسال کننده ( ولت شخصی یا ولت صرافی )','file')
                                                            }
                                                        </>
                                                        :""
                                                }


                                                <div className="form-group">
                                                    {this.renderButton(<span><i
                                                        className='fe-check'></i> پرداخت انجام شد </span>, 'btn btn-primary btn-lg btn-block')}
                                                </div>
                                            </div>

                                        </form>


                                    </div>
                                </div>

                            </div>

                        </React.Fragment>
                }
            </React.Fragment>
        )
    }

}

export default connect()(PayCrypto);
