import React from 'react';
import {updatePageTitle} from "../../actions/breadcrumbs";
import {connect} from "react-redux";
import Pagination from "../../ubold/commons/pagination";
import {all} from "../../modules/comments/CommentService";
import FormLoader from "../../ubold/commons/FormLoader";
import Comment from "./Comment";
import {Link} from "react-router-dom";
import routes from "../../routes";
import DataBox from "../partials/DataBox";
import jwtDecode from "jwt-decode";
import CommentsListLoading from "../../components/loadings/CommentsListLoading";
const feather = require('feather-icons')

class CommentsList extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            currentPage: 1,
            total: 1,
            last_page: 1,
            searchQuery: "",
            sortColumn: {path: "", order: ""},
            comments: {},
            loadingForm: true,
        };


        this.props.dispatch(updatePageTitle(
            'انتقادات و پیشنهادات',
            [
                {
                    'title': 'انتقادات و پیشنهادات',
                    'active': true
                }
            ],
            (
                <Link className={'btn btn-primary primary btn-sm'} to={routes.comments_create}>
                    <i dangerouslySetInnerHTML={{__html: feather.icons['plus'].toSvg()}}/>
                    ثبت دیدگاه جدید</Link>
            )
        ));
    }

    componentDidMount() {
        this._isMounted=true;
        this.getData();
    }

    getData = () => {

        if(this._isMounted){
            this.setState({
                loadingForm: true
            })

            all(this.state.currentPage).then(res => {
                this.setState({
                    total: res.pagination.total,
                    last_page: res.pagination.last_page,
                    comments: (jwtDecode(res.data)),
                    loadingForm: false
                })

            })
        }

    }

    handlePageChange = page => {
        if (page === 'next')
            this.setState({currentPage: this.state.currentPage+1},()=> this.getData());
        else if (page === 'prev')
            this.setState({currentPage: this.state.currentPage-1},()=> this.getData());
        else
            this.setState({currentPage: page},()=> this.getData());

    };

    getPagedData = () => {
        const {
            searchQuery,
            comments
        } = this.state;

        let filtered = comments;
        if (searchQuery)
            filtered = comments.filter(m =>
                m.title.toLowerCase().startsWith(searchQuery.toLowerCase())
            );

        return {totalCount: filtered.length, data: filtered};
    };

    render() {

        const {last_page, currentPage} = this.state;
        const {data: comments} = this.getPagedData();

        return (
            <React.Fragment>
                {
                    this.state.loadingForm ?
                        <CommentsListLoading/>
                        :
                        <React.Fragment>
                            <DataBox data={comments}/>
                            <div id={'comments'} className="comments pt-0 mt-0">
                                {
                                    comments.map((comment,index) => <Comment key={index} comment={comment}/>)
                                }

                            </div>

                           <div className="text-center mt-5">
                               <Pagination
                                   total={last_page}
                                   currentPage={currentPage}
                                   onPageChange={this.handlePageChange}
                               />
                           </div>
                        </React.Fragment>
                }

            </React.Fragment>
        )
    }

}

export default connect()(CommentsList);
