import React from 'react';
import Squre from "../skelete/Squre";
import SkeleteLoading from "../SkeleteLoading";
import {Link} from "react-router-dom";
import routes from "../../routes";

class FinanceListLoading extends React.Component {

    render(){
        var html=[];
        for(var i=0; i<=5;i++){
            html.push(<div className='mb-2'><Squre height={80} /></div>)
        }

        return (
            <React.Fragment>
                <SkeleteLoading>
                    <div className="row mb-4">
                        <div className="col-md-4 mb-3">
                            <Squre height={150} />
                        </div>
                        <div className="col-md-8">
                            <div className="row mb-3">
                                <div className="col-4">
                                    <Squre height={170} />
                                </div>
                                <div className="col-4">
                                    <Squre height={170} />
                                </div>
                                <div className="col-4">
                                    <Squre height={170} />
                                </div>
                            </div>
                        </div>
                    </div>

                    {html}
                </SkeleteLoading>
            </React.Fragment>
        );
    }
}

export default FinanceListLoading;
