import {Link} from "react-router-dom";
import React from "react";

const ErrorBox = ({title,content,link,linkText,newClass=''}) => {

    return (
        <div className={'card card-body card-error-message d-block text-center msg pt-4 '+newClass}>
            <div className="msg-icon">
                <i style={{fontSize:'53px'}} className="fa fa-warning"></i>
            </div>
            <h4 className={'title'}>
                <strong style={{fontWeight:800}}>{title}</strong>
            </h4>
            <p>
                {content}
            </p>
            <div className='mt-4'>
                <Link className="btn btn-outline-primary btn-rounded" to={link}>
                    {linkText?linkText:'کلیک کنید'}
                </Link>
            </div>
        </div>
    );
};

export default ErrorBox;