import React from 'react';
import {connect} from "react-redux";
import EventItems from "./EventItems";
import NotificationItems from "./NotificationItems";

class NotificationsList extends React.Component {

    render() {

        const search = this.props.location.search;
        let type = new URLSearchParams(search);
        const params = Object.fromEntries(type.entries());
        type=params.type;
        if(type==='event')
            return <EventItems/>
        else
            return <NotificationItems/>
    }

}

const mapStateToProps = state => {
    return {
        user: state.user.info
    }
}

export default connect (mapStateToProps)(NotificationsList) ;