import React from 'react';
import Form from "./../../ubold/commons/form";
import Joi from "joi-browser";
import {checkVerifyTokenCode, confirmCode, sendVerifyCode, updateUserInStore} from "../user/userService";
import {updateUserStorage} from "../../modules/auth/authService";
import $ from "jquery";
import loading from "../../assets/images/loading.svg";
import {secondsToHms} from "../../services/helpers";
import {ToastContainer} from "react-toastify";
import {connect} from "react-redux";

class VerifyEmailForm extends Form {

    constructor(props) {
        super(props);
        this.state = {
            data: {code: ""},
            type: 'EMAIL',
            label: ('ایمیل'),
            errors: {},
            loading: false,
            c: 60,
            t: null,
            activeSendCodeButton: false,
            counter: 0,
            be_counter: 0,
        };
        this.schema = {
            code: Joi.string()
                .required()
                .label("کد تایید ایمیل")
        };

    }

    componentDidMount = async () => {
        const me = this;

        $(".max-6").keypress(function () {
            if ($(this).val().length >= 6)
                return false;
        });

        $(".digit").keypress(function (e) {
            //if the letter is not digit then display error and don't type anything
            if (e.which !== 8 && e.which !== 0 && (e.which < 48 || e.which > 57)) {
                //display error message
                return false;
            }
        });

        $(".input-code").keyup(function () {

            me.setState({counter: $(this).val().length});

            if (me.state.be_counter >= 6) {
                me.setState({be_counter: $(this).val().length});
                return false;
            } else {
                if (me.state.counter >= 6) {
                    me.toggle();
                    me.doSubmit();
                }

                me.setState({be_counter: $(this).val().length});
            }


        });

        try {
            const res = await checkVerifyTokenCode(this.state.type);

            if (res.seconds > 0) {
                this.setState({
                    t: res.seconds,
                    c: res.seconds,
                });

                $("#ccc").show();
                this.startCount();
                this.setState({activeSendCodeButton: true});

            } else {
                this.setState({
                    c: 0,
                })
            }


        } catch (e) {

        }
    }

    showSeconds = () => {
        const cm = this.state.c - 1;
        this.setState({c: (cm)});

        if (document.getElementById("counter"))
            document.getElementById("counter").innerHTML = secondsToHms(cm);

        if (cm === 0) {
            this.stopCount();
        }
    };

    timedCount = () => {
        const tm = setInterval(this.showSeconds, 1000);
        this.setState({t: tm});
    }

    startCount = () => {
        this.timedCount();
    }

    stopCount = () => {
        clearTimeout(this.state.t);
        this.setState({c: 60});
        $("#ccc").hide();
        this.setState({activeSendCodeButton: false});
    }

    sendToken = async () => {
        var html = $("#sendCode").html();
        let hh = "<img style='display:inline-block' height='20' className='ml-1' src='" + loading + "' />";

        $("#sendCode").html(hh + '   در حال ارسال ...');
        this.setState({activeSendCodeButton: true});

        try {
            const res = await sendVerifyCode(this.state.type);

            this.setState({
                t: res.seconds,
                c: res.seconds,
            });

            $("#sendCode").html(html);
            $("#ccc").show();
            this.startCount();
        } catch (e) {
            $("#sendCode").html(html);
            $("#ccc").hide();
            this.setState({activeSendCodeButton: false});
        }

    }

    doSubmit = async () => {
        try {
            const res = await confirmCode(this.state.type, this.state.data.code);
            updateUserInStore(this.props.dispatch, res.user);
            updateUserStorage(res.user);
            this.props.reloadUser();
        } catch (ex) {
            this.toggle();
        }
    };


    render() {
        return (
            <React.Fragment>
                <ToastContainer position="top-right"
                                autoClose={5000}
                                hideProgressBar={true}
                                newestOnTop={false}
                                closeOnClick
                                rtl={true}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover/>

                <form method="POST" onSubmit={this.handleSubmit}
                      className="form-horizontal dialog-form embed">
                    <br/>

                    <div className="form-group rtl-label">
                        {this.renderInput("code", "کد دریافت شده را اینجا وارد کنید", 'text', 'ltr input-code digit max-6')}
                    </div>
                    <br/>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="text-centered mb-3">
                            <button disabled={this.state.activeSendCodeButton} type="button"
                                    onClick={this.sendToken} id="sendCode"
                                    className="send-code m-auto text-t">
                                ارسال مجدد کد فعال سازی <span style={{display: 'none'}}
                                                              id="ccc">(<span
                                id="counter">0</span>)</span>
                            </button>
                        </div>
                        <div className="form-group">
                            {this.renderButton("تایید ", 'btn-block ')}
                        </div>
                    </div>


                </form>
            </React.Fragment>

        );
    }

}


export default connect()(VerifyEmailForm);
