import React from 'react';
import Form from "../../../ubold/commons/form";
import Joi from "joi-browser";
import {create, edit ,getBankCodes, update} from "../../../modules/user/BankCardService";
import {hideAppLoader, showAppLoader} from "../../../actions/app";
import http from "../../../services/httpService";
import {connect} from 'react-redux';
import {toast} from "react-toastify";
import $ from "jquery";
import "../../../assets/css/banks.css";
import InputMask from "react-input-mask";

class BankCardForm extends Form {

    constructor(props) {
        super(props);
        this.cardNumberRef = React.createRef();

        this.state = {
            edit_id: null,
            data: {},
            errors: {},
            loading: false,
            bankCodes: [],
            alert: ''
        };

        this.schema = {
            card_number: Joi.string()
                .required()
                .label("شماره 16 رقمی ")
        };

    }


    doSubmit = async () => {

        try {
            if(this.props.checkout){
                let d=this.state.data;
                d['cscwc']=true;
                this.setState({data:d});
            }

            const data = this.convertToFormData();

            if (this.state.edit_id)
                update(this.state.edit_id, data).then(res => {
                    this.setState({cards: res.data.data, edit_id: null, data: {}})
                    this.toggle();
                    this.props.onAction();
                }).catch(res => {
                    this.toggle()
                });
            else
                create(data).then(res => {

                    this.setState({cards: res.data, data: {}})
                    this.toggle();
                    this.props.onAction();
                }).catch(res => {
                    this.toggle()
                });

        } catch (ex) {
            this.toggle();
        }
    };

    editCredit = (id) => {
        try {
            edit(id).then(res => {

                this.setState({
                    data: {
                        card_number: res.data.card.card_number.toString().replace(/-/g, "").replace(/\B(?=(\d{4})+(?!\d))/g, "-"),
                    },
                    edit_id: res.data.card.id,

                });

                this.toggleModal();

                this.keyUpCardNumber(this.cardNumberRef.current.props,7)

            })

        } catch (ex) {

        }
    }


    componentDidMount() {
        getBankCodes().then(data =>{
            this.setState({bankCodes:data.data.codes})
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        $("[maxlength]").keydown(function (event) {
            var key = event.keyCode || event.charCode;

            if( key !== 8 && key !== 46 )
            {
                if($(this).val().length >= $(this).attr('maxlength')){
                    event.preventDefault();
                    return false;
                }
            }

        });
    }

    handleChangeKeyUpCardNumber = ({currentTarget: input})=>{
        this.keyUpCardNumber(input)
    }

    keyUpCardNumber(input,sub=7){
        const errors = {...this.state.errors};
        const errorMessage = this.validateProperty(input);
        if (errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];
        let data = {...this.state.data};
        data[input.name] = input.value;

        const codes=this.state.bankCodes;
        var code = input.value.substring(0, sub);
        code = parseFloat(code.replace('-', ''));
        code = code.toString();

        if (code.length === 6) {
            code = parseInt(code);

            if (codes[code]) {
                $(".bank-css-sprite").attr('class', '').addClass('bank-css-sprite ' + codes[code]);
                data['name']=codes[code];
                $("#name").val(codes[code]);
            } else{
                $("#name").val('');
                $(".bank-css-sprite").attr('class', '').addClass('bank-css-sprite ');
            }

        } else if (input.value.length < 7)
            $(".bank-css-sprite").attr('class', '').addClass('bank-css-sprite ');

        this.setState({data, errors},()=> this.otherProcess(input));

    }

    render() {
        const {data,errors} = this.state;

        return (
            <React.Fragment>
                <form method="POST" onSubmit={this.handleSubmit}>
                        <div className="form-group">
                            <label>شماره 16 رقمی کارت</label>
                            <div style={{position: 'relative'}}>
                                <div className="bank-css-sprite "></div>
                                <input type="hidden" name="name" id="name" />
                                <InputMask ref={this.cardNumberRef} id={'card_number'} onChange={this.handleChangeKeyUpCardNumber} name={'card_number'} value={data['card_number']} className={'form-control ltr '} mask={'9999-9999-9999-9999'} maskPlaceholder="111" />
                            </div>

                            {errors['card_number'] && <small className="font-11 error-label">{errors['card_number']}</small>}

                        </div>

                    {this.renderButton(<span><i className='fe-save'></i> ثبت کارت بانکی </span>, 'btn-block btn-lg ')}

                </form>
            </React.Fragment>

        )
    }
}

export default connect()(BankCardForm);
