
import React from "react";
import GiftQty from "./GiftQty";


const Qty = ({number, value, error, hor,label,handleCalculate,max, ...res}) => {
    return (
        <React.Fragment>
            <div className={hor ? "form-group row align-item-center" : "form-group"}>

                {label ? <label className={hor ? 'col-md-3' : ''} >{label}</label> : ''}

                {
                    hor ?
                        <div className='col-md-9'>
                            <GiftQty maxGiftQty={max} handleCalculate={(qty)=>handleCalculate(qty)} value={value} />

                            {error && <small className="font-11 error-label">{error}</small>}

                        </div>
                        :
                        <React.Fragment>
                            <GiftQty maxGiftQty={max} handleCalculate={(qty)=>handleCalculate(qty)} value={value} />

                            {error && <small className="font-11 error-label">{error}</small>}
                        </React.Fragment>
                }

            </div>
        </React.Fragment>
    );
};

export default Qty;
