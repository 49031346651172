import React from 'react';
import {connect} from "react-redux";

class PayWebmoney extends React.Component {

    render() {
        const cash=this.props.cash;
        const data=this.props.data[cash.service_type];
        const user=this.props.user;

        return (
            <React.Fragment>
                <form action={data.gateway} method="POST">
                    <input type="hidden" name="PAYEE_ACCOUNT" value={data.PAYEE_ACCOUNT} />
                    <input type="hidden" name="PAYEE_NAME" value={data.PAYEE_NAME} />
                    <input type="hidden" name="PAYMENT_AMOUNT" value={cash.amount} placeholder="Amount" />
                    <input type="hidden" name="PAYMENT_UNITS" value={data.PAYEE_UNIT} />
                    <input type="hidden" name="PAYMENT_URL" value={data.PAYMENT_URL}/>
                    <input type="hidden" name="NOPAYMENT_URL" value={data.NOPAYMENT_URL}/>
                    <input type="hidden" name="PAYMENT_ID" value={cash.id} />
                    <input type="hidden" name="MEMO" value={user.name + ' =>  cash id : '+ cash.id} />
                    <input type="hidden" name="SUGGESTED_MEMO" value={cash.fk_user_id + ' =>  cash id : '+ cash.id} />
                    <br />
                    <button className="btn btn-primary  btn-lg btn-block" type="submit">
                        <i  className="fe-arrow-down-right"></i>
                        انتقال به درگاه
                    </button>
                </form>

            </React.Fragment>
        )
    }
}
const mapStateToProps = (state) => {
        return {
            user: state.user.info,
        }
    }

export default connect(mapStateToProps)(PayWebmoney);
