import React from 'react';
import Squre from "../skelete/Squre";
import SkeleteLoading from "../SkeleteLoading";
import Table from "../skelete/Table";

class OrderShowLoading extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className="mt-4">
                    <SkeleteLoading>
                       <div className="mb-3">
                           <Squre height={150} />
                       </div>
                        <div className="mb-3">
                            <Squre height={110} />
                        </div>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <Squre height={350} />
                            </div>
                            <div className="col-md-6 mb-3">
                                <Squre height={350} />
                            </div>
                        </div>
                    </SkeleteLoading>
                </div>
            </React.Fragment>
        );
    }
}

export default OrderShowLoading;
