import types from "../actions/types";
import {getCurrentUser} from "../modules/auth/authService";

const userData={
    is_login:false,
    info:getCurrentUser(),
    mobile_verify:false,
};

export const user = (state = userData, action) => {
    switch (action.type) {
        case types.USER_SET_USER:
            return { ...state,
                info: action.user,
                is_login:true
            };
        default :
            return state;

    }
}