import React from 'react';
import Form from "../../../ubold/commons/form";
import Joi from "joi-browser";
import {connect} from "react-redux";
import {createPaymentId} from "../../user/userService";

class PaymentIdsForm extends Form {

    constructor(props) {
        super(props);

        this.state = {
            data: {
                userFullName:this.props.user.name ,
                userMobile: this.props.user.mobile,
            },
            user:this.props.user,
            errors: {},
        };


        this.schema = {
            userFullName: Joi.string()
                .required()
                .label("نام و نام خانوادگی"),
            userMobile: Joi.string()
                .required()
                .label("شماره همراه"),
        };

    }



    doSubmit = async () => {

        try {
            var data = this.state.data;
            createPaymentId( data).then(res => {
                this.props.callback();
                console.log(res);

            }).catch(res => {
                this.toggle();
                console.log(res);
            });

        } catch (ex) {
            this.toggle();
        }
    };

    render() {

        return (

            <React.Fragment>
                <form onSubmit={this.handleSubmit}>

                    <div className="card card-body mb-0 ">

                        {this.renderInput('userFullName', 'نام و نام خانوادگی','','','','',false,'نام و نام خوادگی (مطابق کارت ملی)')}

                        {this.renderInput('userMobile', 'شماره همراه','','','','',false,'','شماره همراه (به نام خودتان باشد)')}

                        {this.renderButton('ارسال اطلاعات')}

                    </div>
                </form>

            </React.Fragment>
        )
    }

}


const mapStateToProps = (state) => {
    return {
        user: state.user.info,
    }
}


export default connect(mapStateToProps)(PaymentIdsForm);

