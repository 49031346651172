import React from 'react';
import {connect} from "react-redux";
import Form from "../../ubold/commons/form";
import {spilitNumber} from "../../services/helpers";

class BillItem extends Form {


    render() {

        const {transaction}=this.props;

        return (
            <>
                <div className="row">
                    <div className="col-lg-7 col-sm-7 col-7">
                        <div className="row align-items-center">
                            <div className="col-xl-4 col-lg-3">
                                <div>
                                    شماره :
                                    <span className="mr-1">
                                        #{transaction.id}
                                    </span>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-5">
                                 {transaction.trans_type_label}

                            </div>
                            <div className="col-xl-4 col-lg-4">
                                میزان : &nbsp;
                                {
                                    (transaction.type==='WITHDRAW' || transaction.type==='PAY_OUT' || transaction.type==='DECREASE') ?
                                        <span className='text-danger'>{transaction.amount_with_sign} <small className='d-inline-block mr-1'>تومان</small></span> :
                                        <span className='text-success'>{transaction.amount_with_sign} <small className='d-inline-block mr-1'>تومان</small></span>
                                }
                            </div>


                        </div>
                    </div>
                    <div className="col-lg-5 col-sm-5 col-5">
                        <div className="row align-items-center">

                            <div className="col-xl-6 col-lg-6">
                                <div className="">
                                   <div>
                                       موجودی :
                                       <span>
                                           {
                                               transaction.bc>0 ? <span  className='text-success'>{spilitNumber(transaction.bc)} <small>تومان</small></span>
                                                   :<span className='text-danger'>{spilitNumber(transaction.bc)} <small>تومان</small></span>
                                           }
                                       </span>
                                   </div>

                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 ">
                                <div className="d-block d-md-none">
                                    <div className="mb-1"></div>
                                </div>
                                <div>
                                    ثبت :
                                    <span
                                        className={' mr-1 ' }>{transaction.shamsi_created_at}</span>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }

}

export default connect ()(BillItem);
