import React from 'react';
import Form from "../../ubold/commons/form";
import {getServiceCategories} from "../../modules/orders/OrderService";
import FormLoader from "../../ubold/commons/FormLoader";
import {Link} from "react-router-dom";
import {updatePageTitle} from "../../actions/breadcrumbs";
import routes from "../../routes";
import {connect} from "react-redux";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import StickyBox from "react-sticky-box";
import $ from "jquery";
import OrderServicesLoading from "../../components/loadings/OrderServicesLoading";

function initJq(){

    $('.close-services').click(function (){
        $(".services_items_box" ).removeClass('active');
        $(".overlay-services").removeClass('show');
        $("body").removeClass('no-overflow');

    });

    if ($(document).width() < 768) {
        $(".hover").unbind();
        $("body").off( "hover", "hover")
        $(".services-new").removeClass('active');
        $(".services-new").removeClass('hover');
        $(".services-new").addClass('click');
        $(".services_items_box").removeClass('active');

        $(".click").click(function () {
            $(".services_items_box_" + $(this).data('id')).addClass('active');
            $(".overlay-services").addClass('show');
            $("body").addClass('no-overflow');
        });

    } else {  
        $("body").removeClass('no-overflow');
        $(".overlay-services").removeClass('show');
        $(".services-new").addClass('hover');
        $(".services_items_box_1").addClass('active');
        $(".services-new").removeClass('click');

        $(".hover").hover(function () {
            $(".services-new").removeClass('active');
            $(".services_items_box").removeClass('active');
            $(".services_items_box_" + $(this).data('id')).addClass('active')
            $(this).addClass('active')
        });

        $(".hover").click(function () {
            $(".services-new").removeClass('active');
            $(".services_items_box").removeClass('active');
            $(".services_items_box_" + $(this).data('id')).addClass('active');
            $(this).addClass('active')
        });
    }
}


class OrdersServices extends Form {

    constructor(props) {
        super(props);
        this.state = {
            loadingForm: true,
            categories: true,
            cash_cat: null
        };

        this.props.dispatch(updatePageTitle(
            'ثبت سفارش جدید',
            [
                {
                    'title': 'لیست سفارش ها',
                    'link': routes.orders,
                    'active': false
                },
                {
                    'title': 'ثبت سفارش جدید',
                    'active': true
                }
            ]
        ));

    }

    componentDidMount() {
        getServiceCategories().then(res => {
            this.setState({
                loadingForm: false,
                categories: res.data.data,
                cash_cat: res.data.cashincome_cat
            })
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        $(function () {
            initJq();
        });

        $(window).resize(function (){
            initJq();
        });
    }

    render() {

        const categories = this.state.categories;

        return (
            <React.Fragment>
                {
                    this.state.loadingForm ?
                        <OrderServicesLoading/>
                        :
                        <>
                            <div className="overlay-services close-services"></div>

                            <div className="row services-items-box">
                                <div className="col-12 mb-4">
                                    <div className="row">
                                        <div className="col-6 r">
                                            <div className="service-bold">
                                                <div className='icon'>
                                                    <img src='/assets/images/svg-icons/btc.svg'/>
                                                    <h3>معامله ارز دیجیتال</h3>
                                                </div>
                                                <div className="buttons">
                                                    <div className="d-flex">
                                                        <div className="order-6">
                                                            <Link class='btn btn-success btn-block'
                                                                  to={'/orders/crypto?type=buy'}>خرید</Link>
                                                        </div>
                                                        <div className="order-6 mr-2">
                                                            <Link class='btn btn-danger btn-block'
                                                                  to={'/orders/crypto?type=sell'}>فروش</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 l">
                                            <div className="service-bold">
                                                <div className='icon'>
                                                    <img src='/assets/images/svg-icons/cashincomes.svg'/>
                                                    <h3>فروش ارز به ما</h3>
                                                </div>
                                                <div className="buttons">
                                                    <div className="d-flex">
                                                        <div className="order-8">
                                                            <Link class='btn btn-success btn-block'
                                                                  to={'/orders/services/items/16'}>ثبت سفارش</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <h4 className='mb-4 mt-3'>دسته بندی خدمات کافه ارز</h4>
                                </div>

                                <div className="col-12 all_ss" style={{position: 'relative'}}>
                                    <React.Fragment>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <StickyBox offsetTop={20} offsetBottom={20}>

                                                    {categories.map(cat => (
                                                        (cat['cat'].filter_user===0 || (cat['cat'].filter_user===1 && this.props.user.can_see_filter_services===true))?
                                                            <div key={cat['cat'].id} className="">
                                                                <div data-id={cat['cat'].id}
                                                                     className={(cat['cat'].is_crypto ? "crypto " : "") + (cat['cat'].is_cash ? "cash " : "") + " d-flex services-new align-items-center   mb-1 " + (cat['cat'].id === 1 ? "active" : "")}>
                                                                    <LazyLoadImage
                                                                        alt={cat['cat'].name}
                                                                        height={30}
                                                                        src={cat['cat'].service_icon} // use normal <img> attributes as props
                                                                    />
                                                                    <h6 className="mt-2 title">{cat['cat'].name} </h6>
                                                                </div>
                                                            </div>
                                                            :""

                                                    ))}
                                                </StickyBox>
                                            </div>
                                            <div className="col-md-8  ssss">
                                                <StickyBox offsetTop={20} offsetBottom={20}>

                                                    {categories.map(cat => (
                                                        <div key={cat['cat'].id} data-tgs={cat['cat'].id}
                                                             className={"services_items_box services_items_box_" + cat['cat'].id + " " + (cat['cat'].id === 1 ? "active" : "")}>
                                                                <div className="d-md-none d-flex justify-content-between align-items-center p-4 pb-0 ">
                                                                    <h5>لطفا سرویس مورد نظر را انتخاب کنید</h5>
                                                                    <div className="close close-services">
                                                                        <i className='fe-x'></i>
                                                                    </div>
                                                                </div>
                                                           <div className="cont">
                                                               {
                                                                   cat['services'].map((service, index) => (
                                                                       (service.filter_user===0 || (service.filter_user===1 && this.props.user.can_see_filter_services===true) || service.is_cash)?
                                                                       <Link key={index}
                                                                             to={cat['cat'].is_cash || service.is_cash ? (service.crypto?(routes.orders_crypto+'?type=sell'):(routes.cash_new + (service.cash_id?service.cash_id:service.id) + "?cat=" + this.state.cash_cat.id)) : (routes.orders_new + service.id)}>
                                                                           <div
                                                                               className=" card card-body card-order-item  d-flex flex-row-reverse   justify-content-between  align-items-center  ">

                                                                               <div className="d-flex order-8 justify-content-start align-items-center">
                                                                                   <LazyLoadImage
                                                                                       alt={service.title}
                                                                                       height={40}
                                                                                       src={service.icon ? service.icon : service.service_icon} // use normal <img> attributes as props
                                                                                   />

                                                                                   <h5 className="title mr-2">{(cat['cat'].is_cash && service.cash)?service.cash.title:service.title}</h5>

                                                                               </div>

                                                                               <i className='fe-arrow-left'></i>

                                                                           </div>
                                                                       </Link>
                                                                        :""
                                                                   ))
                                                               }
                                                           </div>
                                                        </div>

                                                    ))}
                                                </StickyBox>
                                            </div>

                                        </div>

                                    </React.Fragment>


                                </div>


                            </div>

                        </>
                }
            </React.Fragment>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        user: state.user.info,
    }
}

export default connect(mapStateToProps)(OrdersServices);

