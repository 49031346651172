import React from 'react';

const OrderTypeButtons = ({edit,orderType,changeOrderType }) => {

    return (
        <>
            {
                !edit?
                    <div className="link-box big crypto  mb-4 order">
                        <a onClick={() => changeOrderType('buy')} href="#"
                           data-value="buy"
                           className={"order-btn buy " + (orderType === 'buy' ? 'active ' : '')}>خرید از ما</a>
                        <a onClick={() => changeOrderType('sell')} href="#"
                           data-value="sell"
                           className={"order-btn sell " + (orderType === 'sell' ? 'active ' : '')}>فروش به ما</a>
                    </div>
                    :''
            }
        </>
    );
};

export default OrderTypeButtons;



