import React from 'react';
import {connect} from "react-redux";
import Form from "../../ubold/commons/form";
import {spilitNumber} from "../../services/helpers";

class RefferalItem extends Form {

    render() {

        const {item}=this.props;

        return (
            <>
                <div className="row align-items-center">
                    <div className="col-lg-7 col-sm-7 col-7">
                        <div className="row align-items-center">
                            <div className="col-xl-4 col-lg-3">
                                <div>
                                    شماره :
                                    <span className="mr-1">
                                        #{item.id}
                                    </span>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-5">
                                {spilitNumber(item.user_profit)} <small>تومان</small>
                            </div>
                            <div className="col-xl-4 col-lg-4">
                                 درصد اعمال شده :
                                <span
                                    className={' mr-1 ' }>{parseFloat(item.percentage)} %</span>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-sm-5 col-5">
                        <div className="row align-items-center">
                            <div className="col-xl-6 col-lg-6">
                                <div className="">
                                    <div>
                                        صورت حساب  :
                                        <span className='d-inline-block mr-1'>{item.fk_trans_id}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 ">
                                <div>
                                    سفارش  :
                                    <span className='d-inline-block mr-1'>{item.fk_order_id}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }

}

export default connect ()(RefferalItem);
