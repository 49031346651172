import React from "react";
import $ from "jquery";

var interval;

class Slider extends React.Component {

    constructor(props) {
        super(props);
        const slides = this.props.slides;
        this.state = {
            slides: slides?slides:{},
            selectedIndex: slides?slides.indexOf(slides[0]):null,
            selected: slides?slides[0]:null,
        }
    }

    changeSlide = (index = false) => {
        const {slides, selected, selectedIndex} = this.state;
        var nextIndex = selectedIndex + 1;

        if (index) {
            nextIndex = index;
        }

        if (slides[nextIndex]) {
            this.selectSlide(nextIndex, slides[nextIndex]);
        } else {
            this.selectSlide(0, slides[0]);
        }

        if (index) {
            this.initInterval();
        }

    }

    selectSlide = (index, slide) => {
        this.setState({
            selectedIndex: index,
            selected: slide,
        })
    }

    componentDidMount() {
        this.initInterval();
        const me=this;

        $(function (){
            setTimeout(function (){
                me.initJq();
            },2000)

            $(window).resize(function (){
                me.initJq();
            });
        });
    }

    initInterval = () => {
        clearInterval(interval);
        if (this.state.slides.length > 0) {
            interval = setInterval(this.changeSlide, 5000);
        }
    }

    initJq=()=>{
        var h=$(".app-slider img").height();
        if(h <= 0)
            h=140;

        $(".app-slider").attr('style','height:'+h+'px');

        $(".dashboard-account").attr('style','min-height:'+(h-50)+'px')
    }

    componentDidUpdate() {
        const me=this;
        window.onload=function (){
            me.initJq();
        }
    }

    render() {
        const {slides, selected} = this.state;
        return (
            <>
                {
                    slides.length > 0 ?
                        <div className='app-slider-box'>
                            <div className='app-slider' style={{minHeight:'150px'}}>
                                {
                                    slides.map(slide => (
                                        <div style={{minHeight:'150px'}} key={slide.id}
                                             className={'slide ' + (selected.id === slide.id ? "selected" : "")}>
                                            {slide.link ? <a target='_blank' href={slide.link}>
                                                    <img style={{width: '100%', objectFit: 'cover'}} alt={slide.title} title={slide.title}
                                                         src={slide.file}/>
                                                </a> :
                                                <img  style={{width: '100%',  objectFit: 'cover'}} alt={slide.title} title={slide.title}
                                                     src={slide.file}/>
                                            }
                                        </div>
                                    ))
                                }

                                {
                                    slides.length > 1 ?
                                        <div className='dots'>
                                            {
                                                slides.map((slide, index) => (
                                                    <div onClick={() => this.changeSlide(index, slide)} key={slide.id}
                                                         className={'dot ' + (selected.id === slide.id ? "selected" : "")}>

                                                    </div>
                                                ))
                                            }
                                        </div>
                                        : ""
                                }
                            </div>


                        </div>
                        : ""
                }
            </>
        );
    }
}

export default Slider;


