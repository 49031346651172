import React from "react";
import loading from "../../assets/images/loading4.svg";
import loading2 from "../../assets/images/loading5.svg";
import {connect} from "react-redux";

const FormLoading = props => {

    return (
        <React.Fragment>
            <div className='component-loader d-flex flex-column align-self-center align-items-center  justify-content-center'>
                {
                    props.app.dark_theme? <img  alt={'loading'} src={loading} height='60' />: <img alt={'loading'} src={loading2} height='60' />
                }
                <h5>در حال بارگزاری </h5>
            </div>
        </React.Fragment>
    );
};



const mapStateToProps = appState => {
    return {
        app: appState.app
    }

};
export default connect(mapStateToProps)(FormLoading);