import React from 'react';
import {connect} from "react-redux";
import Form from "../../ubold/commons/form";
import {spilitNumber} from "../../services/helpers";
import parse from "html-react-parser";
import {Link} from "react-router-dom";

class TransactionItem extends Form {


    render() {

        const {transaction}=this.props;

        return (
            <>
                <div className="row">
                    <div className="col-lg-7 col-sm-7 col-7">
                        <div className="row align-items-center">
                            <div className="col-xl-3 col-lg-2">
                                <div>
                                    شماره :
                                    <span className="mr-1">
                                        #{transaction.transaction_id}
                                    </span>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-6">
                                <div >
                                    نحوه پرداخت : {transaction.trans_pay_type?parse(transaction.trans_pay_type):""}
                                </div>
                                <div >
                                    تاریخ  : {transaction.trans_pay_date_label}
                                </div>

                            </div>
                            <div className="col-xl-4 col-lg-4">
                                میزان : &nbsp;
                                { spilitNumber(transaction.price) } &nbsp; <small>تومان</small>
                                <br/>
                                <small style={{fontSize:'11px',opacity:'0.6'}}>علت : &nbsp;{parse( transaction.trans_type_label)}</small>
                                <div>
                                    وضعیت : { parse(('<span class="badge badge-'+ transaction.trans_status_text.class+ '">' +transaction.trans_status_text.label+ '</span>'))}
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className="col-lg-5 col-sm-5 col-5">
                        <div className="row align-items-center">



                            <div className="col-xl-6 col-lg-6">
                                <div className="">
                                    <div>
                                        ثبت :
                                        <span
                                            className={' mr-1 ' }>{transaction.shamsi_created_at}</span>
                                    </div>

                                   <div  style={{fontSize:'11px',opacity:'0.6'}}>
                                       <span>
                                           {
                                              parse( transaction.trans_desc)
                                           }
                                       </span>
                                   </div>

                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 ">
                                <div className="d-block d-md-none">
                                    <div className="mb-3"></div>
                                </div>
                                <div>
                                    {(transaction.status==='INIT' || transaction.status==='FAILED' )?
                                        <Link className="btn btn-default ml-1 btn-xs"
                                              to={'/finance/checkout/'+transaction.transaction_id}><i
                                            className="fe-shopping-cart"></i> پرداخت</Link>
                                        :""}

                                    {
                                        (transaction.status==='PENDING' &&  transaction.type==='withdraw')?

                                            <button onClick={()=> this.props.handleDelete(transaction.transaction_id)} type="button"  className="btn btn-default btn-xs" >
                                                <i className="fe-trash"></i>
                                            </button>

                                            :''
                                    }

                                    {
                                        (transaction.status==='INIT' && transaction.type==='addfund')?

                                            <button onClick={()=> this.props.handleDelete(transaction.transaction_id)} type="button"  className="btn btn-default btn-xs" >
                                                <i className="fe-trash"></i>
                                            </button>

                                            :''
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }

}

export default connect ()(TransactionItem);
