import http from "./../../services/httpService";

export function all(currentPage,no_read=false) {
    return http.post( '/tickets/list'+(no_read?'?no_read=1':''),{page:currentPage});
}

export function create(data,options={}) {
    return http.submit('post',  '/tickets/create', data,options);
}

export function reply(ticket,data,options) {
    return http.post(  '/tickets/'+ticket+'/reply', data,options);
}

export function getTicket(id) {
    return http.post(  '/tickets/'+id);
}

export function getDepartments() {
    return http.post( '/tickets/get-departments' );
}