import React from 'react';
import {updatePageTitle} from "../../actions/breadcrumbs";
import {connect} from "react-redux";
import Form from "../../ubold/commons/form";
import Joi from "joi-browser";
import {create, getDepartments} from "../../modules/tickets/TicketService";
import {latestOrders, updateUserInStore} from "../../modules/user/userService";
import {Redirect} from "react-router-dom";
import FormLoader from "../../ubold/commons/FormLoader";
import routes from "../../routes";
import {Modal, ModalHeader} from "reactstrap";
import OrdersTable from "./OrdersTable";
import TicketCreateLoading from "../../components/loadings/TicketCreateLoading";

class TicketCreate extends Form {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                title:'',
                content:'',
                fk_order_id:'',
                fk_department_id:2,
                priority:'DOWN',
                attachment:[]
            },
            errors: {},
            orders: {},
            modal: false,
            departments: [],
            priorities:[],
            loading: false,
            loadingForm: true,
            loadingModal: false,
            sortColumn: { path: "", order: "" },

            redirect:false
        };

        this.schema = {
            title: Joi
                .string()
                .required()
                .label("موضوع"),
            content: Joi
                .string()
                .required()
                .label("محتوا "),
            fk_order_id: Joi.label("شماره سفارش"),
            fk_department_id: Joi
                .required()
                .label("دپارتمان"),
            priority: Joi
                .required()
                .label("اهمیت"),
            attachment: Joi.label("فایل پیوست"),



        };

        this.props.dispatch(updatePageTitle(
            'ثبت تیکت جدید',
            [
                {
                    'title': 'لیست تیکت ها',
                    'link': routes.ticket
                },
                {
                    'title': 'ثبت تیکت جدید',
                    'active': true
                }
            ]
        ));
    }

    componentDidMount() {
        getDepartments().then(res=>{
            this.setState({
               departments:res.data.departments,
               priorities:res.data.priorities,
               loadingForm:false
           })
        });
    }

    getLatestOrders() {
        this.setState({
            loadingModal:true
        });

        latestOrders().then(res=>{
            this.setState({
                orders:res.data.orders,
                modal:true,
                loadingModal:false
            })
        });
    }

    toggleModal = () => {
        this.setState({modal:!this.state.modal});
    }

    doSubmit = async () => {
        try {
            var data = this.state.data;
            const FData = new FormData();

            Object.keys(data).forEach(item => {
                if (item !== 'attachment')
                    FData.append(item, data[item]);
            });

            if (document.getElementById('attachment').files.length > 0) {
                Array.from(document.getElementById('attachment').files).forEach(file => {
                    FData.append('attachment[]', file);
                });
            }


            create(FData?FData:data,{headers: {
                    'content-type': 'multipart/form-data'
                }}).then(res => {
                this.toggle();
                this.setState({
                    redirect:routes.ticket_show+res.ticket.ticket_id
                })
            }).catch(res => {
                this.toggle();
            });

        } catch (ex) {
            this.toggle();
        }
    };

    openModalOrders=()=>{
        this.getLatestOrders();
    }

    onSelect=(id)=>{
        const {data}=this.state;
        data.fk_order_id=id;
        this.setState({  data , modal:false})
    }


    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        const { sortColumn } = this.state;


        return (
            <React.Fragment>
                    <div className="row mt-4 justify-content-center">
                        <div className="col-lg-10 col-md-12">
                            {
                                this.state.loadingForm ?
                                    <TicketCreateLoading/>
                                    :
                                    <React.Fragment>
                                        <div className="card ">

                                            <Modal size={'lg'} isOpen={this.state.modal} toggle={this.toggleModal} className='latest_order_table '>
                                                <ModalHeader toggle={this.toggleModal}>  سفارش خود را انتخاب کنید</ModalHeader>
                                                <form method="POST" onSubmit={this.handleSubmit}>

                                                    <OrdersTable
                                                        orders={this.state.orders}
                                                        count={this.state.orders.length}
                                                        sortColumn={sortColumn}
                                                        onClick={sortColumn}
                                                        onSelect={id=>this.onSelect(id)}
                                                        className={'table-hovered'}

                                                    />

                                                </form>
                                            </Modal>

                                            <div className='card-body pt-5 mb-5'>
                                                <div className="row justify-content-center ">
                                                    <div className="col-xl-10 col-lg-12">
                                                        <form method="POST" onSubmit={this.handleSubmit}>

                                                            {
                                                                this.renderInput('title', 'موضوع', 'text', '', '', '', true)
                                                            }

                                                            <div className="form-group row">
                                                                <div className="col-md-3">
                                                                    <label> شماره سفارش (اختیاری)</label>
                                                                </div>
                                                                <div className="col-md-9">
                                                                    <div className="input-group">

                                                                        <input name="fk_order_id" value={this.state.data.fk_order_id} onChange={this.handleChange} type="number" className="form-control"  id="fk_order_id" />
                                                                        <div className="input-group-prepend">
                                                            <span style={{cursor:'pointer'}} onClick={this.openModalOrders} className="input-group-text" id="inputGroupPrepend">
                                                                <img alt={'loading'} src='/assets/images/loading.svg' height={20} width={20} className={'ml-2 '+(!this.state.loadingModal?"d-none":'')} />
                                                                انتخاب سفارش
                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            {
                                                                this.renderSelect('priority', 'اهمیت', this.state.priorities, this.state.data.priority,true)
                                                            }

                                                            {
                                                                this.renderSelect('fk_department_id', 'دپارتمان', this.state.departments, this.state.data.fk_department_id,true)
                                                            }


                                                            {
                                                                this.renderTextArea('content', 'محتوا', '', true)
                                                            }


                                                            {
                                                                this.renderInput('attachment', 'فایل پیوست', 'file','','','',true ,'','',null,{multiple:true})
                                                            }


                                                            {this.renderButton(<span><i className='fe-send'></i> ارسال تیکت </span>, '', true)}
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </React.Fragment>
                            }

                        </div>
                    </div>
            </React.Fragment>
        )
    }

}

export default connect()(TicketCreate);
