import React from 'react';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import routes from "../../../../routes";
import $ from "jquery";
const feather = require('feather-icons');

class SidebarContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            width:  window.innerWidth
        }
    }

    updateDimensions() {
        this.setState({ width: window.innerWidth});
    }


    handleMenuOpenClose=(el)=>{
        if ($(el).hasClass('show')){
            $(el).removeClass('show');
            $('body').css('overflow-y','auto')
        }
        else
        {
            $(el).addClass('show');
            $('body').css('overflow-y','hidden')
        }
    }

    componentDidMount() {
        const me=this;
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));

        $(function () {

            $('.close-menu').click(function () {
                $('.left-sidebar').removeClass('show');
                $('body').css('overflow-y','auto')
            });

            var menu_ul_a = $(".sidebar .menu ul > li a");
            var menu_ul_li = $(".sidebar .menu ul > li");
            var menu_ul_li_li = $(".sidebar .menu ul > li  li");

            menu_ul_a.click(function () {
                if ($(this).hasClass('active')) {
                    $(this).removeClass('active');
                    $(this).parent().removeClass('active');

                } else {
                    menu_ul_a.removeClass('active');
                    menu_ul_li_li.removeClass('active');

                    if($(this).hasClass('has-sub')){
                        menu_ul_li.removeClass('active');
                        $(this).parent().addClass('active');
                    }

                    $(this).addClass('active');
                }

                if(me.state.width < 1000 && !$(this).hasClass('has-sub')){
                    $('.left-sidebar').removeClass('show');
                    $('body').css('overflow-y','auto')
                }

            });

        })

    }


    render() {
        const user=this.props.user;
        return (
            <section className="left-sidebar">
                <div className="sidebar">
                    <div className="clearfix"></div>
                    <div className="menu active">
                        <ul className="" id="">
                            <li className=""><Link className=""
                                                      to="/"><i
                                className="fe-home i"></i> <span className="title">داشبورد</span></Link></li>
                            <li className="nav-second-level  "><a className=" has-sub"
                                                                  href="#profile"><i
                                className="fe-user i "></i>
                                <span className="title">حساب کاربری من</span>

                                <i className='a-down'
                                   dangerouslySetInnerHTML={{__html: feather.icons['chevron-down'].toSvg()}}/>

                            </a>
                                <ul className="has-submenu">
                                    <li className="   "><Link className=" "
                                                              to={routes.profile_edit}><i
                                        className=""></i> <span
                                        className="title">ویرایش حساب کاربری</span></Link>
                                    </li>
                                    <li className="  "><Link className=" "
                                                             to={routes.profile_verify}><i
                                        className=""></i> <span
                                        className="title">تایید مدارک (احراز هویت) </span></Link></li>
                                    <li className="  "><Link className=" "
                                                             to={routes.profile_security}><i
                                        className=""></i> <span
                                        className="title">امنیت حساب کاربری</span></Link></li>

                                    {
                                        user.legal ?

                                            <li className="  "><Link className=" "
                                                                     to={routes.profile_ibans}><i
                                                className=""></i> <span
                                                className="title"> افزودن  شماره شبا </span></Link></li>
                                            :   <li className="  "><Link className=" "
                                                                         to={routes.profile_bank_credits}><i
                                                className=""></i> <span
                                                className="title">  افزودن کارت بانکی </span></Link></li>
                                    }



                                    <li className="  "><Link className=" "
                                                              to={routes.profile_vip}><i
                                        className=""></i> <span
                                        className="title"> ارتقا سطح کاربری </span></Link></li>
                                    <li className="  "><Link className=" "
                                                             to={routes.profile_change_password}><i
                                        className=""></i> <span
                                        className="title"> تغییر رمز عبور </span></Link></li>
                                    <li className="  "><Link className=" "
                                                             to={routes.profile_logins}><i
                                        className=""></i> <span
                                        className="title"> سوابق ورود </span></Link></li>
                                </ul>
                            </li>
                            <li className=" nav-second-level  ">
                                <a className="has-sub "
                                                  href="#orders"><i
                                className="fe-shopping-cart i"></i> <span className="title">ثبت سفارش  </span>
                                    <i className='a-down'
                                       dangerouslySetInnerHTML={{__html: feather.icons['chevron-down'].toSvg()}}/>
                            </a>
                                <ul className="has-submenu">
                                    <li className="  "><Link className=" "
                                                             to={routes.orders_service}><i className=""></i>
                                        <span className="title">ثبت سفارش جدید</span></Link>
                                    </li>
                                    <li className="  "><Link className=" "
                                                             to={routes.orders}><i className=""></i>
                                        <span className="title">لیست سفارش ها</span></Link>
                                    </li>
                                </ul>
                            </li>

                            <li className=" nav-second-level  "><a className=" has-sub"
                                                     href='#cashes'><i
                                className="fe-repeat i"></i> <span className="title"> فروش ارز به ما  </span>
                                <i className='a-down'
                                   dangerouslySetInnerHTML={{__html: feather.icons['chevron-down'].toSvg()}}/>
                            </a>
                                <ul className="has-submenu">
                                    <li className="  "><Link className=" "
                                                             to='/orders/services/items/16'><i className=""></i>
                                        <span className="title">ثبت سفارش فروش ارز</span></Link>
                                    </li>
                                    <li className="  "><Link className=" "
                                                             to={routes.cash_list}><i className=""></i>
                                        <span className="title">لیست سفارش ها فروش ارز</span></Link>
                                    </li>
                                </ul>

                            </li>

                            <li className="nav-second-level  "><a className=" has-sub"
                                                                  href="#tickets"><i
                                className="fe-message-square i"></i> <span
                                className="title">تیکت ها</span>
                                <i className='a-down'
                                   dangerouslySetInnerHTML={{__html: feather.icons['chevron-down'].toSvg()}}/>

                                {user.alarms && user.alarms.tickets?<span className='badge badge-danger'>{user.alarms.tickets}</span>:''}

                            </a>
                                <ul className="has-submenu">
                                    <li className="   "><Link className=" "
                                                           to={routes.ticket}><i
                                        className=""></i>
                                        <span
                                            className="title">لیست تیکت ها
                                            {user.alarms && user.alarms.tickets?<div className='text-danger d-inline-block mr-2'> ({user.alarms.tickets})</div>:''}
                                                              </span>

                                      </Link>
                                    </li>
                                    <li className="  "><Link className=" "
                                                          to={routes.ticket_create}><i
                                        className=""></i> <span className="title">ثبت تیکت جدید</span></Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-second-level  "><Link className=" has-sub"
                                                                  to={routes.finance}><i
                                className="fe-dollar-sign i"></i> <span className="title">مالی</span>

                                {user.alarms && user.alarms.transactions?<span className='badge badge-danger'>{user.alarms.transactions}</span>:''}

                            </Link>

                            </li>
                            <li className="  "><Link className=" "
                                                  to={routes.comments}><i
                                className="fe-message-circle i"></i> <span
                                className="title">نظرات و پیشنهادات</span></Link></li>
                            <li className="  "><Link className=" "
                                                  to={routes.referrals}><i
                                className="fe-share-2 i"></i> <span
                                className="title">همکاری در فروش</span></Link></li>
                            <li className="  "><Link className=" "
                                                     to={routes.rewards}><i
                                className="fe-gift i"></i> <span
                                className="title">  پاداش ها</span></Link></li>

                            <li className="  ">
                                <Link className=" " to={routes.prices}>
                                    <i className="fe-trending-up i"></i>
                                    <span  className="title">لیست قیمت ارزها</span>
                                </Link>
                            </li>

                            <li className="  ">
                                <Link className=" " to={'/logout'}>
                                    <i className="fe-log-out i"></i>
                                    <span  className="title">خروج از پنل کاربری</span>
                                </Link>
                            </li>

                        </ul>


                    </div>

                    {/*{user.full_verify ? '' :*/}
                    {/*    <div className="fill-profile mt-4 ">*/}
                    {/*        <h4 className="title"><i className="fe-alert-circle"></i> لطفا مدارک خود را*/}
                    {/*            تایید*/}
                    {/*            کنید</h4>*/}
                    {/*        <p>*/}
                    {/*            متاسفانه برخی مدارک شما از جمله &nbsp;*/}
                    {/*            <strong>{user.unverify_text}</strong>*/}
                    {/*            &nbsp;*/}
                    {/*            تایید نشده اند، لطفا قبل از اقدام به ثبت سفارش مدارک خود را تایید کنید.*/}
                    {/*        </p>*/}
                    {/*        <Link to={routes.profile_verify}*/}
                    {/*           className="btn  btn-transparent btn-block">برای تایید مدارک کلیک کنید</Link>*/}

                    {/*    </div>*/}
                    {/*}*/}


                </div>
            </section>
        );
    }
}


const mapStateToProps = state => {
    return {
        user: state.user.info
    }
}

export default connect (mapStateToProps)(SidebarContent) ;