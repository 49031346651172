import React from 'react';
import LinkBox from "../../ubold/components/layouts/LinkBox";
import routes from "../../routes";

class Layout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            links: [
                {
                    'title': "  ویرایش پروفایل",
                    'url': routes.profile_edit,
                    'icon': 'fe-user'
                },
                {
                    'title': "احراز هویت ",
                    'url': routes.profile_verify,
                    'icon': 'fe-upload'
                },
                {
                    'title': " تغییر رمز عبور",
                    'url': routes.profile_change_password,
                    'icon': 'fe-lock'
                },
                {
                    'title': "  کارت های بانکی",
                    'url': routes.profile_credit_account,
                    'icon': 'fe-credit-card'
                },
                {
                    'title': "امنیت حساب  ",
                    'url': routes.profile_security,
                    'icon': 'fe-shield'
                }
            ]
        };
    }



    render() {

        return (
            <React.Fragment>
                {/*<LinkBox links={this.state.links}/>*/}
                {this.props.children}
            </React.Fragment>
        )
    }

}

export default Layout;
