import React from 'react';
import Squre from "../skelete/Squre";
import SkeleteLoading from "../SkeleteLoading";
import Circle from "../skelete/Circle";

class UserVerifyLoading extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className="mt-4">
                    <SkeleteLoading>
                        <div className="row justify-content-center pt-3 ver-list">
                            <div className="col-xl-6 col-lg-7 col-md-10">
                               <div className="card card-body">
                                   <div className="row justify-content-center">
                                       <div className="col-12 mb-3">
                                           <div className="row justify-content-center">
                                               <div className="col-3 text-center">
                                                   <Squre height={20}/>
                                               </div>
                                           </div>
                                       </div>
                                       <div className="col-12  mb-3">
                                           <div className="row justify-content-center">
                                               <div className="col-3 text-center">
                                                   <Circle class={'m-auto'} height={80} width={80} />
                                               </div>
                                           </div>
                                       </div>

                                       <div className="col-12 mb-4">
                                           <div className="mb-1"><Squre height={20}/></div>
                                           <div className="mb-1"><Squre height={20}/></div>
                                           <div className="mb-1"><Squre height={20}/></div>

                                       </div>

                                       <div className="col-12">
                                           <div className="mb-1"><Squre height={65}/></div>
                                           <div className="mb-1"><Squre height={65}/></div>
                                           <div className="mb-1"><Squre height={65}/></div>

                                       </div>
                                   </div>
                               </div>

                            </div>
                        </div>
                    </SkeleteLoading>
                </div>
            </React.Fragment>
        );
    }
}

export default UserVerifyLoading;
