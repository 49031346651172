import React from 'react';
import {connect} from "react-redux";
import {updatePageTitle} from "../../actions/breadcrumbs";
import Form from "../../ubold/commons/form";
import http from "../../services/httpService";
import Layout from "./Layout";
import UserBankCards from "./UserBankCards";
import ErrorBox from "../partials/ErrorBox";
import routes from "../../routes";
import jwtDecode from "jwt-decode";
import {all, deleteCard} from "./BankCardService";
import {toast} from "react-toastify";
import BankIbanForm from "./forms/BankIbanForm";
import UseBankCardsLoadings from "../../components/loadings/UseBankCardsLoadings";
import BankCardForm from "./forms/BankCardForm";


class UserIbans extends Form {
    constructor(props) {
        super(props);
        this.state = {
            loadingForm: true,
            data: {},
            user: {},
        };

        this.props.dispatch(updatePageTitle(
            'افزودن شماره شبا',
            [
                {
                    'title': 'افزودن شماره شبا',
                    'active': true
                }
            ]
        ));
    }

    componentDidMount() {
        this.getData();
    }

    getData(){
        all().then(res => {
            const data= jwtDecode(res.data.data);
            this.setState({
                data: data,
                loadingForm: false
            });
        });
    }

    onDelete=(id,isTasvie)=>{
        this.setState({loadingForm:true});

        deleteCard(id,isTasvie).then(res=>{
            this.setState({loadingForm:false,  data: jwtDecode(res.data.data)});
            toast.success(res.data.message);
        });
    }


    render() {
        return (
            <>
                {
                    this.state.loadingForm ?
                        <UseBankCardsLoadings/>
                        :
                        <BankIbanForm ondelete={this.onDelete} data={this.state.data}/>
                }

            </>
        )
    }

}


const mapStateToProps = appState => {
    return {
        user: appState.user.info
    }

};
export default connect(mapStateToProps)(UserIbans);
