import React from 'react';

var moment = require('moment-jalaali')
let m = moment()
let year=m.jYear();

const defaultValue = {
    year: year - 58,
    month: 1,
    day: 1
};

const minimumDate = {
    year: year - 58,
    month: 1,
    day: 1
};

const maximumDate = {
    year: year - 18,
    month: 12,
    day: 31
}

class Birthday extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            year: this.props.year?this.props.year:'',
            month:this.props.month?this.props.month:'',
            day:this.props.day?this.props.day:'',
            disabled:this.props.disabled?this.props.disabled:false
        };
    }

    handleChange=({currentTarget:input})=>{
        const data=this.state;
        data[input.name]=input.value;
        this.setState(data);
        this.handleCallback();
    }

    handleCallback = () =>{
        var date=this.state.year+'/'+this.state.month+'/'+this.state.day;

        if(this.state.year && this.state.month && this.state.day)
            this.props.callback(date);
    }

    render() {
        let months = [];
        for (let i = 1; i<=12; i++){
            months.push(<option s key={i} value={i}>{i}</option>)
        }

        let days = [];
        for (let i = 1; i<=31; i++){
            days.push(<option  key={i} value={i}>{i}</option>)
        }

        let years = [];
        for (let i = minimumDate.year; i<=maximumDate.year; i++){
            years.push(<option key={i} value={i}>{i}</option>)
        }

        return (
            <div className='birthday'>
                <div className="row">
                    <div className="col">
                        {
                            this.state.disabled?<div>
                                <div className="form-control text-center p-2">
                                    {this.state.day}
                                </div>
                            </div>:<select value={parseInt(this.state.day)} name='day' className='form-control' onChange={this.handleChange}>
                                <option value=''>روز</option>
                                {
                                    days
                                }
                            </select>
                        }

                    </div>
                    <div className="col">
                        {
                            this.state.disabled?<div>
                                <div className="form-control text-center p-2">
                                    {this.state.month}
                                </div>
                            </div>:<select value={parseInt(this.state.month)} name='month' className='form-control' onChange={this.handleChange}>
                                <option value=''>ماه</option>
                                {
                                    months
                                }
                            </select>
                        }

                    </div>
                    <div className="col">
                        {
                            this.state.disabled?<div>
                                <div className="form-control text-center p-2">
                                    {this.state.year}
                                </div>
                            </div>:   <select value={parseInt(this.state.year)} name='year' className='form-control' onChange={this.handleChange}>
                                <option value=''>سال</option>
                                {
                                    years
                                }
                            </select>
                        }

                    </div>
                </div>
            </div>
        );
    }

}

export default Birthday;
