import React from 'react';
import Form from "../../../ubold/commons/form";
import http from "../../../services/httpService";
import loading from "../../../assets/images/loading2.svg";
import Joi from "joi-browser";
import {toast} from "react-toastify";
import Squre from "../../../components/skelete/Squre";


class SecurityForm extends Form {
    constructor(props) {
        super(props);

        this.state = {
            data: this.props.data,
            errors: {},
            type:null,
            loading: false,
            show_google:(this.props.user.tfa_type==='GOOGLE'),
            remove_google:(this.props.user.tfa_type==='GOOGLE'),

        };

        this.schema = {
            tfa_type: Joi.string()
                .required()
                .label("نوع ورود دو مرحله ای"),
            totp: Joi.label("نوع ورود دو مرحله ای")

        };

    }

    handleClick = (e) =>{
        if(e.target.value==='GOOGLE')
           this.setState({show_google:true})
        else
            this.setState({show_google:false})

    }



    doSubmit = async () => {
        try {
            var data = this.state.data;

            if(this.state.data.tfa_type==='GOOGLE' && !this.state.data.totp){
                toast.error('کد امنیتی را وارد کنید');
                this.setState({loading:false})
                return false;
            }

            if(this.state.data.tfa_type==='GOOGLE'){
                data.totp=this.state.data.totp;
            }else
              delete  data.totp;

            if(this.state.remove_google){
                data.remove_google=1;
            }else
                delete data.remove_google;

            this.setState({loading:true})


            await http.submit('post',  '/user/update/2fa/'+this.props.secret_encrypt, data).then(res=>{
                this.toggle();
                this.props.reloadUser();
                this.setState({
                    remove_google:res.delete_google,
                    show_google:(res.tfa_type==='GOOGLE'),
                    data:{tfa_type:res.tfa_type},
                    loading:false
                })
            });


        } catch (ex) {
            this.toggle();
            delete data.remove_google;
        }
    };

    render() {

        return (
            <div className="row justify-content-center mt-4">
                <div className="col-xl-6 col-lg-7 col-md-10">
                    <form method="POST" onSubmit={this.handleSubmit}>
                       {
                           !this.props.user.google2fa_secret ?
                               <React.Fragment>
                                   <div className="mt-3">
                                       <h5 className={'mb-3'}>تعیین نوع ورود دو مرحله را انتخاب کنید</h5>
                                       <ul className="list-group mb-3 tp-setting">
                                           {
                                               Object.entries(this.props.tfa_types).map(([key, val]) =>
                                                   <li key={key} className="list-group-item">
                                                       &nbsp;
                                                       {val}
                                                       <div className="float-right ml-1">
                                                           {
                                                               this.state.data.tfa_type===key?
                                                                   <input name='tfa_type' onClick={this.handleClick} key={key} checked onChange={this.handleChange} type='radio'  value={key} />
                                                                   :
                                                                   <input name='tfa_type' onClick={this.handleClick}  key={key} onChange={this.handleChange} type='radio'  value={key} />

                                                           }
                                                       </div>
                                                   </li>
                                               )
                                           }
                                       </ul>
                                   </div>
                               </React.Fragment>
                           : ''
                       }

                        {this.state.show_google?
                            <div className="mt-4 googleauth" >

                                {(this.props.user.tfa_type==='GOOGLE' && this.props.user.google2fa_secret)?
                                    <div className='card card-body'>
                                      <div className="card-header">
                                          <div className="card-title">
                                              <strong className=" ">غیر فعال کردن Google
                                              Authenticator
                                              </strong>
                                          </div>
                                      </div>
                                        <p>لطفا کد امنیتی را Google Authenticator خوانده و در کادر زیر وارد نمایید </p>

                                        {this.renderInput('totp','','text')}

                                        <div className="form-group mt-1">
                                            <button disabled={this.state.loading} className='btn btn-t'>
                                                <img alt={'ll'} style={{'display': this.state.loading ? 'inline-block' : 'none'}} height="20" className="ml-1"
                                                     src={loading}/>
                                                به روز رسانی
                                            </button>
                                        </div>
                                    </div>
                                    :
                                    <div>


                                        <div className="card card-body mt-4">


                                            <div className="">
                                                <div className="card-title text-primary"><strong className=" text-primary">
                                                    دانلود و نصب اپلیکیش Google Authenticator
                                                </strong></div>


                                                <div className="text-center mt-4">
                                                    <a style={{fontWeight: 800}} target="_blank" rel={'noopener noreferrer'}
                                                       className="btn btn-dark btn-rounded m-1"
                                                       href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;hl=en_US">
                                                        <svg className="icon" dangerouslySetInnerHTML={{__html: '<use xlink:href="/assets/images/sprite.svg#android"></use>'}}/>
                                                         گوگل پلی </a>
                                                    <a style={{fontWeight: 800}} target="_blank" rel={'noopener noreferrer'}
                                                       className="btn btn-dark btn-rounded m-1"
                                                       href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8">
                                                        <svg className="icon" dangerouslySetInnerHTML={{__html: '<use xlink:href="/assets/images/sprite.svg#apple"></use>'}}/>
                                                        اپ استور</a>
                                                </div>
                                            </div>
                                            <div className=" mt-4 ">

                                                <div className="text-center ">
                                                    <img alt={'code'} className='mb-1' height={200} width={200} src={this.props.qrcode}/>
                                                    <p style={{fontSize:'11px'}}>کد بازیابی : <strong
                                                            className="text-danger">{this.props.secret}</strong></p>
                                                </div>
                                                <div className="clearfix"></div>

                                            </div>
                                            <div className="">

                                                <div className="form-group">
                                                    <label> لطفا کد امنیتی
                                                        رقمی را در زیر وارد کنید </label>
                                                    {this.renderInput('totp','','text')}
                                                </div>

                                            </div>
                                            <div>
                                            </div>


                                            <div className="form-group mt-1">
                                                <button disabled={this.state.loading} className='btn btn-t'>
                                                    <img alt={'ll'} style={{'display': this.state.loading ? 'inline-block' : 'none'}} height="20" className="ml-1"
                                                         src={loading}/>
                                                    به روز رسانی
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {/*{!!Form::hidden('remove-google', 1, ['class'=>'form-control']) !!}*/}




                            </div>
                            :''}


                    </form>
                </div>

            </div>
        )
    }

}


export default SecurityForm;
