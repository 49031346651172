import React from 'react';
import Form from "../../../ubold/commons/form";
import http from "../../../services/httpService";
import Joi from "joi-browser";
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import "./../../../../node_modules/persianjs/persian"
import FormLoader from "../../../ubold/commons/FormLoader";
import {updateUserInStore} from "../userService";
import {toast} from "react-toastify";
import {connect} from "react-redux";


class UpdateEmail extends Form {
    constructor(props) {
        super(props);

        this.state = {
            loadingForm: false,
            data: {},
            userData: {},
            info:{},
            provinces: [],
            verifications: [],
            user: this.props.user,
            cities: [],
            errors: {},
            b:null,
            loading: false,
        };

        this.schema = {
            email: Joi.string()
                .label("ایمیل ")
        };


    }


    doSubmit = async () => {
        try {
            var data = this.state.data;
            http.post( '/user/update/profile?info=1', data).then(res => {
                toast.success(res.data.message);
                this.toggle();
                this.props.reloadUser();
                updateUserInStore(this.props.dispatch,res.data.user);

            }).catch(res => {
                this.toggle();
            });

        } catch (ex) {
            this.toggle();
        }
    };


    render() {

        return (
            this.state.loadingForm ?
                <FormLoader/>
                :
                <div className="">
                    <p>ابتدا ایمیل خود را ثبت کنید</p>
                    <form method="POST" onSubmit={this.handleSubmit}>
                        {
                            this.renderInput('email', 'ایمیل ', 'email', 'ltr', '', '', false, '', '', null)
                        }
                        {
                            this.renderButton(<span><i className='fe-refresh-cw'></i> به روز رسانی</span>, '')
                        }
                    </form>

                </div>

        )
    }

}


export default connect()(UpdateEmail);
