import React from 'react';
import Squre from "../skelete/Squre";
import SkeleteLoading from "../SkeleteLoading";

class FinanceCheckoutLoading extends React.Component {

    render(){

        return (
            <React.Fragment>
                <div className="row justify-content-center">
                    <div className="col-xl-9 col-lg-11">
                            <SkeleteLoading>
                   <div className="mb-1">
                       <Squre height={150} />
                   </div>
                    <div className="mb-1">
                        <Squre height={250} />
                    </div>
                    <div className="mb-1">
                        <Squre height={130} />
                    </div>
                    <div className="mb-3">
                        <Squre height={150} />
                    </div>
                    <div className="mb-1">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="row">
                                    <div className="col-3">
                                        <Squre height={40} />
                                    </div>
                                    <div className="col-3">
                                        <Squre height={40} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7 ltr">
                                <div className="row ">
                                    <div className="col-5 ltr">
                                        <Squre height={50} />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </SkeleteLoading>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default FinanceCheckoutLoading;
