import React from 'react';
import {updatePageTitle} from "../../actions/breadcrumbs";
import {connect} from "react-redux";
import { getTicket, reply} from "../../modules/tickets/TicketService";
import FormLoader from "../../ubold/commons/FormLoader";
import parse from "html-react-parser";
import Form from "../../ubold/commons/form";
import Joi from "joi-browser";
import {updateUserInStore} from "../user/userService";
import DataBox from "../partials/DataBox";
import TItem from "./TItem";
import jwtDecode from "jwt-decode";
import {toast} from "react-toastify";
import {getUrlWithUserAttr} from "../../services/helpers";
import routes from "../../routes";
import TicketShowLoading from "../../components/loadings/TicketShowLoading";


class TicketShow extends Form {
    constructor(props) {
        super(props);

        this.state = {
            data: {
                content: '',
                attachment: []
            },
            errors: {},
            ticket: [],
            replies: [],
            replies_length:0,
            socials_buttons: '',
            ticket_id: this.props.match.params.id,
            loadingForm: true,
        };


        this.schema = {
            content: Joi
                .string()
                .required()
                .label("محتوا "),
            attachment: Joi.label("فایل پیوست"),
        };


        this.props.dispatch(updatePageTitle(
            'تیکت شماره # ' + this.state.ticket_id,
            [
                {
                    'title': 'لیست تیکت ها',
                    'link': routes.ticket
                },
                {
                    'title': 'تیکت شماره # ' + this.state.ticket_id,
                    'active': true
                }
            ]
        ));


    }

    componentDidMount() {
        getTicket(this.state.ticket_id).then(res => {
            const replies=res.data.replies;

            var result = replies.filter((item)=>{
                return item.private!==1;
            });

            this.setState(
                {
                    ticket: jwtDecode(res.data.ticket),
                    replies: replies,
                    // socials_buttons:res.data.socials_buttons,
                    loadingForm: false,
                    replies_length:result.length
                }
            );

            updateUserInStore(this.props.dispatch,res.data.user);

        });
    }


    doSubmit = async () => {
        try {
            var data = this.state.data;
            var FData = new FormData();

            Object.keys(data).forEach(item => {
                if (item !== 'attachment')
                    FData.append(item, data[item]);
            });

            if (document.getElementById('attachment').files.length > 0) {
                Array.from(document.getElementById('attachment').files).forEach(file => {
                    FData.append('attachment[]', file);
                });
            }

            reply(this.state.ticket_id, FData, {
                headers: {
                    'Content-type': 'multipart/form-data'
                }
            }).then(res => {
                this.toggle();
                toast.success(res.data.message);

                this.setState({
                    replies: jwtDecode(res.data.replies),
                    data: {
                        content: '',
                        attachment: [],
                    }
                });

                updateUserInStore(this.props.dispatch,res.data.user)

            }).catch(res => {
                this.toggle();
            });

        } catch (ex) {
            this.toggle();
        }
    };


    render() {

        const {ticket} = this.state;
        const sender = ticket.sender_info ? ticket.sender_info : ticket.user_info;

        return (
            <React.Fragment>
                {
                    this.state.loadingForm ?
                        <TicketShowLoading/>
                        :
                        <React.Fragment>
                            <div className='ticket-container'>
                                <div className="card card-body header mb-4 ticket-info">



                                    <h1 className='d-flex  align-items-center title'>
                                        <img height='45' width='45' className='ml-2' src={sender.avatar}
                                             alt={sender.name}/>

                                        <div>
                                            {ticket.title}
                                            <div style={{fontSize: '12px',  opacity: 0.5}}>
                                                {ticket.shamsi_created_at}
                                            </div>
                                        </div>
                                    </h1>

                                    <div className="user-message">
                                        {parse(ticket.content)}

                                        {
                                            ticket.file_attachs.length > 0 ?
                                                <div className='attach'>
                                                        <ul>
                                                            {ticket.file_attachs.map(file =>
                                                                <li key={file.name}>
                                                                    <a target="_blank" rel={'noopener noreferrer'}
                                                                       href={getUrlWithUserAttr(file.url,ticket.secure_id)}><i
                                                                        className='fa fa-paperclip'></i> {file.name}
                                                                    </a>
                                                                </li>
                                                            )}
                                                        </ul>
                                                </div>
                                                : ''
                                        }
                                    </div>

                                    <div className='mt-0 d-flex mt-2' style={{opacity:0.8}}>
                                        <div className="ml-3">
                                            وضعیت :
                                            <span className={'badge mr-1 badge-'+ticket.status_text.class}>
                                                {ticket.status_text.label}
                                            </span>
                                        </div>
                                        <div className="ml-2">
                                            دپارتمان :
                                            <span className='mr-1'>
                                                {ticket.group}
                                            </span>
                                        </div>
                                    </div>



                                </div>



                                {ticket.status !== 'CLOSED' && ticket.status !== 'RESOLVED' ?
                                   <React.Fragment>

                                       <div className="card ">
                                           <div className="card-body">

                                               <div className="row justify-content-center">
                                                   <div className="col-xl-9 col-lg-11">
                                                       <h5 className='section-title'> پاسخ خود را از اینجا ارسال کنید</h5>
                                                       <hr/>
<br/>
                                                       <form method="POST" onSubmit={this.handleSubmit}>

                                                           {
                                                               this.renderTextArea('content', 'محتوا', '', true, 5)
                                                           }


                                                           {
                                                               this.renderInput('attachment', 'فایل پیوست', 'file', '', '', '', true, '', '', null, {multiple: true})
                                                           }

                                                           {this.renderButton("ارسال پاسخ", '', true)}
                                                       </form>
                                                   </div>
                                               </div>
                                           </div>
                                       </div>
                                   </React.Fragment>
                                    : ''}

                                {this.state.replies.length > 0 ?
                                    <React.Fragment>
                                        <h5 className='section-title'>پاسخ ها ({this.state.replies.length})</h5>
                                            <div id={'comments'} className="comments">
                                                {
                                                    this.state.replies.map((t,index) => <TItem secid={ticket.secure_id} key={index} ticket={t}/>)
                                                }

                                            </div>
                                    </React.Fragment>
                                    :
                                    <DataBox message={'هیچ گونه پاسخی ثبت نشده است'} data={this.state.replies}/>
                                }


                            </div>
                        </React.Fragment>
                }
            </React.Fragment>


        )
    }

}

export default connect()(TicketShow);
