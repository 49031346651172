import React from 'react';
import Form from "./../../../ubold/commons/form";
import Joi from "joi-browser";
import auth from "../../../modules/auth/authService";
import RegisterMobileActive from "../components/tfa/RegisterMobileActive";
import {Redirect, Link} from "react-router-dom";
import {toast} from "react-toastify";
import $ from "jquery";
import {Popover, PopoverHeader, PopoverBody} from 'reactstrap';
import {siteUrl, siteKey} from '../../../config';
import Recaptcha from 'react-google-invisible-recaptcha';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import {convertDateToArray, setCookie} from "../../../services/helpers";
import lock from "../../../assets/images/svg-icons/tfa.svg";
import Birthday from "./Birthday";
import routes from "../../../routes";

var moment = require('moment-jalaali')
let m = moment()
let year = m.jYear();

const defaultValue = {
    year: year - 58,
    month: 1,
    day: 1
};

const minimumDate = {
    year: year - 58,
    month: 1,
    day: 1
};

const maximumDate = {
    year: year - 18,
    month: 12,
    day: 31
}


class Register extends Form {

    constructor(props) {
        super(props);

        this.state = {
            data: {
                mobile: "",
                email: "",
                name: "",
                national_code: "",
                rules: null,
                referred_by: null,
                password: "",
                password_confirmation: "",
                'g-recaptcha-response': "",
                birthday: defaultValue,
                token:null,
                token_type:'login',
                b:false
            },
            redirect: false,
            errors: {},
            loading: false,
            maxNumberLength: 10,
            popoverOpen: false,
            step: 1,
            reagent: [
                {
                    _id: '',
                    name: 'انتخاب کنید ',
                },
                {
                    _id: 'SEARCH_ENGINES',
                    name: 'موتورهای جستجو همانند گوگل '
                },
                {
                    _id: 'NEWSPAPERS_NEWSSITES',
                    name: 'روزنامه و سایت های خبری'
                },
                {
                    _id: 'FRIENDS',
                    name: 'معرفی دوستان'
                },
                {
                    _id: 'BROCHURES_ADVERTISING',
                    name: 'بروشورهای تبلیغاتی'
                },
                {
                    _id: 'INTERNET_ADVERTISING',
                    name: 'تبلیغات در اینترنت'
                },
                {
                    _id: 'SOCIAL_NETWORKS',
                    name: 'شبکه های اجتماعی'
                },
                {
                    _id: 'OTHERS',
                    name: 'سایر موارد'
                }

            ]
        };

        this.schema = {
            mobile: Joi.number()
                .required()
                .label("موبایل")
        };
    }

    togglePopover = () => {
        this.setState({popoverOpen: !this.state.popoverOpen})
    };

    initJquery = () => {
        $("[data-lang=en]").keypress(function (event) {
            var ew = event.which;
            if (ew > 128) {
                toast.warning('لطفا ' + $(this).data('name') + ' را انگلیسی تایپ کنید');
                return false;
            }
        });

        $("[data-lang=fa], .fa").keypress(function (event) {
            var ew = event.which;

            if (ew >= 65 && ew < 128) {
               $(".tf-t").show();
                return false;
            } else {
                $(".tf-t").hide();

            }

        });

        $("input").focus(function () {
            $(this).closest('.form-group').addClass('focus');
        });

        $("input").focusout(function () {
            if ($(this).val() === '')
                $(this).closest('.form-group').removeClass('focus');
        });
    }

    componentDidMount() {
        this.initJquery();
    }

    componentDidUpdate() {
        this.initJquery();
    }

    doSubmit = async () => {
        if (!$('#rules').is(':checked') && !this.state.data.rules) {
            toast.error(' قوانین سایت را مطالعه و قبول نمایید.');
            this.setState({loading: false});
        } else {
            this.recaptcha.execute().then(res => {
                this.toSubmit(res);
            }).catch(res => {
                console.log(res);
                window.location = window.location.href;
            })
        }

    }

    toSubmit = async (token) => {
        try {
            const {data} = this.state;
            data['g-recaptcha-response'] = token;

            const ref = localStorage.getItem("referral");
            if (ref) {
                data['referral'] = ref;
                setCookie("referral", false, 0);
                localStorage.removeItem("referral");
            }
            data.info = {birthday: this.state.data.birthday};
            this.setState({data});
            await auth.register(data);
            window.location = '/register-finish';
        } catch (ex) {
            this.toggle();
            this.recaptcha.reset();
        }
    }

    goToStepTwo = (mobile) => {
        const {data} = this.state;
        data['mobile'] = mobile;
        this.setState({data, step: 2});
        this.schema = {
            national_code: Joi.string()
                .required()
                .label("کد ملی"),
            first_name: Joi.string()
                .required()
                .label("نام"),
            last_name: Joi.string()
                .required()
                .label(" نام خانوادگی"),
            birthday: Joi.string()
                .required()
                .label("تاریخ تولد"),

            password: Joi.string()
                .required()
                .label("رمز عبور"),
            password_confirmation: Joi.string()
                .required()
                .label("تکرار رمز عبور"),
            rules: Joi.required().label('شرایط و قوانین')

        };
    }


    onResolved = () => {
        const {data} = this.state;
        data['g-recaptcha-response'] = this.recaptcha.getResponse();
        this.setState({data});
    }

    handleChangeBirthday = (value) => {
        const {data} = this.state;
        data['birthday'] = value;

        const errors = this.state.errors;
        delete errors['birthday'];
        this.setState({data, errors});

    }

    callbackOtpCde = (res) => {
        this.goToStepTwo(res.mobile);
        const {data} = this.state;
        data['token']=res.token;
        data['b']=res.b;
        this.setState({data });
    }

    render() {
        if (this.state.redirect) return <Redirect to={this.state.redirect}/>;

        const birthday = convertDateToArray(this.state.data.birthday);

        return (
            <React.Fragment>
                {
                    this.state.step === 1 ?
                        <div className='col-lg-5 col-md-6'>
                            <div className="card">
                                <RegisterMobileActive callback={(res) => this.callbackOtpCde(res)}  redirecturl={routes.register}/>
                            </div>
                        </div>
                        :
                        <div className='row justify-content-center'>
                            <div className="col-12">
                                <div className="mb-3">
                                    <div className="img-box">
                                        <img className='d-block m-auto logo' height={100} width={100} alt={'cafearz'}
                                             src={lock}/>
                                        <div className="btt"></div>
                                    </div>
                                    <div className="auth-title-box">
                                        <h1 className="auth-title">تکمیل اطلاعات</h1>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-5 col-md-6'>
                                <div className="card">
                                    <div className="card-body auth-box ">
                                        <form method="POST" onSubmit={this.handleSubmit}
                                              className="form-horizontal dialog-form embed">
                                            <div className="row">
                                                <div style={{display:'none'}}  className="col-12 tf-t ">
                                                        <div className={'alert alert-danger text-danger d-block'}>لطفا نام و نام خوانوادگی را فارسی تایپ کنید</div>

                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group rtl-label">
                                                        {this.renderInput("national_code", "کد ملی", 'number', 'ltr top', '', '', '', '', '', (e) => {
                                                            this.changeNumner(e)
                                                        }, {'maxLength': this.state.maxNumberLength})}
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group rtl-label">
                                                                {this.renderInput("first_name", "نام ", 'text', 'top fa')}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group rtl-label">
                                                                {this.renderInput("last_name", " نام خانوادگی", 'text', 'top fa')}
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group date rtl-label">
                                                        <label>تاریخ تولد</label>
                                                        <div style={{height: 'auto', paddingTop: '43px'}}
                                                             className="form-control">
                                                            <Birthday
                                                                callback={(date) => this.handleChangeBirthday(date)}/>
                                                        </div>

                                                        {this.state.errors.birthday && <small
                                                            className="font-11 error-label">{this.state.errors.birthday}</small>}

                                                    </div>

                                                </div>

                                                <div className="col-md-12">
                                                    <div className="form-group rtl-label ">
                                                        {this.renderInput("password", "رمز عبور", "password", 'top')}
                                                        <Popover placement="top" trigger="hover"
                                                                 isOpen={this.state.popoverOpen}
                                                                 target="password" toggle={this.togglePopover}>
                                                            <PopoverHeader>توجه کنید</PopoverHeader>
                                                            <PopoverBody>
                                                                رمز عبور باید بیش از 8 کاکتر و شامل کاراکتر های بزرگ کو
                                                                چک و اعداد باشد.
                                                            </PopoverBody>
                                                        </Popover>
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="form-group rtl-label ">
                                                        {this.renderInput("password_confirmation", "تکرار رمز عبور", "password")}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group mt-1 "
                                                 style={{textAlign: 'right', direction: 'rtl'}}>
                                                <div className="fancy-checkbox">
                                                    <div>
                                                        {this.renderInput("rules", " ", 'checkbox', '', 1)}

                                                        <span style={{fontSize: '14px'}}><a className='text-t'
                                                                                            href={(siteUrl + 'terms')}
                                                                                            rel='noopener noreferrer'
                                                                                            target="_blank">&nbsp; شرایط و قوانین </a>را مطالعه کرده و قبول دارم.</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group mt-3">
                                                {this.renderButton(" تکمیل ثبت نام ", 'btn-block  btn-primary',false,'',false,'btn-registered')}
                                            </div>

                                            <Recaptcha
                                                ref={ref => this.recaptcha = ref}
                                                sitekey={siteKey}
                                                onResolved={this.onResolved}/>

                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="card register-info  mt-3 p-4 m-3">
                                    <h5>لطفا توجه کنید</h5>
                                    <div className="mt-3">
                                        <ul className='pr-3'>
                                            <li>لطفا نام و نام خانوادگی خود را مطابق با کارت ملی وارد نمایید.</li>
                                            <li>لطفا از شماره ملی خودتان برای ثبت نام استفاده کنید.</li>
                                            <li>لطفا تاریخ تولد خود را صحیح وارد کنید.</li>
                                        </ul>

                                        <p>
                                            با صحیح وارد کردن اطلاعات فرم ، روند ثبت نام و احراز هویت شما در کمتر از 15
                                            دقیقه انجام خواهد شد.
                                        </p>
                                        <p>
                                            کافه ارز هیچ درخواستی برای استخدام نیروی فروش، حسابداری و ... در وب سایت های
                                            اگهی ندارد. اگر از طریق چنین آگهی‌هایی قصد ثبت نام در وب سایت ما را دارید،
                                            هدف کلاهبرداری افرادی قرار گرفته‌اید که قصد سو استفاده از حساب‌های بانکی شما
                                            جهت استفاده از وجوه دزدی را دارند.

                                        </p>
                                        <p>
                                            بدیهی است مسئولیت واگذاری اکانت کاربری به شخص ثالث و هرگونه سو استفاده و یا
                                            کلاهبرداری احتمالی به عهده کاربر می باشد.
                                        </p>

                                    </div>
                                    <div className='mt-3'>
                                        <Link className="text-t font-12 " to="/login">اگر حساب کاربری دارید لاگین
                                            کنید.</Link>
                                        <div className='mt-1'>
                                            <a className="text-t font-12 " href="https://cafearz.com">بازگشت به صفحه
                                                اصلی</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                }

            </React.Fragment>
        );
    }

}


export default Register;

