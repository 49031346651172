import React from 'react';
import {Link} from "react-router-dom";
import routes from "../../../routes";
import {connect} from "react-redux";
import {contains} from "../../../services/helpers";


class Cashincome extends React.Component {

    render() {
        const services= this.props.services.data;
        const cat= this.props.cat;
        return (
            <React.Fragment>
                <div className="row justify-content-center">
                    <div className="col-xl-12">
                        <div className="row justify-content-center">
                            <div className="col-xl-6 col-lg-8">
                                {
                                    services.map((service,index)=>(
                                        ( !contains(service,'filter_user') || (contains(service,'filter_user') && service.filter_user===0) || (contains(service,'filter_user') && service.filter_user===1 && this.props.user.can_see_filter_services===true))?
                                            <Link key={index} to={service.crypto?routes.orders_crypto+'?type=sell':routes.cash_new+service.id+"?cat="+cat.id}>
                                            <div  className=" card card-body card-order-item  d-flex flex-row   align-items-center">
                                                {
                                                    service.crypto?
                                                        <img alt={service.id} src={service.icon} height="40" />
                                                        :
                                                        <img alt={service.id} src={service.cash? (service.cash.ss_icon? service.cash.ss_icon:service.service_icon): service.service_icon} height="40" />
                                                }
                                                <h5 className="title">{service.cash?service.cash.title:service.title}</h5>
                                            </div>

                                        </Link> : ""

                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        user: state.user.info,
    }
}

export default connect(mapStateToProps)(Cashincome);