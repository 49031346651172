import React from 'react';
import StickyBox from "react-sticky-box";
import SidebarContent from "./parts/SidebarContent";
import feather from "feather-icons";
import {spilitNumber} from "../../../services/helpers";
import Verifications from "./Verifications";

class Sidebar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            width:  window.innerWidth
        }
    }

    updateDimensions() {
        this.setState({ width: window.innerWidth});
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }


    render() {
        const {user}=this.props;
        const balance = user.balance;

        return (
            <React.Fragment>
                <div onClick={()=>this.props.toggleSideMenu(false)} className="close d-block d-md-none">
                    <i className='fe-x'></i>
                </div>

                <img className='caferazimg' height={60} src={'/assets/images/app/'+(this.props.darktheme?"cafearz-dark":"cafearz")+'.svg'} />
                <div className='d-flex align-items-center user-info'>
                    <img className='avatar ml-2' alt={user.name} src={user.avatar} height={50} width={50} />
                    <div>
                        <h4 className='name'>
                            {user.name}
                        </h4>
                        <div className='email'>
                            {/*{user.email}*/}
                            موجودی :
                            &nbsp;
                            {(typeof balance === 'number') ? <span style={{margin: '0 3px'}} className={balance < 0 ? "text-danger" : "text-success"}>{spilitNumber(balance)}   <small>تومان</small></span>  : " 0 "}
                        </div>
                    </div>
                </div>

                {
                    (user.legal && user.branch) ?
                    <div style={{background:"rgba(0,0,0,0.06)",borderRadius:'6px',padding:'10px'}} className="mb-2 mt-3">
                        <h5 className={'text-primary'}>کاربر حقوقی</h5>
                        <div style={{fontSize:'12px'}}>
                            <div>
                                شناسه ملی شرکت :
                                <strong className={'mr-1'}>{user.branch.nationalCode}</strong>
                            </div>
                            <div>
                                نام شرکت :
                                <strong className={'mr-1'}>{user.branch.name?user.branch.name:"استعلام نشده"}</strong>
                            </div>
                        </div>
                    </div> :""

                }


                <div className="verifications mb-3 mt-3">
                    <Verifications user={user} verifications={user.verifications}/>
                </div>

                <SidebarContent/>

                {/*{this.state.width < 1000 ?*/}
                {/*    <SidebarContent/>*/}
                {/*    :*/}
                {/*    <StickyBox offsetTop={30} offsetBottom={20}>*/}
                {/*        <SidebarContent/>*/}
                {/*    </StickyBox>*/}
                {/*}*/}
            </React.Fragment>
        );
    }
}


export default Sidebar;