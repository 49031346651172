import React from 'react';
import {updatePageTitle} from "../../actions/breadcrumbs";
import {connect} from "react-redux";
import FormLoader from "../../ubold/commons/FormLoader";
import routes from "../../routes";
import {Link} from "react-router-dom";
import {getCash} from "./CashincomeService";
import PayWebmoney from "./parts/PayWebmoney";
import PayPerfectmoney from "./parts/PayPerfectmoney";
import PayPayeer from "./parts/PayPayeer";
import CashApiLoading from "../../components/loadings/CashApiLoading";
const feather = require('feather-icons')

class CashincomePay extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            cash_id:this.props.match.params.id,
            cash: null,
            loadingForm: true,
        };

        this.props.dispatch(updatePageTitle('انتقال ارز سفارش شماره ( '+this.state.cash_id+' ) '  ,
            [
                {
                    'title': 'انتقال ارز',
                    'active': true
                }
            ],

            (
                <React.Fragment>
                    <Link className={'btn btn-primary primary btn-sm'} to={routes.orders+'?type=sell'}>
                        <i dangerouslySetInnerHTML={{__html: feather.icons['arrow-left'].toSvg()}}/>
                        بازگشت</Link>
                </React.Fragment>
            )
        ));
    }

    componentDidMount() {
        getCash(this.state.cash_id,true).then(res=>{
            this.setState({
                cash:res.data.cash,
                data:res.data.data,
                loadingForm:false
            })
        });
    }



    render() {

        const {cash,  data} = this.state;

        return (
            <React.Fragment>
                {
                    this.state.loadingForm ?
                        <CashApiLoading/>
                        :
                        <React.Fragment>
                            <div className="row justify-content-center">

                                <div className="col-md-8 col-lg-6">
                                    <div className="card card-body">
                                        <div className="card-title text-center">
                                            <img alt={cash.id} className="m-auto mb-2" height="60" width={60} src={cash.cash_icon} />

                                            <h3 className='mt-3' >اتصال به
                                            درگاه {cash.service_type_label}
                                            </h3>

                                            <h5 className='mt-3 text-primary'>میزان ارز :
                                                <strong className='ml-1 mr-1'>{cash.amount}
                                                </strong>

                                                <small className='ml-1 mr-1'>{cash.currency_name}
                                                </small>
                                            </h5>


                                        </div>

                                        <div className="row justify-content-center">
                                            <div className="col-lg-8 col-12">
                                                <div className="mt-2 help-block text-center">
                                                    پس از پرداخت میزان فوق به حساب ما مبلغ فاکتور به کیف پول شما در سایت افزایش می یابد

                                                    <div className="mt-3">
                                                        {
                                                            cash.service_type==='WEBMONEY'?
                                                            <PayWebmoney cash={cash} data={data}/>
                                                            :""
                                                        }

                                                        {
                                                            cash.service_type==='PERFECTMONEY'?
                                                                <PayPerfectmoney cash={cash} data={data}/>
                                                                :""
                                                        }

                                                        {
                                                            cash.service_type==='PAYEER'?
                                                                <PayPayeer cash={cash} data={data}/>
                                                                :""
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                </div>
                            </div>
                        </React.Fragment>
                }
            </React.Fragment>
        )
    }

}

const mapStateToProps = state => {
    return {
        user: state.user.info
    }
}

export default connect (mapStateToProps)(CashincomePay) ;