import React from 'react';
import {updatePageTitle} from "../../actions/breadcrumbs";
import {connect} from "react-redux";
import {getRewards, settlementRewardRequest, updateUserInStore} from "../user/userService";
import moment from "moment-jalaali";
import FormLoader from "../../ubold/commons/FormLoader";
import Alert from "../partials/Alert";
import {spilitNumber} from "../../services/helpers";
import SweetAlert from "react-bootstrap-sweetalert";
import {toast} from "react-toastify";
import RewardItems from "./RewardItems";
import RewardsLoading from "../../components/loadings/RewardsLoading";

class Rewards extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingForm: true,
            rewards: [],
            alert: ''
        }

        this.props.dispatch(updatePageTitle(
            'پاداش ها',
            {}
        ));
    }


    componentDidMount() {
        getRewards().then(res=>{
            this.setState({
                rewards:res.data,
                loadingForm:false
            });
        });

    }

    getAlert() {
        return <SweetAlert
            warning
            showCancel
            confirmBtnText="بله, مطمئنم!"
            cancelBtnText="خیر"
            confirmBtnBsStyle="success"
            title="توجه!"
            onConfirm={() => this.sendRequest()}
            onCancel={this.hideAlert}
            focusCancelBtn
        >
            آیا مطمئن به ثبت درخواست تسویه هستید ؟
        </SweetAlert>
    }

    sendRequest = () => {
        this.setState({loadingForm:true,alert:false});

        settlementRewardRequest().then(res=>{
            this.setState({
                loadingForm:false,
                rewards:res.data.rewards,
            });

            toast.success(res.data.message);

            updateUserInStore(this.props.dispatch,res.data.user);
        })
    }

    handleAlert = () => {
        if(this.state.rewards.canSettlementRewards){
            this.setState({
                alert: this.getAlert()
            })
        } else {
            toast.error(this.state.rewards.howTimeSettlementRewards.label);
        }
    }

    hideAlert = () => {
        this.setState({
            alert: null
        });
    }

    render() {
        const {rewards}=this.state;
        return (
            this.state.loadingForm ?
                    <RewardsLoading/>

                :
            <React.Fragment>
                {this.state.alert}

                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-md-4 "  data-target="#currencies_modal" data-toggle="modal">
                                <div className="card reward-price-box">
                                    <div className="card-body">
                                        <h4 className="mt-0 font-16">مجموع پاداش ها</h4>
                                        <h2 className=" my-3 "><span data-plugin="counterup">{spilitNumber(rewards.totalRewards)}</span> <small>تومان</small></h2>
                                        <img src='/assets/images/app/reward.svg' />
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-4 col-6">
                                <div className="card reward-price-box ts">
                                    <div className="card-body ">
                                        <h4 className="mt-0 font-16 r">پاداش های تسویه شده </h4>
                                        <h2 className=" my-3  "><span data-plugin="counterup">{spilitNumber(rewards.totalSettlementRewards)}</span> <small>تومان</small></h2>
                                        <img src='/assets/images/app/ts.svg' />
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-4 col-6">
                                <div className="card reward-price-box tn">
                                    <div className="card-body">
                                        <h4 className="mt-0 font-16 ">پاداش های تسویه نشده</h4>
                                        <h2 className=" my-3  "><span data-plugin="counterup">{spilitNumber(rewards.totalNotSettlementRewards)}</span> <small>تومان</small></h2>
                                        <img src='/assets/images/app/tn.svg' />
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-12">
                                <br/>


                               <Alert type={'danger'}>
                                   <p className=''>
                                       در راستای ایجاد باشگاه مشتریان و آفر های جذاب برای مشتریان وفادار؛ سیستم پاداش تا تاریخ ۳۰ آذرماه متوقف خواهد شد و سیستم جدیدی جایگزین می شود. خواهشمند ایم نسبت به برداشت پاداش خود تا آن تاریخ اقدام بفرماید.
                                   </p>
                                </Alert>



                                <br/>

                            </div>


                        </div>

                        <div className="mb-3">

                            {rewards.userTotalOrdersInPeriod?
                                <div className="card  card-body">
                                    <h5 className='p-0 m-0'>حجم سفارشات شما در {rewards.discount_user_period} ماه اخیر
                                        : <strong>{spilitNumber(rewards.userTotalOrdersInPeriod)}
                                            &nbsp;
                                            <small >تومان</small></strong></h5>
                                </div>
                                :''}
                        </div>

                        {/*<Alert type={'warning'}>*/}
                        {/*    دکمه درخواست تسویه پاداش ها*/}
                        {/*    &nbsp;<strong className="text-danger ">{rewards.discount_user_time} روز</strong> &nbsp;*/}
                        {/*    پس از تاریخ آخرین تسویه (در صورتی که درخواست تسویه نداشتید تاریخ ثبت آخرین پاداش) فعال می شود و شما میتوانید با فشردن این دکمه مبلغ پاداش ها را به کیف پول خود منتقل کنید.*/}
                        {/*</Alert>*/}

                        <div className="card card-body" >
                            <div className="row  justify-content-between align-items-center">
                                <div className='col-md-8 mb-3'>
                                    <div>

 1.                                        آخرین تسویه :
                                        {rewards.lastSettlementReward?moment(rewards.lastSettlementReward.settlement_time).locale('fa').format('jYYYY/jMM/jDD - HH:mm'):"تا به حال درخواستی ثبت نشده"}
                                    </div>
                                    <div>
2.                                        تعداد روز مانده برای تسویه :
                                        &nbsp;
                                        <span className={'text-'+ rewards.howTimeSettlementRewards.class}>{rewards.howTimeSettlementRewards.label}</span>
                                    </div>
                                </div>

                                <div className="ltr col-md-4">
                                    <button onClick={()=>this.handleAlert()}  className="btn btn-t confirm_delete text-center btn-block btn-lg" style={{textAlign: 'right',direction: 'rtl'}}>
                                    <i className="fe-send ml-2"></i>
                                    ثبت درخواست تسویه</button>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="card-box">
                            <div className="table-responsive mt-3">

                            </div>

                        </div>

                    </div>

                </div>
            </div>


            </React.Fragment>

        )
    }

}

export default connect()(Rewards);
