import React from 'react';
import {Link} from "react-router-dom";
import routes from "../../../routes";
import {LazyLoadImage} from "react-lazy-load-image-component";

class GridServices extends React.Component {


    render() {
        return (
            <React.Fragment>

                <div className="row justify-content-center">
                    <div className="col-xl-12">
                        <div className="row justify-content-center">
                            {
                                this.props.services.map(service=>(
                                    <div key={service.id} className='col-xl-3 col-md-3 col-sm-6'>
                                        <Link to={routes.orders_new+service.id}>
                                            <div className="oo-box card-order-item column d-block card card-body  text-center mb-4 pt-4 pb-4
        d-flex flex-column align-items-center justify-content-center
mb-3">
                                                <LazyLoadImage
                                                    alt={service.title}
                                                    height={70}
                                                    src={service.icon?service.icon:service.service_icon} // use normal <img> attributes as props
                                                />

                                                <h5 className="title">{service.title}</h5>
                                            </div>

                                        </Link>
                                    </div>
                                ))
                            }
                        </div>

                    </div>
                </div>



            </React.Fragment>
        )
    }

}

export default GridServices;
