import React from 'react';

const SelectCoin = ({toggleModal,coin }) => {

    return (
        <>
            <div className="mb-3">
                    <div onClick={() => toggleModal()} className={'order-crypto-coins'}>
                        <div className="d-flex align-items-center">
                            <img className='ml-2' height={53} width={53}   src={coin.icon}/>
                            <div className='d-flex flex-column'>
                                <h2 className='title'>{coin.title}
                                    <small>{coin.name}</small>
                                </h2>
                                <h4 className='type'>{coin.symbol}</h4>
                            </div>
                        </div>

                        <div className="arrow"></div>
                    </div>
                </div>
        </>
    );
};

export default SelectCoin;

