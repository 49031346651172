import React from 'react';
import {connect} from "react-redux";

class PayWebmoney extends React.Component {

    render() {
        const cash=this.props.cash;
        const data=this.props.data[cash.service_type];
        const user=this.props.user;

        return (
            <React.Fragment>
                <form action={data.gateway} method="post">

                    <input type="hidden" name="LMI_PAYMENT_AMOUNT" value={cash.amount} />
                    <input type="hidden" name="LMI_PAYMENT_DESC"   value={('user id => '+ (cash.fk_user_id) +' => cash id : '+cash.id)} />
                    <input type="hidden" name="LMI_PAYMENT_NO" value={cash.id} />
                    <input type="hidden" name="LMI_PAYEE_PURSE" value={data.wallet} />
                    <input type="hidden" name="LMI_RESULT_URL"  value={data.result_url} />
                    <input type="hidden" name="LMI_SUCCESS_URL" value={data.success_url} />
                    <input type="hidden" name="LMI_FAIL_URL" value={data.failed_url} />
                    <input type="hidden" name="LMI_SUCCESS_METHOD" value="1" />
                    <input type="hidden" name="LMI_FAIL_METHOD" value="1" />
                    <input type="hidden" name="is_api" value="web" />
                    <br />
                        <button className="btn btn-primary  btn-lg btn-block" type="submit">
                            <i  className="fe-arrow-down-right"></i>
                            انتقال به درگاه
                        </button>
                </form>
            </React.Fragment>
        )
    }
}
const mapStateToProps = (state) => {
        return {
            user: state.user.info,
        }
    }

export default connect(mapStateToProps)(PayWebmoney);
