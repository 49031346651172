import React from 'react';
import Form from "../../../ubold/commons/form";
import http from "../../../services/httpService";
import Joi from "joi-browser";
import $ from "jquery";
import {convertArrayToDate, convertDateToArray} from '../../../services/helpers';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker from 'react-modern-calendar-datepicker';
import "./../../../../node_modules/persianjs/persian"
import FormLoader from "../../../ubold/commons/FormLoader";
import jwtDecode from "jwt-decode";
import {updateUserInStore} from "../userService";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import ReactTooltip from "react-tooltip";

import Cropper from "../vip/Cropper";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import Birthday from "../../auth/components/Birthday";
import UserEditLoading from "../../../components/loadings/UserEditLoading";

const defaultImage = '/assets/images/avatar.png';

class EditForm extends Form {
    constructor(props) {
        super(props);

        this.state = {
            loadingForm: true,
            data: {},
            userData: {},
            info: {},
            provinces: [],
            verifications: [],
            user: this.props.user,
            cities: [],
            errors: {},
            b: null,
            loading: false,
            selectFace: false,
            faces: {},
            image: defaultImage,
            applyAvatar: defaultImage,
            hasApplyAvatar: false,
            modal: false,

        };

        this.schema = {
            mobile: Joi.number()
                .required()
                .label("موبایل "),
            email: Joi.any().label("ایمیل "),
            last_name: Joi.string()
                .required()
                .label("نام "),
            first_name: Joi.string()
                .required()
                .label("نام خانوادگی "),
            national_code: Joi.number()
                .required()
                .label("کد ملی "),
            phone: Joi.string()
                .required()
                .max(11)
                .min(11)
                .label("تلفن ثابت "),
        };


    }

    verifyCheckLabel = (verify, d) => {
        return (
            <>
                <span style={{
                    display: 'block',
                    transform: 'scale(1.5)',
                    transformStyle: 'preserve-3d',
                    marginTop: '6px'

                }}
                      data-tip={(!this.state.userData[d] ? "ثبت نشده است" : (this.state.verifications[verify] ? "تایید شده" : "تایید نشده")
                      )} className={this.state.verifications[verify] ? "text-success" : "text-danger"}><i
                    className={this.state.verifications[verify] ? "fe-check" : "fe-x"}></i></span>
                <ReactTooltip/>
            </>
        )
    }

    componentDidMount() {
        http.post('/user/get-info?with_province=1').then(res => {
            const data = jwtDecode(res.data);

            const d = {
                national_code: data.user.national_code,
                first_name: data.user.first_name,
                last_name: data.user.last_name,
                email: data.user.email,
                mobile: data.user.mobile,
                phone: data.user.phone,
                birthday: data.user.birthday,
                fk_province_id: data.user.fk_province_id,
                fk_city_id: data.user.fk_city_id,
                face: data.user.avatar,
                avatar_type: data.user.avatar_type,
            };

            this.setState({
                user: data.user,
                data: d,
                faces: data.faces,
                userData: d,
                provinces: data.provinces,
                verifications: data.verifies,
                info: data.info,
                loadingForm: false,
                applyAvatar: data.user.avatar,
                selectFace: data.user.avatar,
                shahkar: data.user.shahkar,
                birthday: data.birthday

            });


            if (data.verifies.PHONE) {
                delete this.state.data.phone;
                delete this.schema.phone;
            }

            if(!data.user.first_name  ||  !data.user.last_name){
                delete this.schema.first_name;
                delete this.schema.last_name;
            }

            this.initJquery();
        });
    }

    initJquery() {
        var myThis = this;

        $("select[name=fk_province_id]").change(function () {
            if ($(this).val()) {
                myThis.updateCities($(this).val());
            }
        }).trigger('change');
    }

    updateCities = (val) => {

        http.post('/user/cities', {pid: val}).then(res => {
            this.setState({
                cities: res.data.cities,
            });

            $("select[name=fk_city_id]").change();
        });
    }

    doSubmit = async () => {
        try {
            var data = this.state.data;
            const FData = new FormData();

            Object.keys(data).forEach(item => {
                FData.append(item, data[item]);
            });

            if (this.state.hasApplyAvatar) {
                FData.append('pavatar', this.state.hasApplyAvatar);
            }

            http.post('/user/update/profile', FData).then(res => {
                toast.success(res.data.message);
                this.toggle();
                updateUserInStore(this.props.dispatch, res.data.user);

            }).catch(res => {
                this.toggle();
            });

        } catch (ex) {
            this.toggle();
        }
    };


    handleSelectFace = (face) => {
        let data = this.state.data;
        data['face'] = face.key;

        this.setState({
            data,
            selectFace: face
        })
    }

    handleChangeAvatarType = (type) => {
        let data = this.state.data;
        data['avatar_type'] = type;

        if (type === 'face') {
            this.setState({applyAvatar: defaultImage, image: defaultImage, hasApplyAvatar: false, data});
        } else {
            this.setState({
                data
            });
        }
    }


    handleSelectFile = (input) => {
        const file = input.target.files[0];
        const {type} = file;

        if (type.endsWith('jpeg') || type.endsWith('jpg') || type.endsWith('png')) {
            this.setState({image: file});
        } else
            toast.error('نوع فایل عکس باید jpeg,jpg,png باشد.')
    }

    onApplyImage = (image, blob) => {
        this.setState({applyAvatar: image, hasApplyAvatar: blob});
        this.toggleModal();
    }

    toggleModal = () => {
        this.setState({modal: !this.state.modal});
    }

    handleChangeBirthday = (value) => {
        var {data} = this.state;
        data['birthday'] = convertArrayToDate(value);

        const errors = this.state.errors;
        delete errors['birthday'];

        this.setState({data, errors});

    }

    render() {

        const birthday = convertDateToArray(this.state.data.birthday);

        return (
            this.state.loadingForm ?
                <UserEditLoading/>
                :

                <div className=" user-edit-form ">
                    <Modal isOpen={this.state.modal} toggle={this.toggleModal} className='credit '>
                        <ModalHeader toggle={this.toggleModal}> آپلود تصویر</ModalHeader>
                        <ModalBody className='d-block'>
                            <div className='m-auto' style={{width: '200px'}}>

                                <button className='btn btn-block btn-default' type='button'
                                        onClick={() => $("#avatar-input").click()}>
                                    <i className='fe-plus'></i> انتخاب تصویر پروفایل
                                </button>

                                <input type='file' id={'avatar-input'} style={{display: "none"}}
                                       onChange={this.handleSelectFile}/>
                                <Cropper onApplyImage={this.onApplyImage} image={this.state.image}/>
                            </div>
                        </ModalBody>

                    </Modal>


                    <form method="POST" onSubmit={this.handleSubmit}>

                        <div className="row justify-content-between mt-3">
                            <div className="col-xl-7 col-lg-8 ">
                               <div className="card card-body">
                                   <div className="row">
                                       {
                                           (this.state.user.first_name && this.state.user.last_name) ?
                                               <div className="col-12">
                                                   <div className="row">
                                                       <div className="col-md-6">
                                                           {
                                                               this.renderInput('first_name', 'نام ', 'text', '', '', '', false, '', '', null, {
                                                                   'disabled': this.state.user.credits_confirms>0 ,
                                                               })
                                                           }
                                                       </div>
                                                       <div className="col-md-6">
                                                           {
                                                               this.renderInput('last_name', 'نام خانوادگی', 'text', '', '', '', false, '', '', null, {
                                                                   'disabled': this.state.user.credits_confirms>0 ,
                                                               })
                                                           }
                                                       </div>
                                                   </div>
                                               </div>
                                               : ""
                                       }
                                       <div className="col-md-12">
                                           {
                                               this.renderInput('national_code', 'کد ملی', 'text', 'ltr', '', '', false, '', '', null, {
                                                   'disabled': this.state.userData.national_code && this.state.verifications.NATIONAL_ID,
                                                   'symbol': this.verifyCheckLabel('NATIONAL_ID', 'national_code')
                                               })
                                           }
                                       </div>
                                       <div className="col-md-12">
                                           {
                                               this.renderInput('mobile', 'موبایل ', 'number', 'ltr', '', '', false, '', '', null, {
                                                   'disabled': this.state.userData.mobile && this.state.verifications.MOBILE,
                                                   'symbol': this.verifyCheckLabel('MOBILE', 'mobile')
                                               })
                                           }
                                       </div>
                                       <div className="col-md-12">
                                           {
                                               this.renderInput('email', 'ایمیل ', 'text', 'ltr', '', '', false, '', '', null, {
                                                   'disabled': this.state.userData.email && this.state.verifications.EMAIL,
                                                   'symbol': this.verifyCheckLabel('EMAIL', 'email')
                                               })
                                           }
                                       </div>
                                       {
                                           this.state.shahkar !== 'confirm' ?
                                               <div className="col-md-12">
                                                   {
                                                       this.renderInput('phone', 'تلفن ثابت ', 'number', 'ltr', '', '', false, '', '', null, {
                                                           'disabled': this.state.verifications.PHONE,
                                                           'symbol': this.verifyCheckLabel('PHONE','phone')
                                                       })
                                                   }
                                               </div>
                                               : ""
                                       }

                                       <div className="col-md-12">
                                           <div className="form-group rtl-label">
                                               <label>تاریخ تولد</label>

                                               <Birthday disabled={this.state.user.credits_confirms>0 && !this.state.birthday} callback={(date) => this.handleChangeBirthday(date)}
                                                         day={this.state.birthday.day} year={this.state.birthday.year}
                                                         month={this.state.birthday.month}/>


                                               {this.state.errors.birthday &&
                                               <small className="font-11 error-label">{this.state.errors.birthday}</small>}

                                           </div>

                                       </div>
                                   </div>
                               </div>
                            </div>
                            <div className="col-xl-4 col-lg-4">
                                <div className="form-group">
                                    <h5>تنظیم عکس پروفایل</h5>
                                    <br/>
                                    <br/>
                                    <div>
                                        <label><input onClick={() => this.handleChangeAvatarType('face')}
                                                      checked={this.state.data.avatar_type === 'face'} type='radio'
                                                      name={'avatar_type'} value={'face'}/>
                                            <span className="mr-1">عکس پیش فرض</span>
                                        </label>
                                        <label className='mr-3'><input
                                            onClick={() => this.handleChangeAvatarType('file')}
                                            checked={this.state.data.avatar_type === 'file'} type='radio'
                                            name={'avatar_type'} value={'file'}/>
                                            <span className="mr-1">آپلود عکس</span>
                                        </label>
                                    </div>

                                    {
                                        this.state.data.avatar_type === 'face' ?
                                            <div className="form-control faces ">
                                                <div className="row">
                                                    {this.state.faces.map((face, index) => (
                                                        <div key={index} className="col-3">
                                                            <img alt={index} onClick={() => this.handleSelectFace(face)}
                                                                 src={face.link} height={50} width={50}
                                                                 className={'mb-2 ' + (this.state.selectFace === face ? 'active' : '')}/>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            :
                                            <div className="form-control faces copper-box text-center">
                                                <img className={'avatar-file'} src={this.state.applyAvatar}/>
                                                <button style={{borderRadius: '50px !important'}} type='button'
                                                        onClick={this.toggleModal} className='btn btn-default'>انتخاب
                                                    تصویر
                                                </button>
                                            </div>
                                    }

                                </div>

                                <div className="form-group mt-5">
                                    {this.renderButton(<span> به روز رسانی</span>, 'btn-lg btn-block')}
                                </div>
                            </div>



                        </div>
                    </form>

                </div>


        )
    }

}


export default connect()(EditForm);
