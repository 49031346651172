import React from 'react';
import {connect} from "react-redux";
import {updatePageTitle} from "../../actions/breadcrumbs";
import Form from "../../ubold/commons/form";
import Layout from "./Layout";
import ChangePassForm from "./forms/ChangePassForm";

class UserChangePass extends Form {
    constructor(props) {
        super(props);
        this.state = {
            loadingForm: true,
            data: {},
        };

        this.props.dispatch(updatePageTitle(
            'تغییر رمز عبور',
            [
                {
                    'title': 'تغییر رمز عبور',
                    'active': true
                }
            ]
        ));
    }

    render() {

        return (
            <Layout>
                <div className="row mt-5 justify-content-center">

                    <div className="col-lg-5 col-md-8" >
                        <div className="card ">

                            <div className="card-body">
                                {
                                    <ChangePassForm data={this.state.data}/>
                                }
                            </div>

                        </div>
                    </div>
                </div>

            </Layout>
        )
    }

}


const mapStateToProps = appState => {
    return {
        user: appState.user.info
    }

};
export default connect(mapStateToProps)(UserChangePass);
