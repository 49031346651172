import React from 'react';
import {Link} from "react-router-dom";
import $ from "jquery";
import routes from "../../routes";
import feather from "feather-icons";
import {Dropdown} from "react-bootstrap";
import Notifications from "./layouts/parts/Notifications";
import ReactTooltip from "react-tooltip";
import {spilitNumber} from "../../services/helpers";

const linkStyle = {
    paddingTop: "4px",
    padding:'6px 7px !important',
    marginTop: '0px !important',
    display: 'block'
};

class UserTopBox extends React.Component {

    constructor(props) {
        super(props);

        const {user}= this.props;

        this.state={
            subPrefix : 'user-topbox-sub-',
            menu : [
                {
                    'id':1,
                    'title': 'سفارشات',
                    'route':routes.orders,
                    'icon':'fe-shopping-cart'
                },
                {
                    'id':2,
                    'title' : 'تیکت ها',
                    'route' : routes.ticket,
                    'icon' :'fe-message-square',
                    'content' :  user.alarms && user.alarms.tickets?<span className='badge badge-danger'>{user.alarms.tickets}</span>:''
                },
                {
                    'id':3,
                    'title':'مالی',
                    'route' : '#',
                    'icon' : 'fe-dollar-sign',
                    'content' : (user.alarms && user.alarms.transactions?<span className='badge badge-danger'>{user.alarms.transactions}</span>:''),
                    'childs' : [
                        {
                            'title': 'افزایش موجودی',
                            'route':routes.finance_add_fund,
                        },
                        {
                            'title': 'درخواست برداشت وجه ',
                            'route':routes.finance_withdraw,
                        },
                        {
                            'title': ' صورت حساب ها',
                            'route':routes.finance,
                        },
                        {
                            'title': 'تراکنش ها ',
                            'route':routes.finance_transactions,
                            'content' : (user.alarms && user.alarms.transactions?<div className='text-danger d-inline-block mr-2'> ({user.alarms.transactions})</div>:'')
                        }
                    ]
                },
                {
                    'id':4,
                    'title':'حساب کاربری ',
                    'route' : '#',
                    'icon' : 'fe-user',
                    'childs' : [
                        {
                            'title': 'ویرایش حساب کاربری',
                            'route':routes.profile_edit,
                        },
                        {
                            'title': 'تایید مدارک (احراز هویت) ',
                            'route':routes.profile_verify,
                        },
                        {
                            'title': 'امنیت حساب کاربری',
                            'route':routes.profile_security,
                        },
                        {
                            'title': 'افزودن کارت بانکی',
                            'route':routes.profile_bank_credits,
                        },
                        {
                            'title': 'تعییر رمز عبور',
                            'route':routes.profile_change_password,
                        }
                    ]
                },
                {
                    'id':5,
                    'title':'',
                    'route' : '#',
                    'icon' : 'fe-more-horizontal',
                    'childs' : [
                        {
                            'title': 'داشبورد پنل کاربری',
                            'route':'/',
                        },
                        {
                            'title': 'نظرات و پیشنهادات',
                            'route':routes.comments,
                        },
                        {
                            'title': 'همکاری در فروش',
                            'route':routes.referrals,
                        },
                        {
                            'title': ' پاداش ها',
                            'route':routes.rewards,
                        }
                    ]
                }
            ]
        }
    }

    closeSubmenu= () => {
        $('.has-submenu').removeClass('show');
        $('.overlay-menu-bottom').removeClass('show');
    }

    toggleSubMenu = (submenu,link,close=false) => {
        if(close){
            $('.has-submenu').removeClass('show');
            $('.overlay-menu-bottom').removeClass('show');
        }

        if($('#'+submenu).hasClass('show')){
            $('#'+submenu).removeClass('show');
            $('.overlay-menu-bottom').removeClass('show');

        }
        else{
            $('#'+submenu).addClass('show');
            if(link.childs){
                $('.overlay-menu-bottom').addClass('show');
            }
        }
    }

    render(){
        const {user,notifications} = this.props;
        const balance = user.balance;

        return (
            <>
               <div className='user-top-box d-flex  align-items-center justify-content-between'>
                        <div className='d-flex align-items-center'>
                            <img className='avatar ml-2' alt={user.name} src={user.avatar} height={50} width={50} />
                            <div>
                                <h4 className='name'>
                                    <span className={'user-level ' + user.level_class} data-tip={user.level_label}
                                         dangerouslySetInnerHTML={{__html: feather.icons.award.toSvg()}}/>
                                    {user.name}
                                </h4>
                                <div className='email'>
                                    {/*{user.email}*/}
                                    موجودی :
                                    &nbsp;
                                    {(typeof balance === 'number') ? <span style={{margin: '0 3px'}} className={balance < 0 ? "text-danger" : "text-success"}>{spilitNumber(balance)}   <small>تومان</small></span>  : " 0 "}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='events d-flex '>



                                <Dropdown>
                                    <Dropdown.Toggle className={'logout pulse pulse--danger '} variant="success" id="dropdown-basic">
                                        <i dangerouslySetInnerHTML={{__html: feather.icons['bell'].toSvg()}}/>

                                        {notifications.count > 0 ?
                                            <React.Fragment>
                                                    <span
                                                        className="badge badge-danger rounded-circle noti-icon-badge">{notifications.count}</span>
                                                <span className="pulse__ring"> </span>
                                            </React.Fragment>
                                            : ''}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Notifications notifications={notifications}/>
                                    </Dropdown.Menu>
                                </Dropdown>


                            </div>

                        </div>
               </div>
            </>

        )
    }
}

export default UserTopBox ;
