import React from 'react';
import moment from "moment-jalaali";
import {Link} from "react-router-dom";
import routes from "../../../../routes";
import {getNotify} from "../../../../modules/notifications/NotificationsService";
import {updateUserStorage} from "../../../../modules/auth/authService";
import {updateUser} from "../../../../actions/user";
import jwtDecode from "jwt-decode";
import {connect} from "react-redux";
import {setAppRedirect} from "../../../../actions/app";
import {convertDataToArray} from "../../../../services/helpers";
const feather = require('feather-icons');

export const mapToRoute = (type,id=null) => {
    if(type=== "Modules\\User\\Notifications\\VerificationRejected" ||
        type==="Modules\\User\\Notifications\\VerificationAccepted" ||
        type==="Modules\\User\\Notifications\\UserRequestToVerifications"){
        return routes.profile_verify;
    }

    if(type===  "Modules\\Tickets\\Notifications\\AdminTicketCreated" ||
        type=== "Modules\\Tickets\\Notifications\\TicketReplied" ||
        type=== "Modules\\Tickets\\Notifications\\TicketAssigned" ||
        type=== "Modules\\Tickets\\Notifications\\TicketCreated"){
        return routes.ticket_show+id;
    }

    if(type===  "Modules\\Orders\\Notifications\\TransactionNotification" ||
        type=== "Modules\\Orders\\Notifications\\WithdrawNotification" ){
        return routes.finance;
    }

    if(type=== "Modules\\Payments\\Notifications\\OrderNotification" ){
        return routes.orders;
    }

    if( type=== "Modules\\Payments\\Notifications\\UserAddTransaction" ){
        return routes.finance_transactions;
    }

    if(type=== "Modules\\BankCards\\Notifications\\BankCardNotification" ){
        return routes.profile_bank_credits;
    }
}


class Notifications extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            redirect:''
        }
    }



    handleEvent= (event)=>{
        if(!event.data.content){
            getNotify(event.id,'event').then((res)=>{
                this.props.dispatch(setAppRedirect(mapToRoute(event.type,event.notifiable_id)));

                if(res.user){
                    updateUserStorage(res.user);
                    let user = jwtDecode(res.user);
                    this.props.dispatch(updateUser(user))
                }
            });
        }else{
            this.props.dispatch(setAppRedirect("/notify/event/"+event.id));
        }
    }

    handleNotification= (url)=>{
        this.props.dispatch(setAppRedirect(url));
    }

    render() {
        const notifications=this.props.notifications;
        return (
            <React.Fragment>
                {
                    notifications.count > 0 ?
                        <div className="dropdown-events ">
                        <div className="head">

                                    <ul className="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-success kt-notification-item-padding-x"
                                        role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link " data-toggle="tab"
                                               href="#topbar_notifications_notifications" role="tab"
                                               aria-selected="true"> اطلاعیه ها
                                                {notifications.notifications_count > 0 ?
                                                    <span

                                                        className="badge  badge-danger">{notifications.notifications_count}</span>
                                                    : '(0)'}


                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link active show" data-toggle="tab"
                                               href="#topbar_notifications_events" role="tab"
                                               aria-selected="false">پیام ها
                                                {notifications.events_count > 0 ?
                                                    <span

                                                        className="badge  badge-danger">{notifications.events_count}</span>
                                                    : '(0)'}

                                            </a>
                                        </li>

                                        {notifications.documents_count > 0 ?
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab"
                                                   href="#topbar_notifications_documents" role="tab"
                                                   aria-selected="false">
                                                    تایید مدارک
                                                    {notifications.documents_count > 0 ?
                                                        <span

                                                            className="badge  badge-danger">{notifications.documents_count}</span>
                                                        : '(0)'}
                                                </a>
                                            </li>
                                            : ''}


                                    </ul>
                                </div>
                                <div className="tab-content">
                                    <div className="tab-pane " id="topbar_notifications_notifications" role="tabpanel">
                                        <div className="notification pb-4  ">
                                            {notifications && notifications.notifications_count > 0 ? convertDataToArray(notifications.notifications).map((noti) => (
                                                    <Link key={noti.id} to={"/notify/notification/"+noti.id}
                                                       className="notification__item d-flex v3-flex-center">
                                                        <div className="notification__item-details">
                                                            <div className="notification__item-title">
                                                                {noti.title}
                                                            </div>
                                                            <div className="notification__item-time">
                                                                {moment(noti.created_at).locale('fa').format('jYYYY/jMM/jDD - HH:mm')}
                                                            </div>
                                                        </div>
                                                    </Link>
                                                )) :
                                                <div className="text-center p-3 pt-4">
                                                    هیچ اطلاعیه ای ثبت نشده است
                                                </div>
                                            }

                                            {
                                                notifications && notifications.notifications_count > 0 ?

                                                <Link to={routes.notifications+"?type=notification"}
                                                  className="notification__item d-flex v3-flex-center">
                                                    <div className="notification__item-details">
                                                        <div className="notification__item-title text-t">
                                                            مشاهده همه
                                                        </div>

                                                    </div>
                                                </Link>
                                                :''
                                            }


                                        </div>
                                    </div>
                                    <div className="tab-pane active show" id="topbar_notifications_events"
                                         role="tabpanel">
                                        <div className="notification pb-4  ">
                                            {notifications && notifications.events_count > 0 ? notifications.events.map((event) => (
                                                    <Link key={Math.round(10)} onClick={()=>this.handleEvent(event)}
                                                       className="notification__item d-flex v3-flex-center">
                                                        <div className="notification__item-details">
                                                            <div className="notification__item-title">
                                                                {event.data.msg}
                                                            </div>
                                                            <div className="notification__item-time">
                                                                {moment(event.data.date).locale('fa').format('jYYYY/jMM/jDD - HH:mm')}
                                                            </div>
                                                        </div>
                                                    </Link>
                                                )) :
                                                <div className="text-center p-3 pt-4">
                                                    هیچ پیامی ثبت نشده است
                                                </div>
                                            }
                                            {
                                                notifications && notifications.events_count > 0 ?
                                                    <Link to={routes.notifications+"?type=event"}
                                                          className="notification__item d-flex v3-flex-center">
                                                        <div className="notification__item-details">
                                                            <div className="notification__item-title text-t">
                                                                مشاهده همه
                                                            </div>

                                                        </div>
                                                    </Link>
                                                    :''
                                            }

                                        </div>
                                    </div>

                                    {
                                        notifications.documents_count>0?
                                            <div className="tab-pane" id="topbar_notifications_documents" role="tabpanel">
                                                <div className="notification pb-4  ">
                                                    {notifications && notifications.documents_count > 0 ? notifications.documents.map((doc) => (
                                                            <Link to={routes.profile_verify}
                                                               className="notification__item d-flex v3-flex-center">
                                                                <div className="notification__item-details">
                                                                    <div className="notification__item-title">
                                                                        {doc.name}
                                                                    </div>
                                                                    <div className="notification__item-time">
                                                                        {doc.label}
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        )) :
                                                        ''
                                                    }

                                                </div>
                                            </div>
                                            :''
                                    }


                                </div>
                        </div>
                        : ''
                }
            </React.Fragment>
        )
    }
}



export default connect()(Notifications);
