import React from 'react';
import {connect} from "react-redux";
import {updatePageTitle} from "../../actions/breadcrumbs";
import Form from "../../ubold/commons/form";
import http from "../../services/httpService";
import Layout from "./Layout";
import FormLoader from "../../ubold/commons/FormLoader";
import PopUpVerify from "./forms/PopUpVerify";
import jwtDecode from "jwt-decode";
import $ from "jquery";
import Step2 from "./forms/verify_forms/Step2";
import Step3 from "./forms/verify_forms/Step3";
import Step4 from "./forms/verify_forms/Step4";
import Step5 from "./forms/verify_forms/Step5";
import {user} from "../../reducers/user";
import Alert from "../partials/Alert";
import {Link} from "react-router-dom";
import routes from "../../routes";
import BankCardFormField from "./forms/BankCardFormField";
import UserVerifyLoading from "../../components/loadings/UserVerifyLoading";
import CompleteInformations from "./completeInformations";


class UserVerify extends Form {
    constructor(props) {
        super(props);
        this.state = {
            loadingForm: true,
            user: true,
            credits: [],
            verifications: {},
            provinces: {},
            verify_data: [],
            loadingReload: true
        };

        this.props.dispatch(updatePageTitle(
            'ارسال مدارک (احراز هویت)',
            [
                {
                    'title': 'احراز هویت ',
                    'active': true
                }
            ]
        ));
    }

    getUser = (loading = false) => {
        if (loading)
            this.setState({loadingForm: true});

        http.post('/user/get-info?withCreditInfo=1').then(res => {
            const data = jwtDecode(res.data);

            var verifications = data.verifications;

            var state = {
                verifications: verifications,
                verify_data: data.verify_data,
                user: data.user,
                info: data.info,
                credits: data.credits,
                provinces: data.provinces,
                loadingForm: false,
                loadingReload: false
            };

            verifications.map((v, index) => {
                if (v['key'] === 'MOBILE')
                    delete verifications[index];

                if (v['key'] === 'PHONE' && v['status'] === "CONFIRMED") {
                    delete verifications[index];
                }

                // state['show'+v.key]=false;

            });

            this.setState(state);

        });
    }

    componentDidMount() {
        this.getUser();
    }

    reloadUser = (loading = true) => {
        var state = this.state;
        if (loading === true) {
            this.state.verifications.map((v, index) => {
                state['show' + v.key] = false;
            });
            this.setState(state);
        } else
            this.setState({loadingReload: true})


        this.getUser(loading);
    }

    handleSubmitSaveCard = () => {
        this.reloadUser();
    }

    handleShowPopUP = (key, bol) => {
        var data = this.state;
        data['show' + key] = bol;
        this.setState(data);

        if (bol)
            $("body").addClass('no-overflow');
        else
            $("body").removeClass('no-overflow');

    }


    render() {
        return (

            <React.Fragment>
                <Layout>
                    {
                        this.state.loadingForm ?
                            <UserVerifyLoading/>
                            :
                            <div className="verifications-card mt-4">
                                <div className="row justify-content-center pt-3 ver-list">
                                    <div className="col-xl-6 col-lg-7 col-md-10">
                                        <div className="card card-body">
                                            <div className='mt-3'>
                                                {
                                                    this.props.user.completeProfile==='no'?
                                                        <CompleteInformations user={this.props.user}/>

                                                        : (
                                                            this.state.credits.bank_credits_verify<=0 && !this.props.user.legal?
                                                                <div className="">

                                                                    <h5 className={'dash-card-title mt-2 mb-5'}><svg className="icon" dangerouslySetInnerHTML={{__html: '<use xlink:href="/assets/images/sprite.svg#EMAIL"></use>'}}/>ثبت کارت بانکی</h5>

                                                                    <Alert type='info'>
                                                                        لطفا قبل از شروع مراحل احراز هویت ابتدا یک کارت بانکی که به نام ( <strong className='text-danger'>{this.props.user.name}</strong> ) است و قصد پرداخت با آن  دارید را ثبت کنید
                                                                    </Alert>

                                                                    {
                                                                        this.state.credits.all_credits_verify.length>0?
                                                                            <div className="">
                                                                                {this.state.credits.all_credits_verify.map(credit=>(
                                                                                    <div key={credit.id} data-value={credit.card_number} className="card-body card-credit">
                                                                                        <div className="d-flex align-item-center justify-content-between">
                                                                                            <div  className="">
                                                                                                وضعیت : &nbsp;
                                                                                                <span      className={"badge badge-"+credit.status_text.class}>
                                                    {credit.status_text.label}
                                                        </span>
                                                                                            </div>

                                                                                            <div className="ltr">
                                                                                                <div className="number p-0">
                                                                                                    {credit.bank_icon? <img src={credit.bank_icon} height="40" width="40"/>:''}
                                                                                                    {credit.card_number}
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>
                                                                                        {credit.status==='REJECTED'?
                                                                                            <>
                                                                                                {credit.reject_message?
                                                                                                    <div className="text-warning">
                                                                                                        <small>علت عدم تایید : {credit.reject_message}</small>
                                                                                                    </div>
                                                                                                    :''}

                                                                                                <Link className="btn btn-warning btn-xs mt-3"
                                                                                                      to={routes.profile_bank_credits}>
                                                                                                    <i   className="flaticon2-edit"></i>
                                                                                                    برای ویرایش کارت بانکی کلیک کنید
                                                                                                </Link>
                                                                                            </>
                                                                                            :''}

                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                            :
                                                                            <BankCardFormField onAction={()=>this.handleSubmitSaveCard()}/>
                                                                    }


                                                                </div>
                                                                :
                                                                <div>
                                                                    <div>
                                                                        <h5 className="text-center ">احراز هویت</h5>

                                                                        <div className='mb-3 mt-3 m-auto  ' style={{width: 'fit-content'}}>
                                                                            <svg width="64" height="75" className='mb-3 mt-3' viewBox="0 0 64 75"
                                                                                 fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <g opacity="0.7">
                                                                                    <path
                                                                                        d="M22.8537 29.1627C30.9068 29.1627 37.435 22.6344 37.435 14.5814C37.435 6.52838 30.9068 0.00012207 22.8537 0.00012207C14.8007 0.00012207 8.27246 6.52838 8.27246 14.5814C8.27246 22.6344 14.8007 29.1627 22.8537 29.1627Z"
                                                                                        fill="#DBDCE5"/>
                                                                                    <circle cx="44.9875" cy="55.056" r="19.0124" fill="#A5A8BE"/>
                                                                                    <path
                                                                                        d="M25.055 25.0409C17.0035 25.0409 10.4737 18.5111 10.4737 10.4596C10.4737 8.80502 10.7396 7.23905 11.2419 5.76172C9.38046 8.21409 8.27246 11.2722 8.27246 14.5814C8.27246 22.6329 14.8023 29.1627 22.8537 29.1627C29.2654 29.1627 34.7167 25.0261 36.6668 19.2645C34.0076 22.7806 29.7972 25.0409 25.055 25.0409Z"
                                                                                        fill="#A7ABBF"/>
                                                                                    <path
                                                                                        d="M6.26346 32.398C8.3465 30.8763 10.9171 30.0638 13.4876 30.0195C16.1764 30.0195 18.8503 30.0342 21.5391 30.0195C21.6129 30.0195 21.7607 30.0195 21.8345 30.0195L21.6425 30.0786C21.0516 30.2411 20.4311 30.3149 19.8401 30.4774C16.915 31.2457 14.3001 33.1219 12.616 35.6481C11.2125 37.7164 10.4591 40.2131 10.4739 42.7098C10.4739 51.7658 10.4739 60.8366 10.4739 69.8927C10.4739 70.7791 10.4 71.7246 10.858 72.5223C11.3012 73.394 12.1876 73.9406 13.1183 74.177H3.67812C2.1417 73.9258 0.930285 72.478 0.989378 70.9268C0.989378 61.4423 0.989378 51.9431 0.989378 42.4586C1.0337 38.5141 3.04287 34.6731 6.26346 32.398Z"
                                                                                        fill="#A7ABBF"/>
                                                                                    <path
                                                                                        d="M48.811 50.4749C50.5986 51.5829 52.3271 52.7795 54.0703 53.9466C51.9873 57.0933 49.8747 60.24 47.7769 63.3867C46.9643 64.672 45.162 65.1891 43.8028 64.5095C41.6755 63.4458 39.5481 62.4117 37.4355 61.3037C38.381 59.4423 39.297 57.566 40.2425 55.6898C41.513 56.2955 42.7539 56.9456 44.0097 57.5661L44.0835 57.5365C45.6791 55.2023 47.245 52.8386 48.811 50.4749Z"
                                                                                        fill="#4A517D"/>
                                                                                    <path
                                                                                        d="M48.811 47.2156C50.5986 48.3236 52.3271 49.5203 54.0703 50.6874C51.9873 53.8341 49.8747 56.9808 47.7769 60.1275C46.9643 61.4128 45.162 61.9299 43.8028 61.2503C41.6755 60.1866 39.5481 59.1525 37.4355 58.0445C38.381 56.183 39.297 54.3068 40.2425 52.4306C41.513 53.0363 42.7539 53.6864 44.0097 54.3068L44.0835 54.2773C45.6791 51.9431 47.245 49.5794 48.811 47.2156Z"
                                                                                        fill="white"/>
                                                                                    <path
                                                                                        d="M19.8547 30.4627C20.4456 30.3002 21.0661 30.2264 21.657 30.0638C25.439 30.0343 29.2062 30.0343 32.9882 30.0491C37.2872 30.1525 41.3942 32.6787 43.5068 36.4164C40.5669 36.6823 37.6713 37.6278 35.1599 39.1938C31.5109 41.4541 28.6744 44.9997 27.2709 49.0623C25.7936 53.2875 25.897 58.0298 27.5368 62.1811C29.6494 67.6472 34.4655 71.961 40.1237 73.4974C40.9362 73.7633 41.7931 73.7929 42.5908 74.1031L42.3249 74.1622H13.1181C12.1873 73.9259 11.3009 73.3792 10.8577 72.5076C10.3998 71.7099 10.4736 70.7644 10.4736 69.878C10.4736 60.8219 10.4736 51.7511 10.4736 42.695C10.4589 40.1983 11.2123 37.7017 12.6158 35.6334C14.3147 33.1219 16.9148 31.2309 19.8547 30.4627Z"
                                                                                        fill="#C6C8D5"/>
                                                                                </g>
                                                                            </svg>
                                                                        </div>
                                                                        با هدف جلوگیـری از خرید با کارت های سرقتـــی و یا هر گونه سواستفـاده
                                                                        احتمالی، ارسال مدارک این بخش الزامیست.این مدارک صرفا جهت احــراز هویت در وب
                                                                        سایت کافه ارز میباشد و نزد این وب سایت محفوظ است.
                                                                        <br/>
                                                                        <br/>

                                                                    </div>

                                                                    {
                                                                        this.state.verifications.map(verification => {
                                                                            return (
                                                                                (verification.key !== 'EMAIL' || (verification.key === 'EMAIL' && (this.state.user.level === 'B' || this.state.user.level === 'A'))) ?
                                                                                    <div key={verification.index}
                                                                                         onClick={() => this.handleShowPopUP(verification.key, true)}
                                                                                         className={'link d-flex justify-content-between align-items-center ' + verification.key + ' ' + (verification.status === 'CONFIRMED' ? "CONFIRMED " : "")}>

                                                                                        <div className='d-flex align-items-center'>
                                                                                            <div className="icon">
                                                                                                <svg
                                                                                                    dangerouslySetInnerHTML={{__html: '<use xlink:href="/assets/images/sprite-ver.svg#' + verification.key + '"></use>'}}/>
                                                                                            </div>

                                                                                            <div className="mr-2 title">
                                                                                                {(verification.key === 'EMAIL' || verification.key === 'PHONE') ? 'تایید ' : 'ارسال '}
                                                                                                {verification.label}
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="d-flex align-items-center ">
                                                                                            {
                                                                                                verification.status ?
                                                                                                    <div className='d-inline-block mr-2'>
                                                                            <span
                                                                                className={'badge badge-' + verification.status_class}>
                                                                                {verification.status === 'CONFIRMED' ?
                                                                                    <svg width="13" height="13"
                                                                                         viewBox="0 0 13 13" fill="none"
                                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                                        <path
                                                                                            d="M6.5 13C2.91015 13 0 10.0899 0 6.5C0 2.91015 2.91015 0 6.5 0C10.0899 0 13 2.91015 13 6.5C13 10.0899 10.0899 13 6.5 13ZM6.50004 11.8182C9.43719 11.8182 11.8182 9.43714 11.8182 6.49999C11.8182 3.56284 9.43719 1.1818 6.50004 1.1818C3.56289 1.1818 1.18185 3.56284 1.18185 6.49999C1.18185 9.43714 3.56289 11.8182 6.50004 11.8182ZM8.44599 4.30946L5.31837 7.43708L3.96348 6.08219L3.12781 6.91786L5.31837 9.10843L9.28166 5.14513L8.44599 4.30946Z"
                                                                                            fill="white"/>
                                                                                    </svg>
                                                                                    : ""}
                                                                                {verification.status === 'CANCELED' || verification.status === 'REJECTED' ?
                                                                                    <svg width="13" height="13"
                                                                                         viewBox="0 0 13 13" fill="none"
                                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                                        <path
                                                                                            d="M6.5 13C2.91015 13 0 10.0899 0 6.5C0 2.91015 2.91015 0 6.5 0C10.0899 0 13 2.91015 13 6.5C13 10.0899 10.0899 13 6.5 13ZM6.49998 11.8182C9.43713 11.8182 11.8182 9.43713 11.8182 6.49998C11.8182 3.56283 9.43713 1.1818 6.49998 1.1818C3.56283 1.1818 1.1818 3.56283 1.1818 6.49998C1.1818 9.43713 3.56283 11.8182 6.49998 11.8182ZM4.55426 9.28154L6.50006 7.33574L8.44587 9.28154L9.28154 8.44587L7.33574 6.50006L9.28154 4.55426L8.44587 3.71859L6.50006 5.66439L4.55426 3.71859L3.71859 4.55426L5.66439 6.50006L3.71859 8.44587L4.55426 9.28154Z"
                                                                                            fill="white"/>
                                                                                    </svg>
                                                                                    : ""}
                                                                                {verification.status === 'PENDING' ?
                                                                                    <svg width="13" height="13"
                                                                                         viewBox="0 0 13 13" fill="none"
                                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                                        <path
                                                                                            d="M6.5 13C2.91015 13 0 10.0899 0 6.5C0 2.91015 2.91015 0 6.5 0C10.0899 0 13 2.91015 13 6.5C13 10.0899 10.0899 13 6.5 13ZM6.4999 11.8182C9.43706 11.8182 11.8181 9.43712 11.8181 6.49997C11.8181 3.56282 9.43706 1.18179 6.4999 1.18179C3.56275 1.18179 1.18172 3.56282 1.18172 6.49997C1.18172 9.43712 3.56275 11.8182 6.4999 11.8182ZM9.45459 5.90911H7.09096V2.95457H5.90914V7.09093H9.45459V5.90911Z"
                                                                                            fill="white"/>
                                                                                    </svg>

                                                                                    : ""}

                                                                                {verification.status_text}
                                                                            </span>
                                                                                                    </div> : ""
                                                                                            }

                                                                                            <svg className='mr-2 arr' width="8" height="11"
                                                                                                 viewBox="0 0 8 11" fill="none"
                                                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                                                <path
                                                                                                    d="M7.51123 9.45043L5.96168 11L0.804095 5.8424L5.96168 0.684823L7.51123 2.23438L3.90321 5.8424L7.51123 9.45043Z"/>
                                                                                            </svg>

                                                                                        </div>

                                                                                    </div>
                                                                                    : ""
                                                                            )
                                                                        })
                                                                    }

                                                                </div>
                                                        )
                                                }


                                            </div>

                                            {
                                                this.state.verifications.map((verification, index) => {
                                                    if (verification.key === 'EMAIL') {
                                                        return (
                                                            <PopUpVerify key={index}
                                                                         show={this.state['show' + verification.key]}>
                                                                <Step2 loadingreload={this.state.loadingReload}
                                                                       closepopup={(key, bol) => this.handleShowPopUP(key, bol)}
                                                                       verification={verification}
                                                                       reloadUser={(load) => this.reloadUser(load)}
                                                                       user={this.state.user}/>
                                                            </PopUpVerify>
                                                        );
                                                    }
                                                    if (verification.key === 'NATIONAL_ID') {
                                                        return (
                                                            <PopUpVerify key={index}
                                                                         show={this.state['show' + verification.key]}>
                                                                <Step3 loadingreload={this.state.loadingReload}
                                                                       closepopup={(key, bol) => this.handleShowPopUP(key, bol)}
                                                                       verification={verification}
                                                                       reloadUser={(load) => this.reloadUser(load)}
                                                                       user={this.state.user}/>
                                                            </PopUpVerify>
                                                        );
                                                    }
                                                    if (verification.key === 'CREDIT') {
                                                        return (
                                                            <PopUpVerify key={index}
                                                                         show={this.state['show' + verification.key]}>
                                                                <Step4 loadingreload={this.state.loadingReload}
                                                                       closepopup={(key, bol) => this.handleShowPopUP(key, bol)}
                                                                       verification={verification}
                                                                       reloadUser={(load) => this.reloadUser(load)}
                                                                       user={this.state.user}/>
                                                            </PopUpVerify>
                                                        );
                                                    }
                                                    if (verification.key === 'PHONE') {
                                                        return (
                                                            <PopUpVerify key={index}
                                                                         show={this.state['show' + verification.key]}>
                                                                <Step5 loadingreload={this.state.loadingReload}
                                                                       closepopup={(key, bol) => this.handleShowPopUP(key, bol)}
                                                                       verification={verification}
                                                                       reloadUser={(load) => this.reloadUser(load)}
                                                                       user={this.state.user}/>
                                                            </PopUpVerify>
                                                        );
                                                    }


                                                })
                                            }

                                        </div>
                                    </div>
                                </div>


                            </div>
                    }
                </Layout>
            </React.Fragment>
        )
    }

}


const mapStateToProps = appState => {
    return {
        user: appState.user.info
    }

};
export default connect(mapStateToProps)(UserVerify);
