import React from 'react';
import {siteUrl} from '../../../config.json';
import {formatMoney,formatMoneyDollar} from "../../../services/helpers";
import {Link} from "react-router-dom";
import routes from "../../../routes";

class PricesItem extends React.Component {

    cellClick(coin){
        if(coin.symbol!=='USDT')
            window.open(siteUrl+'coin/'+(coin.symbol.toLowerCase()),'_blank');

        // window.location=url+'coin/'+coin.n_type.toLowerCase();
    }

    render() {
        const coin=this.props.coin;

        return (
            <React.Fragment>
                <div id={'_box'} className="item">
                    <div onClick={()=>this.cellClick(coin)} className="d-flex align-items-center dg-name">

                        <div className="lazy-box img-box">
                            <img style={{height:32,width:32}} height="32" width="32" src={coin.icon}/>
                        </div>
                        <span className="d-none d-md-block">
                                {coin.name? coin.name:coin.symbol}
                            <span>
                            </span>
                        </span>

                    </div>
                    <div onClick={()=>this.cellClick(coin)} className="dg-price" >
                        {formatMoneyDollar(coin.dollar_price)}
                    </div>
                    <div onClick={()=>this.cellClick(coin)} className=" dg-price">
                        <div>
                            <span > خرید :</span>
                            <span className="mr-1" >
                    {formatMoney(coin.buy_price)}
                </span>
                        </div>

                        <div>
                            <span>فروش :</span>
                            <span className="mr-1">{formatMoney(coin.sell_price)}</span>
                        </div>

                    </div>
                    <div className="  dg-price d-none d-md-block">
                        <Link to={routes.service_items+this.props.cat.id +'?select='+ coin.service_id+'&type=buy'}
                           className="btn  btn-buy"> خرید </Link>
                        <Link to={routes.service_items+this.props.cat.id +'?select='+ coin.service_id+'&type=sell'}
                           className="btn  btn-sell">
                            فروش
                        </Link>
                    </div>
                    <div onClick={()=>this.cellClick(coin)} className="d-none d-lg-block dg-100">
            <span         className={(coin.percent_change_24h >= 0 ? 'text-success' : 'text-danger') + ' d-flex align-items-center'}>
            <span>%</span>
            <span>{coin.percent_change_24h}</span>
            <span>{coin.percent_change_24h >= 0 ? '+' : '-'}</span>
    </span>
                    </div>
                    <div onClick={()=>this.cellClick(coin)} className="dg-100 d-none d-md-block">
                        {coin.chart?
                            <div  className="lazy-box">
                                <img  className="chart" src={coin.chart} width="134" height="45"/>
                            </div>
                            :""}

                    </div>
                </div>

            </React.Fragment>
        )
    }

}


export default PricesItem;
