import React from 'react';
import {Link} from "react-router-dom";
import {url} from "../../../config.json";
import $ from "jquery";
import routes from "../../../routes";
import {connect} from "react-redux";

class MenuButton extends React.Component {

    constructor(props) {
        super(props);


    }

    closeSubmenu = () => {
        $('.has-submenu').removeClass('show');
        $('.overlay-menu-bottom').removeClass('show');
    }

    toggleSubMenu = (submenu, link, close = false) => {
        if (close) {
            $('.has-submenu').removeClass('show');
            $('.overlay-menu-bottom').removeClass('show');
        }

        if ($('#' + submenu).hasClass('show')) {
            $('#' + submenu).removeClass('show');
            $('.overlay-menu-bottom').removeClass('show');

        } else {
            $('#' + submenu).addClass('show');
            if (link.childs) {
                $('.overlay-menu-bottom').addClass('show');
            }
        }
    }

    render() {
        var i = 1;

        const {user} = this.props;

        const st = {
            subPrefix: 'menu-bottom-sub-',
            menu: [
                {
                    'id': 1,
                    'title': 'داشبورد',
                    'route': '/',
                    'icon': 'fe-home'
                },
                {
                    'id': 2,
                    'title': 'سفارش',
                    'route': routes.orders,
                    'icon': 'fe-shopping-cart',
                    'childs': [
                        {
                            'id': 11,
                            'title': 'ثبت سفارش جدید',
                            'route': routes.orders_service,
                        },
                        {
                            'id': 22,
                            'title': 'لیست سفارش ها',
                            'route': routes.orders,
                        },
                        {
                            'id': 33,
                            'title': '',
                            'route': '/orders/services/items/16',
                            'content':  <div className='text-danger d-inline-block mr-2'> ثبت سفارش فروش ارز (نقد درآمد)</div>
                        },

                        {
                            'id': 44,
                            'title': '',
                            'route': routes.cash_list,
                            'content': <div className='text-danger d-inline-block mr-2'> لیست سفارش های فروش ارز</div>
                        }
                    ]
                },
                {
                    'id': 3,
                    'title': 'تیکت ',
                    'route': routes.ticket,
                    'icon': 'fe-message-square',
                    'content': user.alarms && user.alarms.tickets ?
                        <span className='badge badge-danger'>{user.alarms.tickets}</span> : ''
                },
                {
                    'id': 4,
                    'title': 'مالی',
                    'route': '#',
                    'icon': 'fe-dollar-sign',
                    'content': (user.alarms && user.alarms.transactions ?
                        <span className='badge badge-danger'>{user.alarms.transactions}</span> : ''),
                    'childs': [
                        {
                            'id': 11,
                            'title': ' کیف پول من (صورت حساب ها)',
                            'route': routes.finance,
                        },
                        {
                            'id': 22,
                            'title': 'افزایش موجودی',
                            'route': routes.finance_add_fund,
                        },
                        {
                            'id': 33,
                            'title': 'درخواست برداشت وجه ',
                            'route': routes.finance_withdraw,
                        },

                        {
                            'id': 44,
                            'title': 'تراکنش ها ',
                            'route': routes.finance_transactions,
                            'content': (user.alarms && user.alarms.transactions ? <div
                                className='text-danger d-inline-block mr-2'> ({user.alarms.transactions})</div> : '')
                        }
                    ]
                },
                {
                    'id': 5,
                    'title': 'حساب من ',
                    'route': '#',
                    'icon': 'fe-user',
                    'childs': [
                        {
                            'id': 111,
                            'title': 'ویرایش حساب کاربری',
                            'route': routes.profile_edit,
                        },
                        {
                            'id': 222,
                            'title': 'تایید مدارک (احراز هویت) ',
                            'route': routes.profile_verify,
                        },
                        {
                            'id': 333,
                            'title': 'امنیت حساب کاربری',
                            'route': routes.profile_security,
                        },
                        {
                            'id': 444,
                            'title': 'افزودن کارت بانکی',
                            'route': routes.profile_bank_credits,
                        },
                        {
                            'id': 555,
                            'title': 'تعییر رمز عبور',
                            'route': routes.profile_change_password,
                        },
                    ]
                },
                {
                    'id': 6,
                    'title': '',
                    'route': '#',
                    'icon': 'fe-more-horizontal',
                    'childs': [
                        {
                            'id': 2222,
                            'title': '',
                            'content': <div className=''><i className='fe-trending-up d-inline-block ml-1'></i> لیست
                                قیمت ارزها</div>,
                            'route': routes.prices,
                        },
                        {
                            'id': 3333,
                            'title': '',
                            'route': routes.profile_vip,
                            'content': <div><i className='fe-award d-inline-block ml-1'></i> ارتقاع سطح کاربری</div>,

                        },

                        {
                            'id': 5555,
                            'title': '',
                            'route': routes.referrals,
                            'content': <div><i className='fe-share-2 d-inline-block ml-1'></i>همکاری در فروش</div>,

                        },
                        {
                            'id': 6666,
                            'title': ' ',
                            'route': routes.rewards,
                            'content': <div><i className='fe-gift d-inline-block ml-1'></i> مشاهده پاداش ها</div>,

                        },

                        {
                            'id': 4444,
                            'title': '',
                            'route': routes.comments,
                            'content': <div><i className='fe-message-circle d-inline-block ml-1'></i> نظرات و پیشنهادات
                            </div>,

                        },
                        {
                            'id': 8888,
                            'title': '',
                            'content': <div className='text-danger'><i
                                className='fe-log-out d-inline-block ml-1'></i> خروج از حساب کاربری</div>,
                            'route': routes.logout,
                        },

                    ]
                }
            ]
        }
        return (
            <>
                <div onClick={() => this.closeSubmenu()} className="overlay-menu-bottom"></div>
                <div className='menu-mobile d-flex justify-content-center align-items-center'>

                    {st.menu.map(link => (
                        <div key={link.id} className={"link flex-fill " + (link.childs ? 'has-sub' : '')}>
                            <Link onClick={() => this.toggleSubMenu(st.subPrefix + link.id, link, true)}
                                  className="d-flex flex-column "
                                  to={link.childs ? ('#' + st.subPrefix + link.id) : link.route}>
                                <i className={link.icon}></i>
                                <span className="title">{link.title}</span>
                                {link.content}
                            </Link>

                            {link.childs &&
                            <ul id={st.subPrefix + link.id} className="has-submenu">
                                {link.childs.map(lin => (
                                    <li key={lin.id}>
                                        {lin.rel ?
                                            <a className={lin.class ? lin.class : ''}
                                               onClick={() => this.toggleSubMenu(st.subPrefix + link.id)}
                                               target='_blank' href={lin.route}>
                                                <span
                                                    className="title">{lin.title}
                                                    {lin.content}
                                                  </span>
                                            </a>
                                            :
                                            <Link className={lin.class ? lin.class : ''}
                                                  onClick={() => this.toggleSubMenu(st.subPrefix + link.id)}
                                                  to={lin.route}>
                                                <span
                                                    className="title">{lin.title}
                                                    {lin.content}
                                                  </span>
                                            </Link>
                                        }

                                    </li>
                                ))}
                            </ul>
                            }
                        </div>
                    ))}
                </div>
            </>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.info,
    }
}

export default connect(mapStateToProps)(MenuButton);
