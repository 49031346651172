import React from 'react';
import Squre from "../skelete/Squre";
import SkeleteLoading from "../SkeleteLoading";

class DashboardOrdersLoading extends React.Component {

    render(){
        var html=[];
        for(var i=0; i<=4;i++){
            html.push(<div ke={i} className='mb-2'><Squre height={40} /></div>)
        }

        return (
            <React.Fragment>
                <SkeleteLoading>
                    {html}
                </SkeleteLoading>
            </React.Fragment>
        );
    }
}

export default DashboardOrdersLoading;
