import React from "react";

const Input = ({ name, label, error,type,classn,ref,checked,hor,handleKeyUp,symbol,maxlength,desc, ...rest }) => {
  return (
      (type==='checkbox' || type==='radio')?
            <React.Fragment>
                {
                    (type === 'radio' ?
                        checked ?
                            <input onClick={handleKeyUp} {...rest} checked type={type} className={classn}/>
                            :
                            <input onClick={handleKeyUp} {...rest} type={type} className={classn}/>
                        :
                        checked ?
                            <input onClick={handleKeyUp} {...rest} checked type={type} name={name} className={classn}/>
                            :
                            <input onClick={handleKeyUp} {...rest} type={type} name={name} className={classn}/>)
                }
                {
                    error && <small className="font-11 error-label">{error}</small>
                }

                { desc && <div className="font-11 help-block">{desc}</div>    }

            </React.Fragment>
          :
          (
              (type==='hidden')?
                  <input {...rest} type={type} name={name} id={name} className={'form-control '+classn} />
                  :
                  <div className={hor?"form-group row horizontal align-item-center":"form-group"}>

                      {label?<label className={hor?'col-md-3':''} htmlFor={name}>{label}</label>:''}

                      {
                          hor?
                              <div className='col-md-9'>
                                  {symbol ?
                                      <div className="input-group">
                                          <input data-maxlength={maxlength} maxLength={maxlength}   data-name={label} onKeyUp={handleKeyUp} {...rest} type={type} name={name} id={name} ref={ref} className={"form-control  "+ classn} />

                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="inputGroupPrepend3">{symbol}</span>
                                            </div>
                                        </div>
                                      :
                                      <input  data-maxlength={maxlength} maxLength={maxlength} data-name={label} onKeyUp={handleKeyUp} {...rest} type={type} name={name} id={name} ref={ref} className={"form-control  "+ classn} />
                                  }
                                  {error && <small className="font-11 error-label">{error}</small>}
                                  { desc && <div className="font-11 help-block">{desc}</div>    }


                              </div>
                              :
                              <React.Fragment>
                                  {symbol ?
                                      <div className="input-group">
                                          <input data-maxlength={maxlength} maxLength={maxlength}  data-name={label} onKeyUp={handleKeyUp} {...rest} type={type} name={name} id={name} ref={ref} className={'form-control '+classn} />
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="inputGroupPrepend3">{symbol}</span>
                                            </div>
                                        </div>
                                      :
                                      <input  data-maxlength={maxlength} maxLength={maxlength} data-name={label} onKeyUp={handleKeyUp} {...rest} type={type} name={name} id={name} ref={ref} className={'form-control '+classn} />
                                  }
                                  {error && <small className="font-11 error-label">{error}</small>}
                                  { desc && <div className="font-11 help-block">{desc}</div>    }

                              </React.Fragment>

                      }

                  </div>
          )


  );
};

export default Input;
