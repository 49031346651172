import React from 'react';

import {connect} from "react-redux";

class LoadingReload extends React.Component {

    render() {
        return (
            <>
                <div className='loadingAll pt-5 pb-5'>
                    <img src={'/assets/images/loading.svg'} height={30} width={30} align={'loading'} className={'mb-2'} />
                    <h6>درحال به روز رسانی ...</h6>
                </div>
            </>
        );
    }

}

export default connect()(LoadingReload);
