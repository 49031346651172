import React from 'react';
import {connect} from "react-redux";
import FormLoader from "../../ubold/commons/FormLoader";
import routes from "../../routes";
import { Redirect} from "react-router-dom";
import {toast} from "react-toastify";
import queryString from 'query-string';
import {getCash} from "./CashincomeService";

class CashincomeCallback extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            params: this.props.location.search,
            redirect: false
        };
    }

    componentDidMount = async () => {
        const parsed = queryString.parse(this.state.params);

        if (parseInt(parsed.res)!==0 && parseInt(parsed.cash_id)!==0) {
            getCash(parsed.cash_id).then(res=>{
                if (parsed.message) {
                    toast.success(parsed.message);
                }
                this.setState({redirect: routes.cash_show + parsed.cash_id});

            }).catch(res=>{
                toast.error('خطا در انتقال ارز، لطفا مجددا امتحان نمایید');
                window.location.href = routes.orders+'?type=sell';
            })

        }else if(parsed.cash_id!==0 && parsed.message){
            toast.error(parsed.message);
            this.setState({redirect: routes.cash_pay + parsed.cash_id});

        } else {
            toast.error('خطا در انتقال ارز، لطفا مجددا امتحان نمایید');
            window.location.href = routes.orders+'?type=sell';
        }

    }


    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }

        return (
            <div className='card card-body'>
                <FormLoader/>
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        user: state.user.info
    }
}

export default connect (mapStateToProps)(CashincomeCallback) ;