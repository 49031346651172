import React, { Component } from "react";
import Table from "../../ubold/commons/table";
import parse from "html-react-parser";

class OrdersTable extends Component {

    columns = [
        {
            path: "id",
            label: "شماره سفارش",
            width : '100'

        },
        {
            path: "title",
            label: "موضوع",
            width : '300'
        },

        {
            path: "status",
            label: "وضعیت",
            content : ticket=> parse(("<span class='badge badge-"+ticket.status_text.class+"'>"+ticket.status_text.label+"</span>"))
        },
        {
            path: "shamsi_created_at",
            label: "تاریخ ثبت",
        },


    ];

    render() {
        const { orders,  sortColumn ,onSelect} = this.props;
        return (
            <React.Fragment>
                <Table
                    columns={this.columns}
                    data={orders}
                    sortColumn={sortColumn}
                    onSelect={onSelect}
                    onSort={()=>{}}
                />
            </React.Fragment>
        );
    }
}

export default OrdersTable;
