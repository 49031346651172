import React from 'react';
import Squre from "../skelete/Squre";
import SkeleteLoading from "../SkeleteLoading";

class FinanceAddFundLoading extends React.Component {

    render(){
        return (
            <React.Fragment>
                <SkeleteLoading>
                    <div className="">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-xl-6 col-lg-8 ">
                               <div className="mb-3">
                                   <Squre height={100} />
                               </div>
                                <Squre height={180} />
                            </div>
                        </div>
                    </div>
                </SkeleteLoading>
            </React.Fragment>
        );
    }
}

export default FinanceAddFundLoading;
