import React from 'react';
import {updatePageTitle} from "../../actions/breadcrumbs";
import {connect} from "react-redux";
import {addFund, checkUserInfo} from "./FinanceService";
import {Link, Redirect} from "react-router-dom";
import FormLoader from "../../ubold/commons/FormLoader";
import Joi from "joi-browser";
import Form from "../../ubold/commons/form";
import routes from "../../routes";
import ErrorBox from "../partials/ErrorBox";
import Alert from "../partials/Alert";
import NumberFormat from 'react-number-format';
import {updateUserInStore} from "../user/userService";
import FinanceListLoading from "../../components/loadings/FinanceListLoading";
import FinanceAddFundLoading from "../../components/loadings/FinanceAddFundLoading";

class FinanceAddfund extends Form {
    constructor(props) {
        super(props);

        this.state = {
            redirect: '',
            data: {
                price: '',
            },
            errors: {},
            loadingForm: true,
            canAddFund: false,
            balance: false,

        }

        this.schema = {
            price: Joi
                .string()
                .required()
                .label("مبلغ")
        };

        this.props.dispatch(updatePageTitle(
            'افزایش موجودی',
            [
                {
                    'title': 'کیف پول من',
                    'link': routes.finance
                },
                {
                    'title': 'فرم افزایش موجودی',
                    'active': true
                }
            ]
        ));
    }

    componentDidMount() {
        checkUserInfo().then(res => {
            this.setState({
                loadingForm: false,
                creditsForPay: res.data.creditsForPay,
                balance:res.data.balance
            });
        })
    }


    doSubmit = async () => {
        try {
            var data = this.state.data;

            addFund(data).then(res => {
                this.toggle();
                updateUserInStore(this.props.dispatch,res.user)
                this.setState({
                    redirect: routes.finance_checkout + res.transaction
                });

            }).catch(res => {
                this.toggle();
            });

        } catch (ex) {
            this.toggle();
        }
    };


    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }

        return (

            <React.Fragment>
                <div className="mt-4">
                    {
                        this.state.loadingForm ?
                            <FinanceAddFundLoading/>
                            :
                            this.state.creditsForPay === 0 && !this.props.user.legal ?
                                <ErrorBox title={'پیام در رابطه با کارت بانکی'}
                                          content="برای افزایش موجودی در سایت، ابتدا باید کارت بانکی مورد نظری که با آن پرداخت های اینترنتی خود را انجام می دهید را به سیستم اضافه و تایید کنید"
                                          link={routes.profile_credit_account}
                                          linkText={'برای ثبت کارت بانکی کلیک کنید'}
                                />
                                :
                                <>

                                    <div className="row justify-content-center align-items-center">

                                        <div className="col-xl-6 col-lg-8 withdraw-form">



                                            <div className='card card-body  mb-4 pt-5'>
                                                <div className={"mb-1"} style={{marginTop:'-20px'}}><strong className={'badge badge-danger'}>جدید</strong></div>
                                                <h5 className={"mb-4"}>افزایش موجودی از طریق ساتنا و شبا برای مبالغ بالای 25 میلیون تومان</h5>
                                                <Link className="btn btn-t " to={routes.finance_payment_id}>دریافت شماره شبا و شناسه پرداخت </Link>
                                            </div>


                                            <div className='card card-body  finance-form'>
                                                <h5 className={"mb-4"}>افزایش موجودی از طریق درگاه اینترنتی برای مبالغ کمتر از 25 میلیون تومان</h5>

                                                <Alert type={'warning'} title={'لطفا توجه کنید'}>
                                                    انجام پرداخت های درگاه بانکی باید با استفاده از  کارت هایی که در سیستم ثبت کرده اید انجام شود در غیر این صورت پرداخت شما انجام نخواهد شد

                                                </Alert>

                                                <form method="POST" onSubmit={this.handleSubmit}>

                                                    <div className="form-group">
                                                        <label>مبلغ (تومان)</label>
                                                        <NumberFormat
                                                            value={this.state.data.price}
                                                            className="form-control ltr eng"
                                                            thousandSeparator={true}
                                                            prefix={' تومان '}
                                                            onValueChange={(values) => {
                                                                const { formattedValue, value } = values;
                                                                var data=this.state.data;
                                                                data['price']=value;
                                                                this.setState({ data });
                                                            }}
                                                        />

                                                        {
                                                            this.state.errors['price'] && <small className="font-11 error-label">{this.state.errors['price']}</small>
                                                        }
                                                    </div>




                                                    {this.renderButton("افزایش موجودی ", 'btn btn-t btn-lg btn-block mt-5')}
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                </>
                    }
                </div>

            </React.Fragment>
        )
    }

}

const mapStateToProps = (state) => {

    return {
        user: state.user.info,
        app: state.app,
    }
}

export default connect(mapStateToProps)(FinanceAddfund);
