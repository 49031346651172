import React from "react";

function handleChangeSchema(callback,t=false) {
    callback('user_account_username','نام کاربری',t);
    callback('user_account_password','رمز عبور',t);
}

const Account = ({ valueAccount,valueUser,valuePass,errorAccount,errorUser,errorPass,onChange,changeSchema}) => {



  return (
          <React.Fragment>

              <div className="form-group  form-control-select" style={{height:' auto !important'}}>
                  <label className="form-label ml-3" htmlFor="f1">
                      اطلاعات حساب کاربری:
                      <span className="label-require">*</span>
                  </label>
                  &nbsp; &nbsp;
                  <label>
                      بله

                      <input onClick={()=>handleChangeSchema(changeSchema,true)} onChange={onChange} checked={valueAccount==='بله'} type="radio" name="account"  value="بله" />
                  </label> &nbsp;
                  <label>
                      خیر
                      <input onClick={()=>handleChangeSchema(changeSchema,false)} onChange={onChange} type="radio" checked={valueAccount==='خیر' || !valueAccount}  name="account"  value="خیر" />
                  </label>
                  <div>
                      {errorAccount && <small className="font-11 error-label">{errorAccount}</small>}

                  </div>
                  <div className="help-block">آیا برای پرداخت باید به حساب کاربری شما وارد شویم ؟</div>
                  <br/>
                  <div style={{display:(valueAccount==='بله'?'block':'none')}}   id="account-info" className="">
                      <div className="form-group ">
                          <label className="form-label form-label--left" htmlFor="f1">
                              نام کاربری
                              <span className="label__required">*</span>
                          </label>
                          <input onChange={onChange} name='user_account_username' value={valueUser?valueUser:''}  className='form-control english'/>
                            {errorUser && <small className="font-11 error-label">{errorUser}</small>}

                      </div>

                      <div className="form-group ">
                          <label className="form-label form-label--left" htmlFor="f1">
                              رمز عبور
                              <span className="label__required">*</span>
                          </label>
                          <input onChange={onChange} name='user_account_password' value={valuePass?valuePass:''}  className='form-control english'/>
                            {errorPass && <small className="font-11 error-label">{errorPass}</small>}

                      </div>

                  </div>
              </div>


          </React.Fragment>
  );
};

export default Account;
