import React from "react";

const Memo = ({selectedNetwork,tag=null,orderType,handleChange,errors,isChecked,handleUseMemo})=>{
    return(

        (orderType==='buy' && selectedNetwork && selectedNetwork.memoRegex)?
                <>
                    <div className="form-group ltr english">
                        <div className="d-flex flex-row-reverse justify-content-between align-items-center">

                            <div>
                                <label>
                                    نمی خواهم استفاده کنم
                                    &nbsp;
                                    <input type={"checkbox"} onClick={()=>handleUseMemo()} checked={isChecked} />

                                </label>
                            </div>
                            <div>
                                <label >Memo/Tag/Private Code</label>
                            </div>
                        </div>

                        {
                            isChecked?'':
                                <>
                                    <input type="text" disabled={isChecked} className="ltr english form-control momotag"  name="tag" onChange={handleChange} value={tag} />
                                    {
                                        errors['tag'] && <small className="font-11 error-label">{errors['tag']}</small>
                                    }
                                </>
                        }

                    </div>
                </>
                :''

    );
}

export default Memo;