import React, { Component } from "react";
import { showAppLoader} from "../../../actions/app";
import {connect} from "react-redux";
import { tokenKey} from "./../../auth/authService";
import PageLoading from "../../../ubold/components/layouts/PageLoading";
import {siteUrl} from "../../../config.json";

class swichUserLogout extends Component {
    constructor(props) {
        super(props);
        this.props.dispatch(showAppLoader());
        this.state={
            redirect:false
        }
    }

  componentDidMount() {
        if(localStorage.getItem('before')){
            localStorage.removeItem(tokenKey);
            localStorage.removeItem('user');
            localStorage.removeItem('before');
            window.location=siteUrl+'/panel/profile/switch/stop';
        }
  }

  render() {
      return <PageLoading/>
  }
}

export default connect()(swichUserLogout);
