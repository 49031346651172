import React from 'react';
import {formatMoney} from "../../../../services/helpers";
import $ from "jquery";

const  allowNetwork= (networks,network) =>{

    if(networks.length > 0 ){
        var check=false;
        $.each(networks, function (i, item) {
            if(item.addressRegex === network.addressRegex){
                check= true
            }
        });
        return check;
    } else
        return false;
}

const NetworkItem = ({ item,selectedNetwork,onSelectNetwork,orderType,selectedCoin,dollarprice,currencyprice,allowNetworks}) => {

    var allow=(allowNetwork(allowNetworks,item) );

    if(orderType==='sell')
        allow=true;

    return (
        <>
            <div key={item.name} onClick={()=>((allow )?onSelectNetwork(item):'')} className={'item  d-flex justify-content-between v3-flex-center '+ (selectedNetwork===item.chain?'active':'')+((!allow )?" disabled":'')}  >
                <div className='w-100'>
                    <div>
                            {item.network}
                    </div>
                    {
                        item.exchanger==='KUCOIN'?
                            (item.network+ ' ('+item.chain+')' ):item.name
                    }
                </div>
                <div className='w-100' >
                    {
                        (orderType==='buy')?
                            <div className="fee">
                              <div>
                                  <span className="text-danger">Fee</span>
                                  : {parseFloat(item.withdrawFee)}
                                  <small className={'ml-1'}>{selectedCoin.symbol} &nbsp; ≈ &nbsp; ($ {(dollarprice* item.withdrawFee).toFixed(2)} )   </small>
                              </div>
                                <div>

                                    <small> {formatMoney(currencyprice* item.withdrawFee)} &nbsp;<small>تومان</small></small>

                                </div>
                            </div>
                            :''
                    }
                </div>
            </div>

        </>
    );
};

export default NetworkItem;

