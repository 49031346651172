import React from "react";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Alert from "../../../partials/Alert";
import {formatMoney} from "../../../../services/helpers";
import loadingIMG from "../../../../assets/images/loading2.svg";
import loadingIMG6 from "../../../../assets/images/loading6.svg";

const CryptoModalInfoBeforeSubmit = ({exchanger,orderType,data,selectedNetwork,currency_price,loading,selectedCoin,networkSelect,toggleModalSubmit,doSubmitFinal,modal_submit,hasInnerTransfer,totalFee=0})=>{


    const exFee = () => {
        var withdrawFeeRate = parseFloat(selectedNetwork?.withdrawFeeRate);
        var amount = parseFloat(data.amount);
        var fee = 0;

        if (amount) {
            fee = hasInnerTransfer ? 0 :
                (withdrawFeeRate ? ((amount * withdrawFeeRate)) : 0)

            return fee;
        }
    }

    return(
        <>
            <Modal isOpen={modal_submit} toggle={toggleModalSubmit} className='credit'>

                <ModalHeader toggle={toggleModalSubmit}> تایید اطلاعات و ثبت سفارش</ModalHeader>

                <ModalBody>
                    <Alert type={'danger'} title={' '}>
                        لطفا قبل از ثبت سفارش مقدار و شبکه انتخابی را با دقت بررسی کنید و  مطمئن شوید که شبکه انتقال بر روی بستر <strong className='ml-1 mr-1'>{networkSelect}</strong> است ، اگر شبکه اشتباه انتخاب شود و پلتفرم مقصد از آن پشتبانی نکند ، دارایی ارز دیجیتال شما قابل بازیابی نیست.
                    </Alert>


                    <ul className="list-group">
                        {
                            selectedCoin?
                                <li className="list-group-item d-flex justify-content-between">
                                    <div className=''><strong>نوع ارز</strong></div>
                                    <div className='ltr'>{selectedCoin.symbol} - {selectedCoin.name}</div>
                                </li>
                                :""
                        }
                        {
                            data && data.amount?
                                <li className="list-group-item d-flex justify-content-between">
                                    <div className=''><strong>میزان ارز</strong></div>
                                    <div className='ltr'>{data.amount}</div>
                                </li>
                                :""
                        }
                        {
                            data && data.wallet?
                                <li className="list-group-item d-flex justify-content-between">
                                    <div className=''><strong>آدرس کیف پول شما</strong></div>
                                    <div className='ltr'>{data.wallet}</div>
                                </li>
                                :""
                        }
                        {
                            data && data.tag?
                                <li className="list-group-item d-flex justify-content-between">
                                    <div className=''><strong>Tag / Memo</strong></div>
                                    <div className='ltr'>{data.tag}</div>
                                </li>
                                :""
                        }
                        {
                            selectedNetwork?
                                <li className="list-group-item d-flex justify-content-between">
                                    <div className=''><strong>شبکه انتخابی شما</strong></div>
                                    <div className='ltr'>
                                        {
                                            networkSelect
                                        }
                                    </div>
                                </li>
                                :""
                        }
                        {
                            orderType==='buy'?(
                                <div>
                                    {
                                        selectedNetwork && selectedNetwork.withdrawFee?
                                            <li className="list-group-item d-flex justify-content-between">
                                                <div className=''><strong>کارمزد ماینرها (شبکه)</strong></div>
                                                <div className='ltr d-flex'>
                                                    <div>
                                                        {(hasInnerTransfer?0:(selectedNetwork.withdrawFee)+'  ')}<small>{selectedCoin.symbol}</small>
                                                    </div>
                                                    <div>
                                                        &nbsp;
                                                        &nbsp;
                                                        معادل
                                                        &nbsp; &nbsp;
                                                    </div>
                                                    <div>
                                                        ( <span>{formatMoney((hasInnerTransfer?0:selectedNetwork.withdrawFee) * currency_price)}</span> <small>تومان</small>)

                                                    </div>
                                                </div>
                                            </li>
                                            :""
                                    }
                                    {
                                        selectedNetwork && selectedNetwork.withdrawFee?
                                            <li className="list-group-item d-flex justify-content-between">
                                                <div className=''><strong>کارمزد صرافی {exchanger} </strong></div>
                                                <div className='ltr d-flex'>
                                                    <div>
                                                        { exFee() +'  '}<small>{selectedCoin.symbol}</small>
                                                    </div>
                                                    <div>
                                                        &nbsp;
                                                        &nbsp;
                                                        معادل
                                                        &nbsp; &nbsp;
                                                    </div>
                                                    <div>
                                                        ( <span>{formatMoney((hasInnerTransfer?0:exFee()) * currency_price)}</span> <small>تومان</small>)

                                                    </div>
                                                </div>
                                            </li>
                                            :""
                                    }

                                    {
                                        selectedCoin && selectedNetwork ?
                                            <li className="list-group-item d-flex justify-content-between">
                                                <div className=''><strong>خالص دریافتی شما</strong></div>
                                                <div className='ltr'>{totalFee}

                                                    &nbsp;
                                                    <small>{selectedCoin.symbol}</small>
                                                </div>
                                            </li>
                                            :""
                                    }


                                </div>

                            ):''
                        }

                    </ul>

                </ModalBody>

                <ModalFooter>
                    <button className='btn btn-default' type='button' onClick={()=>toggleModalSubmit()}>
                        <span>  بازگشت </span>
                    </button>
                    <button disabled={loading} type='button' onClick={()=>doSubmitFinal()} className={ 'btn btn-t  ml-2 mr-2'}>
                        <img alt={'loading'} style={{'display': loading ? 'inline-block' : 'none'}} height="20" className="ml-1" src={loadingIMG6}/>
                        <span><i className='fe-send'></i>  تایید و ثبت سفارش </span>
                    </button>
                </ModalFooter>

            </Modal>
        </>
    );
}

export default CryptoModalInfoBeforeSubmit;