import React from 'react';
import Squre from "../skelete/Squre";
import SkeleteLoading from "../SkeleteLoading";

class UseBankCardsLoadings extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className="mt-4">
                    <SkeleteLoading>
                        <div className="row justify-content-center pt-3 ver-list">
                            <div className="col-lg-6 col-md-8">
                                <div className="mb-2">
                                    <Squre height={120}/>
                                </div>
                                <div className="mb-2">
                                    <Squre height={120}/>
                                </div>

                                <div className="mb-2">
                                    <Squre height={60}/>
                                </div>
                            </div>
                        </div>
                    </SkeleteLoading>
                </div>
            </React.Fragment>
        );
    }
}

export default UseBankCardsLoadings;
