import React from 'react';
import {connect} from "react-redux";
import Form from "../../ubold/commons/form";
import {Link} from "react-router-dom";
import routes from "../../routes";
import moment from "moment-jalaali";
import {formatMoney} from "../../services/helpers";

class CashincomeItem extends Form {

    render() {

        const {cash}=this.props;

        return (
            <>
                <div className="row align-items-center">
                    <div className="col-lg-6 col-sm-6 col-6">
                        <div className="row align-items-center">
                            <div className="col-xl-3 col-lg-3">
                                <div>
                                    <span className="mr-1">
                                        <Link to={routes.cash_show+cash.id}>#{cash.id}</Link>
                                    </span>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-5">
                                <img alt={cash.title} src={cash.cash_icon} height={20} width={20} /> &nbsp;
                                <strong>{formatMoney(cash.amount)}</strong>
                                <div style={{opacity:0.8,fontSize:'10px'}} className="ml-1 mr-1 d-inline-block">
                                    {cash.currency_name}
                                </div>

                            </div>
                            <div className="col-xl-4 col-lg-4">
                                <div>
                                    <div style={{opacity:'1'}}>نرخ خرید :  {formatMoney(cash.currency_price)}
                                        <small className='d-inline-block ml-1 mr-1'>تومان</small>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-6 col-6">
                        <div className="row align-items-center">

                            <div className="col-xl-4 col-lg-4">
                                <div className="">
                                    <div>
                                        <span
                                            className={' mr-1 ' }>{moment(cash.created_at).locale('fa').format('jYYYY/jMM/jDD - HH:mm')}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3">
                                <div className="">
                                    <div>

                                        <span
                                            className={'badge d-inline-block mr-1 badge-' + cash.status_text.class}>{cash.status_text.label}</span>

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-5 ">
                                <div className="d-block d-md-none">
                                    <div className="mb-3"></div>
                                </div>
                                <div>

                                    <Link to={routes.cash_show+ cash.id} className='btn btn-default btn-xs'><i className='fe-eye'></i> مشاهده </Link>
                                    {
                                        (cash.status==='PAYING' && cash.is_crypto)?
                                            <Link to={routes.cash_crypt_pay+ cash.id} className='btn btn-default btn-xs'><i className='fe-repeat'></i> پرداخت </Link>
                                        :''
                                    }

                                    {
                                        (cash.has_gateway_payment)?
                                            <Link to={routes.cash_pay+ cash.id} className='btn btn-default btn-xs'><i className='fe-shopping-bag'></i> پرداخت </Link>
                                            :''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }

}

export default connect ()(CashincomeItem);
