import React from 'react';
import Form from "../../ubold/commons/form";
import Joi from "joi-browser";
import {sendVoucher} from "./FinanceService";
import {connect} from "react-redux";
import {hideAppLoader, showAppLoader} from "../../actions/app";

class Checkout extends Form {

    constructor(props) {
        super(props);

        this.state = {
            data: {
                code: '',
            },
            errors: {},
        };


        this.schema = {
            code: Joi
                .required()
                .label("کد تخفیف"),
        };

    }


    doSubmit = async () => {
        this.toggle();
        // this.props.dispatch(showAppLoader());

        try {
            var data = this.state.data;
            sendVoucher(this.props.trans_id, data).then(res => {
                this.props.reload();
                // this.props.dispatch(hideAppLoader());
            }).catch(res => {
                console.log(res);
                this.toggle();
                // this.props.dispatch(hideAppLoader());
            });

        } catch (ex) {
            this.toggle();
        }
    };

    render() {

        return (

            <React.Fragment>
                <form onSubmit={this.handleSubmit}>

                    <div className="card card-body mb-0 ">
                        <div className="desc">اگر کد تخفیف دارید در این قسمت وارد نمایید</div>

                        <div className="input">
                            {this.renderInput('code', '','','','','',false,'','کد تخفیف را وارد کن')}
                            <div className="bb">
                                {this.renderButton("بررسی کد", '')}
                            </div>
                        </div>
                    </div>
                </form>

            </React.Fragment>
        )
    }

}

export default connect()(Checkout);
