import React from 'react';
import {connect} from "react-redux";
import Form from "../../../ubold/commons/form";
import {Link} from "react-router-dom";
import routes from "../../../routes";
import moment from "moment-jalaali";
import {formatMoney} from "../../../services/helpers";

class CashincomeItemDashboard extends Form {

    render() {

        const {cash}=this.props;

        return (
            <>
                <div className="row align-items-center">
                    <div className="col-lg-7 col-sm-7 col-7">
                        <div className="row align-items-center">
                            <div className="col-xl-4 col-lg-3">
                                <div>
                                    <span className="mr-1">
                                        <Link to={routes.cash_show+cash.id}>#{cash.id}</Link>
                                    </span>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-5">
                                <img className='ml-1' alt={cash.title} src={cash.cash_icon} height={20} width={20} /> {' '}
                                {formatMoney(cash.amount)}
                                &nbsp;
                                <small>{cash.currency_name}</small>

                            </div>
                            <div className="col-xl-4 col-lg-4">
                                <div>
                                    <div style={{opacity:'1'}}>نرخ  :  {formatMoney(cash.currency_price)}
                                        <small className='d-inline-block ml-1 mr-1'>تومان</small>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className="col-lg-5 col-sm-5 col-5">
                        <div className="row align-items-center">
                            <div className="col-xl-6 col-lg-6">
                                <div className="">

                                    <div>

                                        <span
                                            className={'badge d-inline-block mr-1 badge-' + cash.status_text.class}>{cash.status_text.label}</span>

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 ">
                                <div className="d-block d-md-none">
                                    <div className="mb-3"></div>
                                </div>
                                <div>
                                    <div>

                                        <span
                                            className={' mr-1 ' }>{moment(cash.created_at).locale('fa').format('jYYYY/jMM/jDD - HH:mm')}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }

}

export default connect ()(CashincomeItemDashboard);
