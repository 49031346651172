import React from 'react';
import {formatMoney} from "../../../services/helpers";

class PricesItem extends React.Component {

    render() {
        const service=this.props.service;
        const icon=service.service_icon;

        return (
            <React.Fragment>
                <div  className="item">
                    <div className="d-flex align-items-center dg-name">
                            <div className="lazy-box img-box">
                                <img height="32" width="32" data-src={icon}/>
                            </div>



                    </div>

                    <div className="d-flex align-items-center dg-price sss">

                            <strong> {service.name}</strong>

                    </div>

                    <div  className=" dg-price">
                        {formatMoney(service.currency_buy_price)}
                        &nbsp;
                        <small>تومان</small>
                    </div>

                    <div  className=" dg-price">
                        {
                            !service.currency_sell_price || service.currency_sell_price!=="NaN"?
                                <>
                                    {formatMoney(service.currency_sell_price)}
                                    &nbsp;
                                    <small>تومان</small>
                                </>
                                :'----'
                        }

                    </div>

                </div>

            </React.Fragment>
        )
    }

}


export default PricesItem;
