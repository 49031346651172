import React from 'react';
import Squre from "../skelete/Squre";
import SkeleteLoading from "../SkeleteLoading";

class RefferalListLoading extends React.Component {

    render(){
        var html=[];
        for(var i=0; i<=5;i++){
            html.push(<div className='mb-2'><Squre height={50} /></div>)
        }

        return (
            <React.Fragment>
                <SkeleteLoading>
                    {html}
                </SkeleteLoading>
            </React.Fragment>
        );
    }
}

export default RefferalListLoading;
