import React from 'react';
import {connect} from "react-redux";
import {spilitNumber} from "../../services/helpers";


class RewardItems extends React.Component {


    render() {
        const rewards =this.props.rewards;
        return (
            <React.Fragment>
                {rewards.items.map(item=>(
                    <div key={item.key} className="col-6 col-lg-3">
                        <div className="card card-body reward_item mb-2">

                            {
                                rewards.getUserRewardLevel && item.key===rewards.getUserRewardLevel?
                                <div className='active' style={{ backgroundColor: item.color}}>
                                    سطح شما
                                </div>
                                :''
                            }

                            <h4 className={item.key} >
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        {item.title}
                                    </div>
                                    <div>
                                        <img src={'/assets/images/app/r'+item.key+'.png'} height={40} width={40} />
                                    </div>
                                </div>
                            </h4>

                            <br/>
                            <div className='d-flex align-items-center justify-content-between'>
                               <div>
                                   <strong>از
                                       :</strong> &nbsp;

                               </div>
                               <div>
                                   {spilitNumber(item.min_amount)}
                                   &nbsp;
                                   <small>تومان</small>
                               </div>
                            </div>
                            <div className='d-flex align-items-center justify-content-between'>
                              <div>
                                  <strong>تا
                                      :</strong>
                                  &nbsp;
                              </div>
                               <div>
                                   {spilitNumber(item.max_amount)}
                                   &nbsp;
                                   <small>تومان</small>
                               </div>
                            </div>

                            <div className='discount d-flex align-items-center justify-content-between mt-3'>
                              <div>
                                  <strong>درصد تخفیف
                                      :</strong>&nbsp;
                              </div>

                               <div>
                                   {item.discount}%
                               </div>
                            </div>
                        </div>
                    </div>
                ))}
            </React.Fragment>
        )
    }

}

export default connect()(RewardItems);
