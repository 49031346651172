import React from 'react';
import {connect} from 'react-redux';
import {Route, Switch, Redirect, BrowserRouterProps, withRouter} from "react-router-dom";
import NoMatch from "./NoMatch";
import Dashboard from "./Dashboard";
import Logout from "./auth/components/logout";
import axios from "axios";
import jwtDecode from "jwt-decode";
import {updateUser} from "../actions/user";
import {ToastContainer} from "react-toastify";
import ReactTooltip from 'react-tooltip';
import $ from "jquery";
import {
    hideAppLoader,
    setAppRedirect,
    setCryptoActiveExchangers,
    setCryptoBaseExchanger,
    setEmailPhoneApp, setShowEvent, setVersion
} from "../actions/app";
import loading from "../assets/images/loading.svg";
import Breadcrumbs from "../ubold/components/layouts/Breadcrumbs";
import routes from "../routes";
import UserEdit from "./user/UserEdit";
import UserVerify from "./user/UserVerify";
import UserChangePass from "./user/UserChangePass";
import UserCreditAccount from "./user/UserCreditAccount";
import UserSecurity from "./user/UserSecurity";
import UserBankCards from "./user/UserBankCards";
import {createBrowserHistory} from "history";
import Sidebar from "../ubold/components/layouts/Sidebar";
import {updateUserStorage} from "./auth/authService";
import PageLoading from "../ubold/components/layouts/PageLoading";
import FinanceList from "./finance/FinanceList";
import FinanceWithdraw from "./finance/FinanceWithdraw";
import FinanceAddfund from "./finance/FinanceAddfund";
import FinanceTransactions from "./finance/FinanceTransactions";
import Checkout from "./finance/Checkout";
import CallbackBank from "./finance/CallbackBank";
import TicketList from "./tickets/TicketList";
import TicketCreate from "./tickets/TicketCreate";
import TicketShow from "./tickets/TicketShow";
import CommentsList from "./comments/CommentsList";
import CommentCreate from "./comments/CommentCreate";
import Referals from "./user/Referals";
import VipLayers from "./user/VipLayers";
import {changeTheme} from "../actions/app";
import OrdersList from "./orders/OrdersList";
import Crypto from "./orders/services/Crypto";
import NotificationShow from "./notifications/NotificationShow";
import NotificationsList from "./notifications/NotificationsList";
import Rewards from "./rewards/Rewards";
import MenuButton from "../ubold/components/layouts/MenuButton";
import UserTopBox from "../ubold/components/UserToBox";
import MessageFromAdmin from "./partials/MessageFromAdmin";
import OrdersServices from "./orders/OrdersServices";
import ServiceItems from "./orders/ServiceItems";
import OrderShow from "./orders/OrderShow";
import PayCrypto from "./cashincomes/parts/PayCrypto";
import CashincomeShow from "./cashincomes/CashincomeShow";
import OrdersNew from "./orders/OrdersNew";
import OrderEdit from "./orders/OrderEdit";
import CashincomeCreate from "./cashincomes/CashincomeCreate";
import CashincomePay from "./cashincomes/CashincomePay";
import CashincomeCallback from "./cashincomes/CashincomeCallback";
import Prices from "./Prices";
import swichUserLogout from "./auth/components/swichUserLogout";
import CashincomeList from "./cashincomes/CashincomeList";
import swichUser from "./auth/components/swichUser";

// import * as serviceWorkerRegistration from "../serviceWorkerRegistration";
import LoginInfo from "./user/LoginInfo";
import Notifications from "../ubold/components/layouts/parts/Notifications";
import LocationsHandler from "../components/LocationsHandler";
import RegisterFinish from "./auth/components/RegisterFinish";
import FinancePaymentId from "./finance/FinancePaymentId";
import TagManager from 'react-gtm-module'
import {setChatUserId} from "./user/userService";
import UserIbans from "./user/UserIbans";

const feather = require('feather-icons')

var timerTimeout='';
var timerTimeout2='';

class Panel extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            pageLoading: true,
            is_checked_theme: false,
            redirect: false,
            show_event: false,
            show_sidebar: this.props.show_sidebar
        };

    }

    componentDidMount() {

        const tagManagerArgs = {
            gtmId: 'GTM-N7GZ3WH'
        }

        TagManager.initialize(tagManagerArgs)


        axios.post('/user/get')
            .then(response => {
                updateUserStorage(response.data.user);
                let user = jwtDecode(response.data.user);
                this.props.dispatch(updateUser(user))
                this.props.dispatch(setEmailPhoneApp(user.app.email, user.app.phone));
                this.props.dispatch(hideAppLoader());
                this.props.dispatch(setCryptoBaseExchanger(user.app.crypto_base_exchanger));
                this.props.dispatch(setCryptoActiveExchangers(user.app.crypto_active_exchangers));
                this.props.dispatch(setVersion(user.app.version));
                this.setState({
                    pageLoading: false,
                });

            });

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        $(".logout").removeClass('btn').removeClass('btn-success');

        const user = this.props.user;

        if (user) {
            var ver = user.mobile_verify ? "mobile | " : "";
            ver += user.email_verify ? "email" : " | ";
            ver += user.national_code ? "national_code" : " | ";
            ver += user.credit_verify ? "selfi" : " | ";
            ver += user.phone_verify ? "phone" : "";

            const UserData = {
                email: user.email,
                name: user.name,
                about: 'سطح کاربری :' + user.level_label + " | توضیحات ادمین : " + user.admin_text + "  |||  " + "https://api.cafearz.com/admin/users/" + user.id,
                phone: user.mobile,
                avatar: user.avatar,
                tags: ver,
                forceUpdate: true
            };

            window.addEventListener('goftino_ready', function () {
                window.Goftino.setUser(UserData);

                if (user.userIdChat) {
                    clearTimeout(timerTimeout);

                    timerTimeout = setTimeout(function () {
                        window.Goftino.setUserId(user.userIdChat, function (callback) {
                        });
                    }, 1000);

                } else {
                    clearTimeout(timerTimeout2);
                    timerTimeout2 = setTimeout(function () {
                        setChatUserId(window.Goftino.getUserId())
                            .then(res => {
                            }).catch((err) => {
                            console.log(err)
                        })
                    }, 100);


                }

            });
        }
    }

    handleChangeTheme = (e) => {
        this.setState({
            is_checked_theme: !this.state.is_checked_theme
        });

        const theme = (e.target.checked ? 1 : 0)
        this.props.dispatch(changeTheme(theme));
        localStorage.removeItem('dark_theme');
        localStorage.setItem('dark_theme', theme);
    }

    handleShowEvent = (type) => {
        this.setState({show_event: type});
    }

    toggleSideMenu = (type) => {
        this.setState({show_sidebar: type});
    }

    syncHandler = () => {
        this.toggleSideMenu(false)
        this.handleShowEvent(false)
    }

    render() {

        if (this.props.app.redirect) {
            let uri = this.props.app.redirect;
            this.props.dispatch(setAppRedirect(null));
            return <Redirect to={uri}/>
        }

        const {user} = this.props;
        var useTag = ' <use xlink:href="/assets/images/sprite.svg#telephone"></use>';
        const notifications = this.props.user? this.props.user.notifications:[];

        return (

            this.state.pageLoading ?
                <PageLoading/>
                :
                <React.Fragment>

                    <LocationsHandler sync={() => this.syncHandler()}/>

                    {
                        this.state.show_event ?
                            <div>
                                <div onClick={() => this.handleShowEvent(false)} className="box-app-events">

                                </div>
                            </div>

                            : ""
                    }

                    <div className={"app-events " + (this.state.show_event ? "show" : '')}>
                        <Notifications notifications={notifications}/>
                    </div>


                    <ToastContainer/>
                    <div className='full-loading justify-content-center align-items-center'
                         style={{display: this.props.app.full_loading ? "flex" : "none"}}>
                        <img alt={'loading'} src={loading} height={150}/>
                    </div>

                    {
                        this.state.show_sidebar ?
                            <div onClick={() => this.toggleSideMenu(false)} className='togglemenu-over'></div> : ""
                    }

                    <div className={"n-app-sidebar " + (this.state.show_sidebar ? "show" : "")}>
                        <Sidebar darktheme={this.props.app.dark_theme} toggleSideMenu={(t) => this.toggleSideMenu(t)}
                                 user={user}/>
                    </div>
                    <main id="panel-wrapper">

                        <div className="n-app-main">

                            <div className="app-container">
                                <div className="top-header d-flex justify-content-between">
                                    <div className='d-flex align-items-center'>
                                        <button onClick={() => this.toggleSideMenu(true)}
                                                className={'toggle-menu d-block d-lg-none'}>
                                            <i dangerouslySetInnerHTML={{__html: feather.icons['menu'].toSvg()}}/>
                                        </button>

                                        <a href='https://cafearz.com' target='_blank' className="events ml-2">
                                            <i dangerouslySetInnerHTML={{__html: feather.icons['home'].toSvg()}}/>
                                        </a>
                                        <div onClick={() => this.handleShowEvent(true)} className="events ">
                                            <i dangerouslySetInnerHTML={{__html: feather.icons['bell'].toSvg()}}/>
                                            <span className="badge badge-danger ">{notifications.count}</span>
                                        </div>
                                    </div>
                                    <div>

                                        <a href={'tel:+9821-91014920'} className="phone float-right">
                                            <svg className="icon" dangerouslySetInnerHTML={{__html: useTag}}/>
                                            {this.props.app.phone}
                                        </a>

                                        <div className="p-switch ">
                                            <input checked={this.props.app.dark_theme}
                                                   onChange={(e) => this.handleChangeTheme(e)} type="checkbox"/>

                                            {
                                                this.props.app.dark_theme ? <i className='fe-sun '></i> :
                                                    <i className='fe-moon '></i>
                                            }

                                        </div>
                                    </div>
                                </div>

                                <section className="main">
                                    <div className="d-block d-lg-none">
                                        <MessageFromAdmin user={user}/>
                                    </div>

                                    <Breadcrumbs/>

                                    <Switch>
                                        <Route path={routes.register_finish} component={RegisterFinish}/>
                                        <Route path={routes.login}><Redirect to='/'/></Route>
                                        <Route path={routes.login_pass}><Redirect to='/'/></Route>
                                        <Route path={routes.register}><Redirect to='/'/></Route>
                                        <Route path={routes.forgot_password}><Redirect to='/'/></Route>
                                        <Route path={routes.reset_password}><Redirect to='/'/></Route>
                                        <Route path={routes.two_factor_authenticator}><Redirect to='/'/></Route>

                                        <Route exact path={routes.home} component={Dashboard}/>
                                        <Route path={routes.logout} component={Logout}/>
                                        <Route path={routes.switchLogout} component={swichUserLogout}/>
                                        <Route path={routes.profile_edit} component={UserEdit}/>
                                        <Route path={routes.profile_verify} component={UserVerify}/>
                                        <Route path={routes.profile_change_password} component={UserChangePass}/>
                                        <Route path={routes.profile_credit_account} component={UserCreditAccount}/>
                                        <Route path={routes.profile_security} component={UserSecurity}/>
                                        <Route path={routes.profile_ibans} component={UserIbans}/>
                                        <Route path={routes.profile_bank_credits} component={UserBankCards}/>
                                        <Route path={routes.profile_vip} component={VipLayers}/>
                                        <Route path={routes.profile_logins} component={LoginInfo}/>

                                        <Route path={routes.finance_payment_id} component={FinancePaymentId}/>
                                        <Route path={routes.finance_withdraw} component={FinanceWithdraw}/>
                                        <Route path={routes.finance_add_fund} component={FinanceAddfund}/>
                                        <Route path={routes.finance_transactions} component={FinanceTransactions}/>
                                        <Route path={routes.finance_checkout_id} component={Checkout}/>
                                        <Route path={routes.finance_callback} component={CallbackBank}/>
                                        <Route path={routes.finance} component={FinanceList}/>

                                        <Route path={routes.ticket_create} component={TicketCreate}/>
                                        <Route path={routes.ticket_show_id} component={TicketShow}/>
                                        <Route path={routes.ticket} component={TicketList}/>

                                        <Route path={routes.comments_create} component={CommentCreate}/>
                                        <Route path={routes.comments} component={CommentsList}/>
                                        <Route path={routes.referrals} component={Referals}/>

                                        <Route path={routes.rewards} component={Rewards}/>

                                        <Route path={routes.service_items_id} component={ServiceItems}/>
                                        <Route path={routes.orders_crypto} component={Crypto}/>
                                        <Route path={routes.orders_service} component={OrdersServices}/>
                                        <Route path={routes.orders_new_id} component={OrdersNew}/>
                                        <Route path={routes.orders_edit_id} component={OrderEdit}/>
                                        <Route path={routes.orders_show_id} component={OrderShow}/>

                                        <Route path={routes.orders} component={OrdersList}/>

                                        <Route path={routes.cash_crypt_pay_id} component={PayCrypto}/>
                                        <Route path={routes.cash_new_id} component={CashincomeCreate}/>
                                        <Route path={routes.cash_show_id} component={CashincomeShow}/>
                                        <Route path={routes.cash_pay_id} component={CashincomePay}/>
                                        <Route path={routes.cash_callback} component={CashincomeCallback}/>
                                        <Route path={routes.cash_list} component={CashincomeList}/>


                                        <Route path={routes.notification_show} component={NotificationShow}/>
                                        <Route path={routes.notifications} component={NotificationsList}/>
                                        <Route path={routes.prices} component={Prices}/>
                                        <Route path={routes.switch} component={swichUser}/>

                                        <Route component={NoMatch}/>
                                    </Switch>


                                </section>

                                <footer className="panel-footer">
                                    <div className=" d-flex justify-content-between align-items-center">

                                        <div className="footer-links">
                                            <a className='' href="https://cafearz.com/terms">شرایط و قوانین</a>
                                            <a href="https://cafearz.com/faq">سوالات متداول</a>
                                            <a href="https://cafearz.com/blog">اخبار و مقالات</a>
                                            <a className='d-none d-md-inline-block' href="https://cafearz.com/contact">تماس
                                                با ما</a>
                                            <a className='d-none d-md-inline-block' href="https://cafearz.com/about">درباره
                                                ما</a>
                                        </div>
                                        <div>
                                            <img className='d-block m-auto logo' height={40} alt={'cafearz'}
                                                 src='/assets/images/logo/new/arm.svg'/>
                                        </div>
                                    </div>

                                </footer>

                            </div>
                        </div>
                    </main>

                    <ReactTooltip/>
                </React.Fragment>

        );
    }
}

const mapStateToProps = (state) => {

    return {
        user: state.user.info,
        app: state.app,
    }
}

export default connect(mapStateToProps)(withRouter(Panel));



