import types from "../actions/types";

const appState = {
    full_loading: 1,
    redirect: null,
    dark_theme: localStorage.hasOwnProperty('dark_theme')?(parseInt(localStorage.getItem('dark_theme'))):1,
    fastAccess:false,
    crypto_base_exchanger:null,
    crypto_active_exchangers:null,
    base_exchanger:false,
    phone:'021-91014920',
    email:'info@cafearz.com',
    app_version:0,
    show_sidebar:false
};

export const app = (state = appState, action) => {
    switch (action.type) {
        case types.APP_HIDE_LOADER:
            return { ...state,
                full_loading: false };
        case types.APP_REDIRECT:
            return { ...state,
                redirect: action.redirect };
        case types.APP_SHOW_LOADER:
            return { ...state,
                full_loading: true };
        case types.APP_CHANGE_THEME:
            return { ...state,
                dark_theme: action.dark_theme };
        case types.APP_SHOW_FAST_ACCESS:
            return { ...state,
                fastAccess: action.fastAccess };
        case types.APP_BASE_EXCHANGER:
            return { ...state,
                crypto_base_exchanger: action.crypto_base_exchanger };
        case types.APP_ACTIVE_EXCHANGERS:
            return { ...state,
                crypto_active_exchangers: action.crypto_active_exchangers };
        case types.APP_VERSION:
            return { ...state,
                app_version: action.app_version };

        case types.APP_SET_PHONE_EMAIL:
            return { ...state,
                phone: action.phone,email: action.email, };
        case types.APP_SOW_EVENT:
            return { ...state,
                show_sidebar: action.show_sidebar };
        default :
            return state;
    }
}