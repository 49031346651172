import React from 'react';
import {connect} from "react-redux";
import {updatePageTitle} from "../../actions/breadcrumbs";
import Form from "../../ubold/commons/form";
import http from "../../services/httpService";
import Layout from "./Layout";
import FormLoader from "../../ubold/commons/FormLoader";
import SecurityForm from "./forms/SecurityForm";
import jwtDecode from "jwt-decode";
import UserVerifyLoading from "../../components/loadings/UserVerifyLoading";
import UserSecurityLoading from "../../components/loadings/UserSecurityLoading";


class UserSecurity extends Form {

    constructor(props) {
        super(props);
        this.state = {
            loadingForm: true,
            data: {},
            user:null
        };

        this.props.dispatch(updatePageTitle(
            'امنیت حساب کاربری',
            [
                {
                    'title': 'امنیت حساب',
                    'active': true
                }
            ]
        ));
    }

    componentDidMount() {
       this.reloadUser();
    }

    reloadUser=()=>{
        this.setState({
            loadingForm: true
        });
        http.post('/user/get-info?security=1').then(res => {
            const data= jwtDecode(res.data);

            this.setState({
                data: {
                    tfa_type: data.user.tfa_type,
                    totp:''
                },
                user:data.user,
                tfa_types:data.tfa_types,
                qrcode:data.qrcode,
                secret:data.secret,
                secret_encrypt:data.secret_encrypt,
                loadingForm: false
            });

        });
    }

    render() {

        return (
            <Layout>
                    {
                        this.state.loadingForm ?
                            <UserSecurityLoading/>
                            :
                                <SecurityForm reloadUser={this.reloadUser} secret_encrypt={this.state.secret_encrypt} qrcode={this.state.qrcode} secret={this.state.secret} tfa_types={this.state.tfa_types} data={this.state.data} user={this.state.user} />


                    }
            </Layout>
        )
    }

}


const mapStateToProps = appState => {
    return {
        user: appState.user.info
    }

};
export default connect(mapStateToProps)(UserSecurity);
