import React from 'react';
import Form from "../../../../ubold/commons/form";
import CreditUploadForm from "./CreditUploadForm";
import Alert from "../../../partials/Alert";
import HeaderForm from "./HeaderForm";
import LoadingReload from "./LoadingReload";


class step4 extends Form {
    constructor(props) {
        super(props);
        if (this.props.verification.is_verify) {
            this.props.onChangeStep(this.props.currentStep)
        }
    }

    render() {
        const {user, verification} = this.props;

        return (
            <React.Fragment>
                <div className="row justify-content-center mt-5">
                    <div className="col-xl-10 col-lg-12 ">
                        <div className="box">
                            <HeaderForm closepopup={() => this.props.closepopup(this.props.verification.key, false)}
                                        icon={'/assets/images/sprite-ver.svg#CREDIT'}
                                        title={'ارسال سلفی و تعهدنامه'} k={this.props.verification.key}/>

                            <div style={{position: 'relative'}}>
                                {
                                    this.props.loadingreload ?
                                        <LoadingReload/>
                                        : ''
                                }

                                {
                                    (verification && verification.rejected_message && verification.status === 'REJECTED')
                                        ?
                                        <Alert type={'danger'} title={'دلیل رد شدن تصویر سلفی و تعهدنامه '}>
                                            {verification.rejected_message}
                                        </Alert>
                                        : ''
                                }

                                {(verification || (!verification && verification.status === 'REJECTED')) ?
                                    <CreditUploadForm verification={verification}
                                                      reloadUser={(load) => this.props.reloadUser(load)} user={user}/>

                                    : ''}
                            </div>

                        </div>
                    </div>
                </div>


            </React.Fragment>
        )
    }

}


export default step4;