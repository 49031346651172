import React from "react";

function handleChangeSchema(callback, type) {
    callback('company_address', 'آدرس سایت واریز کننده', false);
}

const WebmoneyAccount = ({
                     valueAccountType,
                     valueCompanyAddress,
                     errorAccountType,
                     errorCompanyAddress,
                     onChange,
                     changeSchema
                 }) => {

    return (
        <React.Fragment>

            <div className="form-group  form-control-select" style={{height: ' auto !important'}}>
                <label className="form-label ml-3" htmlFor="f1">
                    نوع حساب واریز کننده :
                    <span className="label-require">*</span>
                </label>
                &nbsp; &nbsp;
                <label>
                    شخصی

                    <input onClick={() => handleChangeSchema(changeSchema, 'personal')} onChange={onChange}
                           checked={valueAccountType === 'personal'} type="radio" name="account_type" value="personal"/>
                </label> &nbsp;
                <label>
                    شرکتی
                    <input onClick={() => handleChangeSchema(changeSchema, 'business')} onChange={onChange} type="radio"
                           checked={valueAccountType === 'business' || !valueAccountType} name="account_type"
                           value="business"/>
                </label>
                <div>
                    {errorAccountType && <small className="font-11 error-label">{errorAccountType}</small>}
                </div>
                <br/>
                <div style={{display: (valueAccountType === 'business' ? 'block' : 'none')}} id="account-info"
                     className="">
                    <div className="form-group ">
                        <label className="form-label form-label--left" htmlFor="f1">
                            آدرس سایت واریز کننده
                            <span className="label__required">*</span>
                        </label>
                        <input onChange={onChange} name='company_address'
                               value={valueCompanyAddress ? valueCompanyAddress : ''} className='form-control english'/>
                        {errorCompanyAddress && <small className="font-11 error-label">{errorCompanyAddress}</small>}
                    </div>

                </div>
            </div>

        </React.Fragment>
    );
};

export default WebmoneyAccount;
