import React from 'react';
import {updatePageTitle} from "../actions/breadcrumbs";
import http from "./../services/httpService";
import FormLoader from "../ubold/commons/FormLoader";
import {connect} from "react-redux";
import $ from "jquery";
import DigitalPrices from "./partials/prices/DigitalPrices";
import ElectronicPrices from "./partials/prices/ElectronicPrices";
import Pagination from "../ubold/commons/pagination";
import jwtDecode from "jwt-decode";
import PricesLoading from "../components/loadings/PricesLoading";

class Prices extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingForm: true,
            services: {},
            total:1,
            type: 'digital',
            currentPage:1,
            last_page:1,
            search:'',
            cat:null
        }

        this.props.dispatch(updatePageTitle(
            'لیست قیمت ارزها',
            {},
        ));
    }

    handleGetDataWithType = (type,search='') => {
        if(type==='digital')
            this.getDigitalPrice(search);
        else
            this.getElectronicPrice();
    }

    getDigitalPrice(search='') {
        this.setState({loadingForm:true})

        http.post('/prices/digital')
            .then(response => {

                var allCoins=[];
                for (const [key, value] of Object.entries(response.data.coins)) {
                    allCoins.push(value);
                }

                this.setState({
                    services:allCoins,
                    coinsBackup:allCoins,
                    loadingForm:false,
                    total:allCoins.length,
                    cat:response.data.cat
                });

                $('html, body').animate({
                    scrollTop: 0
                }, 600);
        });
    }

    getElectronicPrice() {
        this.setState({loadingForm:true,last_page:1,currentPage:1})

        http.post('/prices/electronic')
            .then(response => {
                this.setState({
                    services:jwtDecode(response.data.services),
                    loadingForm:false
                })
        });
    }

    handleSearch=(input)=> {
        var coins=this.state.coinsBackup;
        if(input.target.value){
            coins = coins.filter(coin => {
                let regex = new RegExp(input.target.value.toUpperCase(), 'i'); 	// Create a regex object (a constructor).
                if(regex.test(coin.symbol))
                    return true;
                else if(regex.test(coin.name))
                    return true;
                else if(regex.test(coin.title))
                    return true;
                else
                    return false;
            } );
        }

        else
            coins=this.state.coinsBackup;

        this.setState({services:coins,search:input.target.value});

    }

    componentDidMount() {
        this.handleGetDataWithType(this.state.type)
    }

    changePriceType = (type) => {
        this.setState({type: type});
        this.handleGetDataWithType(type);
    }

    componentDidUpdate() {
        $(".lazy-box img").prepend(function () {
            $(this).attr('src', $(this).data('src'));
            $(this).closest('.lazy-box').removeClass('lazy-box');
        });

        $(".lazy-img").prepend(function () {
            $(this).attr('src', $(this).data('src'));
        });
    }

    handlePageChange = page => {
        if (page === 'next')
            this.setState({currentPage: this.state.currentPage+1},()=> this.handleGetDataWithType(this.state.type));
        else if (page === 'prev')
            this.setState({currentPage: this.state.currentPage-1},()=> this.handleGetDataWithType(this.state.type));
        else
            this.setState({currentPage: page},()=> this.handleGetDataWithType(this.state.type));
    };

    render() {
        const {last_page, currentPage,cat } = this.state;

        return (

            <React.Fragment>
                <div className="link-box big mb-4 order">
                    <button  onClick={() => this.changePriceType('digital')}
                       className={"order-btn " + (this.state.type === 'digital' ? 'active' : '')}>  ارزهای دیجیتال</button>
                    <button  onClick={() => this.changePriceType('electronic')}
                       className={"order-btn " + (this.state.type === 'electronic' ? 'active' : '')}> دیگر ارزها </button>
                </div>
                {
                    this.state.loadingForm ?
                        <PricesLoading/>
                        :
                        <>
                            <div className="card digital-prices" id="digital_currencies">

                                {this.state.type==='digital'?
                                    <DigitalPrices cat={cat} total={this.state.total} search={this.state.search} handleSearch={this.handleSearch} services={this.state.services}/>
                                        :
                                    <ElectronicPrices services={this.state.services}/>
                                }

                            </div>

                            <Pagination
                                total={last_page}
                                currentPage={currentPage}
                                onPageChange={this.handlePageChange}
                            />
                        </>
                }



            </React.Fragment>

        )
    }

}


export default connect()(Prices)
