import React from 'react';
import {sendVerifyCode, updateUserInStore} from "../../../../modules/user/userService";
import ButtonLoading from "../../../../ubold/commons/ButtonLoading";
import Form from "../../../../ubold/commons/form";
import Alert from "../../../partials/Alert";
import {connect} from "react-redux";
import UpdateInfo from "../UpdateInfo";

class PhoneForm extends Form {

    sendRequest = async () => {
        this.setState({loading:true});
        try {
            await sendVerifyCode('PHONE').then(res=>{
                this.setState({loading:false});
                updateUserInStore(this.props.dispatch,res.new_user);
                this.props.reloadUser(false);
            });

        } catch (ex) {
            this.setState({loading:false});
        }
    };


    render() {
        const {verification,user}=this.props;

        return (
           verification.status==='PENDING'?
               <Alert type={'warning'} >
                   درخواست تایید تلفن ثابت شما با موفقیت ثبت شد،تا دقایقی دیگر کارشناسان ما به  شماره <strong>{user.phone}</strong> تماس خواهد گرفت لطفا منتظر باشید.

               </Alert>
               :
               (
                   user.phone ?
                       <React.Fragment>
                           <ButtonLoading icon='fe-send' sn={this.sendRequest} classname='btn btn-block btn-primary btn-send-request' show={this.state.loading}>
                               درخواست تایید تلفن
                           </ButtonLoading>
                       </React.Fragment>
                       :
                       <>
                           <UpdateInfo doAction={(load)=>this.props.reloadUser(load)} provinces={this.props.provinces} user={user} verifications={verification}/>
                       </>
               )

       );
    }

}

export default connect()(PhoneForm);
