import React from 'react';
import Squre from "../skelete/Squre";
import SkeleteLoading from "../SkeleteLoading";
import Table from "../skelete/Table";

class UserVipLoading extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className="mt-4">
                    <SkeleteLoading>
                        <div className="row mb-3 ver-list">
                            <div className="col-4 ">
                                <div style={{transform:'scale(0.9)'}} className="">
                                    <Squre height={400}/>
                                </div>
                            </div>
                            <div className="col-4 ">
                                <div className="">
                                    <Squre height={400}/>
                                </div>
                            </div>
                            <div className="col-4 ">
                                <div style={{transform:'scale(0.9)'}} className="">
                                    <Squre height={400}/>
                                </div>
                            </div>
                        </div>
                    </SkeleteLoading>
                </div>
            </React.Fragment>
        );
    }
}

export default UserVipLoading;
