import React from 'react';
import {connect} from "react-redux";
import {updatePageTitle} from "../../actions/breadcrumbs";
import Form from "../../ubold/commons/form";
import FormLoader from "../../ubold/commons/FormLoader";
import {spilitNumber} from "../../services/helpers";
import axios from "axios";
import $ from "jquery";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import jwtDecode from "jwt-decode";
import {getReferralUsers} from "./userService";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import ReferralsTable from "./ReferralsTable";
import Pagination from "../../ubold/commons/pagination";
import FormLoading from "../../ubold/commons/FormLoader";
import ReferralData from "./ReferralData";
import RefferalLoading from "../../components/loadings/RefferalLoading";

class Referals extends Form {
    constructor(props) {
        super(props);
        this.state = {
            loadingForm: true,
            user: {},
            copied_code:false,
            copied_link:false,
            value_code:false,
            value_link:false,
            total: 1,
            last_page: 1,
            sortColumn: {path: "", order: ""},
            users: [],
            modal: false,
            loadingModal: true,
        };

        this.props.dispatch(updatePageTitle(
            'همکاری در فروش',
            [
                {
                    'title': 'همکاری در فروش و کسب در آمد',
                    'active': true
                }
            ]
        ));
    }

    componentDidMount() {
        axios.post('/user/get-info?referal=1').then(res=>{
            const data= jwtDecode(res.data);
            this.setState({
                user:data.user,
                loadingForm:false,
                value_code:data.user.referralCode,
                value_link:data.user.getReferralLink
            })
        });

    }

    getUsers = () =>{
        this.setState({
            loadingModal: true,
        });

        getReferralUsers(this.state.currentPage).then(res=>{
            const users=jwtDecode(res.data.data);

            this.setState({
                total: res.data.pagination.total,
                last_page: res.data.pagination.last_page,
                users: users,
                loadingModal: false,
                // modal: true
            });
        });
    }



    toggleModal = (getUser=false) => {
        if(getUser===true){
            this.getUsers()
            this.setState({modal: !this.state.modal});
        } else{
            this.setState({modal: !this.state.modal});
        }
    }

    handlePageChange = page => {
        if (page === 'next')
            this.setState({currentPage: this.state.currentPage + 1}, () => this.getUsers());
        else if (page === 'prev')
            this.setState({currentPage: this.state.currentPage - 1}, () => this.getUsers());
        else
            this.setState({currentPage: page}, () => this.getUsers());

    };

    render() {
        const {last_page,  currentPage, users,sortColumn} = this.state;



        const count = users.length;
        const user= this.state.user;

        if(this.state.copied_code){
            $(".copied_code .copied").addClass('show');
            const me=this;

            setTimeout(function () {
                me.setState({copied_code:false});
                $(".copied_code .copied").removeClass('show');
            },1000);
        }

        if(this.state.copied_link){
            $(".copied_link .copied").addClass('show');
            const me=this;

            setTimeout(function () {
                me.setState({copied_link:false});
                $(".copied_link .copied").removeClass('show');
            },1000);
        }

        return (
          <React.Fragment>
              {
                  this.state.loadingForm ?
                      <div className='loader-container'>
                          <RefferalLoading/>
                      </div>
                      :
                      <React.Fragment>
                          <Modal isOpen={this.state.modal} toggle={this.toggleModal} className='credit '>
                              <ModalHeader toggle={this.toggleModal}> کاربران ارجاع شده توسط من</ModalHeader>
                                  <ModalBody>
                                      {this.state.loadingModal?
                                          <FormLoading/>
                                          :<>
                                              <ReferralsTable
                                                  users={users}
                                                  count={count}
                                                  sortColumn={sortColumn}
                                              />
                                              <Pagination
                                                  total={last_page}
                                                  currentPage={currentPage}
                                                  onPageChange={this.handlePageChange}
                                              />
                                          </>}

                                  </ModalBody>
                          </Modal>

                            <div className="referrals-page">
                                <div className="row justify-content-center">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <h4 className='h4-title'>
                                                            با معرفی دوستان و آشنایان خود به کافه ارز کسب درآمد کنید
                                                        </h4>

                                                        <p>
                                                            شما می توانید از طریق لینک ارجاع یا  (Referral) دوستان و آشنایان خود را به کافه ارز معرفی کنید و
                                                            &nbsp;                                                          <strong
                                                            style={{fontWeight: 800,fontSize: '16px'}}
                                                            className="text-danger">{user.referral_profit} درصد</strong>&nbsp;
                                                            از سود خالص سفارش های های آنها را به خود اختصاص دهید.
                                                        </p>
                                                    </div>
                                                </div>

                                            </div>


                                            <div className="col-12">
                                                <div className="card referral-code" style={{overflow:'hidden'}}>
                                                    <div className="card-body ">
                                                        <div className=" d-flex justify-content-between align-items-center">
                                                            <div style={{flexBasis: '85%'}}>
                                                                <div className="row">
                                                                    <div className="col-md-5">
                                                                        <h5 className='title-h5'>کد معرفی</h5>
                                                                    </div>
                                                                    <div className="col-md-7 ltr eng " style={{position: 'relative'}}>
                                                                        <div className="d-flex">
                                                                            <CopyToClipboard text={this.state.value_code}
                                                                                             onCopy={() => this.setState({copied_code: true})}>
                                                                                <button  title={'کپی'} className={'btn-copy copied_code'}><i className='fe-copy'></i>
                                                                                    <span className="copied">کپی شد</span>
                                                                                </button>
                                                                            </CopyToClipboard>

                                                                            <h5 className={'ref-code'}><strong>{user.referralCode}</strong></h5>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <hr/>
                                                                <div className="row">
                                                                    <div className="col-md-5">
                                                                        <h5 className='title-h5'>لینک ارجاع (Referral Link)</h5>
                                                                    </div>
                                                                    <div className="col-md-7 ltr eng" style={{position: 'relative'}}>
                                                                        <CopyToClipboard text={this.state.value_link}
                                                                                         onCopy={() => this.setState({copied_link: true})}>
                                                                            <button  title={'کپی'} className={'btn-copy copied_link'}><i className='fe-copy'></i>
                                                                                <span className="copied">کپی شد</span>
                                                                            </button>
                                                                        </CopyToClipboard>
                                                                        <strong className='ref-code'> {user.getReferralLink}</strong>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={' ltr'}>
                                                                <img src='/assets/images/app/referral.svg' height={80} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>



                                            <div className="col-md-6 col-xl-6">
                                                <div className="card" style={{cursor:'pointer'}} onClick={()=>this.toggleModal(true)}>
                                                    <div className="card-body">
                                                        <h4 className="mt-0 card-title font-16">کاربران ارجاع شده توسط من</h4>
                                                        <h2 className="text-primary my-3 text-center"><span
                                                            data-plugin="counterup">{spilitNumber(user.referredCount)}</span>
                                                        </h2>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col-md-6 col-xl-6">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <h4 className="mt-0 card-title font-16">مجموع پورسانت ها</h4>
                                                        <h2 className="text-primary my-3 text-center"><span
                                                            data-plugin="counterup">{spilitNumber(user.commission)}</span>
                                                            &nbsp;
                                                            <small>تومان</small></h2>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>



                                    </div>
                                </div>

                            </div>

                          <ReferralData/>

                      </React.Fragment>
              }
          </React.Fragment>
        )
    }

}


export default connect()(Referals);
