import http from "./../../services/httpService";

export function all(currentPage) {
    return http.submit('post',  '/comments/list', {page:currentPage});
}

export function create(data) {
    return http.submit('post',  '/comments/create', data);
}

