import http from "./../../services/httpService";

export function allNotifications(currentPage,type,no_read=false) {
    return http.post( '/notifications?type='+type+(no_read?'?no_read=1':''),{page:currentPage});
}

export function getNotify(id,type) {
    return http.submit('post',  '/notifications/'+type+'/'+id);
}

export function readNotify(id,type) {
    return http.submit('post',  '/notifications/'+type+'/'+id);
}