import React from 'react';
import {connect} from "react-redux";
import {formatMoney,spilitNumber} from "../../../services/helpers";
import Alert from "../../partials/Alert";
import {Link} from "react-router-dom";
import routes from "../../../routes";
import Form from "../../../ubold/commons/form";
import Joi from "joi-browser";
import {settlement} from "../CashincomeService";
import SweetAlert from "react-bootstrap-sweetalert";
import {toast} from "react-toastify";

class Invoice extends Form {
    constructor(props) {
        super(props);

        this.state = {
            data: {
                tx_id: '',
                service:null
            },
            errors:{},
            loadingForm: true,
            redirect:null,
            alert: ''
        };

        const cash=this.props.cash;

        this.schema = {
            user_attachment: Joi
                .any()
                .label("فایل پیوست"),
            user_desc: Joi
                .any()
                .label("توضیحات"),
        };

        if(cash.service_model.type==='UTOPIA'){
            this.schema['tx_id']=Joi.string().required().label("شماره تراکنش tx_id");
        }

    }

    doSubmit = async () => {
        try {
            var data = this.state.data;
            data['service']=this.props.cash.service;

            const FData = new FormData();

            Object.keys(data).forEach(item => {
                if (item !== 'user_attachment')
                    FData.append(item, data[item]);
            });

            if (document.getElementById('user_attachment') && document.getElementById('user_attachment').files.length > 0) {
                Array.from(document.getElementById('user_attachment').files).forEach(file => {
                    FData.append('user_attachment[]', file);
                });
            }
            this.toggle();
            this.hideAlert();

            settlement(this.props.cash.id,FData?FData:data,{headers: {
                    'content-type': 'multipart/form-data'
                }}).then(res => {
                this.toggle();
                toast.success('درخواست شما ثبت شد، لطفا شکیبا باشید');
                this.props.onReload();
            }).catch(res => {
                console.log(res)
                this.toggle();
            });

        } catch (ex) {
            console.log(ex)
            this.toggle();
        }
    };

    getAlert() {
        return <SweetAlert
            warning
            showCancel
            confirmBtnText="بله, مطمئنم!"
            cancelBtnText="خیر"
            confirmBtnBsStyle="danger"
            title="توجه!"
            onConfirm={() => this.doSubmit()}
            onCancel={this.hideAlert}
            focusCancelBtn
        >
            آیا میزان ارز مورد نظر را به حساب ما منتقل کرده اید ؟
        </SweetAlert>
    }

    handleSubmitForm = () => {
        this.setState({
            alert: this.getAlert()
        })
    }

    hideAlert = () => {
        this.setState({
            alert: null
        });
    }

    handleSubmit = e => {
        e.preventDefault();
        const errors = this.validate();

        this.setState({errors: errors || {}});

        if (errors) return;

        this.handleSubmitForm()
    };


    render() {
        const cash=this.props.cash;

        return (
            <React.Fragment>
                {this.state.alert}

                <div style={{paddingBottom: '30px !important;'}} className="card card-body cashincomes pb-3 ">
                    <div className="head d-flex mb-3 align-item-center">
                        <h5 className="d-inline-block m-0">
                            فاکتور درخواست شماره #{cash.id}
                        </h5>
                    </div>

                    <div className='rec d-flex justify-content-between'>
                        <div className="title">
                            میزان ارز
                            &nbsp;
                            {
                                cash.is_crypto?
                                    <strong className="kt-font-danger"> ( معادل دلار تتر)</strong>
                                    :''
                            }
                        </div>
                        <div className="value">
                            {
                                cash.is_crypto?
                                    (
                                        cash.service_type!=='TETER'?
                                            <td>{formatMoney(cash.dollar_amount)}</td>
                                            :
                                            <td>{formatMoney(cash.amount)}</td>
                                    )
                                    :
                                    <td>{formatMoney(cash.amount)}</td>
                            }
                        </div>
                    </div>
                    <div className='rec d-flex justify-content-between'>
                        <div className="title">
                            فی
                        </div>
                        <div className="value">
                            {formatMoney(cash.fee?cash.fee:0)}
                        </div>
                    </div>

                    {
                        ((cash.is_crypto && cash.is_crypto==='TETER') || !cash.is_crypto)?
                        <div className='rec d-flex justify-content-between'>
                            <div className="title">
                                میزان خالص دریافتی
                                {cash.is_crypto?<strong className="kt-font-danger"> ( معادل تتر)</strong>:''}

                            </div>
                            <div className="value">
                                {formatMoney(cash.receive_amount)}
                            </div>
                        </div>
                        :''
                    }

                    {
                        cash.is_crypto && cash.is_crypto!=='TETER'?
                            <div className='rec d-flex justify-content-between'>
                                <div className="title">
                                    میزان خالص دریافتی

                                </div>
                                <div className="value">
                                    {formatMoney(cash.meta.receive_amount_crypto?cash.meta.receive_amount_crypto:0,8)}
                                </div>
                            </div>
                            :''
                    }
                    <div className='rec d-flex justify-content-between'>
                        <div className="title">
                            نوع ارز

                        </div>
                        <div className="value">
                            {cash.currency_name}
                        </div>
                    </div>

                    <div className='rec d-flex justify-content-between'>
                        <div className="title">
                            نرخ خرید
                            {
                                (cash.is_crypto && cash.service_type!=='TETER')?<strong className="kt-font-danger"> ( تتر)</strong>:''
                            }
                        </div>
                        <div className="value">
                            {
                                cash.meta&& cash.meta.usdt_currency_price?
                                <strong className="kt-font-danger">
                                    {formatMoney(cash.meta.usdt_currency_price)}
                                </strong>
                                :
                                formatMoney(cash.currency_price)
                            }

                            <small className='d-inline-block  mr-1'>تومان</small>
                        </div>
                    </div>

                    {
                        cash.meta && cash.meta.networkName?
                            <div className='rec d-flex justify-content-between'>
                                <div className="title">
                                    شبکه
                                </div>
                                <div className="value">
                                    {
                                        cash.meta.networkName
                                    }
                                </div>
                            </div>
                            :''
                    }

                    <div className='rec big d-flex justify-content-between'>
                        <div className="title">
                            مجموع درآمد
                            {
                                (cash.status!=='DONE' &&  cash.status!=='SETTLEMENT') ?' نسبی ':''
                            }
                        </div>
                        <div className="value">
                            {spilitNumber(cash.calc_total)} <small className='d-inline-block  mr-1'>تومان</small>
                        </div>
                    </div>


                    <div style={{marginBottom:'-30px'}} className={"mt-3 " + ((cash.status==='DONE'|| cash.status==='REJECTED')?'d-none':'')} >
                        <Alert type={'info'} title={'توجه کنید'}>
                            {
                                (cash.receive_amount===0 && cash.service_is_auto)?
                                    <div>
                                        با توجه به بازه زمانی متفاوت در انتقال ارزهای دیجیتال در شبکه بلاکچین، مجموع درآمد
                                        شما پس ارسال به کیف ما بر اساس نرخ در لحظه دریافت محاسبه و تسویه خواهد شد
                                    </div>
                                    :
                                    <div >
                                        مجموع درآمد شما ممکن است پس از انتقال پول و صدور فاکتور جدید تغییر کند، به این دلیل
                                        که در انتقال پول، ممکن است مبلغ کارمزد انتقال از مبلغ کلی کسر گردد و ما خالص دریافتی
                                        را با شما حساب می کنیم.
                                    </div>

                            }
                        </Alert>

                    </div>

                    <br/>
                </div>

                {cash.status === 'PAYING' && !cash.is_crypto ?
                    <>
                        <div className="form-group mt-3">
                            <form method="POST" onSubmit={this.handleSubmit}>


                                {
                                    cash.service_model.type==='UTOPIA'?
                                    this.renderInput('tx_id','شماره تراکنش tx_id'):
                                        this.renderInput('user_attachment','فایل پیوست','file')
                                }
                                {this.renderButton(<span>
                                    <i className="fe-bell d-inline-block ml-1 mr-1"></i>
                                    اعلام پرداخت
                                    </span>,'btn btn-block btn-primary btn-lg')}

                            </form>
                        </div>


                    </>



                    : ''
                }

                <div className="mt-3">
                    {
                        cash.has_gateway_payment?
                            <Link to={routes.cash_pay+ cash.id} className='btn btn-primary btn-block btn-lg btn-xs'><i className='fe-shopping-bag'></i> برای انتقال ارز کلیک کنید </Link>
                            :''
                    }
                </div>





            </React.Fragment>

        )
    }

}

export default connect()(Invoice);
