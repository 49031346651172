import React from 'react';
import {updatePageTitle} from "../../actions/breadcrumbs";
import {connect} from "react-redux";
import Form from "../../ubold/commons/form";
import Joi from "joi-browser";
import {create} from "../../modules/comments/CommentService";
import {Redirect} from "react-router-dom";
import FormLoader from "../../ubold/commons/FormLoader";
import  {getCurrentUser} from "../../modules/auth/authService";
import routes from "../../routes";
import CommentsCreateLoading from "../../components/loadings/CommentsCreateLoading";

class CommentCreate extends Form {
    constructor(props) {
        super(props);
        const user=getCurrentUser();
        this.state = {
            data: {
                model_type:'Home',
                model_id:0,
                parent:0,
                email:user.email,
                name:user.name,
                company:'',
                content:'',
                captcha:'',
            },

            errors: {},
            departments: [],
            priorities:[],
            loading: false,
            loadingForm: false,

            redirect:false
        };

        this.schema = {
            model_type:Joi.label("نوع دیدگاه"),
            model_id:Joi.label("شماره نوع دیدگاه"),
            parent:Joi.label("پدر"),
            email: Joi
                .string()
                .required()
                .label("ایمیل"),
            company: Joi.label("کمپانی یا شغل"),
            name: Joi
                .string()
                .required()
                .label("نام و نام خانوادگی "),
            content: Joi.string()
                .required().label("محتوا"),

        };

        this.props.dispatch(updatePageTitle(
            'ثبت دیدگاه جدید',
            [
                {
                    'title': 'لیست دیدگاه ها',
                    'link': '/comments'
                },
                {
                    'title': 'ثبت دیدگاه جدید',
                    'active': true
                }
            ]
        ));
    }



    doSubmit = async () => {
        try {
            create(this.state.data).then(res => {
                this.toggle();
                this.setState({
                    redirect:routes.comments
                })
            }).catch(res => {
                this.toggle();
            });

        } catch (ex) {
            this.toggle();
        }
    };


    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        return (

            <React.Fragment>
               <div className="row mt-4 justify-content-center">
                   <div className="col-lg-10 col-md-11">
                       {
                           this.state.loadingForm ?
                               <CommentsCreateLoading/>
                               :
                               <div className="card card-body   mb-5">
                                   <h5 className="card-title mb-5 ">فرم ثبت دیدگاه</h5>

                                   <React.Fragment>
                                       <div className="row justify-content-center ">
                                           <div className="col-xl-10 col-lg-12">

                                               <form method="POST" onSubmit={this.handleSubmit}>

                                                   {
                                                       this.renderInput('company', 'کمپانی یا شغل (اختیاری)', 'text', '', '', '', true)
                                                   }

                                                   {
                                                       this.renderTextArea('content', 'محتوا', '', true)
                                                   }

                                                   {this.renderButton("ارسال دیدگاه", '', true)}
                                               </form>
                                           </div>
                                       </div>
                                   </React.Fragment>
                               </div>
                       }
                   </div>
               </div>
            </React.Fragment>
        )
    }

}

export default connect()(CommentCreate);
