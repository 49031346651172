import React from 'react';
import Form from "../../../ubold/commons/form";
import ButtonLoading from "../../../ubold/commons/ButtonLoading";
import Joi from "joi-browser";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {create, edit ,getBankCodes, update} from "../../../modules/user/BankCardService";
import SweetAlert from "react-bootstrap-sweetalert";
import {hideAppLoader, showAppLoader} from "../../../actions/app";
import http from "../../../services/httpService";
import {connect} from 'react-redux';
import {toast} from "react-toastify";
import $ from "jquery";
import "../../../assets/css/banks.css";
import InputMask from "react-input-mask";
import BankCardItem from "./BankCardItem";
import jwtDecode from "jwt-decode";
import DataBox from "../../partials/DataBox";
import CompleteInformations from "../completeInformations";
import UserCheckLegal from "../UserCheckLegal";

class BankIbanForm extends Form {

    constructor(props) {
        super(props);

        this.cardNumberRef = React.createRef();

        this.state = {
            cards: this.props.data,
            edit_id: null,
            searchQuery: "",
            sortColumn: {path: "", order: ""},
            data: {},
            errors: {},
            loading: false,
            modal: false,
            bankCodes: [],
            alert: ''
        };

        this.schema = {
            sheba: Joi.string()
                .required()
                .label("شماره شبا ")
        };

    }

    getAlert(id,isTasvie) {
        return <SweetAlert
            warning
            showCancel
            confirmBtnText="بله, مطمئنم!"
            cancelBtnText="خیر"
            confirmBtnBsStyle="danger"
            title="توجه!"
            onConfirm={() => this.submitDelete(id,isTasvie)}
            onCancel={this.hideAlert}
            focusCancelBtn
        >
            آیا مطمئن به حذف این شماره شبا هستید ؟
        </SweetAlert>
    }

    handleDelete = (id,isTasvie) => {
        this.setState({
            alert: this.getAlert(id,isTasvie)
        })
    }

    submitDelete=(id,isTasvie)=>{
        this.hideAlert();
        this.props.ondelete(id,isTasvie);
    }

    hideAlert = () => {
        this.setState({
            alert: null
        });
    }

    handleSearch = query => {
        this.setState({searchQuery: query, currentPage: 1});
    };

    handleSort = sortColumn => {
        this.setState({sortColumn});
    };

    handlePageChange = page => {
        if (page === 'next')
            this.setState({currentPage: this.state.currentPage++}, () => this.getData());
        else if (page === 'prev')
            this.setState({currentPage: this.state.currentPage--}, () => this.getData());
        else
            this.setState({currentPage: page}, () => this.getData());

    };

    getPagedData = () => {
        const {
            searchQuery,
            cards
        } = this.state;

        let filtered = cards;
        if (searchQuery)
            filtered = cards.filter(m =>
                m.title.toLowerCase().startsWith(searchQuery.toLowerCase())
            );

        // const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

        return {totalCount: filtered.length, data: filtered};
    };

    toggleModal = () => {
        this.setState({modal: !this.state.modal});
    }

    doSubmit = async () => {

        try {
            const d=this.state.data;

            d['legal']='yes';
            const data = this.convertToFormData(d);

            if (this.state.edit_id)
                update(this.state.edit_id, data).then(res => {
                    this.setState({cards: jwtDecode(res.data.data), edit_id: null, data: {}});
                    toast.success(res.data.message);
                    this.toggle();
                    this.toggleModal();
                }).catch(res => {
                    this.toggle();
                });
            else
                create(data).then(res => {
                    this.setState({cards: jwtDecode(res.data), data: {}})
                    this.toggle();
                    this.toggleModal();
                }).catch(res => {
                    this.toggle()
                });

        } catch (ex) {
            this.toggle();
        }
    };

    editCredit = (id) => {
        try {
            this.props.dispatch(showAppLoader());

            edit(id).then(res => {
                let sheba=res.data.card.sheba;
                if(sheba){
                    sheba=res.data.card.sheba.replace(/IR/g,'');
                    sheba=sheba.replace(/ir/g,'');
                }

                this.setState({
                    data: {
                        sheba: sheba,
                    },
                    edit_id: res.data.card.id,

                });

                this.toggleModal();
                this.props.dispatch(hideAppLoader());

                this.keyUpCardNumber(this.cardNumberRef.current.props,7)

            })

        } catch (ex) {

        }
    }


    render() {
        const {data: cards} = this.getPagedData();
        const {data,errors} = this.state;

        return (
            <React.Fragment>
                {this.state.alert}

                <Modal isOpen={this.state.modal} toggle={this.toggleModal} className='credit '>
                    <ModalHeader toggle={this.toggleModal}> ثبت شماره شبا</ModalHeader>
                    <form method="POST" onSubmit={this.handleSubmit}>
                        <ModalBody>

                            <div className="form-group">
                                <div style={{position: 'relative'}}>
                                    <div className="bank-css-sprite "></div>
                                    {
                                        this.renderInput('sheba','شماره شبا','text','ltr en eng','',false,false,'','IRxxxxxxxxxxxxxxxxxxxxxx')
                                    }
                                </div>

                                {errors['sheba'] && <small className="font-11 error-label">{errors['sheba']}</small>}

                            </div>

                        </ModalBody>
                        <ModalFooter>
                            {this.renderButton(<span><i className='fe-upload-cloud'></i>  ارسال </span>, '')}
                        </ModalFooter>
                    </form>
                </Modal>

                <div className="row justify-content-center">
                       <div className="col-lg-6 col-md-8">
                           {
                               this.props.user.completeProfile==='no'?
                                   <div className="card card-body">
                                       <CompleteInformations user={this.props.user}/>
                                   </div>
                                   :
                                   !this.props.user.national_code?<UserCheckLegal/>:
                                   <>
                                       <DataBox data={cards}/>


                                       {cards.map((card, index) => (
                                           <div key={index} className='mb-3'>
                                               <BankCardItem key={index}
                                                             card={card}
                                                             onDelete={this.handleDelete}
                                                             deleteCredit={this.handleDelete}
                                                             editCredit={this.editCredit}
                                               />
                                           </div>
                                       ))}

                                       <button  onClick={this.toggleModal} className='btn btn-t btn-lg btn-block  mt-4 '
                                                disabled={this.state.loading}>
                                           افزودن شماره شبا
                                       </button>
                                   </>
                           }


                       </div>
                    </div>

            </React.Fragment>

        )
    }

}

const mapStateToProps = appState => {
    return {
        user: appState.user.info
    }

};
export default connect(mapStateToProps)(BankIbanForm);
