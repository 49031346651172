import React from 'react';
import {updatePageTitle} from "../../actions/breadcrumbs";
import {connect} from "react-redux";
import Pagination from "../../ubold/commons/pagination";
import {all} from "../../modules/tickets/TicketService";
import FormLoader from "../../ubold/commons/FormLoader";
import routes from "../../routes";
import {Link} from "react-router-dom";
import DataBox from "../partials/DataBox";
import TicketItem from "./TicketItem";
import jwtDecode from "jwt-decode";
import TicketListLoading from "../../components/loadings/TicketListLoading";
const feather = require('feather-icons')

class TicketList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPage: 1,
            total: 1,
            last_page: 1,
            searchQuery: "",
            sortColumn: {path: "", order: ""},
            tickets: [],
            loadingForm: true,
        };


        const user=this.props.user;

        this.props.dispatch(updatePageTitle(
            'تیکت ها',
            [
                {
                    'title': 'لیست تیکت ها',
                    'active': true
                }
            ],

            (
                <React.Fragment>
                    {user.alarms && user.alarms.tickets?
                        <React.Fragment>
                          <div className="d-none d-md-inline-block">
                              <button className='btn btn-default ml-2  btn-sm' onClick={()=>{this.getData(false)}}> همه</button>
                              <button className='btn btn-default ml-2  btn-sm' onClick={()=>{this.getData(true)}}>خوانده نشده <span className='badge badge-danger'>{user.alarms.tickets}</span></button>
                          </div>
                        </React.Fragment>
                        :''}

                    <Link className={'btn btn-primary primary btn-sm'} to={routes.ticket_create}>
                        <i dangerouslySetInnerHTML={{__html: feather.icons['plus'].toSvg()}}/>
                        ثبت تیکت جدید</Link>


                </React.Fragment>
            )
        ));
    }

    componentDidMount() {
        this.getData();
    }


    getData = (no_read=false) => {
        this.setState({
            loadingForm: true
        })

        all(this.state.currentPage,no_read).then(res => {
            this.setState({
                total: res.data.pagination.total,
                last_page: res.data.pagination.last_page,
                tickets: jwtDecode(res.data.data),
                loadingForm: false
            })
        })
    }

    handlePageChange = page => {
        if (page === 'next')
            this.setState({currentPage: this.state.currentPage + 1}, () => this.getData());
        else if (page === 'prev')
            this.setState({currentPage: this.state.currentPage - 1}, () => this.getData());
        else
            this.setState({currentPage: page}, () => this.getData());

    };

    getPagedData = () => {
        const {
            searchQuery,
            tickets
        } = this.state;

        let filtered = tickets;
        if (searchQuery)
            filtered = tickets.filter(m =>
                m.title.toLowerCase().startsWith(searchQuery.toLowerCase())
            );

        return {totalCount: filtered.length, data: filtered};
    };

    render() {

        const {last_page,  currentPage} = this.state;
        const {data: t} = this.getPagedData();
        const tickets = t.data;

        return (
            <React.Fragment>
                {
                    this.state.loadingForm ?
                        <TicketListLoading/>
                        :
                        <React.Fragment>
                            <DataBox data={tickets}/>

                            {tickets.map(ticket=>(
                                <div key={ticket.ticket_id} className='card card-body card-table'>
                                    <TicketItem key={ticket.ticket_id}
                                        ticket={ticket}
                                    />
                                </div>
                            ))}

                            <Pagination
                                total={last_page}
                                currentPage={currentPage}
                                onPageChange={this.handlePageChange}
                            />
                        </React.Fragment>
                }
            </React.Fragment>
        )
    }

}

const mapStateToProps = state => {
    return {
        user: state.user.info
    }
}

export default connect (mapStateToProps)(TicketList) ;