import React from 'react';
import Squre from "../skelete/Squre";
import SkeleteLoading from "../SkeleteLoading";

class FinanceWithdrawFundLoading extends React.Component {

    render(){
        return (
            <React.Fragment>
                <SkeleteLoading>
                    <div className="mt-4">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-xl-6 col-lg-8 ">
                               <div className="mb-4">
                                   <Squre height={60} />
                               </div>
                                <Squre height={280} />
                            </div>
                        </div>
                    </div>
                </SkeleteLoading>
            </React.Fragment>
        );
    }
}

export default FinanceWithdrawFundLoading;
