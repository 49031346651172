import React from 'react';
import Form from "../../../ubold/commons/form";
import ButtonLoading from "../../../ubold/commons/ButtonLoading";
import Joi from "joi-browser";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {create, edit ,getBankCodes, update} from "../../../modules/user/BankCardService";
import SweetAlert from "react-bootstrap-sweetalert";
import {hideAppLoader, showAppLoader} from "../../../actions/app";
import http from "../../../services/httpService";
import {connect} from 'react-redux';
import {toast} from "react-toastify";
import $ from "jquery";
import "../../../assets/css/banks.css";
import InputMask from "react-input-mask";
import BankCardItem from "./BankCardItem";
import jwtDecode from "jwt-decode";
import DataBox from "../../partials/DataBox";
import CompleteInformations from "../completeInformations";

class BankCardForm extends Form {

    constructor(props) {
        super(props);

        this.cardNumberRef = React.createRef();

        this.state = {
            cards: this.props.data,
            edit_id: null,
            searchQuery: "",
            sortColumn: {path: "", order: ""},
            data: {},
            errors: {},
            loading: false,
            modal: false,
            bankCodes: [],
            alert: ''
        };

        this.schema = {
            card_number: Joi.string()
                .required()
                .label("شماره 16 رقمی ")
        };

    }

    getAlert(id,isTasvie) {
        return <SweetAlert
            warning
            showCancel
            confirmBtnText="بله, مطمئنم!"
            cancelBtnText="خیر"
            confirmBtnBsStyle="danger"
            title="توجه!"
            onConfirm={() => this.submitDelete(id,isTasvie)}
            onCancel={this.hideAlert}
            focusCancelBtn
        >
            آیا مطمئن به حذف این کارت بانکی هستید ؟
        </SweetAlert>
    }

    handleDelete = (id,isTasvie) => {
        this.setState({
            alert: this.getAlert(id,isTasvie)
        })
    }

    submitDelete=(id,isTasvie)=>{
        this.hideAlert();
        this.props.ondelete(id,isTasvie);
    }

    hideAlert = () => {
        this.setState({
            alert: null
        });
    }

    handleSearch = query => {
        this.setState({searchQuery: query, currentPage: 1});
    };

    handleSort = sortColumn => {
        this.setState({sortColumn});
    };

    handlePageChange = page => {
        if (page === 'next')
            this.setState({currentPage: this.state.currentPage++}, () => this.getData());
        else if (page === 'prev')
            this.setState({currentPage: this.state.currentPage--}, () => this.getData());
        else
            this.setState({currentPage: page}, () => this.getData());

    };

    getPagedData = () => {
        const {
            searchQuery,
            cards
        } = this.state;

        let filtered = cards;
        if (searchQuery)
            filtered = cards.filter(m =>
                m.title.toLowerCase().startsWith(searchQuery.toLowerCase())
            );

        // const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

        return {totalCount: filtered.length, data: filtered};
    };

    toggleModal = () => {
        this.setState({modal: !this.state.modal});
    }

    doSubmit = async () => {

        try {
            const data = this.convertToFormData();

            if (this.state.edit_id)
                update(this.state.edit_id, data).then(res => {
                    this.setState({cards: jwtDecode(res.data.data), edit_id: null, data: {}});
                    toast.success(res.data.message);
                    this.toggle();
                    this.toggleModal();
                }).catch(res => {
                    this.toggle();
                });
            else
                create(data).then(res => {
                    this.setState({cards: jwtDecode(res.data), data: {}})
                    this.toggle();
                    this.toggleModal();
                }).catch(res => {
                    this.toggle()
                });

        } catch (ex) {
            this.toggle();
        }
    };

    editCredit = (id) => {
        try {
            this.props.dispatch(showAppLoader());

            edit(id).then(res => {
                let sheba=res.data.card.sheba;
                if(sheba){
                    sheba=res.data.card.sheba.replace(/IR/g,'');
                    sheba=sheba.replace(/ir/g,'');
                }

                this.setState({
                    data: {
                        card_number: res.data.card.card_number.toString().replace(/-/g, "").replace(/\B(?=(\d{4})+(?!\d))/g, "-"),
                        sheba: sheba,
                    },
                    edit_id: res.data.card.id,

                });

                this.toggleModal();
                this.props.dispatch(hideAppLoader());

                this.keyUpCardNumber(this.cardNumberRef.current.props,7)

            })

        } catch (ex) {

        }
    }

    doConvertCardToSheba = () => {

        if (!this.state.data.card_number)
            toast.error('ابتدا شماره 16 رقمی را وارد کنید');
        else {
            this.props.dispatch(showAppLoader());
            const {data} = this.state;

            http.post('/user/get/card-to-sheba', {
                card: this.state.data.card_number
            }).then((res) => {
                var sheba=(res.data.sheba.replace('IR','')).replace('ir','');
                data['sheba'] = sheba;
                this.setState({data});
                this.props.dispatch(hideAppLoader());
            }).catch(res => {
                this.props.dispatch(hideAppLoader());
            });
        }
    }

    componentDidMount() {
        getBankCodes().then(data =>{
            this.setState({bankCodes:data.data.codes})
        });

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        $("[maxlength]").keydown(function (event) {
            var key = event.keyCode || event.charCode;

            if( key !== 8 && key !== 46 )
            {
                if($(this).val().length >= $(this).attr('maxlength')){
                    event.preventDefault();
                    return false;
                }
            }

        });
    }

    handleChangeKeyUpCardNumber = ({currentTarget: input})=>{
        this.keyUpCardNumber(input)
    }

    keyUpCardNumber(input,sub=7){
        const errors = {...this.state.errors};
        const errorMessage = this.validateProperty(input);
        if (errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];
        let data = {...this.state.data};
        data[input.name] = input.value;

        const codes=this.state.bankCodes;
        var code = input.value.substring(0, sub);
        code = parseFloat(code.replace('-', ''));
        code = code.toString();

        if (code.length === 6) {
            code = parseInt(code);

            if (codes[code]) {
                $(".bank-css-sprite").attr('class', '').addClass('bank-css-sprite ' + codes[code]);
                data['name']=codes[code];
                $("#name").val(codes[code]);
            } else{
                $("#name").val('');
                $(".bank-css-sprite").attr('class', '').addClass('bank-css-sprite ');
            }

        } else if (input.value.length < 7)
            $(".bank-css-sprite").attr('class', '').addClass('bank-css-sprite ');

        this.setState({data, errors},()=> this.otherProcess(input));

    }

    render() {
        const {data: cards} = this.getPagedData();
        const {data,errors} = this.state;

        return (
            <React.Fragment>
                {this.state.alert}

                <Modal isOpen={this.state.modal} toggle={this.toggleModal} className='credit '>
                    <ModalHeader toggle={this.toggleModal}> ثبت کارت بانکی</ModalHeader>
                    <form method="POST" onSubmit={this.handleSubmit}>
                        <ModalBody>

                            <div className="form-group">
                                <label>شماره 16 رقمی کارت</label>
                                <div style={{position: 'relative'}}>
                                    <div className="bank-css-sprite "></div>
                                    <input type="hidden" name="name" id="name" />
                                    <InputMask ref={this.cardNumberRef} id={'card_number'} onChange={this.handleChangeKeyUpCardNumber} name={'card_number'} value={data['card_number']} className={'form-control ltr '} mask={'9999-9999-9999-9999'} maskPlaceholder="111" />
                                </div>

                                {errors['card_number'] && <small className="font-11 error-label">{errors['card_number']}</small>}

                            </div>

                        </ModalBody>
                        <ModalFooter>
                            {this.renderButton(<span><i className='fe-upload-cloud'></i>  ارسال </span>, '')}
                        </ModalFooter>
                    </form>
                </Modal>

                <div className="row justify-content-center">
                       <div className="col-lg-6 col-md-8">
                           {
                               this.props.user.completeProfile==='no'?
                                   <div className="card card-body">
                                       <CompleteInformations user={this.props.user}/>
                                   </div>
                                   :
                                   <>
                                       <DataBox data={cards}/>


                                       {cards.map((card, index) => (
                                           <div key={index} className='mb-3'>
                                               <BankCardItem key={index}
                                                             card={card}
                                                             onDelete={this.handleDelete}
                                                             deleteCredit={this.handleDelete}
                                                             editCredit={this.editCredit}
                                               />
                                           </div>
                                       ))}

                                       <button  onClick={this.toggleModal} className='btn btn-t btn-lg btn-block  mt-4 '
                                                disabled={this.state.loading}>
                                           افزودن کارت
                                       </button>
                                   </>
                           }

                       </div>
                    </div>

            </React.Fragment>

        )
    }

}

const mapStateToProps = appState => {
    return {
        user: appState.user.info
    }

};
export default connect(mapStateToProps)(BankCardForm);
