import React, { Component } from "react";
import _ from "lodash";

class TableBody extends Component {
  renderCell = (item, column) => {
    if (column.content) return column.content(item);

    return _.get(item, column.path);
  };

  createKey = (item, column) => {
    return item.id + (column.path || column.key);
  };

  render() {
    const { data, columns,onSelect } = this.props;
    return (
      <tbody>
      {data.length > 0 ?
          data.map(item => (
                <tr  onClick={()=>onSelect(item.id)} key={item.id}>
                  {columns.map(column => (
                      <td key={this.createKey(item, column)}>
                        {this.renderCell(item, column)}
                      </td>
                  ))}
                </tr>
            ))

          :

          <tr >
                <td style={{padding:'20px',textAlign:'center',opacity:0.4}} key='1' colSpan={columns.length}>
                  <div className='mb-2'><i style={{fontSize:'30px'}} className='fe-box '></i></div>
                   هیچ رکوردی برای نمایش وجود ندارد
                </td>
          </tr>
      }

      </tbody>
    );
  }
}

export default TableBody;
