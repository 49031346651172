import React from "react";
import TableHeader from "./tableHeader";
import TableBody from "./tableBody";

const Table = ({ columns, sortColumn, onSort=()=>{},onSelect=()=>{}, data }) => {
  return (
    <table className="table table-striped tablesaw tablesaw-stack" data-tablesaw-mode="stack">
      <TableHeader columns={columns} sortColumn={sortColumn} onSort={onSort} />
      <TableBody  columns={columns} data={data} onSelect={onSelect} />
    </table>
  );
};

export default Table;
