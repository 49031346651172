import React from 'react';
import Squre from "../skelete/Squre";
import SkeleteLoading from "../SkeleteLoading";

class RefferalLoading extends React.Component {

    render(){


        return (
            <React.Fragment>
                <SkeleteLoading>
                    <div className="mb-3">
                        <Squre height={130} />
                    </div>
                    <div className="mb-3">
                        <Squre height={160} />
                    </div>
                    <div className="mb-3">
                        <div className="row">
                            <div className="col-md-6"><Squre height={140} /></div>
                            <div className="col-md-6"><Squre height={140} /></div>
                        </div>

                    </div>


                </SkeleteLoading>
            </React.Fragment>
        );
    }
}

export default RefferalLoading;
