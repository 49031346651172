import React from 'react';

const Wallet = ({ orderType,data,errors,handleChangeWallet}) => {

    return (
        <>
            {
                orderType==='buy'?<>
                    <div className="form-group mt-3" id="wallet_box">
                        <label className="form-label ">
                            آدرس کیف پول
                            <span className="label-require">*</span>
                        </label>
                        <input autoComplete='off' id="wallet" onChange={handleChangeWallet} className="form-control english wallet"
                               placeholder="" value={data.wallet} required="" data-parsley-pattern="" name="wallet"
                               type="text"/>

                        {
                            errors['wallet'] && <small className="font-11 error-label">{errors['wallet']}</small>
                        }

                    </div>
                </>:""
            }
        </>
    );
};

export default Wallet;

