import React from "react";
import {Link} from "react-router-dom";

const MessageFromAdmin = ({user}) => {

    return (
        <>
            {user.limit ?
                user.unverify_text ?
                    <div className="alert alert-danger bg-danger text-white mt-3 alert-top-fix">
                        برای استفاده از خدمات سایت ابتدا
                        باید <strong>{user.verifyText}</strong> خود را تایید کنید.
                        <p>
                            برای تایید مشخصات و ارسال مدارک ، <Link
                            to="/profile/verify">اینجا کلیک
                            کنید</Link>

                        </p>

                    </div>
                    : ''
                : ""}

        </>
    );
};

export default MessageFromAdmin;