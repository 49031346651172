import React from 'react';
import Squre from "../skelete/Squre";
import SkeleteLoading from "../SkeleteLoading";

class OrderFormLoading extends React.Component {

    render() {
        return (
            <React.Fragment>
                <SkeleteLoading>
                    <div className="row">
                        <div className="col-md-7 mb-3">
                            <Squre height={400}/>
                        </div>
                        <div className="col-md-5 mb-3">
                            <Squre height={250}/>
                        </div>
                    </div>
            </SkeleteLoading>
            </React.Fragment>
        );
    }
}

export default OrderFormLoading;
