import React from 'react';
import {updatePageTitle} from "../../actions/breadcrumbs";
import {connect} from "react-redux";
import Form from "../../ubold/commons/form";
import Joi from "joi-browser";
import {Link, Redirect} from "react-router-dom";
import FormLoader from "../../ubold/commons/FormLoader";
import routes from "../../routes";
import {getCashForm} from "../cashincomes/CashincomeService";
import feather from "feather-icons";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import parse from "html-react-parser";
import StickyBox from "react-sticky-box";
import loading from "../../assets/images/loading.svg";
import {siteUrl} from "../../config.json";
import {calculate, storeOrder} from "../orders/OrderService";
import {toast} from "react-toastify";
import OrderFormLoading from "../../components/loadings/OrderFormLoading";
import CompleteInformations from "../user/completeInformations";

class CashincomeCreate extends Form {

    no_meta_filed = [
        'amount' ,
        'fk_currency_id' ,
        'fk_plan_id' ,
        'attachments' ,
        'user_description',
        'user_desc',
        'rules',
        'account_type',
        'company_address',
        'wallet',
        'service',
        'voucher',
        'voucher_code'
    ];

    attach_fields=[
        'passport',
        'ghabz'
    ];


    constructor(props) {
        super(props);
        this.state = {
            loadingForm: true,
            service: null,
            currencies: null,
            data: {},
            errors: {},
            loading: false,
            calcLoading: false,
            timer: null,
            hasAttachField:true,
            redirect: false,
            modal:false,
            currency_price:0,
            total:0
        };
    }

    componentDidMount() {
        getCashForm(this.props.match.params.id).then(res => {
            let sh = {};
            let data = {};
            const service=res.data.service;
            const cash=res.data.cash;

            res.data.fields.map(f => {
                if (f.type === 'account') {
                    sh['account'] = Joi.label('اطلاعات حساب کاربری');
                    sh['user_account_username'] = Joi.label('نام کاربری');
                    sh['user_account_password'] = Joi.label('رمز عبور');
                }
                else if (f.type === 'protection_code') {
                    sh['hpcode'] = Joi.label('فیلد کد حفاظتی');
                    data['hpcode'] = 'خیر';
                    sh['pcode'] = Joi.label('کد حفاظتی');
                }
                else if (f.type === 'paypal_account') {
                    sh['account_type'] = Joi.label('نوع حساب');
                    data['account_type'] = 'personal';
                    sh['wallet'] = Joi.any().required().label('ایمیل حساب پی پال');
                    sh['company_address'] = Joi.label('آدرس سایت واریز کننده');
                }
                else if (f.type === 'webmoney_account') {
                    sh['account_type'] = Joi.label('نوع حساب');
                    data['account_type'] = 'personal';
                    sh['company_address'] = Joi.label('آدرس سایت واریز کننده');
                }
                else {
                    if(f.pattern)
                        sh[f.name] = Joi.string().regex(new RegExp(f.pattern)).required().label(f.label);
                    else
                        sh[f.name] = Joi.string().required().label(f.label);
                }

                data[f.name] = f.default_value?f.default_value:'';

                return data;
            });

            data['amount']=0;
            data['service']=this.props.match.params.id;

            this.setState({
                data: data,
            });

            if (cash.currency.length > 0) {
                const data = this.state.data;
                data.fk_currency_id = cash.currency[0].id;
                let ot = [];
                cash.currency.map(cr => {
                    ot.push({
                        'name': cr.name,
                        '_id': cr.id,
                    });


                    return ot;
                });

                this.setState({data, currenciesOptions: ot});

                sh['fk_currency_id'] = Joi.required()
                    .label('نوع ارز');
            }

            sh['rules'] = Joi.required()
                .label('شرایط و قوانین');

            sh['user_desc'] = Joi.label('توضیحات کاربر');
            sh['attachments'] = Joi.label(' فایل پیوست');

            const search = this.props.location.search;
            let cash_cat = new URLSearchParams(search);
            const params = Object.fromEntries(cash_cat.entries());
            cash_cat=params.cat;

            this.setState({
                loadingForm: false,
                fields: res.data.fields,
                cash: cash,
                service: service,
                currencies: cash.currency,
            });

            this.schema = sh;

            this.props.dispatch(updatePageTitle(
                '' + cash.title,
                [
                    {
                        'title': 'خدمات فروش ارز (نقد درآمد)',
                        'link': routes.service_items+cash_cat
                    },
                    {
                        'title': 'ثبت سفارش جدید',
                        'active': true
                    }
                ],(
                    <Link className={'btn btn-primary primary btn-sm'} to={routes.service_items+cash_cat}>
                        <i className='d-inline-block ml-1' dangerouslySetInnerHTML={{__html: feather.icons['arrow-right'].toSvg()}}/>
                        بازگشت
                    </Link>
                )
            ));

            if(service.plans)
                this.orderCalculate();

        });
    }

    toggleModal = () => {
        this.setState({modal: !this.state.modal});
    }

    doSubmit = async () => {
        try {
            var data = this.state.data;
            const FData = new FormData();
            const cl = this;

            Object.keys(data).forEach(function (item) {
                if (cl.no_meta_filed.indexOf(item) < 0) {
                    if (cl.attach_fields.indexOf(item) >= 0) {
                        FData.append('attachments[' + item + ']', data[item]);
                    } else
                        FData.append('meta[' + item + ']', data[item]);
                } else
                    FData.append(item, data[item]);
            });

            storeOrder(this.state.service.id, FData,'sell').then(res => {
                this.toggle();
                toast.success(res.data.message);
                if(res.data.cashincome.has_gateway_payment)
                    this.setState({
                        redirect: routes.cash_pay+res.data.cashincome.id
                    });
                else
                    this.setState({
                        redirect: routes.cash_show+res.data.cashincome.id
                    });
            }).catch(res => {
                this.toggle();
            });

        } catch (ex) {
            this.toggle();
        }
    };

    otherProcess = (input) => {
        if (input.name === 'fk_plan_id' || input.name === 'fk_currency_id') {
            this.orderCalculate();
        }
    }

    handleKeyUp = (input) => {
        const cl = this;
        if (input.target.name === 'amount') {
            clearTimeout(this.state.timer);

            const t = setTimeout(function () {
                cl.orderCalculate();
            }, 1000);

            this.setState({
                timer: t
            });
        }
    }

    orderCalculate = () => {

        this.setState({
            calcLoading: true
        });

        var data = this.state.data;
        data['order_type']='sell';

        calculate(this.state.service.id, data).then(res => {
           this.setState({
               total:res.data.data.total,
               currency_price:res.data.data.currency_price,
           })
            this.setState({
                calcLoading: false
            });
        });

    }


    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        return (
            <React.Fragment>
                    {
                        this.state.loadingForm ?
                            <OrderFormLoading/>
                            :
                            <React.Fragment>
                                <Modal isOpen={this.state.modal} toggle={this.toggleModal} className='credit '>
                                    <ModalHeader toggle={this.toggleModal}> نکات مهم قبل از سفارش</ModalHeader>
                                    <form method="POST" onSubmit={this.handleSubmit}>
                                        <ModalBody>
                                            <div className="show-info-order">
                                                {this.state.cash.description && parse(this.state.cash.description)}
                                            </div>
                                        </ModalBody>
                                    </form>
                                </Modal>

                                {
                                    this.props.user.completeProfile==='no'?
                                        <div className="row justify-content-center mt-5">
                                            <div className="col-lg-6">
                                                <div className="card card-body">
                                                    <CompleteInformations user={this.props.user}/>
                                                </div>
                                            </div>

                                        </div>
                                        :
                                        <form method="POST" onSubmit={this.handleSubmit}>
                                            <div className="row mt-1 card-order-new">
                                                <div className="col-md-7">
                                                    <div className="card card-body">

                                                        <div className="d-flex justify-content-between align-items-center hed">
                                                            <h4>{this.state.cash.title}</h4>
                                                            <img alt={this.state.service.title} src={
                                                                this.state.cash.ss_icon?this.state.cash.service_icon:this.state.service.service_icon
                                                            } height={60} width={60} />
                                                        </div>

                                                        {this.state.service.instruction?
                                                            <button className="btn btn-outline-warning btn-block mt-3 mb-4" type="button"
                                                                    onClick={()=>this.toggleModal()}>
                                                                <i  className="fe-file-text"></i>
                                                                مشاهده نکات مهم سفارش
                                                            </button>
                                                            :                                            <hr/>
                                                        }

                                                        {
                                                            this.state.currencies.length > 1 ?
                                                                this.renderSelect('fk_currency_id', 'نوع ارز', this.state.currenciesOptions, this.state.data.fk_currency_id)
                                                                : ''
                                                        }
                                                        {
                                                            this.state.fields.map(field => {
                                                                switch (field.type) {
                                                                    case 'text' :
                                                                    case 'file' :
                                                                    case 'checkbox' :
                                                                    case 'number' :
                                                                    case 'email' :
                                                                    case 'link' :
                                                                        return this.renderInput(field.name, field.label, field.type, field.class, null, 0, false, field.value, field.placeholder, this.handleKeyUp,{desc:field.desc})
                                                                    case 'account':
                                                                        return this.renderAccount();
                                                                    case 'select':
                                                                        return this.renderSelect(field.name,field.label,field.opt)
                                                                    case 'protection_code':
                                                                        return this.renderProtectionCode(field.name,field.label,field.opt)
                                                                    case 'qty':
                                                                        return this.renderQty(field.label,field.opt,field.default_value,false,this.state.maxGiftQty)
                                                                    case 'paypal_account':
                                                                        return this.renderPaypalAccount();
                                                                    case 'webmoney_account':
                                                                        return this.renderWebmoneyAccount();
                                                                }

                                                                return field;
                                                            })
                                                        }

                                                        {
                                                            this.renderTextArea('user_desc', 'توضیحات ',)
                                                        }


                                                        {
                                                            !this.state.hasAttachField ?
                                                                this.renderInput('attachments', 'فایل پیوست', 'file', '')
                                                                : ''
                                                        }

                                                    </div>
                                                </div>
                                                <div id='afix' className="col-md-5">
                                                    <StickyBox offsetTop={80} offsetBottom={20}>
                                                        <div className="card calculate-box">
                                                            <div className=" card-body cal-box">
                                                                <div className="card-title mb-4">
                                                                    پیش فاکتور سفارش
                                                                </div>

                                                                <div className="form-group mt-4" id="box_info_prices">
                                                                    {
                                                                        this.state.calcLoading?
                                                                            <div className={'loading'}  style={{
                                                                                padding: '20px 0',
                                                                                display: (this.state.calcLoading )
                                                                            }}>
                                                                                <img src={loading} className={'ml-2'} height="40" width="40"/>
                                                                                <div >لطفا
                                                                                    صبر
                                                                                    کنید ...
                                                                                </div>
                                                                            </div>
                                                                            :''
                                                                    }

                                                                    <div className="cart-invoice" id="result">
                                                                        <div className="p-2 prices">
                                                                            <div className="row">
                                                                                <div className="col-12 col-md-6">
                                                                                    نرخ فروش  :
                                                                                </div>
                                                                                <div  className="col-12 col-md-6 ltr">
                                                                                    {this.state.currency_price}
                                                                                    <small className='d-inline-block ml-1 mr-1'>تومان</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="mt-0 p-2 text-primary total">
                                                                            <small> مجموع هزینه نسبی
                                                                                : </small> {this.state.total} تومان
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label className="fancy-checkbox font-11">

                                                                        {
                                                                            this.renderCheckbox('rules')
                                                                        }
                                                                        <span>
                                                                <a href={siteUrl + 'terms'} rel='noreferrer' target="_blank"> شرایط   </a>   استفاده را با دقت مطالعه نموده و قبول دارم
                                                            </span>
                                                                    </label>

                                                                    <div>

                                                                        {
                                                                            (this.state.errors && this.state.errors['rules']) &&   <div className="form-error"><small
                                                                                className="font-11 ">{this.state.errors.rules}</small>
                                                                            </div>
                                                                        }
                                                                    </div>

                                                                </div>

                                                                {this.renderButton(<span><i className='fe-shopping-bag'></i> ثبت سفارش </span>, 'btn btn-t btn-block btn-lg')}

                                                            </div>
                                                        </div>
                                                    </StickyBox>
                                                </div>
                                            </div>
                                        </form>
                                }

                            </React.Fragment>
                    }

            </React.Fragment>
        )
    }

}

const mapStateToProps = appState => {
    return {
        user: appState.user.info
    }

};

export default connect(mapStateToProps)(CashincomeCreate);
