import React from 'react';
import {connect} from "react-redux";

class PayWebmoney extends React.Component {

    render() {
        const cash=this.props.cash;
        const data=this.props.data[cash.service_type];
        const user=this.props.user;

        return (
            <React.Fragment>
                <form action={data.gateway} method="POST">
                    <input type="hidden" name="m_shop" value={data.m_shop}/>
                    <input type="hidden" name="m_orderid" value={data.m_orderid} />
                    <input type="hidden" name="m_amount" value={data.m_amount}/>
                    <input type="hidden" name="m_curr" value={data.m_curr}  />
                    <input type="hidden" name="m_desc" value={data.m_desc}  />
                    <input type="hidden" name="m_sign" value={data.sign} />

                    <br />
                        <button className="btn btn-primary  btn-lg btn-block" type="submit">
                            <i  className="fe-arrow-down-right"></i>
                            انتقال به درگاه
                        </button>
                </form>
            </React.Fragment>
        )
    }
}
const mapStateToProps = (state) => {
        return {
            user: state.user.info,
        }
    }

export default connect(mapStateToProps)(PayWebmoney);
