import React from 'react';
import Squre from "../skelete/Squre";
import SkeleteLoading from "../SkeleteLoading";

class CashApiLoading extends React.Component {

    render(){


        return (
            <React.Fragment>
                <SkeleteLoading>
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <Squre height={300} />

                        </div>
                    </div>
                </SkeleteLoading>
            </React.Fragment>
        );
    }
}

export default CashApiLoading;
