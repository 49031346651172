import React from "react";

const DataBox = ({data,message='هیچ رکوردی در سیستم ثبت نشده است'}) => {

    return (
        (!data || (data && data.length <= 0)) ?
            <div className='empty-list text-center'>
                <i className='fe-package'></i>
                <div className="title">{message}</div>
            </div>
            :
            ''
    );
};

export default DataBox;