import React from 'react';
import {connect} from "react-redux";
import Form from "../../../ubold/commons/form";
import {Link} from "react-router-dom";
import routes from "../../../routes";

class OrderItemDashboard extends Form {


    render() {

        const {order}=this.props;

        return (
            <>
                <div className="row">
                    <div className="col-lg-7 col-sm-7 col-7">
                        <div className="row align-items-center">
                            <div className="col-xl-4 col-lg-3">
                                <div>

                                    <span className="mr-1">
                                        <Link to={routes.orders_show+order.id}>#{order.id}</Link>
                                    </span>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-5">
                                {order.title}

                            </div>
                            <div className="col-xl-4 col-lg-4">
                                <div>
                                    <div style={{opacity:'1'}}>  {order.amount} {order.currency_name}</div>
                                </div>

                            </div>


                        </div>
                    </div>
                    <div className="col-lg-5 col-sm-5 col-5">
                        <div className="row align-items-center">

                            <div className="col-xl-6 col-lg-6">
                                <div className="">

                                    <span
                                        className={'badge d-inline-block mr-1 ' }>{order.shamsi_created_at}</span>


                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 ">

                                <div>

                                    <span
                                        className={'badge d-inline-block mr-1 badge-' + order.status_text.class}>{order.status_text.label}</span>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }

}

export default connect ()(OrderItemDashboard);
