import React from "react";
import NetworkItem from "./NetworkItem";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import Alert from "../../../partials/Alert";

const Networks = ({data,errors,networks,allowNetworks,selectedNetwork,onSelectNetwork,orderType,toggleNetworkModal,showNetworkModal,selectedCoin,networkSelect,dollarprice,currencyprice})=>{
    return(
        <>
            {
                networks.length > 0 ?
                    <div className='form-group'>
                        <div className='order-crypto-networks'>
                            <label>انتخاب شبکه انتقال</label>
                            <div onClick={toggleNetworkModal} className="form-control">
                                <div className="d-flex">
                                    <div className='w-100'>
                                       <div className="mt-1">
                                           <div className="d-flex justify-content-between">
                                               <div>
                                                   {
                                                       selectedNetwork?
                                                           <div>
                                                               <p>
                                                                   <strong>{networkSelect}</strong>
                                                               </p>

                                                           </div>
                                                           :" انتخاب کنید"
                                                   }
                                               </div>
                                               {
                                                   selectedNetwork && orderType==='buy'?
                                                       <div className="ltr ml-2" >
                                                           <strong style={{fontSize:'12px'}} className="text-danger">Fee : {selectedNetwork.withdrawFee}</strong>
                                                       </div>
                                                       :""
                                               }

                                           </div>
                                       </div>

                                    </div>
                                    <div>
                                        <div className="arrow"></div>

                                    </div>
                                </div>

                            </div>

                            <Modal isOpen={showNetworkModal} toggle={toggleNetworkModal}
                                   className='order-crypto-search '>
                                <ModalHeader toggle={toggleNetworkModal}>انتخاب شبکه</ModalHeader>

                                <ModalBody>
                                    {
                                        (allowNetworks.length ===0 && data.wallet ) ?
                                            <Alert type='danger' title={'خطا'}>
                                                آدرس کیف پول وارد شده با هیچ کدام از شبکه ها تطابق ندارد
                                            </Alert>
                                            :""
                                    }

                                    {
                                        ( !data.wallet && orderType==='buy') ?
                                            <Alert type='warning' title={'توجه'}>
                                                برای انتخاب شبکه ابتدا باید آدرس کیف پول خود را وارد کنید
                                            </Alert>
                                            :""
                                    }
                                    <div className="crypto-networks-item">
                                        {
                                            networks.map((network,index)=>(
                                                <NetworkItem allowNetworks={allowNetworks} currencyprice={currencyprice} dollarprice={dollarprice} selectedCoin={selectedCoin}  orderType={orderType} key={index} item={network} selectedNetwork={(selectedNetwork)} onSelectNetwork={net=> onSelectNetwork(net)} />
                                            ))
                                        }
                                    </div>
                                </ModalBody>
                            </Modal>

                        </div>
                        {
                            errors['network'] && <small className="font-11 error-label">{errors['network']}</small>
                        }

                        {
                            (data.wallet && !selectedNetwork) && <small className="font-11 error-label">لطفا شبکه انتقال را انتخاب کنید</small>
                        }
                    </div>

                    :''
            }
        </>

    );
}

export default Networks;