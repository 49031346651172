import React from "react";
import {Link} from "react-router-dom";
import routes from "../../../routes";
import {getVerification} from "../../../services/helpers";

const B = ({user,info}) =>{

    return (
            <>
                <p>پس از تکمیل موارد زیر سطح شما به صورت اتوماتیک به
                    <strong className={info.next_level_class+' d-inline-block ml-1 mr-1'}>{info.next_level_label}</strong>
                    تغییر می یابد.
                </p>

                <table className="table">
                    <tr>
                        <th><Link to={routes.profile_verify}> تایید موبایل </Link></th>
                        <td>
                            {getVerification(user.verifications,'MOBILE').is_verify?
                                <span className="badge badge-success">تایید شده</span>
                                :
                                <span className="badge badge-danger">تایید نشده</span>
                            }

                        </td>
                    </tr>
                    <tr>
                        <th><Link to={routes.profile_bank_credits}> داشتن حداقل یک کارت بانکی بدون نیاز به تایید</Link>
                        </th>
                        <td>
                            {info.credits > 0?
                                <span className="badge badge-success">
                                    <span className="ml-1">{info.credits}</span>
                                    عدد
                                    ثبت شده</span>
                                :
                                <span className="badge badge-danger">ثبت نشده</span>
                            }
                        </td>
                    </tr>
                </table>

            </>
    );
}

export default B;