import React from 'react';
import DataBox from "../partials/DataBox";
import http from "../../services/httpService";
import FormLoader from "../../ubold/commons/FormLoader";
import LoginInfoRecord from "../partials/LoginInfoRecord";
import {updatePageTitle} from "../../actions/breadcrumbs";
import {connect} from "react-redux";
import Pagination from "../../ubold/commons/pagination";
import ListLoading from "../../components/loadings/ListLoading";

class LoginInfo extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            currentPage: 1,
            total: 1,
            last_page: 1,
            authorizes: {},
            loadingForm: true
        }

        this.props.dispatch(updatePageTitle(
            'سوابق ورود',
            {},
        ));
    }


    componentDidMount() {
        this.getData();
    }


    getData = (no_read=false) => {
        this.setState({
            loadingForm: true
        });

        http.post('/user/get/info/login',{page:this.state.currentPage})
            .then(response => {
                const list=response.data.data;
                this.setState({
                    total: list.total,
                    last_page:list.last_page,
                    authorizes: list.data,
                    loadingForm: false
                })
            });
    }

    handlePageChange = page => {
        if (page === 'next')
            this.setState({currentPage: this.state.currentPage + 1}, () => this.getData());
        else if (page === 'prev')
            this.setState({currentPage: this.state.currentPage - 1}, () => this.getData());
        else
            this.setState({currentPage: page}, () => this.getData());

    };

    getPagedData = () => {
        const {
            searchQuery,
            tickets
        } = this.state;

        let filtered = tickets;
        if (searchQuery)
            filtered = tickets.filter(m =>
                m.title.toLowerCase().startsWith(searchQuery.toLowerCase())
            );

        return {totalCount: filtered.length, data: filtered};
    };

    render() {
        const {last_page, currentPage } = this.state;

        return (
            this.state.loadingForm ?
                <ListLoading height={90}/>
                :
            <React.Fragment>
                <DataBox data={this.state.authorizes}/>

                {this.state.authorizes.map(info => (
                    <div key={info.id} className='card card-body card-table'>
                        <LoginInfoRecord key={info.id}
                                   info={info}
                        />
                    </div>
                ))}

                <Pagination
                    total={last_page}
                    currentPage={currentPage}
                    onPageChange={this.handlePageChange}
                />
            </React.Fragment>
        )
    }

}

export default connect ()(LoginInfo) ;