import axios from "axios";
import {toast} from "react-toastify";
import {apiUrl} from "../config";
import {clearStorage,getToken} from "../modules/auth/authService";
import {convertDataToArray} from "./helpers";

axios.defaults.headers.common["Content-Type"] = 'application/json';
axios.defaults.headers.common["Access-Control-Allow-Origin"] = '*';
axios.defaults.headers.common["Authorization"] = 'Bearer ' + getToken();
axios.defaults.headers["isApi"] = 'web';
axios.defaults.baseURL = apiUrl;

axios.interceptors.response.use(null, error => {
    if (error && error.response && error.response.status >= 400 && error.response.status <= 500) {
        var data = null;
        if (error.response.data)
            data = error.response.data;
        else
            data = JSON.parse(error.response.responseText);

        if(error.response.status === 401){
            toast.error('لطفا مجددا به سیستم وارد شوید.');
            clearStorage();
           setTimeout(function () {
               window.location='/login';
           },2000);
        } else if(error.response.status === 404){
            toast.error('داده مورد نظر یافت نشد');
            // window.location='/';

        } else if(error.response.status === 403) {
            toast.error('مجوز دسترسی به این صغحه را ندارید');
            // window.location='/';

        }  else if(data.errors){
            const errList=convertMessageToList(data.errors);
            errList.map(err=>{
               return  toast.error(err);
            });
        }
        else{
            if(data.message instanceof Object){
                const errList=convertMessageToList(Object.values(data.message));
                errList.map(err=>{
                    return toast.error(err);
                });
            }else
                toast.error(bsMSGNew(data.message));

            if(data.new_login){
                setTimeout(function () {
                    window.location='/';
                },1000);
            }

            if(data.token_expire){
                setTimeout(function () {
                    window.location=window.location.href;
                },1000);
            }

        }

    } else
        toast.error('خطای داخلی، لطفا به مدیریت سایت اطلاع دهید');

    // if (error && error.response && error.response.status ===401)
    // {
    //     clearStorage();
    //     window.location.href='/';
    // }

    return Promise.reject(error);

});

function submit(requestType, url, data,options={}) {

    return new Promise((resolve, reject) => {
        axios[requestType](url, data,options)
            .then(response => {
                if(response.data.message)
                    toast.success(response.data.message);

                resolve(response.data);
            })
            .catch(data => {
                reject(data);
            });
    });

}

function setAuthorizeHeader(token) {
    axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
}

function bsMSGNew(msg) {
    let html = '';
    if(msg instanceof Array){
        msg=[msg];
    }else {
        try {
            msg = convertDataToArray(JSON.parse(msg));
        }catch (e){
            msg=[msg];
        }
    }

    for (let i = 0; i < msg.length; i++) {

        if (msg[i] instanceof Object) {

            Object.keys(msg[i]).forEach(function(key){
                if (msg[i][key] instanceof Object) {
                    Object.keys(msg[i][key]).forEach(function(key2) {
                        html += msg[i][key][key2] + "\n"; //showing only the first error.
                    });
                } else
                    html += msg[i][key] + "\n"; //showing only the first error.
            });

        } else
            html += msg[i] + "\n";
    }
    return html;
}

function convertMessageToList(msg) {

    msg = (msg instanceof Array ? msg : [msg]);

    var list = [];
    for (var i = 0; i < msg.length; i++) {

        if (msg[i] instanceof Object) {
            Object.keys(msg[i]).forEach(function(key) {
                if (msg[i][key] instanceof Object) {
                    Object.keys(msg[i][key]).forEach(function(key2) {
                        list.push(msg[i][key][key2]);
                    });
                } else
                    list.push( msg[i][key]);

            });

        } else
            list.push( msg[i])
    }
    return list;
}

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    submit,
    setAuthorizeHeader
};
