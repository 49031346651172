import React from 'react';
import http from "../../../../services/httpService";
import Form from "../../../../ubold/commons/form";
import Joi from "joi-browser";
import img from "../../../../assets/images/user-ver.jpg"
import $ from "jquery";
import '../../../../../node_modules/dropify/dist/css/dropify.min.css'
import '../../../../../node_modules/dropify/dist/js/dropify.min'
import {updateUserInStore} from "../../userService";
import {connect} from "react-redux";
import Alert from "../../../partials/Alert";
import {user} from "../../../../reducers/user";

class CreditUploadForm extends Form {

    constructor(props) {
        super(props);
        this.state = {
            data: {
                credit: ''
            },
            errors: {},
            loading: false,
        };
        this.schema = {
            credit: Joi.label("تصویر سلفی و تهدنامه"),
        };
    }

    componentDidMount() {
        $('.dropify').dropify();
    }

    doSubmit = async () => {
        try {
            const data = new FormData();
            data.append('credit', this.state.data.credit);
            data.append('isCreditCard', 1);
            http.submit('post', '/user/update/upload-document', data).then(res => {
                this.toggle();
                updateUserInStore(this.props.dispatch, res.new_user);
                this.props.reloadUser(false);

            }).catch(res => {
                this.toggle();
            });

        } catch (ex) {
            this.toggle();
        }
    }


    render() {
        const verification = this.props.verification;
        const user = this.props.user;

        return (
            verification.status === 'PENDING' ?
                <div>
                    <Alert title={'پیغام کارشناس تایید مدارک'} type={'warning'} >
                        سلفی شما با موفقیت بارگذاری شد و در دست کارشناس مربوطه قرار گرفت، لذا  تا دقایقی دیگر پاسخ استعلام  به شما اعلام خواهد شد.
                        <div className="mt-2 text-muted">وضعیت   : &nbsp;
                            <span className={'badge badge-'+verification.status_class}>
{verification.status_text}
</span>
                        </div>
                    </Alert>
                </div>
                :
                <React.Fragment>
                    <form method="POST" onSubmit={this.handleSubmit}>
                        <div className="row justify-content-center">

                            <div className="col-12">
                                <div className="row">
                                    <div className="col-md-4">
                                        <img alt={'dfg'} style={{width: '100%'}} src={img}/>
                                    </div>
                                    <div className="col-md-8 ">
                                        <Alert  type={'info'}>
                                            <h4>متن زیر را  روی کاغد بنویسید: </h4>
                                            اینجانب، <strong className={'text-danger'}>{user.name}</strong> با شماره ملی <strong className={'text-danger'}>{user.national_code}</strong>، تعهد می‌دهم که قوانین کافه ارز را خوانده‌ام و تمامی خریدها برای خودم می باشد.به افرادی که در سایت دیوار و یا شبکه اجتماعی مثل تلگرام وعده پورسانت می‌دهند اعتماد نمی کنم و از حساب کاربری و کارت بانکی‌ام  برای خرید دیگران استفاده نمی کنم. از تبعات قانونی و مشارکت در جرم بابت حساب اجاره‌ای و اجاره کارت بانکی آگاهم و مسئولیت حقوقی و قضایی هرگونه واریز مشکوک به کافه ارز را می‌پذیرم.


                                            <div className={'mt-2'}>
                                                تاریخ و امضاء
                                            </div>
                                        </Alert>
                                    </div>
                                </div>

                                <br/>

                                <ul className=''  style={{lineHeight: '25px'}}>
                                        <li>اطلاعات متن تعهدنامه و مدرک هویتی شما میبایست واضح و خوانا باشد.</li>
                                        <li>از پوشاندن اطلاعات مدرک هویتی خودداری کنید.</li>
                                        <li>از گرفتن برگ تعهدنامه جلوی صورت خود خودداری کنید.</li>
                                        <li>چهره شما و متن تعهد نامه و کارت شناسایی همه میبایست در یک کادر باشند</li>
                                        <li>از گذاشتن انگشت به روی متن تعهدنامه و مدرک هویتی خودداری کنید.</li>
                                        <li>از زاویه روبرو عکس تهیه کنید</li>
                                        <li>حجم تصویر می بایست کمتر از 6 مگابایت باشد</li>
                                </ul>
                            </div>




                            <div className="col-12 mt-4">

                                {this.renderInput("credit", "تصویر سلفی و تعهد تامه", 'file')}

                                {this.renderButton("آپلود تصویر سلفی و تعهد نامه")}

                            </div>
                        </div>
                    </form>
                </React.Fragment>



        )
    }

}

export default connect()(CreditUploadForm);

