import React from 'react';
import Form from "../../../ubold/commons/form";
import {
    exchangePriceByType,
    getService,
    getCryptoMinAmount, updateOrder, create, getCoin, getOrder,
} from "../../../modules/orders/OrderService";
import {updatePageTitle} from "../../../actions/breadcrumbs";
import {connect} from "react-redux";
import routes from "../../../routes";
import {Link, Redirect} from "react-router-dom";
import feather from "feather-icons";
import $ from "jquery";
import loadingExPriceImg from "./../../../assets/images/loading2.svg"
import Alert from "../../partials/Alert";
import Memo from "./crypto-component/Memo";
import Joi from "joi-browser";
import queryString from 'query-string';
import NumberFormat from "react-number-format";
import loading from "../../../assets/images/loading2.svg";
import loadingIMG from "../../../assets/images/loading2.svg";
import OrderTypeButtons from "./crypto-component/OrderTypeButtons";
import SelectCoin from "./crypto-component/SelectCoin";
import ModalCoins from "./crypto-component/ModalCoins";
import Wallet from "./crypto-component/Wallet";
import Networks from "./crypto-component/Networks";
import CryptoModalInfoBeforeSubmit from "./crypto-component/CryptoModalInfoBeforeSubmit";
import {toast} from "react-toastify";
import {formatMoney, formatMoneyDollar} from "../../../services/helpers";

var timerForExPrice = null;

class Crypto extends Form {

    constructor(props) {
        super(props);
        var u = new URL(window.location.href)
        const parsed = queryString.parse(u.search);
        this.state = {
            orderType: parsed.type ? parsed.type : 'buy',
            select_from_url:parsed.select?parsed.select:null,
            edit:parsed.edit?parsed.edit:null,
            showCoinsModal: false,
            showNetworkModal: false,
            selectFirstItemFromCoinsInModal: true,
            loadingForm: true,
            selectedCoin: {
                icon: '/assets/images/no-image.png',
                title: 'Empty',
                symbol: 'NAN',
                name: 'بدون ارز',
                id:0
            },
            selectedNetwork: {},
            allowNetworks: {},
            data: {},
            errors: {},
            loadingExPrice: false,
            totalTomanEx: 0,
            decimal_digit: 8,
            notUseMemo: false,
            minAmount: 0,
            receive_amount: 0,
            networkSelect: null,
            currency_price: 0,
            currency_dollar_price: 0,
            max_amount: 0,
            networks: {},
            modal_submit:false,
            base_exchanger:'',
            hasInnerTransfer:false,
            donPayPersianWalletPay:false,
            totalFee:0
        };

        this.schema = {
            amount: Joi
                .string()
                .required()
                .label("میزان ارز"),
            wallet: Joi
                .string()
                .required()
                .label("کیف پول ")
        };

        this.props.dispatch(updatePageTitle(
            this.state.edit ? 'ویرایش سفارش' : 'خرید و فروش ارزهای دیجیتال',
            [
                {
                    'title': this.state.edit ? 'لیست سفارش ها ' : 'لیست خدمات ',
                    'link': this.state.edit ? routes.orders : routes.orders_service,
                    'active': false
                },
                {
                    'title': this.state.edit ? 'ویرایش سفارش' : 'ثبت سفارش خرید و فروش ارزهای دیجیتال',
                    'active': true
                }
            ],

            (
                <Link className={'btn btn-primary primary btn-sm'} to={routes.orders_service}>
                    <i className='d-inline-block ml-1'
                       dangerouslySetInnerHTML={{__html: feather.icons['arrow-right'].toSvg()}}/>
                    بازگشت
                </Link>
            )
        ));

    }

    changeOrderType = (type) => {
        const data=this.state.data;
        data['tag']='';
        this.getCoinData(null,type);
        this.setState({orderType: type,data});
        this.refreshSchema(type);
    }

    getCoinData = (id, type, network = null,update=false) => {
        id = id ? id : this.state.selectedCoin.id;

        if (!type)
            type = this.state.orderType;

        var  data = this.state.data;
        var st={
            loadingForm: true,
            selectedNetwork: null,
            networkSelect: null,
            data:data
        };

        if(!update){
            data['wallet'] = '';
            data['tag'] = '';
            data['amount'] = '';
            st['minAmount']=0;
            st['totalTomanEx']='';
        }

        st['data']=data;

        this.checkWalletRegex('');

        this.setState(st);

        if (id)
            getService(id, network, type).then(res => {
                const data = res.data;
                var d = this.state.data;
                d['fk_currency_id'] = data.id;
                d['fk_service_id'] = data.service_id;

                if (!this.state.edit)
                    d['wallet'] = '';

                this.setState({
                    data: d,
                    errors: {},
                    description: data.description,
                    fee_percentage: data.fee_percentage,
                    minAmount: data.minAmount,
                    currency_price: data.currency_price,
                    currency_dollar_price: data.currency_dollar_price,
                    decimal_digit: data.decimal_digit,
                    loadingForm: false,
                    networks: data.network,
                    max_amount: data.max_amount

                });

                // if (this.state.data.amount) {
                //     this.calculate();
                // }

                this.initNetwork(data.network);

                if (this.state.totalTomanEx)
                    this.exchangePrice('toman', this.state.totalTomanEx, 0);
                else if (this.state.data.amount)
                    this.exchangePrice('dollar', this.state.data.amount, 0);

                this.refreshSchema(type);

                if(this.state.data.wallet){
                    this.checkWalletRegex(this.state.data.wallet);
                    this.selectNetowrk(this.state.data.networkName);
                }

            });
    }

    exchangePrice(type, amount, timeout = 800) {
        clearTimeout(timerForExPrice);
        const me = this;
        timerForExPrice = setTimeout(function () {
            if (me.state.selectedCoin) {
                me.setState({loadingExPrice: true});

                exchangePriceByType(me.state.selectedCoin.id, type, amount, me.state.orderType)
                    .then(response => {
                            response = response.data;

                            let data = me.state.data;
                            const errors = {...me.state.errors};

                            if (type !== 'dollar')
                                data['amount'] = response.amount.toString();

                            delete errors['amount'];

                            me.setState({
                                data,
                                errors,
                                totalTomanEx: (response.totalToman),
                                loadingExPrice: false
                            });
                            me.calcReceiveAmount(response.amount);

                            // me.calculate();
                        }
                    );
            }
        }, timeout);

    }

    checkWalletRegex(value) {
        var allowNetworks = [];

        if (this.state.networks.length > 0) {
            $.each(this.state.networks, function (i, item) {
                if (item.addressRegex) {
                    if (new RegExp(item.addressRegex).test(value)) {
                        allowNetworks.push(item);
                    }
                }
            });
        }

        if (value === '')
            allowNetworks = [];

        this.setState({allowNetworks})

    }

    selectNetowrk(networkName) {
        const me=this;
        if (this.state.networks.length > 0) {
            $.each(this.state.networks, function (i, item) {
                if (item.chain===networkName) {
                    me.onSelectNetwork(item);
                }
            });
        }
    }

    handleChangeToman = ({currentTarget: input}) => {
        this.setState({totalTomanEx: (input.value)});
        this.exchangePrice('toman', input.value);
    }

    handleChangeDollar = ({currentTarget: input}) => {
        let data = this.state.data;
        if (this.state.decimal_digit) {
            const data = this.state.data;
            var count = this.countDecimal(input.value);
            if (count <= this.state.decimal_digit)
                data['amount'] = input.value

        } else
            data['amount'] = input.value;

        this.setState({data});
        this.handleError(input);
        this.exchangePrice('dollar', input.value);
    }

    countDecimal = (value) => {
        var result = (value.toString().split('.'));
        if (result.length > 1 && result[1] !== '') {
            return result[1].length;
        }
        return 0;
    }

    calcReceiveAmount(amount = 0) {
        amount = amount ? amount : this.state.data.amount;
        const fee = this.state.selectedNetwork ? this.state.selectedNetwork.fee : 0;

        if (amount > 0) {
            let total = parseFloat(amount - fee).toFixed(8);
            this.setState({receive_amount: parseFloat(total)});
        } else if (amount === 0) {
            this.setState({receive_amount: parseFloat(0)});
        }
    }

    toggleCoinsModal = () => {
        this.setState({showCoinsModal: !this.state.showCoinsModal});
    }

    toggleNetworkModal = () => {
        this.setState({showNetworkModal: !this.state.showNetworkModal});
    }

    handleSelectCoinFromModal = (selectedCoin,exchanger) => {
        this.setState({selectedCoin: selectedCoin,base_exchanger:exchanger, loadingForm: false, showCoinsModal: false});
        this.getCoinData(selectedCoin.id);
    }

    handleChangeWallet = ({currentTarget: input}) => {
        let data = this.state.data;
        data['wallet'] = input.value;
        this.setState({data});
        this.handleError(input);
        this.checkWalletRegex(input.value);
    }

    handleChangeMemo = ({currentTarget: input}) => {
        let meta = this.state.meta;
        let data = this.state.data;
        data['tag'] = input.value;
        this.setState({data});
        this.handleError(input);
    }

    handleUseMemo = ()=>{
        const data=this.state.data;
        data['tag']='';

        if(!this.state.notUseMemo===true){
            this.onChangeSchema('tag','Memo/Tag',false);
        }
        else
            this.onChangeSchema('tag','Memo/Tag',true);

        this.setState({notUseMemo:!this.state.notUseMemo,data});
    }

    onChangeSchema = (name,label,required=false) => {
        const sch=this.schema;
        const errors=this.state.errors;
        if(required)
            sch[name]=Joi.string().required().label(label);
        else{
            sch[name]=Joi.label(label);
            delete errors[name];
            this.setState({errors});
        }

        this.schema=sch;
    }

    handleError = (input) => {
        const errors = {...this.state.errors};
        const errorMessage = this.validateProperty(input);
        if (errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];
        this.setState({errors});
    }

    onSelectNetwork = (network) => {
        let withdrawMin = parseFloat(network.withdrawMin);
        this.setState({
            selectedNetwork: network,
            minWithdraw: withdrawMin,
            showNetworkModal: false,
            networkSelect: (
                network.exchanger === 'KUCOIN' ?
                    (network.network + ' (' + network.chain + ')') : network.name
            )
        });

        this.refreshSchema(false,network);

        if (network.withdrawEnable)
            this.refreshMinAmount(network);

        var sh=this.schema;

        delete sh['network'];

        this.schema=sh;

        console.log(sh);
        // this.checkErrors();
    }

    initNetwork = (newtworkList) => {
        if (newtworkList.length) {
            let index = 0;

            const {data} = this.state;

            if (data.networkName) {
                $.each(newtworkList, function (i, item) {
                    if (item.chain === data.networkName)
                        index = i;
                });
            }

            if (index === 0)
                $.each(newtworkList, function (i, item) {
                    if (item.isDefault)
                        index = i;
                });

            // this.onSelectNetwork(newtworkList[index]);
        }
    }

    refreshSchema = (type,network = false) => {

        type=type?type:this.state.orderType;
        network=network?network:(this.state.selectedNetwork?this.state.selectedNetwork:null);

        let sh = {
            amount: Joi
                .string()
                .required()
                .label("میزان ارز"),
            wallet: Joi
                .string()
                .required()
                .label("کیف پول "),
            network: Joi
                .string()
                .required()
                .label("شبکه ")
        };

        if (network && network.addressRegex && this.state.data.wallet) {
            sh['wallet'] = Joi
                .string()
                .required()
                .regex(new RegExp(network.addressRegex))
                .label("کیف پول");
        }

        if (network && network.memoRegex && !this.state.notUseMemo) {
            sh['tag'] = Joi
                .string()
                .required()
                .label("Memo/Tag");

        } else {
            delete sh['tag'];
        }

        if (type === 'sell') {
            delete sh['tag'];
            delete sh['wallet'];
        }


        this.schema = sh;
    }

    refreshMinAmount = (network) => {
        getCryptoMinAmount(this.state.selectedCoin.id, network.chain, this.state.orderType).then(res => {
            this.setState({
                minAmount: res.data.minAmount,
            })
        });
    }

    toggleModalSubmit = () => {
        this.setState({modal_submit: !this.state.modal_submit});
    }


    handleChangeInnerTransfer = ({currentTarget: input}) => {
        const data = this.state.data;
        this.setState({hasInnerTransfer:input.checked});
    }

    handleChangeAcceptDontPayExhcnagerIrani = ({currentTarget: input}) => {
        this.setState({donPayPersianWalletPay:input.checked});
    }


    handleSubmit = e => {
        e.preventDefault();
        const errors = this.validate();

        this.setState({errors: errors || {}});

        if (errors) return;

        if(!this.state.selectedNetwork)
            return;

        this.doSubmit();
    };

    doSubmit = async () => {
        this.toggleModalSubmit();
    };

    componentDidMount() {
        if(this.state.select_from_url ){
            getCoin(this.state.select_from_url).then(res => {
                this.setState({selectedCoin:res.data.coin});
                this.getCoinData(this.state.select_from_url);
            });
        } else {
            if(this.state.edit && this.state.orderType==='buy'){
                getOrder(this.state.edit).then(res=>{
                    const order=res.data.order;
                    let data=this.state.data;
                    data['amount']=order.amount;
                    data['fk_currency_id']=order.fk_currency_id;
                    data['fk_service_id']=order.fk_service_id;
                    data['wallet']=order.meta.wallet;
                    data['tag']=order.meta.tag;
                    data['network']=order.meta.networkName;
                    data['networkName']=order.meta.networkName;
                    this.setState({data,selectedCoin:res.data.coin});
                    this.getCoinData(order.service.id,'','',true);
                });
            }
        }

    }

    doSubmitFinal=()=>{
        this.toggle();
        try {
            var {data,selectedNetwork,hasInnerTransfer} = this.state;

            data['service']=data.fk_service_id;
            data['order_type']=this.state.orderType;
            data['network']=selectedNetwork.network;
            data['networkName']=selectedNetwork.chain;

            data['meta']= {
                'amount': data.amount,
                'wallet': data.wallet,
                'tag': data.tag,
                'networkName': selectedNetwork.chain,
                'network': selectedNetwork.network,
                'confirms': selectedNetwork.confirms,
                'hasInnerTransfer':hasInnerTransfer
            };

            if(this.state.edit){
                updateOrder(this.state.edit, data).then(res => {
                    this.toggle();
                    toast.success(res.data.message);
                    this.setState({
                        redirect: '/finance/checkout/' + res.data.transaction
                    })
                }).catch(res => {
                    console.log(res);
                    this.toggle();
                });
            } else {
                create(this.state.selectedCoin.id,data).then(res => {
                    this.toggle();
                    toast.success(res.data.message);
                    if(this.state.orderType==='buy'){
                        this.setState({
                            redirect:routes.finance_checkout+res.data.transaction
                        })
                    }else {
                        this.setState({
                            redirect:routes.cash_crypt_pay+res.data.cashincome.id
                        });
                    }

                }).catch(res => {
                    this.toggle();
                });
            }


        } catch (ex) {
            this.toggle();
        }
    }

    calcFee = ()=>{
        var withdrawFee = parseFloat(this.state.selectedNetwork?.withdrawFee);
        var withdrawFeeRate = parseFloat(this.state.selectedNetwork?.withdrawFeeRate);
        var amount = parseFloat(this.state.data.amount);
        var hasInnerTransfer = this.state.hasInnerTransfer;

        var fee = 0;

        if(amount){
            fee= hasInnerTransfer ? 0:
                (withdrawFeeRate ?((amount * withdrawFeeRate) + withdrawFee) :withdrawFee)

        } else {
            if(this.state.selectedNetwork)
                fee= this.state.hasInnerTransfer?0:this.state.selectedNetwork.withdrawFee
        }

        return amount?(amount - fee):0;
    }

    render() {

        const {
            selectFirstItemFromCoinsInModal,
            edit,
            orderType,
            showCoinsModal,
            showNetworkModal,
            selectedCoin,
            selectedNetwork,
            data,
            loadingExPrice,
            totalTomanEx,
            errors,
            notUseMemo,
            minAmount,
            networkSelect,
            currency_dollar_price,
            currency_price,
            networks,
            allowNetworks,
            max_amount,
            loading,
            modal_submit,
            hasInnerTransfer
        } = this.state;

        if (this.state.redirect)
            return <Redirect to={this.state.redirect}/>

        return (
            // this.state.loadingForm?
            //     <OrderCryptoLoading/>
            //     :
                <div className="row justify-content-center mt-4">
                    <div className="col-lg-6 col-md-8">

                            <OrderTypeButtons edit={edit}
                                              orderType={orderType}
                                              changeOrderType={type => this.changeOrderType(type)}/>

                            <ModalCoins selectedCoin={selectedCoin}
                                        orderType={orderType}
                                        handleSelectCoinFromModal={(coin,exchanger) => this.handleSelectCoinFromModal(coin,exchanger)}
                                        showCoinsModal={showCoinsModal} toggleCoinsModal={this.toggleCoinsModal}/>

                            <form method="POST" onSubmit={this.handleSubmit}>
                                <div className='card card-crypto card-body calculator'>

                                <div
                                    className={"v3-flex-center loader loader-calc justify-content-center align-items-center " + (this.state.loadingForm ? 'show' : '')}>
                                    <img width="42" src="/assets/images/loading.svg" className=""/>
                                </div>

                                <SelectCoin toggleModal={this.toggleCoinsModal} coin={selectedCoin}/>
                                    <div className="help">
                                        {
                                            currency_dollar_price?
                                                <>
                                                    <div className='mb-2'>
                                                        <strong>هر</strong> {selectedCoin.name}: <strong className='ml-2 mr-2'>{formatMoneyDollar(currency_dollar_price)}$</strong>
                                                        معادل (<strong className=''>{formatMoney(currency_price)}</strong> <small>تومان</small>)
                                                    </div>
                                                </>
                                                :""
                                        }
                                    </div>

                                <Wallet data={data} errors={errors} notUseMemo={notUseMemo}
                                        selectedNetwork={selectedNetwork} orderType={orderType}
                                        handleChangeWallet={this.handleChangeWallet}/>
                                {
                                    (allowNetworks.length === 0 && data.wallet) ?
                                        <div style={{marginTop:'-12px', marginBottom:'15px'}} className='help-block text-danger '> آدرس کیف پول وارد شده اشتباه است</div>
                                        : ""
                                }

                                <Networks data={data} allowNetworks={allowNetworks} currencyprice={currency_price}
                                          dollarprice={currency_dollar_price} networkSelect={networkSelect}
                                          selectedCoin={selectedCoin} toggleNetworkModal={this.toggleNetworkModal}
                                          showNetworkModal={showNetworkModal} orderType={orderType}
                                          selectedNetwork={selectedNetwork}
                                          errors={errors}
                                          onSelectNetwork={network => this.onSelectNetwork(network)}
                                          networks={networks}/>


                                {
                                    (selectedNetwork && !selectedNetwork.withdrawEnable) ?
                                        <Alert type={'danger'}>
                                            شبکه انتقال {selectedNetwork.network} غیر فعال است و انتقال ارز میسر نمی
                                            باشد
                                        </Alert>
                                        : <>

                                            <Memo orderType={orderType} selectedNetwork={selectedNetwork} errors={errors}
                                                        handleUseMemo={this.handleUseMemo} data={data}
                                                        isChecked={notUseMemo} handleChange={this.handleChangeMemo}/>

                                            <div className={'form-group-2'}>
                                                <label>میزان ارز</label>
                                                <div className="row amounts">
                                                    <div className="col">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                            <span className="input-group-text incc"
                                                                  id="inputGroupPrepend2">{selectedCoin.symbol} </span>
                                                            </div>
                                                            <input type="text" value={data.amount} name="amount"
                                                                   onChange={this.handleChangeDollar}
                                                                   className="form-control text-center "
                                                                   placeholder=" "/>
                                                        </div>
                                                    </div>
                                                    <div className="col-1 text-center or incc">
                                                        {
                                                            loadingExPrice ?
                                                                <img src={loadingExPriceImg} height="20" width="20"/>
                                                                :
                                                                <span className="l ">یا</span>
                                                        }
                                                    </div>
                                                    <div className="col">
                                                        <div className="input-group">
                                                            <NumberFormat
                                                                value={totalTomanEx}
                                                                className="form-control ltr eng"
                                                                thousandSeparator={true}
                                                                onChange={({currentTarget: input}) => {
                                                                    this.setState({totalTomanEx: input.value});
                                                                    this.exchangePrice('toman', input.value);
                                                                }}
                                                            />

                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text incc"
                                                                      id="currency_name">تومان</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    errors['amount'] &&
                                                    <small className="font-11 error-label">{errors['amount']}</small>
                                                }
                                            </div>

                                            <p className={'mt-2 help-block'}>حداقل میزان سفارش : <span
                                                id="minAmount">{minAmount}</span></p>

                                        </>
                                }

                                {
                                    this.state.base_exchanger==='KUCOIN' && orderType==='buy'?
                                        <div style={{background:"rgba(0,0,0,0.1)",borderRadius:'5px;'}} className={'mb-3 p-2'}>

                                            <div>
                                                <label className={'m-0 p-0'}>
                                                    {
                                                        hasInnerTransfer?
                                                            <input name='hasInnerTransfer' value={hasInnerTransfer} onClick={this.handleChangeInnerTransfer} checked type='checkbox'  />
                                                            :
                                                            <input name='hasInnerTransfer' value={hasInnerTransfer} onClick={this.handleChangeInnerTransfer}  type='checkbox'  />
                                                    }
                                                    &nbsp;
                                                    آدرس کیف پول من در صرافی <strong className='ml-1 mr-1'  style={{color:'#24ae8f'}}>Kucoin</strong> است (انتقال بدون کارمزد)
                                                </label>
                                            </div>

                                        </div>
                                        :""
                                }

                                {
                                    orderType === 'buy' ?
                                        <div className="mt-2 mb-2">
                                            <div className='text-danger'>
                                                <span>خالص دریافتی شما : </span>  &nbsp;
                                                {
                                                    <span>
                                                        {this.calcFee()}
                                                    </span>
                                                }
                                                &nbsp;
                                                {
                                                    selectedCoin ? <small
                                                        className='ml-1 mr-1'>{selectedCoin.symbol}</small> : ""
                                                }

                                            </div>
                                        </div>:""
                                }

                                    <label className={'m-0 p-0 mb-3'} style={{fontWeight:"bold"}}>
                                        {
                                                <input value={hasInnerTransfer} onClick={this.handleChangeAcceptDontPayExhcnagerIrani}  type='checkbox'  />
                                        }

                                        {
                                            orderType==='buy' ?"   تایید میکنم از کافه ارز به ولت صرافی های ایرانی  رمز ارز منتقل نمی کنم.":"   تایید میکنم از  ولت صرافی های ایرانی  رمز ارز را به کافه ارز منتقل نمی کنم."
                                        }

                                    </label>

                                <div className="form-group ">
                                    <button disabled={this.state.loading || (selectedNetwork && !selectedNetwork.withdrawEnable || this.state.donPayPersianWalletPay===false)}
                                        className={'btn  btn-lg btn-block ' + (orderType === 'buy' ? "btn-success" : "btn-danger")}>
                                        <img alt={'loading'}
                                             style={{'display': this.state.loading ? 'inline-block' : 'none'}}
                                             height="20"
                                             className="ml-1"
                                             src={loadingIMG}/>
                                        <i className='fe-shopping-bag'></i> &nbsp;
                                        <span>
                                            {orderType === 'buy' ? (this.state.edit?"ویرایش سفارش":"ثبت سفارش خرید") : "ثبت سفارش فروش"}

                                        </span>
                                    </button>
                                </div>
                            </div>
                            </form>

                            <CryptoModalInfoBeforeSubmit exchanger={this.state.base_exchanger} totalFee={this.calcFee()} hasInnerTransfer={hasInnerTransfer} currency_price={currency_price} data={data}  selectedNetwork={selectedNetwork}  toggleModalSubmit={this.toggleModalSubmit} doSubmitFinal={this.doSubmitFinal} networkSelect={networkSelect}  selectedCoin={selectedCoin} orderType={orderType} loading={loading} modal_submit={modal_submit} />

                        <Alert className='mb-2 ' type={'warning'}>
                            <h5>توضیحات خرید</h5>
                            لطفاً دقت کنید که برای خرید ارز از کافه ارز، از کیف پول صرافی‌های ایرانی مانند <strong className={'text-warning'}>نوبیتکس، ولکس، رمزینکس، پیت‌پین و...</strong> استفاده نکنید.

                            <h5 className={'mt-3'}>توضیحات فروش</h5>
                            لطفاً دقت کنید که برای فروش ارز دیجیتال به کافه ارز، ارز خود را از صرافی‌های ایرانی مانند <strong className={'text-warning'}>نوبیتکس، ولکس، رمزینکس، پیت‌پین و...</strong> ارسال نکنید.

                            <p className={'mt-3'}>
                                به دلیل تحریم‌های بین‌المللی، تراکنش‌های صرافی‌های P2P ایرانی رهگیری می‌شوند و با توجه به اینکه کافه ارز از سرویس‌های خارجی برای خرید و فروش استفاده می‌کند، در صورت انتقال از ولت ایرانی، موجودی در کیف پول ریجکت یا مسدود می‌شود. پیشنهاد ما این است که ابتدا ارز خود را به کیف پول‌های معتبر شخصی مانند Trust Wallet، SafePal، MetaMask و غیره منتقل کنید و سپس از آنجا پول را به حساب ما انتقال دهید.
                            </p>
                        </Alert>
                        </div>



                </div>

        )
    }

}

const mapStateToProps = (state) => {
    return {
        app: state.app,
    }
}

export default connect(mapStateToProps)(Crypto);
