import React from 'react';
import {checkout, payRequest} from "./FinanceService";
import $ from "jquery";
import parse from "html-react-parser";
import {orderEditLink, spilitNumber} from "../../services/helpers"
import {Link, Redirect} from "react-router-dom";
import Form from "../../ubold/commons/form";
import Joi from "joi-browser";
import Voucher from "./Voucher";
import {connect} from "react-redux";
import {updateUserStorage} from "../../modules/auth/authService";
import {updateUser} from "../../actions/user";
import jwtDecode from "jwt-decode";
import {updatePageTitle} from "../../actions/breadcrumbs";
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import {convertArrayToDate, convertDateToArray, formatMoney} from "../../services/helpers";
import routes from "../../routes";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import BankCardFormField from "../user/forms/BankCardFormField";
import {hideAppLoader, showAppLoader} from "../../actions/app";
import FinanceCheckoutLoading from "../../components/loadings/FinanceCheckoutLoading";
import Alert from "../../modules/partials/Alert";
import headerImg from "../../assets/images/invoice-header.svg";
import logoArm from "../../assets/images/logo/arm.svg";
import loading from "../../assets/images/loading2.svg";
import PaymentIdsForm from "./components/PaymentIdsForm";
import {forEach} from "react-bootstrap/ElementChildren";


const headerStyle = {
    background: "#3377E8 url(" + headerImg + ")"
}

class Checkout extends Form {

    constructor(props) {
        super(props);
        this.formRef = React.createRef();

        this.state = {
            data: {
                transaction_attachment: '',
            },
            choose_pay_method: this.props.user.legal?'draft':'online',
            bank_pay_date: '',
            errors: {},
            trans_id: this.props.match.params.id,
            is_night: false,
            is_crypto: false,
            transaction: [],
            banks: [],
            redirect: '',
            loadingForm: true,
            modal: false,
            modalChoose: false,
            credits: [],
            cards_count: this.props.user.credits.length,
            use_wallet_balance:'no'
        };


        this.schema = {
            transaction_attachment: Joi.label("تصویر رسید بانکی"),
        };

        this.props.dispatch(updatePageTitle(
            '',
            [
                {
                    'title': 'تراکنش ها',
                    'link': routes.finance_transactions
                },
                {
                    'title': 'فاکتور',
                    'active': true
                }
            ]
        ));

    }

    handleChangePayDate = (value) => {
        const {data} = this.state;
        data['bank_pay_date'] = convertArrayToDate(value);
        this.setState({data});
    }

    handleUseWallet = (e) => {

        this.setState({
            use_wallet_balance: (e.target.checked ? "yes" : "no")
        })
    }
    handleChooseMethod = (e) => {

        // if (e.target.value === 'draft') {
        //     this.schema = {
        //         transaction_attachment: Joi.any().meta({swaggerType: 'file'}).required().label("تصویر بانکی"),
        //     };
        // } else {
        //     this.schema = {
        //         transaction_attachment: Joi.label("تصویر بانکی"),
        //     };
        // }

        e.target.checked = true;

        this.setState({
            choose_pay_method: e.target.value
        })
    }

    handlePayDate = (val) => {
        this.setState({
            bank_pay_date: val
        })
    }

    componentDidMount() {
        this.getInvoice();
    }

    getInvoice = () => {
        this.setState({
            loadingForm: true
        });

        checkout(this.state.trans_id).then(res => {
            var data = this.state.data;

            // if (res.data.transaction.type === 'addfund')
            //     data.choose_pay_method = 'online';
            // else
            //     data.choose_pay_method = res.data.user_can_withdraw ? 'credit' : 'online';


            this.setState({
                transaction: res.data.transaction,
                loadingForm: false,
                order: res.data.order,
                orderModel: res.data.orderModel,
                banks: res.data.banks,
                is_night: res.data.is_night,
                is_crypto: res.data.is_crypto,
                user_can_withdraw: res.data.user_can_withdraw,
                hasCreditButton: res.data.user_can_withdraw && res.data.transaction.type !== 'addfund',
                site_version: res.data.site_version,
                site_gateway: res.data.site_gateway,
                // choose_pay_method: data.choose_pay_method,
                cards_count: res.data.credits.length,
                credits: res.data.credits,
                user_balance: res.data.user_balance,
                data: data
            });

        }).catch(res => {
            console.log(res)
            this.setState({
                redirect: routes.finance_transactions
            })
        });

    }

    saveCardAndSubmitForm = () => {
        if (this.state.selectCard) {
            const data = this.state.data;
            data['cardNumber'] = this.state.selectCard;
            this.setState({data});
            this.toggleModalChoose();
            this.doSubmit();
        }
    }

    handleSubmit = e => {
        e.preventDefault();
        const errors = this.validate();
        this.setState({errors: errors || {}});

        if (errors) return;

        this.doSubmit();
    };

    doSubmit = async () => {
        try {
            var data = this.state.data;
            const cl = this;
            const order = this.state.order;
            data.choose_pay_method = this.state.choose_pay_method;

            if (data.choose_pay_method === 'online' && !this.state.selectCard) {
                this.toggleModalChoose();
            } else {
                this.props.dispatch(showAppLoader());
                this.toggle();

                data.cardNumber = this.state.selectCard;
                data.use_wallet_balance = this.state.use_wallet_balance;


                payRequest(this.state.trans_id, data).then(res => {
                    if (res.redirect_url) {
                        window.location.href = res.redirect_url;
                        return false;
                    }

                    if (cl.state.order) {

                        if (res.user) {
                            updateUserStorage(res.user);
                            this.props.dispatch(updateUser(jwtDecode(res.user)))
                        }

                        cl.setState({
                            redirect: routes.orders_show + order.id
                        })
                    } else if (res.order)
                        cl.setState({
                            redirect: routes.orders_show + res.order.id
                        })
                    else
                        this.getInvoice();

                    this.props.dispatch(hideAppLoader());

                }).catch(res => {
                    console.log(res);
                    data = res.response.data;
                    if (data && data.cardListIsEmpty) {
                        this.toggleModal()
                    }
                    this.toggle();
                    this.props.dispatch(hideAppLoader());
                });
            }

        } catch (ex) {
            this.toggle();
        }
    };

    toggleModal = () => {
        this.setState({modal: !this.state.modal});
    }


    toggleModalChoose = () => {
        if (this.state.cards_count > 0) {
            if (this.state.modalChoose)
                this.setState({modalChoose: !this.state.modalChoose, selectCard: null});
            else
                this.setState({modalChoose: !this.state.modalChoose});
        } else
            this.toggleModal();

    }

    handleSubmitSaveCard = () => {
        this.toggleModal();
        this.getInvoice();
    }

    handleChangeCard = (credit) => {
        this.setState({selectCard: credit.card_number});
    }

    render() {

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }

        const {transaction, order, orderModel} = this.state;

        const bank_pay_date = convertDateToArray(this.state.data.bank_pay_date);

        var co = 0;
        if(!this.props.user.legal){
            this.state.credits.map((credit, index) => {
                if (credit.status === 'CONFIRMED' || credit.status === 'PENDING')
                    co = co + 1;
            });

            if (co === 1 && !this.state.selectCard) {
                this.handleChangeCard(this.state.credits[0]);
            }
        }

        var invoiceFinish=false;

        if( transaction.type==='order' &&  (transaction.status === 'PAID' ||  transaction.status === 'REJECTED')){
            invoiceFinish=true;
        }

        return (
            <div className='mt-4'>
                {
                    this.state.loadingForm ?
                        <FinanceCheckoutLoading/>
                        :
                        <React.Fragment>
                            <div className="row justify-content-center">
                                <div className="col-xl-10 col-lg-11">
                                    <div className='invoice'>
                                        <div className="checkout-box mb-1 header" style={headerStyle}>
                                            <div className="logo">
                                                <div className="box">
                                                    <img src={logoArm} height={60} width={60}/>
                                                </div>
                                            </div>

                                            <div className="d-flex justify-content-between ">
                                                <div className="text-center">
                                                    {transaction.trans_status_text ?
                                                        <>
                                                            <div>وضعیت سفارش</div>
                                                            <div>
                                                                <span
                                                                    className={'badge badge-' + transaction.trans_status_text.class}>{transaction.trans_status_text.label}</span>
                                                            </div>
                                                        </>
                                                        : ''}
                                                </div>

                                                <div className='text-center d-none d-md-block'>
                                                    <h4 className="m-0 d-print-none">فاکتور سفارش</h4>
                                                    {transaction.shamsi_created_at}
                                                </div>

                                                <div className="text-center">
                                                    {order ?
                                                        <React.Fragment>
                                                            <div> شماره سفارش</div>
                                                            <div className='badge badge-dark'>#{order.id} </div>

                                                        </React.Fragment>
                                                        :
                                                        <React.Fragment>
                                                            <div>شماره تراکنش</div>
                                                            <div
                                                                className='badge badge-dark'>#{transaction.transaction_id} </div>
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" checkout-box body mb-3">
                                            <div className="row">
                                                {
                                                    !invoiceFinish &&
                                                    <div className="col-md-6">
                                                        <div className="voucher">
                                                            <div className="mb-4">
                                                                <div className="row">
                                                                    {
                                                                        order ?
                                                                            <div className="col-6">
                                                                                <Link className='button'
                                                                                      to={orderEditLink(this.state.transaction)}>
                                                                                    <svg width="25" height="24"
                                                                                         viewBox="0 0 25 24" fill="none"
                                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                                        <path
                                                                                            d="M11.6944 2H9.66987C4.60852 2 2.58398 4 2.58398 9V15C2.58398 20 4.60852 22 9.66987 22H15.7435C20.8048 22 22.8294 20 22.8294 15V13"
                                                                                            stroke="#4A517D"
                                                                                            stroke-width="1.5"
                                                                                            stroke-linecap="round"
                                                                                            stroke-linejoin="round"/>
                                                                                        <path
                                                                                            d="M16.7947 3.02025L8.81804 10.9003C8.51436 11.2003 8.21068 11.7903 8.14994 12.2203L7.71467 15.2303C7.55271 16.3203 8.33215 17.0803 9.43553 16.9303L12.4825 16.5003C12.9076 16.4403 13.5049 16.1403 13.8187 15.8403L21.7953 7.96025C23.172 6.60025 23.8199 5.02025 21.7953 3.02025C19.7708 1.02025 18.1714 1.66025 16.7947 3.02025Z"
                                                                                            stroke="#4A517D"
                                                                                            stroke-width="1.5"
                                                                                            stroke-miterlimit="10"
                                                                                            stroke-linecap="round"
                                                                                            stroke-linejoin="round"/>
                                                                                        <path
                                                                                            d="M15.6523 4.15039C16.3306 6.54039 18.2235 8.41039 20.653 9.09039"
                                                                                            stroke="#4A517D"
                                                                                            stroke-width="1.5"
                                                                                            stroke-miterlimit="10"
                                                                                            stroke-linecap="round"
                                                                                            stroke-linejoin="round"/>
                                                                                    </svg>
                                                                                    ویرایش سفارش
                                                                                </Link>

                                                                            </div>
                                                                            : ""
                                                                    }

                                                                    {
                                                                        order ?
                                                                            <div className="col-6">

                                                                                <Link className='button'
                                                                                      to={routes.orders_show + order.id}>
                                                                                    <svg width="24" height="24"
                                                                                         viewBox="0 0 24 24" fill="none"
                                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                                        <path
                                                                                            d="M15.5799 11.9999C15.5799 13.9799 13.9799 15.5799 11.9999 15.5799C10.0199 15.5799 8.41992 13.9799 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C13.9799 8.41992 15.5799 10.0199 15.5799 11.9999Z"
                                                                                            stroke="#4A517D"
                                                                                            stroke-width="1.5"
                                                                                            stroke-linecap="round"
                                                                                            stroke-linejoin="round"/>
                                                                                        <path
                                                                                            d="M11.9998 20.2697C15.5298 20.2697 18.8198 18.1897 21.1098 14.5897C22.0098 13.1797 22.0098 10.8097 21.1098 9.39973C18.8198 5.79973 15.5298 3.71973 11.9998 3.71973C8.46984 3.71973 5.17984 5.79973 2.88984 9.39973C1.98984 10.8097 1.98984 13.1797 2.88984 14.5897C5.17984 18.1897 8.46984 20.2697 11.9998 20.2697Z"
                                                                                            stroke="#4A517D"
                                                                                            stroke-width="1.5"
                                                                                            stroke-linecap="round"
                                                                                            stroke-linejoin="round"/>
                                                                                    </svg>

                                                                                    مشاهده جزییات
                                                                                </Link>

                                                                            </div>
                                                                            : ''
                                                                    }

                                                                </div>

                                                            </div>
                                                            {(transaction.type === 'order' && (transaction.status !== 'PAID' && transaction.status !== 'PENDING')) ?
                                                                <>
                                                                    <div className="title">کد تخفیف دارید ؟</div>
                                                                    <Voucher reload={this.getInvoice}
                                                                             trans_id={this.state.trans_id}/>
                                                                </>
                                                                : ''}
                                                        </div>

                                                    </div>
                                                }
                                                <div className={"col-md-"+((transaction.type==='order' && !invoiceFinish)?6:12)}>
                                                    <div className="prices">
                                                        <div
                                                            className="price head d-flex align-items-center justify-content-between">
                                                            <div className="item">
                                                                توضیحات
                                                            </div>
                                                            <div>مبلغ</div>
                                                        </div>

                                                        {transaction.type === 'order' ?
                                                            transaction.is_complete_order_pay ?
                                                                <div key={transaction.transaction_id}
                                                                     className="price d-flex align-items-center justify-content-between">
                                                                    <div
                                                                        className="item"> {transaction.description}</div>
                                                                    <div
                                                                        className="value">{spilitNumber(transaction.price)}
                                                                        <small>تومان</small></div>
                                                                </div>
                                                                :
                                                                order.prices.map((price, index) =>

                                                                    price.is_wage_no_toman ?
                                                                        <div key={index}
                                                                             className="price d-flex align-items-center justify-content-between">
                                                                            <div className="item"> {price.title}</div>
                                                                            <div
                                                                                className="value">{spilitNumber(price.amount)}  &nbsp;
                                                                                <small>{price.currency_name}</small>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div key={index}
                                                                             className="price d-flex align-items-center justify-content-between">
                                                                            <div className="item">
                                                                                {price.type === 'AMOUNT' ? order.fk_plan_id ?
                                                                                    <strong
                                                                                        className="text-danger ml-1">{order.service_title}</strong>
                                                                                    : '' : ''}

                                                                                {
                                                                                    price.type === 'DISCOUNT' ?
                                                                                        <small
                                                                                            className="text-danger">تخفیف</small> : ''
                                                                                }

                                                                                {' ' + price.title}

                                                                                {(orderModel.qty > 1 && price.type === 'AMOUNT') ?
                                                                                    <small
                                                                                        className="text-danger">({orderModel.qty} عدد)</small> : ''}
                                                                            </div>
                                                                            <div
                                                                                className="value">{spilitNumber(price.total)}
                                                                                &nbsp;
                                                                                <small>تومان</small></div>
                                                                        </div>
                                                                )

                                                            : ''}


                                                        {transaction.type === 'addfund' ?
                                                            <div key={transaction.transaction_id}
                                                                 className="price d-flex align-items-center justify-content-between">
                                                                <div className="item"> {transaction.description}</div>
                                                                <div className="value">{spilitNumber(transaction.price)}&nbsp;
                                                                    <small>تومان</small></div>
                                                            </div>
                                                            : ''}


                                                        <div
                                                            className="price total d-flex align-items-center justify-content-between">
                                                            <div className="item">
                                                                {transaction.status === 'PAID' ?
                                                                    <div className='title text-success'>
                                                                        پرداخت شده
                                                                    </div>
                                                                    :
                                                                    <div className='title'>
                                                                        مبلغ قابل پرداخت
                                                                    </div>
                                                                }

                                                            </div>
                                                            <h5 className="value">{spilitNumber(transaction.price)}
                                                                &nbsp;
                                                                <small>تومان</small></h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {
                                                (transaction.status === 'PAID' || transaction.status === 'PENDING' || transaction.status === 'REJECTED') &&  transaction.type === 'order' ?
                                                    <div
                                                        className={'mt-3 ' + (transaction.status === 'PAID' ? 'alert alert-success' : 'alert alert-info')}>
                                                        <div className="row justify-content-between align-items-center">
                                                            <div className="col-md-8">
                                                                {transaction.status === 'PAID' ? ' سفارش پرداخت شده است.' : ''}
                                                            </div>
                                                            <div className="col-md-4">
                                                                <Link to={routes.orders_show + order.id}
                                                                      className={transaction.status === 'PAID' ? 'btn btn-success btn-block' : 'btn btn-info'}>
                                                                    <i className="fe-eye"></i> &nbsp;
                                                                    مشاهده وضعیت سفارش
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ''
                                            }
                                        </div>


                                        <div className=" mb-1 w3-flex-center ">
                                            {(transaction.status === 'PAID' || transaction.status === 'PENDING' || transaction.status === 'REJECTED' || transaction.status==='WAITING_FOR_VERIFICATION') ?
                                                ''
                                                :
                                                <div className="invoice-payment card card-body">

                                                    <form onSubmit={this.handleSubmit} ref={this.formRef}>
                                                        <React.Fragment>

                                                            <div className=" mb-0">
                                                                <div className="head d-flex justify-content-between align-items-center">
                                                                    <h5>{ transaction.type==='order'?" انتخاب روش پرداخت":"عملیات پرداخت"}</h5>
                                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M18.334 5.00033V7.01699C18.334 8.33366 17.5006 9.16699 16.184 9.16699H13.334V3.34199C13.334 2.41699 14.0923 1.66699 15.0173 1.66699C15.9257 1.67533 16.759 2.04199 17.359 2.64199C17.959 3.25033 18.334 4.08366 18.334 5.00033Z" stroke="#4E5480" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        <path d="M1.66602 5.83366V17.5003C1.66602 18.192 2.44933 18.5837 2.99933 18.167L4.42435 17.1003C4.75768 16.8503 5.22435 16.8837 5.52435 17.1837L6.90766 18.5753C7.23266 18.9003 7.76603 18.9003 8.09103 18.5753L9.49103 17.1753C9.78269 16.8837 10.2494 16.8503 10.5744 17.1003L11.9994 18.167C12.5494 18.5753 13.3327 18.1837 13.3327 17.5003V3.33366C13.3327 2.41699 14.0827 1.66699 14.9993 1.66699H5.83268H4.99935C2.49935 1.66699 1.66602 3.15866 1.66602 5.00033V5.83366Z" stroke="#4E5480" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        <path d="M5 7.5H10" stroke="#4E5480" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        <path d="M5.625 10.833H9.375" stroke="#4E5480" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>

                                                                </div>
                                                                <div className="row">
                                                                    <>
                                                                        { transaction.type==='order' &&
                                                                            <div className="col-12 col-sm-4">
                                                                                <label
                                                                                    className={'choose_icon_payment radio-container ' + (this.state.user_balance < transaction.price ? "disabled" : "")}>
                                                                                    <input
                                                                                        onChange={this.handleChooseMethod}
                                                                                        type="radio"
                                                                                        value="credit"
                                                                                        checked={this.state.choose_pay_method === 'credit'}
                                                                                        className={this.state.choose_pay_method === 'credit' ? 'active' : ''}
                                                                                        name="choose_pay_method"/>

                                                                                    <div
                                                                                        className={'d-flex boxx align-items-center ' + (this.state.user_balance < transaction.price ? "disabled" : "")}>
                                                                                        <div className="check">
                                                                                            <svg width="10" height="8"
                                                                                                 viewBox="0 0 10 8"
                                                                                                 fill="none"
                                                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                                                <path
                                                                                                    d="M1.24023 4.02723L3.64313 6.40601L8.44146 1.64844"
                                                                                                    fill="#23C463"/>
                                                                                                <path
                                                                                                    d="M1.24023 4.02723L3.64313 6.40601L8.44146 1.64844"
                                                                                                    stroke="white"
                                                                                                    stroke-width="1.5"
                                                                                                    stroke-miterlimit="10"
                                                                                                    stroke-linecap="round"
                                                                                                    stroke-linejoin="round"/>
                                                                                            </svg>
                                                                                        </div>

                                                                                        <svg className="icon" width="47"
                                                                                             height="46"
                                                                                             viewBox="0 0 47 46"
                                                                                             fill="none"
                                                                                             xmlns="http://www.w3.org/2000/svg">
                                                                                            <circle cx="23.6953" cy="23"
                                                                                                    r="23"
                                                                                                    fill="#E5FFEF"/>
                                                                                            <path
                                                                                                d="M32.054 25.0663C31.494 25.613 31.174 26.3997 31.254 27.2397C31.374 28.6797 32.694 29.733 34.134 29.733H36.6673V31.3197C36.6673 34.0797 34.414 36.333 31.654 36.333H16.3473C13.5873 36.333 11.334 34.0797 11.334 31.3197V22.3464C11.334 19.5864 13.5873 17.333 16.3473 17.333H31.654C34.414 17.333 36.6673 19.5864 36.6673 22.3464V24.2664H33.974C33.2273 24.2664 32.5473 24.5597 32.054 25.0663Z"
                                                                                                stroke="#23C362"
                                                                                                stroke-width="2"
                                                                                                stroke-linecap="round"
                                                                                                stroke-linejoin="round"/>
                                                                                            <path
                                                                                                d="M11.334 23.5467V17.4535C11.334 15.8668 12.3073 14.4534 13.7873 13.8934L24.374 9.89341C26.0273 9.26675 27.8007 10.4935 27.8007 12.2668V17.3334"
                                                                                                stroke="#23C362"
                                                                                                stroke-width="2"
                                                                                                stroke-linecap="round"
                                                                                                stroke-linejoin="round"/>
                                                                                            <path
                                                                                                d="M38.0791 25.6265V28.3733C38.0791 29.1066 37.4924 29.7066 36.7457 29.7332H34.1324C32.6924 29.7332 31.3724 28.6799 31.2524 27.2399C31.1724 26.3999 31.4924 25.6132 32.0524 25.0666C32.5457 24.5599 33.2257 24.2666 33.9724 24.2666H36.7457C37.4924 24.2933 38.0791 24.8932 38.0791 25.6265Z"
                                                                                                stroke="#23C362"
                                                                                                stroke-width="2"
                                                                                                stroke-linecap="round"
                                                                                                stroke-linejoin="round"/>
                                                                                            <path d="M17.334 23H26.6673"
                                                                                                  stroke="#23C362"
                                                                                                  stroke-width="2"
                                                                                                  stroke-linecap="round"
                                                                                                  stroke-linejoin="round"/>
                                                                                        </svg>

                                                                                        <div>
                                                                                            <h4> کسر از موجودی</h4>
                                                                                            <div className={'desc'}>
                                                                                                موجودی
                                                                                                : {formatMoney(this.state.user_balance)}
                                                                                                &nbsp;
                                                                                                <small>تومان</small>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>

                                                                                </label>

                                                                            </div>
                                                                        }

                                                                        {
                                                                            !this.props.user.legal &&
                                                                            <div className={'col-12 col-sm-4'}>
                                                                                <label
                                                                                    className={'choose_icon_payment radio-container'}>
                                                                                    <input
                                                                                        onChange={this.handleChooseMethod}
                                                                                        type="radio"
                                                                                        value="online"
                                                                                        checked={this.state.choose_pay_method === 'online'}
                                                                                        className={this.state.choose_pay_method === 'online' ? 'active' : ''}
                                                                                        name="choose_pay_method"/>

                                                                                    <div
                                                                                        className='d-flex boxx align-items-center'>
                                                                                        <div className="check">
                                                                                            <svg width="10" height="8"
                                                                                                 viewBox="0 0 10 8"
                                                                                                 fill="none"
                                                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                                                <path
                                                                                                    d="M1.24023 4.02723L3.64313 6.40601L8.44146 1.64844"
                                                                                                    fill="#23C463"/>
                                                                                                <path
                                                                                                    d="M1.24023 4.02723L3.64313 6.40601L8.44146 1.64844"
                                                                                                    stroke="white"
                                                                                                    stroke-width="1.5"
                                                                                                    stroke-miterlimit="10"
                                                                                                    stroke-linecap="round"
                                                                                                    stroke-linejoin="round"/>
                                                                                            </svg>
                                                                                        </div>
                                                                                        <svg className="icon" width="47"
                                                                                             height="46"
                                                                                             viewBox="0 0 47 46"
                                                                                             fill="none"
                                                                                             xmlns="http://www.w3.org/2000/svg">
                                                                                            <circle cx="23.3906" cy="23"
                                                                                                    r="23"
                                                                                                    fill="#EFF4FE"/>
                                                                                            <path
                                                                                                d="M10.0566 18.3398H36.7233"
                                                                                                stroke="#1471EA"
                                                                                                stroke-width="2"
                                                                                                stroke-miterlimit="10"
                                                                                                stroke-linecap="round"
                                                                                                stroke-linejoin="round"/>
                                                                                            <path
                                                                                                d="M15.3906 29.0068H18.0573"
                                                                                                stroke="#1471EA"
                                                                                                stroke-width="2"
                                                                                                stroke-miterlimit="10"
                                                                                                stroke-linecap="round"
                                                                                                stroke-linejoin="round"/>
                                                                                            <path
                                                                                                d="M21.3906 29.0068H26.724"
                                                                                                stroke="#1471EA"
                                                                                                stroke-width="2"
                                                                                                stroke-miterlimit="10"
                                                                                                stroke-linecap="round"
                                                                                                stroke-linejoin="round"/>
                                                                                            <path
                                                                                                d="M15.9766 11.6729H30.79C35.5366 11.6729 36.7233 12.8462 36.7233 17.5262V28.4729C36.7233 33.1529 35.5366 34.3262 30.8033 34.3262H15.9766C11.2433 34.3395 10.0566 33.1662 10.0566 28.4862V17.5262C10.0566 12.8462 11.2433 11.6729 15.9766 11.6729Z"
                                                                                                stroke="#1471EA"
                                                                                                stroke-width="2"
                                                                                                stroke-linecap="round"
                                                                                                stroke-linejoin="round"/>
                                                                                        </svg>

                                                                                        <div>
                                                                                            <h4>پرداخت آنلاین</h4>
                                                                                            <div className={'desc'}>
                                                                                                مبالغ کمتر از 25 میلیون تومان
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </label>


                                                                            </div>
                                                                        }

                                                                        {
                                                                         transaction.type==='order' &&
                                                                        <div className={'col-12 col-sm-4'}>
                                                                            <label
                                                                                className={'choose_icon_payment radio-container'}>
                                                                                <input
                                                                                    onChange={this.handleChooseMethod}
                                                                                    type="radio"
                                                                                    value="draft"
                                                                                    checked={this.state.choose_pay_method === 'draft'}
                                                                                    className={this.state.choose_pay_method === 'draft' ? 'active' : ''}
                                                                                    name="choose_pay_method"/>

                                                                                <div
                                                                                    className='d-flex boxx align-items-center'>
                                                                                    <div className="check">
                                                                                        <svg width="10" height="8"
                                                                                             viewBox="0 0 10 8"
                                                                                             fill="none"
                                                                                             xmlns="http://www.w3.org/2000/svg">
                                                                                            <path
                                                                                                d="M1.24023 4.02723L3.64313 6.40601L8.44146 1.64844"
                                                                                                fill="#23C463"/>
                                                                                            <path
                                                                                                d="M1.24023 4.02723L3.64313 6.40601L8.44146 1.64844"
                                                                                                stroke="white"
                                                                                                stroke-width="1.5"
                                                                                                stroke-miterlimit="10"
                                                                                                stroke-linecap="round"
                                                                                                stroke-linejoin="round"/>
                                                                                        </svg>
                                                                                    </div>
                                                                                    <svg className="icon" width="47"
                                                                                         height="46"
                                                                                         viewBox="0 0 47 46"
                                                                                         fill="none"
                                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                                        <circle cx="23.3906" cy="23"
                                                                                                r="23"
                                                                                                fill="#EFF4FE"/>
                                                                                        <path
                                                                                            d="M10.0566 18.3398H36.7233"
                                                                                            stroke="#1471EA"
                                                                                            stroke-width="2"
                                                                                            stroke-miterlimit="10"
                                                                                            stroke-linecap="round"
                                                                                            stroke-linejoin="round"/>
                                                                                        <path
                                                                                            d="M15.3906 29.0068H18.0573"
                                                                                            stroke="#1471EA"
                                                                                            stroke-width="2"
                                                                                            stroke-miterlimit="10"
                                                                                            stroke-linecap="round"
                                                                                            stroke-linejoin="round"/>
                                                                                        <path
                                                                                            d="M21.3906 29.0068H26.724"
                                                                                            stroke="#1471EA"
                                                                                            stroke-width="2"
                                                                                            stroke-miterlimit="10"
                                                                                            stroke-linecap="round"
                                                                                            stroke-linejoin="round"/>
                                                                                        <path
                                                                                            d="M15.9766 11.6729H30.79C35.5366 11.6729 36.7233 12.8462 36.7233 17.5262V28.4729C36.7233 33.1529 35.5366 34.3262 30.8033 34.3262H15.9766C11.2433 34.3395 10.0566 33.1662 10.0566 28.4862V17.5262C10.0566 12.8462 11.2433 11.6729 15.9766 11.6729Z"
                                                                                            stroke="#1471EA"
                                                                                            stroke-width="2"
                                                                                            stroke-linecap="round"
                                                                                            stroke-linejoin="round"/>
                                                                                    </svg>

                                                                                    <div>
                                                                                        <h4> پرداخت (شبا-ساتنا)</h4>
                                                                                        <div className={'desc'}>
                                                                                            مبالغ بالای 25 میلیون تومان
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </label>


                                                                        </div>
                                                                        }
                                                                    </>

                                                                    {
                                                                        this.state.choose_pay_method === 'draft' &&
                                                                        (
                                                                            <div className="col-12">
                                                                                <div className='card-select-card text-center mb-4 pt-5'>
                                                                                    <h5 className={"mb-4"}>افزایش موجودی از طریق ساتنا و شبا برای مبالغ بالای 25 میلیون تومان</h5>
                                                                                    <Link className="btn btn-primary" to={routes.finance_payment_id}>+ افزایش موجودی </Link>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }

                                                                    {
                                                                        this.state.choose_pay_method === 'online' &&
                                                                        <div className="col-12">
                                                                            <div className="card-select-card">
                                                                                <h5>لطفا یکی از کارت های زیر را برای
                                                                                    پرداخت انتخاب کنید</h5>
                                                                                <br/>

                                                                                {
                                                                                    co === 0 ?
                                                                                        <Alert type={'warning'}
                                                                                               title={'توجه کنید'}>
                                                                                            برای ثبت سفارش باید یک کارت
                                                                                            بانکی معتبر و به نام <strong
                                                                                            className={'ml-1 mr-1 text-t'}>{this.props.user.name}</strong> در
                                                                                            سیستم
                                                                                            ثبت کنید

                                                                                            <div className={'mt-3'}>
                                                                                                <Link
                                                                                                    className={'btn btn-outline-warning'}
                                                                                                    to={routes.profile_bank_credits}>ثبت
                                                                                                    کارت بانکی</Link>
                                                                                            </div>
                                                                                        </Alert>
                                                                                        : ""
                                                                                }

                                                                                {this.state.credits.map((credit, index) => (
                                                                                    (credit.status === 'CONFIRMED' || credit.status === 'PENDING') ?
                                                                                        <div key={index}
                                                                                             id={'cd-' + credit.card_number}
                                                                                             onClick={() => {
                                                                                                 this.handleChangeCard(credit)
                                                                                             }}
                                                                                             className={"card-body card-credit "+(this.state.selectCard===credit.card_number?"active":"")}>

                                                                                            <div className="d-block d-md-flex align-item-center justify-content-between ">
                                                                                                <div className='d-flex align-items-center'>
                                                                                                    {credit.bank_icon ?
                                                                                                        <div
                                                                                                            className=" ">
                                                                                                            <img
                                                                                                                alt={'bank icon'}
                                                                                                                src={credit.bank_icon}
                                                                                                                height="40"
                                                                                                                width="40"/>
                                                                                                        </div>
                                                                                                        : ''}
                                                                                                    &nbsp;
                                                                                                    {credit.name}

                                                                                                    <div style={{marginRight:'7px'}}>
                                                                                                        <span className={"badge badge-" + credit.status_text.class}>
                                                                                                            {credit.status_text.label}
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="d-flex align-items-center justify-content-between">

                                                                                                    <div className="number ltr">{(credit.card_number).match(new RegExp('.{1,4}', 'g')).join("-")}</div>
                                                                                                    <div className="check">
                                                                                                        <svg width="10" height="8"
                                                                                                             viewBox="0 0 10 8"
                                                                                                             fill="none"
                                                                                                             xmlns="http://www.w3.org/2000/svg">
                                                                                                            <path
                                                                                                                d="M1.24023 4.02723L3.64313 6.40601L8.44146 1.64844"
                                                                                                                fill="#23C463"/>
                                                                                                            <path
                                                                                                                d="M1.24023 4.02723L3.64313 6.40601L8.44146 1.64844"
                                                                                                                stroke="white"
                                                                                                                stroke-width="1.5"
                                                                                                                stroke-miterlimit="10"
                                                                                                                stroke-linecap="round"
                                                                                                                stroke-linejoin="round"/>
                                                                                                        </svg>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        : ""
                                                                                ))}


                                                                                <div className='mt-4'>
                                                                                    <h6 className='text-warning'>توجه
                                                                                        کنید</h6>
                                                                                    <p>
                                                                                        تنها امکان پرداخت از کارت انتخاب
                                                                                        شده در درگاه انلاین ممکن می باشد
                                                                                        و در صورت
                                                                                        پرداخت با سایر کارت ها سفارش
                                                                                        انجام نمی شود.
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                    {
                                                                        (this.state.user_balance>0 && (this.state.user_balance <= transaction.price)) &&
                                                                        <div className="col-12">
                                                                            <label
                                                                                className="credit-gateway d-flex justify-content-between align-items-center">
                                                                                <div className='d-flex align-items-center'>
                                                                                    <input
                                                                                        onChange={this.handleUseWallet}
                                                                                        type="checkbox"
                                                                                        value="1"
                                                                                        className={this.state.use_wallet_balance === 'yes' ? 'active' : ''}
                                                                                        name="choose_pay_method"/>


                                                                                    <div className="ch">
                                                                                        <svg width="10" height="8"
                                                                                             viewBox="0 0 10 8" fill="none"
                                                                                             xmlns="http://www.w3.org/2000/svg">
                                                                                            <path
                                                                                                d="M1.24023 4.02723L3.64313 6.40601L8.44146 1.64844"
                                                                                                fill="#23C463"/>
                                                                                            <path
                                                                                                d="M1.24023 4.02723L3.64313 6.40601L8.44146 1.64844"
                                                                                                stroke="white"
                                                                                                stroke-width="1.5"
                                                                                                stroke-miterlimit="10"
                                                                                                stroke-linecap="round"
                                                                                                stroke-linejoin="round"/>
                                                                                        </svg>
                                                                                    </div>


                                                                                    <span className='text'>
                                                                                میخواهم مبلغ موجودی حسابم از مبلغ سفارش کسر شود
                                                                            </span>
                                                                                </div>

                                                                                {
                                                                                    this.state.use_wallet_balance === 'yes' &&
                                                                                    <div className={'total-text'}>
                                                                                        مبلغ
                                                                                        <strong style={{marginLeft:'1px',marginRight:'5px'}}>{formatMoney(this.state.user_balance)} </strong>
                                                                                        تومان از مبلغ سفارش
                                                                                        شما کم شد
                                                                                    </div>
                                                                                }

                                                                            </label>
                                                                        </div>

                                                                    }

                                                                    {
                                                                        this.state.choose_pay_method === 'draft' ?" " : <div className="col-12 mt-4">
                                                                            <button disabled={this.state.loading || (this.state.choose_pay_method === 'online' && !this.state.selectCard)  }
                                                                                    className='btn btn-block btn-lg btn-t btn-pay'>
                                                                                <img alt={'loading'} style={{'display': this.state.loading ? 'inline-block' : 'none'}} height="20"
                                                                                     className="ml-1"
                                                                                     src={loading}/>
                                                                                <i className="fe-shopping-cart"></i>
                                                                                &nbsp;
                                                                                <small>پرداخت مبلغ</small>
                                                                                <strong>{ this.state.use_wallet_balance === 'yes'?formatMoney(transaction.price - this.state.user_balance): formatMoney(transaction.price)}</strong>
                                                                                <small>تومان</small>
                                                                            </button>
                                                                        </div>
                                                                    }



                                                                </div>

                                                            </div>

                                                        </React.Fragment>
                                                    </form>
                                                </div>

                                            }


                                        </div>

                                    </div>
                                </div>
                            </div>
                            <Modal isOpen={this.state.modal} toggle={this.toggleModal} className='credit '>
                                <ModalHeader toggle={this.toggleModal}> ارسال کارت بانکی</ModalHeader>
                                <ModalBody>
                                    <BankCardFormField checkout={true} onAction={() => this.handleSubmitSaveCard()}/>
                                </ModalBody>
                            </Modal>

                            <Modal isOpen={this.state.modalChoose} toggle={this.toggleModalChoose} className='credit '>
                                <ModalHeader toggle={this.toggleModalChoose}>انتخاب کارت</ModalHeader>
                                <ModalBody>
                                    <h5>لطفا یکی از کارت های زیر را برای پرداخت انتخاب کنید</h5>
                                    <br/>

                                    {
                                        co === 0 ?
                                            <Alert type={'warning'} title={'توجه کنید'}>
                                                برای ثبت سفارش باید یک کارت بانکی معتبر و به نام <strong
                                                className={'ml-1 mr-1 text-t'}>{this.props.user.name}</strong> در سیستم
                                                ثبت کنید

                                                <div className={'mt-3'}>
                                                    <Link className={'btn btn-outline-warning'}
                                                          to={routes.profile_bank_credits}>ثبت کارت بانکی</Link>
                                                </div>
                                            </Alert>
                                            : ""
                                    }

                                    {this.state.credits.map((credit, index) => (
                                        (credit.status === 'CONFIRMED' || credit.status === 'PENDING') ?
                                            <div key={index} id={'cd-' + credit.card_number} onClick={() => {
                                                this.handleChangeCard(credit)
                                            }} className="card-body card-credit">
                                                <div className="d-flex align-item-center justify-content-between ">
                                                    <div className='d-flex align-items-center'>
                                                        {credit.bank_icon ?
                                                            <div className=" ">
                                                                <img alt={'bank icon'} src={credit.bank_icon}
                                                                     height="40" width="40"/>
                                                            </div>
                                                            : ''}
                                                        &nbsp;
                                                        {credit.name}
                                                    </div>
                                                    <div>
                                        <span className={"badge badge-" + credit.status_text.class}>
                                            {credit.status_text.label}
                                        </span>
                                                    </div>
                                                    <div className="number ltr">
                                                        {credit.card_number}
                                                    </div>
                                                </div>
                                            </div>
                                            : ""
                                    ))}


                                    <div className='mt-4'>
                                        <h6 className='text-warning'>توجه کنید</h6>
                                        <p>
                                            تنها امکان پرداخت از کارت انتخاب شده در درگاه انلاین ممکن می باشد و در صورت
                                            پرداخت با سایر کارت ها سفارش انجام نمی شود.
                                        </p>
                                    </div>

                                </ModalBody>
                                <ModalFooter>
                                    <button disabled={!this.state.selectCard} type="button"
                                            onClick={() => this.saveCardAndSubmitForm()} className="btn btn-primary"><i
                                        className="flaticon2-shopping-cart-1"></i>
                                        پرداخت
                                    </button>
                                </ModalFooter>
                            </Modal>

                        </React.Fragment>
                }
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        user: state.user.info,
    }
}


export default connect(mapStateToProps)(Checkout);
