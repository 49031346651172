import React from 'react';
import Squre from "../skelete/Squre";
import SkeleteLoading from "../SkeleteLoading";

class OrderServicesLoading extends React.Component {

    render(){

        return (
            <React.Fragment>
                <SkeleteLoading>
                    <div className="row">
                        <div className="col-md-6 mb-3"><Squre height={150} /></div>
                        <div className="col-md-6 mb-3"><Squre height={150} /></div>
                        <div className="col-12">
                            <div className="mb-3">
                                <div className="row">
                                    <div className="col-3">
                                        <Squre height={20} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 mb-3"><Squre height={400} /></div>
                                <div className="col-md-8 mb-3"><Squre height={250} /></div>
                            </div>
                        </div>
                    </div>
                </SkeleteLoading>
            </React.Fragment>
        );
    }
}

export default OrderServicesLoading;
