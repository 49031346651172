import React from 'react';
import Squre from "../skelete/Squre";
import SkeleteLoading from "../SkeleteLoading";

class CashShowLoading extends React.Component {

    render(){


        return (
            <React.Fragment>
                <SkeleteLoading>
                    <div className="mb-3">
                        <Squre height={100} />
                    </div>

                    <div className="mb-3">
                        <div className="row">
                            <div className="col-md-6">
                                <Squre height={300} />
                            </div>
                            <div className="col-md-6">
                                <Squre height={300} />
                            </div>
                        </div>
                    </div>
                </SkeleteLoading>
            </React.Fragment>
        );
    }
}

export default CashShowLoading;
