import React from 'react';
import {updatePageTitle} from "../../actions/breadcrumbs";
import {connect} from "react-redux";
import {checkUserInfo, withdraw} from "./FinanceService";
import {Link, Redirect} from "react-router-dom";
import FormLoader from "../../ubold/commons/FormLoader";
import Joi from "joi-browser";
import Form from "../../ubold/commons/form";
import {spilitNumber} from "../../services/helpers";
import routes from "../../routes";
import Alert from "../partials/Alert";
import NumberFormat from "react-number-format";
import {updateUserInStore} from "../user/userService";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import FinanceWithdrawFundLoading from "../../components/loadings/FinanceWithdrawFundLoading";

class FinanceWithdraw extends Form {
    constructor(props) {
        super(props);

        this.state = {
            full_verify: '',
            user_level: null,
            loadingForm: true,
            redirect: '',
            credits: {},
            data: {
                price: '',
            },
            errors: {},
            collapseOpen: false

        }

        this.schema = {
            price: Joi
                .string()
                .required()
                .label("مبلغ"),
            account: Joi
                .string()
                .required()
                .label("حساب تسویه")
        };

        this.props.dispatch(updatePageTitle(
            'برداشت وجه',
            [
                {
                    'title': 'کیف پول من',
                    'link': routes.finance
                },
                {
                    'title': 'برداشت وجه',
                    'active': true
                }
            ]
        ));
    }

    componentDidMount() {
        checkUserInfo().then(res => {
            this.setState({
                full_verify: res.data.full_verify,
                user_level: res.data.level,
                balance: res.data.balance ? res.data.balance : 0,
                credits: res.data.credits,
                loadingForm: false,
            });
        });
    }

    handleChange = ({currentTarget: input}) => {
        const errors = {...this.state.errors};
        const errorMessage = this.validateProperty(input);
        if (errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];

        const data = {...this.state.data};
        if (input.name === 'price')
            data[input.name] = spilitNumber(input.value);
        else
            data[input.name] = input.value;


        this.setState({data, errors});
    };

    doSubmit = async () => {
        try {
            var data = this.state.data;

            withdraw(data).then(res => {
                this.toggle();
                updateUserInStore(this.props.dispatch, res.user)
                this.setState({
                    redirect: routes.finance_transactions
                })
            }).catch(res => {
                this.toggle();
            });

        } catch (ex) {
            this.toggle();
        }
    };

    toggleCollapseOpen = () => {
        this.setState({collapseOpen: !this.state.collapseOpen})
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }

        const styleBorderBlance = this.state.balance > 0 ? {borderColor: 'green'} : {borderColor: 'red'};

        return (

            <React.Fragment>

                {
                    this.state.loadingForm ?
                        <FinanceWithdrawFundLoading/>
                        :
                        <React.Fragment>

                            {
                                <>
                                    <Modal isOpen={this.state.collapseOpen} toggle={this.toggleCollapseOpen}
                                           className='credit modal-lg'>
                                        <ModalHeader toggle={this.toggleCollapseOpen}>
                                            <span>به نکات زیر توجه کنید</span></ModalHeader>

                                        <ModalBody className={'modal-lg'}>
                                            <ul style={{lineHeight: '25px', listStyle: 'circle', paddingRight: '30px'}}>
                                                <li>پردازش تسویه ریالی توسط تیم حسابداری در ساعات 9 صبح الی 23
                                                    به&zwnj;صورت حواله پایا و در ساعت&zwnj;های اعلام&zwnj;شده توسط بانک
                                                    مرکزی انجام می شود.
                                                </li>
                                                <li>به&zwnj; دلیل استفاده از درگاه پرداخت آنلاین و اعمال کارمزد توسط
                                                    بانک، برای هر تراکنش بر اساس مبلغ حداقل 1000 و حداکثر مبلغ 5 هزار
                                                    تومان کسر خواهد شد.
                                                </li>
                                                <li>اگر در بازه زمانی ساعت 23 تا ساعت 10 صبح درخواست برداشت داشته باشید،
                                                    طی حواله پایا بین ساعات 12 الی 14 ظهر مبلغ را در حساب بانکی دریافت
                                                    خواهید نمود.
                                                </li>
                                                <li>اگر در بازه زمانی ساعت 10 صبح تا ساعت 12.30 ظهر درخواست برداشت داشته
                                                    باشید، طی حواله پایا بین ساعات 16 الی 18 مبلغ را در حساب بانکی
                                                    دریافت خواهید نمود.
                                                </li>
                                                <li>اگر در بازه زمانی ساعت 13 تا ساعت 23 درخواست برداشت داشته باشید، طی
                                                    حواله پایا بین 4 الی 6 صبح مبلغ را در حساب بانکی دریافت خواهید نمود.
                                                </li>
                                                <li>لطفا توجه داشته باشید حواله پایا در روزهای تعطیل از سمت بانک پردازش
                                                    نخواهد شد.
                                                </li>
                                                <li>برای تسریع در دریافت وجه حواله پایا استفاده از بانک آینده موجب تسریع
                                                    در زمان تسویه خواهد شد.
                                                </li>
                                                <li>در صورتی که طی 24 ساعت مبلغ را در حساب بانکی تان دریافت نکردید،
                                                    میتوانید با بانک خود در تماس باشید.
                                                </li>
                                                <li>تسویه موجودی حساب در صورتی که ملبغ از طریق جهت شارژ اکانت صورت گرفته
                                                    باشد، فقط به کارتی که از طریق آن اکانت شارژ شده است برگشت داده می
                                                    شود.
                                                </li>
                                                <li>در صورتی که تسویه از طریق نقد درآمد ارزی باشد، کارت بانکی که تسویه
                                                    به آن انجام می شود، میبایست در حساب کاربری شما به تایید برسد.
                                                </li>
                                            </ul>
                                        </ModalBody>
                                    </Modal>

                                    <div className="row justify-content-center align-items-center mt-4 ">

                                        <div className="col-xl-6 col-lg-8 withdraw-form">

                                            {(
                                                this.state.user_level === 'E' ?
                                                    <Alert type='danger' title={'پیام در رابطه با سطح کاربری'}>
                                                        برای درخواست برداشت وجه سطح کاربری شما باید ارتقا یابد، لطفا
                                                        برای
                                                        &nbsp;
                                                        <Link to={routes.profile_vip}>ارتقا سطح کاربری کلیک کنید</Link>
                                                    </Alert>
                                                    : ''
                                            )}


                                            {

                                                this.state.balance <= 0 ?
                                                    <div className='mt-3'>
                                                        <Alert type={'danger'} title={'توجه'}>
                                                            موجودی شما قابل برداشت نیست
                                                            <div className=' mt-3'>
                                                                <div style={styleBorderBlance}
                                                                     className="balance d-flex justify-content-between ">
                                                                    <strong>موجودی </strong>
                                                                    <div
                                                                        className={('price is-number-input ' + (this.state.balance > 0 ? 'text-success' : 'text-danger'))}>{spilitNumber(this.state.balance)}
                                                                        <small>تومان</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Alert>
                                                    </div>

                                                    :
                                                    <>
                                                        <button onClick={() => this.toggleCollapseOpen()}
                                                                className='btn btn-default btn-lg btn-block'><i
                                                            className='fe-info ml-2'></i>
                                                            توضیحات مهم نحوه تسویه
                                                        </button>
                                                        <div className="card card-body card-form mt-4">
                                                            {
                                                                this.state.user_level === 'E' ?
                                                                    <div className={'overBox'}>

                                                                    </div> : ''
                                                            }

                                                            <form method="POST" onSubmit={this.handleSubmit}>

                                                                <div className="form-group mt-3">
                                                                    <label>مبلغ درخواستی (تومان)</label>
                                                                    <NumberFormat
                                                                        value={this.state.data.price}
                                                                        className="form-control ltr eng"
                                                                        thousandSeparator={true}
                                                                        prefix={' تومان '}
                                                                        onValueChange={(values) => {
                                                                            const {value} = values;
                                                                            var data = this.state.data;
                                                                            data['price'] = value;
                                                                            this.setState({data});
                                                                        }}
                                                                    />

                                                                    {
                                                                        this.state.errors['price'] && <small
                                                                            className="font-11 error-label">{this.state.errors['price']}</small>
                                                                    }
                                                                </div>


                                                                {
                                                                    this.renderSelect('account', 'انتخاب حساب تسویه', this.state.credits)
                                                                }


                                                                {this.renderButton("ثبت درخواست", 'btn btn-t btn-lg btn-block mt-5', '')}
                                                            </form>
                                                        </div>
                                                    </>

                                            }
                                        </div>
                                    </div>

                                </>

                            }
                        </React.Fragment>

                }


            </React.Fragment>
        )
    }

}

export default connect()(FinanceWithdraw);
