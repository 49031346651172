import React from 'react';
import Form from "../../../ubold/commons/form";
import http from "../../../services/httpService";
import Joi from "joi-browser";
import $ from "jquery";
import {convertArrayToDate, convertDateToArray} from '../../../services/helpers';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker from 'react-modern-calendar-datepicker';
import "./../../../../node_modules/persianjs/persian"
import FormLoader from "../../../ubold/commons/FormLoader";
import jwtDecode from "jwt-decode";
import {updateUserInStore} from "../userService";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import ReactTooltip from "react-tooltip";


var nsureMobile=false;

class UpdateInfo extends Form {
    constructor(props) {
        super(props);

        this.state = {
            loadingForm: true,
            data: {},
            userData: {},
            info:{},
            provinces: [],
            verifications: [],
            user: this.props.user,
            cities: [],
            errors: {},
            b:null,
            loading: false,
        };

        this.schema = {
            phone: Joi.string()
                .required()
                .max(11)
                .min(11)
                .label("تلفن ثابت ")
        };


    }


    doSubmit = async () => {
        try {
            var data = this.state.data;
            http.post( '/user/update/profile?info=1', data).then(res => {
                toast.success(res.data.message);
                this.toggle();
                this.props.doAction(false);

                updateUserInStore(this.props.dispatch,res.data.user);

            }).catch(res => {
                this.toggle();
            });

        } catch (ex) {
            this.toggle();
        }
    };

    handlePhone=()=>{
        const data=this.state.data;
        if(nsureMobile){
            data['phone']='';
            nsureMobile=false;
        } else {
            data['phone']=this.props.user.mobile;
            nsureMobile=true;
        }
        this.setState({data});
    }

    render() {

        return (

                <div className="">
                    <p>لطفا ابتدا تلفن ثابت خود را ثبت کنید</p>
                    <form method="POST" onSubmit={this.handleSubmit}>

                    <div className="row justify-content-between mt-3">
                        <div className="col-xl-12 ">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {
                                                this.renderInput('phone', 'تلفن ثابت ', 'number', 'ltr', '', '', false, '', '', null)
                                            }
                                        </div>

                                    </div>

                        </div>


                        <div className="col-12">
                            {this.renderButton(<span><i className='fe-refresh-cw'></i> به روز رسانی</span>, '')}

                        </div>

                    </div>
                    </form>

                </div>

        )
    }

}


export default connect()(UpdateInfo);
