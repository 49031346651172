import React, {Component} from "react";
import Comment from "./Comment";

class Parents extends Component {
    render() {

        const {comments} = this.props;

        if(comments && comments.length >0 )
        {
            return (
                <React.Fragment>
                    {
                        comments.map(comment=> <Comment key={comment.id} comment={comment} child={true}/>)
                    }
                </React.Fragment>
            );
        }

        return '';

    }
}

export default Parents;
