import React from 'react';
import {connect} from "react-redux";
import {updatePageTitle} from "../../actions/breadcrumbs";
import Form from "../../ubold/commons/form";
import Layout from "./Layout";
import EditForm from "./forms/EditForm";


class UserEdit extends Form {
    constructor(props) {
        super(props);
        this.state = {
            loadingForm: true,
            data: {},
            info:{},
            provinces: [],
            verifications: [],
        };

        this.props.dispatch(updatePageTitle(
            'ویرایش پروفایل',
            [
                {
                    'title': 'ویرایش کاربر',
                    'active': true
                }
            ]
        ));
    }


    render() {

        return (
            <Layout>
                <EditForm  user={this.props.user} verifications={this.state.verifications}/>
            </Layout>
        )
    }

}


const mapStateToProps = appState => {
    return {
        user: appState.user.info
    }

};
export default connect(mapStateToProps)(UserEdit);
