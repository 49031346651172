import http from "./../../services/httpService";

export function bills(currentPage) {
    return http.post( '/finance/bills',{page:currentPage});
}

export function transactions(currentPage,no_pay=false) {
    return http.post( '/finance/transactions'+(no_pay?'?no_pay=1':''),{page:currentPage});
}

export function withdraw(data) {
    return http.submit('post', '/finance/withdraw',data);
}

export function checkUserInfo() {
    return http.post( '/finance/checkuser');
}

export function addFund(data) {
    return http.submit('post', '/finance/addfund',data);
}

export function checkout(id) {
    return http.post( '/finance/checkout/'+id);
}

export async function getTransaction(id) {
    return await http.submit('post', '/finance/get-transaction/'+id);
}

export async function getPaymentId() {
    return await http.submit('get', '/finance/get-paymentid');
}

export function payRequest(id,data) {
    return http.submit('post', '/finance/pay-request/'+id,data);
}

export function callback(data) {
    return http.get( '/finance/callback-bank/'+data,{headers: {
            "Access-Control-Expose-Headers": "Access-Control-*",
            "Access-Control-Allow-Headers": "Access-Control-*, Origin, X-Requested-With, Content-Type, Accept",
            'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS, HEAD',
            'Access-Control-Allow-Origin': '*',
            'Allow': 'GET, POST, PUT, DELETE, OPTIONS, HEAD'

    }});
}

export function deleteTransaction(id) {
    return http.submit('delete', '/finance/transactions/'+id);
}

export function sendVoucher(id,data) {
    return http.submit('post', '/finance/checkout/send-voucher/'+id,data);
}