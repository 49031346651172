import React from 'react';
import Squre from "../skelete/Squre";
import SkeleteLoading from "../SkeleteLoading";

class CashCryptoLoading extends React.Component {

    render(){


        return (
            <React.Fragment>
                <SkeleteLoading>
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <Squre height={500} />

                        </div>
                    </div>
                </SkeleteLoading>
            </React.Fragment>
        );
    }
}

export default CashCryptoLoading;
